<section class="form-box-container animate__animated animate__fadeIn"  *ngIf="!modal && !userNotesModal" >
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Administracion de distribuidores</h2>
        <div  class="form-box-container__header-options">                     
            <button matToolTip="crear distribuidor" class="btn btn-primary" (click)="openCreateUserModal()"><i class="fa-solid fa-user-plus"></i> <span class="description"> Agregar</span></button>
            <button matToolTip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i class="fa-solid fa-file-export"></i> <span  class="description"> Exportar</span></button>
            <!--<div class="" (click)="filterModal()" [ngClass]="{active: filter}"> <i class="fa-solid fa-filter"></i> Filtros</div>-->
            <div class="search">
                <input id="inputSearch" type="search" placeholder="Buscar" (input)="search()" [(ngModel)]="searchText">
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
      <div class="form-box__content form-box__content-h-auto">
        <div class="w100 overflow-auto">
          <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="distributorsToShowInTable">
              
            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de activación"> Activo </th>
                <td mat-cell *matCellDef="let distributor"> 
                    <mat-slide-toggle
                    [matTooltip]="toggleMessageActive(distributor.active)"
                        [checked]="isActive(distributor.active)"
                        color="primary"
                        (change)="statusChange($event,distributor)">
                    </mat-slide-toggle>   
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por email"> Email </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.email}} </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por nombre"> Razón social </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.name}} </td>
            </ng-container>
            <ng-container matColumnDef="country" >
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por país" > País </th>
              <td mat-cell *matCellDef="let distributor"> {{getCountryName(distributor.country_id)}} </td>
            </ng-container>
            <ng-container matColumnDef="document_number">
              <th mat-header-cell *matHeaderCellDef > Número de documento </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.document_number }} </td>
            </ng-container>
            <ng-container matColumnDef="contact_name">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por nombre de contacto"> Nombre de contacto </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.contact_name}} </td>
            </ng-container>
            <ng-container matColumnDef="phone_number">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por número de teléfono"> Teléfono </th>
              <td mat-cell *matCellDef="let distributor"> {{distributor.phone_number}} </td>
            </ng-container>
            <ng-container matColumnDef="complete_data">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de datos" disabled=""> Estado </th>
              <td mat-cell *matCellDef="let distributor" class="text-center"> 
                <div class="status complete" *ngIf="isDataDistributorComplete(distributor)" matTooltip="datos completos"><i class="fa-solid fa-circle-check"></i></div>
                <div class="status incomplete" *ngIf="!isDataDistributorComplete(distributor)" matTooltip="datos incompletos"><i class="fa-solid fa-circle-xmark"></i></div>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
              <td mat-cell *matCellDef="let distributor" class="text-center"> 
                <div class="actions-container">
                    <button matTooltip="Editar usuario" (click)="openUpdateDistributorModal(distributor)"><i class="fa-solid fa-pen-to-square"></i></button>
                    <button matTooltip="Eliminar usuario" (click)="deleteDistributor(distributor)"><i class="fa-solid fa-trash-can"></i></button>      
                </div>
              </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
          </table>
        </div>
            <mat-paginator  [length]="lengthPage"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="paginator($event)"
            matTooltipPosition="right"
            aria-label="Selecciona página">
            </mat-paginator>
      </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../assets/loader.svg">
    </div>
  </section>


  <section class="form-box-container animate__animated animate__fadeIn"  *ngIf="modal && !userNotesModal">
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeModal()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-user"></i></span>{{isUpdate ? 'Actualizar distribuidor' : 'Nuevo distribuidor' }}</h2>
        <div  class="form-box-container__header-options">
            <button class="btn btn-primary " (click)="userNotesModal=true;formModal=false;permissionsListModal=false; " *ngIf="isUpdate" matTooltip="Permite registrar notas sobre el usuario"><i class="fa-solid fa-book"></i> <span class="description">Notas</span></button>
        </div>
    </section>
    <form (submit)="adminDistributor($event,isUpdate)" id="registrationForm" class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content">
        
        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon class="text-info">description</mat-icon>
                  Principal
                </ng-template>
                <div class="form-box__content">
        
                    <mat-accordion multi>
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon class="text-info">description</mat-icon> Datos
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section class="form-box transparent-scroll-vertical two-panel two-panel--automatic-orientation animate__animated animate__fadeIn">
                                <div  class="form-box__content  form-box__content-shadow form-box__content-fill  transparent-scroll-vertical">
                                    <div class="form-box__content-group-fields">
                        
                                        <mat-form-field appearance="fill">
                                            <mat-label  matTooltip="Asigna el plan de acceso a la plataforma">Plan de acceso</mat-label>
                                            <mat-select  name="platform_plan_id" [(ngModel)]="newDistributor.platform_plan_id">
                                            <mat-option *ngFor="let plan of platformPlans" [value]="plan.id"  color="primary">{{plan.name}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label>País</mat-label>
                                            <mat-select name="country_id"(selectionChange)="getIdentificationDocumentTypes();getCitiesList()" [(ngModel)]="newDistributor.country_id">
                                            <mat-option *ngFor="let item of countriesList" [value]="item.id"  color="primary">{{item.name}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field id="inputCity" appearance="fill">
                                            <mat-label>Ciudad</mat-label>
                                            <mat-select (closed)="clearSearchCity()" [(ngModel)]="newDistributor.city_id"name="city_id" placeholder="Buscar...">
                                                <mat-option>
                                                <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar ciudad..." [(ngModel)]="cityToSearch" name="cityToSearch" (keyup)="searchCity()">
                                                    <mat-icon (click)="clearSearchCity()" ngxMatSelectSearchClear>close</mat-icon>
                                                </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option value="" *ngIf="newDistributor.country_id == null">-- {{newDistributor.country_id == null ? 'Seleccione primero un país' : 'Seleccione una opción' }} -- </mat-option>
                                                <mat-option *ngFor="let city of citiesListFilter" [value]="city.id" color="primary">
                                                    {{city.name}}                   
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field appearance="fill">
                                            <mat-label >Tipo de documento de identificación</mat-label>
                                            <mat-select  name="identification_document_type_id" (selectionChange)="showIdentificationDocumentType()" [(ngModel)]="newDistributor.identification_document_type_id">
                                            <mat-option value=""  color="primary">-- {{validationService.isNullOrEmpty(newDistributor.country_id)?'Seleccione primero un país':'Seleccione una opción' }} -- </mat-option>                      
                                            <mat-option *ngFor="let item of identificationDocumentTypeList" [value]="item.id"  color="primary">{{ item.name +(validationService.isNullOrEmpty(item.acronym)?"":(" - "+item.acronym)) }}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                                     
                                        <mat-form-field appearance="fill">
                                            <mat-label [matTooltip]="!validationService.isNullOrEmpty(newUserIdentificationDocumentType) && !validationService.isEmptyObject(newUserIdentificationDocumentType)?(newUserIdentificationDocumentType.name+' - '+newUserIdentificationDocumentType.acronym):''" >
                                                {{validationService.isNullOrEmpty(newUserIdentificationDocumentType) || validationService.isEmptyObject(newUserIdentificationDocumentType)?"Número documento":(newUserIdentificationDocumentType.name.length>40?newUserIdentificationDocumentType.acronym:newUserIdentificationDocumentType.name) }}
                                            </mat-label>
                                            <input matInput type="text" name="document_number" [value]="newDistributor.document_number||''">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label >Tipo de personalidad legal</mat-label>
                                            <mat-select  name="legal_personality_type"   [(ngModel)]="newDistributor.legal_personality_type">
                                            <mat-option value=""  color="primary">-- {{validationService.isNullOrEmpty(newDistributor.country_id)?'Seleccione primero un país':'Seleccione una opción' }} -- </mat-option>                      
                                            <mat-option *ngFor="let item of dataService.legal_personality_types_list" [value]="item.value"  color="primary">{{item.value}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                                                 
                                        <mat-form-field appearance="fill">
                                            <mat-label [matTooltip]="!validationService.isNullOrEmpty(newUserIdentificationDocumentType) && !validationService.isEmptyObject(newUserIdentificationDocumentType)?(newUserIdentificationDocumentType.name+' - '+newUserIdentificationDocumentType.acronym):''" >
                                                {{newDistributor.legal_personality_type==dataService.legal_personality_types.legal_person || newDistributor.legal_personality_type==dataService.legal_personality_types.natural_person_trader?"Razón social":"Nombre"}}
                                            </mat-label>
                                            <input matInput type="text" name="business_name" [value]="newDistributor.name||''">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="newDistributor.legal_personality_type==dataService.legal_personality_types.legal_person || newDistributor.legal_personality_type==dataService.legal_personality_types.natural_person_trader">
                                            <mat-label [matTooltip]="!validationService.isNullOrEmpty(newUserIdentificationDocumentType) && !validationService.isEmptyObject(newUserIdentificationDocumentType)?(newUserIdentificationDocumentType.name+' - '+newUserIdentificationDocumentType.acronym):''" >
                                                Nombre de contacto
                                            </mat-label>
                                            <input matInput type="text" name="contact_name" [(ngModel)]="newDistributor.contact_name">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label >Tratamiento</mat-label>
                                            <mat-select name="courtesy_title" [(ngModel)]="newDistributor.courtesy_title">
                                            <mat-option *ngFor="let item of dataService.courtesy_title" [value]="item.value"  color="primary">{{item.value}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="newDistributor.legal_personality_type==dataService.legal_personality_types.legal_person || newDistributor.legal_personality_type==dataService.legal_personality_types.natural_person_trader">
                                            <mat-label>Dirección</mat-label>
                                            <input matInput type="text" name="address" [value]="newDistributor.address||''">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" >
                                            <mat-label matTooltip="Campo requerido">Email</mat-label>
                                            <input matInput type="email" name="email" [(ngModel)]="newDistributor.email" required="">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label >Prefijo Telefónico</mat-label>
                                            <mat-select name="phone_prefix" [(ngModel)]="newDistributor.phone_prefix">
                                            <mat-option *ngFor="let item of dataService.phone_prefix" [value]="item.code"  color="primary">{{item.code +" - "+item.country}}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" >
                                            <mat-label >Teléfono</mat-label>
                                            <input matInput type="tel" name="phone_number" [value]="newDistributor.phone_number||''">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="isUpdate">
                                            <mat-label>Fecha ingreso</mat-label>
                                            <input matInput [matDatepicker]="picker" disabled=""  name="join_date" [value]="utils.getDate(newDistributor.created_at)">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="!isUpdate || (isUpdate && newDistributor.is_update_password)">
                                            <mat-label matTooltip="Campo requerido">Contraseña</mat-label>
                                            <input matInput autocomplete="off" required="" type="password" name="password"  [(ngModel)]="newDistributor.password">
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill" *ngIf="!isUpdate || (isUpdate && newDistributor.is_update_password)">
                                            <mat-label matTooltip="Campo requerido">Confirmar Contraseña</mat-label>
                                            <input matInput type="password"  autocomplete="off"name="password_confirmation" [(ngModel)]="newDistributor.password_confirmation" required="">
                                        </mat-form-field>
                                        <div class="form-box__content-section">
                                            <div class="form-box__content-group-fields" *ngIf="isUpdate" >
                                                <mat-checkbox  color="primary" name="check_enable_password_update" [(ngModel)]="newDistributor.is_update_password">Actualizar Contraseña</mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-box__content form-box__content-auto form-box__content-flex-basis-medium transparent-scroll-vertical  order-mobile-first">
                                    <div class="form-box__content-section">
                                        <div class="container-inputs-file container-inputs-file__column">
                                            <!-- las clases class="sign-img" class="sign-img" se estan usando para asignar los valores de imagenes a los img-->
                                            <div class="img-form img-form__bordered animate__animated animate__bounceIn"><span class="img-form__title">Logo</span>
                                                <img class="logo-img animate__animated animate__bounceIn" [matTooltip]="showMessageAboutFile(newDistributor.logo_url,'logo')" src="https://sgc.internationalgpsonline.com/logo/{{selectFile(newDistributor.logo_url,'logo')}}">
                                                <input form="registrationForm"(change)="loadFileIntoImgElement($event,'logo-img')"  class="form-control" accept="image/png,image/jpg,image/svg,image/jpeg" type="file" name="file_logo">
                                            </div>
                                            <div class="img-form img-form__bordered animate__animated animate__bounceIn"><span class="img-form__title">Fírma</span>
                                                <img class="sign-img animate__animated animate__bounceIn" [matTooltip]="showMessageAboutFile(newDistributor.sign_url,'firma')" src="https://sgc.internationalgpsonline.com/sign/{{selectFile(newDistributor.sign_url,'sign')}}">
                                                <input form="registrationForm"(change)="loadFileIntoImgElement($event,'sign-img')"  class="form-control" accept="image/png,image/jpg,image/svg,image/jpeg" type="file" name="file_sign">
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                            </section>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon class="text-success">attach_money</mat-icon> Plan de facturación
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="d-block">
                                <article class="form-box__content-section">
                                <div class="form-box__content-group-fields" >
                                    <mat-radio-group color="primary" class="fill" id="inputBillingPlanType" name="billing_plan_type" [(ngModel)]="this.billingPlanType" (change)="changeBillingPlanType($event)" aria-label="Seleccione uno de los tipos de planes de facturación">
                                        <mat-radio-button  [checked]="billingPlanType=='standardized'" value="standardized">Facturar según los planes estandarizados</mat-radio-button>
                                        <mat-radio-button  [checked]="billingPlanType=='personalized'" value="personalized">Facturar de forma personalizada</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                </article>
                                <div class="form-box__content-group-fields" >
                                    <ng-container *ngIf="billingPlanType=='personalized'">
                                        <mat-form-field appearance="fill" >
                                            <mat-label  >Precio por dispositivo</mat-label>
                                            <input matInput type="number" (mousewheel)="$event.preventDefault()" [(ngModel)]="newDistributor.personalized_plan.unit_price" [value]="newDistributor.personalized_plan.unit_price||''" name="personalized_plan[unit_price]" min="0" type="number" >
                                        </mat-form-field>
                            
                                        <mat-form-field appearance="fill">
                                            <mat-label>Tipo de moneda</mat-label>
                                            <mat-select [(ngModel)]="newDistributor.personalized_plan.monetary_unit_id" name="personalized_plan[monetary_unit_id]" [(ngModel)]="newDistributor.personalized_plan.monetary_unit_id">
                                            <mat-option *ngFor="let item of monetaryUnitsList" [value]="item.id"  color="primary">{{item.code+' - '+item.currency }}</mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                
                                        <mat-form-field appearance="fill">
                                            <mat-label>Frecuencia de pago</mat-label>
                                            <mat-select  name="personalized_plan[billing_frequency_id]" [(ngModel)]="newDistributor.personalized_plan.billing_frequency_id">
                                            <mat-option *ngFor="let item of billingFrequenciesList" [value]="item.id"  color="primary">{{item.name}} </mat-option>                      
                                            </mat-select>
                                        </mat-form-field>
                                        
                                        <mat-form-field appearance="fill" >
                                            <mat-label matTooltip="Indique el dia de cada mes el cual se realizará la facturacion" >Día de pago</mat-label>
                                            <input matInput name="personalized_plan[payday]" [(ngModel)]="newDistributor.personalized_plan.payday" [value]="newDistributor.personalized_plan.payday||''" min="1"max="31" type="number">
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                  </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon class="text-info">lock</mat-icon>
                  Permisos
                </ng-template>
                <div class="form-box__content">
                    <div class="permissions-container animate__animated animate__fadeInRight">
                        <mat-tab-group>

                            <mat-tab label="Plataforma GPS">
                                <div class="form-box__content-section">
        
                                    <table class="data-table data-table__align-content-left mt-1">
                                        <thead>
                                            <tr>
                                                <th style="text-align: left">Permiso</th>
                                                <th style="text-align: center">Ver</th>
                                                <th style="text-align: center">Editar</th>
                                                <th style="text-align: center">Eliminar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let permission of defaultGpsPlatformPermissions">
                                                <tr *ngIf="!permission.name.includes('device.') && !permission.name.includes('user.')"  class="group-main">
                                                    <td>{{permission.label}}</td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_view"[disabled]="isPermissionSelected(isUpdate,permission.name ,['edit','remove'],newDistributor.permissions ) || isPermissionDisabled(permission.name,'view')" (change)="modifySelection($event,'view')" [checked]="isPermissionSelected(isUpdate,permission.name,'view',newDistributor.permissions )"
                                                                name="perms[{{permission.name}}][view]" [(ngModel)]="newDistributor['permissions_to_update'][permission.name]['view']" type="checkbox" value="1">
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_edit" name="perms[{{permission.name}}][edit]" [(ngModel)]="newDistributor['permissions_to_update'][permission.name]['edit']"  [disabled]="isPermissionDisabled(permission.name,'edit')" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,permission.name,'edit',newDistributor.permissions )"
                                                                type="checkbox" value="1">
                                                        </div>
                                                    </td>
                                                    <td style="text-align: center">
                                                        <div class="checkbox">
                                                            <input form="registrationForm" class="perm_checkbox perm_remove" name="perms[{{permission.name}}][remove]"  [(ngModel)]="newDistributor['permissions_to_update'][permission.name]['remove']" [disabled]="isPermissionDisabled(permission.name,'remove')"(change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,permission.name,'remove',newDistributor.permissions )"
                                                                type="checkbox" value="1">
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr *ngIf="defaultGpsPlatformPermissions.length == 0">
                                                <td class="mat-cell text-center empty py-1" [attr.colspan]="4">Sin datos</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <details class="device-permissions-container" open>
                                        <summary class="cursor-pointer">Device</summary>
                                        <table class="data-table data-table__align-content-left">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: left">Permiso</th>
                                                    <th style="text-align: center">Ver</th>
                                                    <th style="text-align: center">Editar</th>
                                                    <th style="text-align: center">Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let permission of defaultGpsPlatformPermissions">
                                                    <tr *ngIf="permission.name.includes('device.')"  class="group-device collapse">
                                                        <td>{{permission.label}}</td>
                                                        <td style="text-align: center">
                                                            <div class="checkbox">
                                                                <input form="registrationForm" class="perm_checkbox perm_view"[disabled]="isPermissionSelected(isUpdate,permission.name ,['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled(permission.name,'view')" (change)="modifySelection($event,'view')" [checked]="isPermissionSelected(isUpdate,permission.name,'view',newDistributor.permissions )"
                                                                    name="perms[{{permission.name}}][view]" [(ngModel)]="newDistributor['permissions_to_update'][permission.name]['view']" type="checkbox" value="1">
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center">
                                                            <div class="checkbox">
                                                                <input form="registrationForm" class="perm_checkbox perm_edit" name="perms[{{permission.name}}][edit]" [(ngModel)]="newDistributor['permissions_to_update'][permission.name]['edit']"  [disabled]="isPermissionDisabled(permission.name,'edit')" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,permission.name,'edit',newDistributor.permissions )"
                                                                    type="checkbox" value="1">
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center">
                                                            <div class="checkbox">
                                                                <input form="registrationForm" class="perm_checkbox perm_remove" name="perms[{{permission.name}}][remove]"  [(ngModel)]="newDistributor['permissions_to_update'][permission.name]['remove']" [disabled]="isPermissionDisabled(permission.name,'remove')"(change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,permission.name,'remove',newDistributor.permissions )"
                                                                    type="checkbox" value="1">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </details>
                                    <details class="device-permissions-container">
                                        <summary class="cursor-pointer">User</summary>
                                        <table class="data-table data-table__align-content-left">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: left">Permiso</th>
                                                    <th style="text-align: center">Ver</th>
                                                    <th style="text-align: center">Editar</th>
                                                    <th style="text-align: center">Eliminar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let permission of defaultGpsPlatformPermissions">
                                                    <tr *ngIf="permission.name.includes('user.')"  class="group-device collapse">
                                                        <td>{{permission.label}}</td>
                                                        <td style="text-align: center">
                                                            <div class="checkbox">
                                                                <input form="registrationForm" class="perm_checkbox perm_view"[disabled]="isPermissionSelected(isUpdate,permission.name ,['edit','remove'],newDistributor.permissions ) ||isPermissionDisabled(permission.name,'view')" (change)="modifySelection($event,'view')" [checked]="isPermissionSelected(isUpdate,permission.name,'view',newDistributor.permissions )"
                                                                    name="perms[{{permission.name}}][view]" [(ngModel)]="newDistributor['permissions_to_update'][permission.name]['view']" type="checkbox" value="1">
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center">
                                                            <div class="checkbox">
                                                                <input form="registrationForm" class="perm_checkbox perm_edit" name="perms[{{permission.name}}][edit]" [(ngModel)]="newDistributor['permissions_to_update'][permission.name]['edit']"  [disabled]="isPermissionDisabled(permission.name,'edit')" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,permission.name,'edit',newDistributor.permissions )"
                                                                    type="checkbox" value="1">
                                                            </div>
                                                        </td>
                                                        <td style="text-align: center">
                                                            <div class="checkbox">
                                                                <input form="registrationForm" class="perm_checkbox perm_remove" name="perms[{{permission.name}}][remove]"  [(ngModel)]="newDistributor['permissions_to_update'][permission.name]['remove']" [disabled]="isPermissionDisabled(permission.name,'remove')"(change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,permission.name,'remove',newDistributor.permissions )"
                                                                    type="checkbox" value="1">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </details>
                                    <div class="loader" *ngIf="permissionLoadingStatus == LOAD_STATE.LOADING">
                                        <img src="../../../assets/loader.svg">
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="SGC" *ngIf="isUpdate && !validationService.isNullOrEmpty(newDistributor.platform_plan_id)">
                                <app-sgc-permissions [distributorId]="newDistributor.id" (closeUpdateModal)="closeModal()"></app-sgc-permissions>
                            </mat-tab>
                            <mat-tab label="Permisos otorgables" *ngIf="isUpdate && !validationService.isNullOrEmpty(newDistributor.platform_plan_id)">
                                <app-assignable-module-operations [distributorId]="newDistributor.id" (closeUpdateModal)="closeModal()"></app-assignable-module-operations>
                            </mat-tab>
                          </mat-tab-group>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div class="form-box__content-actions">
            <button type="submit" class="btn btn-primary" [disabled]="!utilsDataLoaded || loading || permissionLoadingStatus != LOAD_STATE.SUCCESS" > {{ isUpdate ? 'Actualizar' : 'Registrar' }} </button>
            <button class="btn btn-secondary"  (click)="closeModal()">cancelar</button>
        </div>
        </div>
    </form>
  </section>

<app-user-notes *ngIf="userNotesModal" [userId]="userIdToAddNote" (close)="closeNotesView()"></app-user-notes>
<div class="loader" *ngIf="loading">
    <img src="../../../../assets/loader.svg">
</div>