export enum URLLoginSectionForUserTypes {driver = "driver",user=""};

export enum RequestMethodHTTP {POST="POST",DELETE="DELETE",GET="GET",PUT="PUT"};
export enum FormControlTypes {RADIO = 1,CHECKBOX = 2,RADIO_GRID = 3,CHECKBOX_GRID = 4};
export enum AlignDataTable {LEFT="left",RIGHT = "right", CENTER= "center"};
export enum swalIcon { SUCCESS= "success", ERROR = "error", WARNING="warning", INFO = "info", QUESTION = "question"};
/**
 * Almacena los estados de observacion de una factura
 */
export enum InvoiceObservation {PENDING = "pendiente", PAID = "pagada",CANCELLED = "anulada"};

export enum PipeTypes {DATE="date",CURRENCY="currency"};

export enum DataStatesTypes {INFO = "info",WARNING = "warning", ERROR = "error", SUCCESS = "success"};

export enum TimeUnit { MINUTE="minute",HOUR="hour", DAY = 'day', WEEK = 'week', MONTH = 'month', YEAR = 'year'};

export enum DevicePlanType {STANDARDIZED="standardized", PERSONALIZED="personalized"};
/** Puntos de interrupcion de pantalla, valores dados en px
 */
export enum Breakpoints {X_SMALL=420,SMALL=576,MEDIUM=768,LARGE=992, X_LARGE=1200,XX_LARGE=1400};
export enum ChartTypeChartjs { PIE = "pie", DOUGHTNUT = "doughnut" ,LINE = "line", BAR = "bar" }; 
/** Indica los roles de usuario usados en  */
export enum USER_ROLES_ID { ADMIN=1, MANAGER=3,USER=2}

export enum CredentialType {ACCESS_TOKEN='access token',API_KEY='api key', JWT_TOKEN='jwt token',NO_AUTHENTICATION='no authentication',SECRET_KEY = 'secret key',USERNAME_AND_PASSWORD = 'username and password'};

export enum MODULE_OPERATIONS_SGC {VIEW=1, EDIT= 2, DELETE = 3, VIEW_PAYMENT_PROJECTIONS = 4, VIEW_GROUPED_DEVICES = 5}


export enum LOAD_STATE {
    
    IDLE = 'Idle',//no ha iniciado ningun actividad o proceso
    LOADING = 'Loading',
    SUCCESS = 'Success',
    ERROR = 'Error'
  }