import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BillingService } from '../../billing.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data/data.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { DialogUserDevicesByExpirationComponent } from '../../dialogs/dialog-user-devices-by-expiration/dialog-user-devices-by-expiration.component';

@Component({
  selector: 'app-device-renewal-billing',
  templateUrl: './device-renewal-billing.component.html',
  styleUrls: ['./device-renewal-billing.component.scss']
})
export class DeviceRenewalBillingComponent implements OnInit {
  user: IUserSessionData;

  loading: boolean = false;
  loadingDeviceList: boolean = false;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();


  displayedColumnsInDataSummary: String[] = ["name", "devicesExpiredTotal", "soonToDevicesExpireTotal"];

  displayedColumnsInGeneralTable: String[] = ["index", "distributorName", "imei", "deviceName", "plate_number", "last_connection", "expiration_date"];
  
  //usado para el calendario de Renovaciones
  expirationRange:FormGroup;
  devicesExpHeader:any=["distributorEmail","distributorName","plate_number","name","imei","last_connection","expiration_date"];//define las columnas que se van a exportar de los datos de dispsitivos expirados

  isDatePickerRange:boolean=true;
  devicesWithExpDate:any=[];
  devicesWithExpDateSorted:any=[];
  
  userDevicesWithExpDate:any=[];
  userDevicesWithExpDateSorted:any=[];
  distributorEmailByExpDate:string="";//almacena el email del distribuidor a cnsultar los dispositivos, en la talba de renovaciones 

  searchEmailByExpirations:string="";//para input de busqueda en vista renovaciones
  allDevicesWithExpDate:any=[];//alamcena todos los datos de dispositivos vinculados a la fecha
  loadingExpDeviceList:boolean = false;
  distributorsByTotalExpGps:any=[];
  distributorsByTotalExpGpsSorted:any=[];
  expDeviceIndexList:any=[];
  isUserExpDevicesSelected:boolean=false;
  deviceExpTableDataSource:string="soonToExpire";
  dataRenovationsLoaded=false;

  isCompleteLoadRenovations:boolean=true;

  UserEmailSelected:string="";//usado en el button de la plantilla para cuando se cargan los datos de un cliente especifico 

  isUserDevicesSelected:boolean=false;//usado en el button de la plantilla para cuando se cargan los datos de un cliente especifico 
  
  constructor(
    public utils: UtilsService,
    private api: ApiService,
    public dataService: DataService,
    private router: Router,
    public userDevicesdialog: MatDialog,
    private billingService: BillingService
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;

    const date: Date = new Date();
    this.expirationRange = new FormGroup({
      start: new FormControl(new Date(date.getFullYear(),date.getMonth(),1)),
      end: new FormControl(new Date(date.getFullYear(),(date.getMonth()+1),0)),
    });
  }
  ngOnInit(): void {
    this.getDistExpirationDataSummary(this.expirationRange.value.start,this.expirationRange.value.end);
  }
  getDistExpirationDataSummary(startDate?:any,endDate?:any,page?:any,fromInput:any=false){
    this.loading = true;
    let data="?action=getManagersExpiredDevicesQuantity&user_api_hash="+this.user.hash;
    let startDateExpDateRange=startDate;
    let endDateExpDateRange=endDate;

    if(fromInput!=false && ((startDate ===null || startDate ===false) || (endDate ===false || endDate ===null))){
      this.utils.showMsg("","No ha seleccionado un rango de fechas válida");
     this.utils.hideLoading(()=>this.loading = false);

      return;
    }
    
    if(typeof page !="undefined"){
      data += "&page="+page;
    }
    if((typeof startDate !="undefined" && typeof endDate !="undefined")&& ((startDate!==null && startDate !=false) && (endDate !=false && endDate !=null))){
      
      let dateRange=this.billingService.getDateRange(startDate,endDate);
      startDate=dateRange.start;
      endDate=dateRange.end;
      data += "&start_date="+startDate+"&end_date="+endDate;

    }
    this.api.getData("device",data,true).then((data:any)=>{
      if(data.status==1){
        this.distributorsByTotalExpGps=data.data;
        this.distributorsByTotalExpGpsSorted=data.data.slice();
        for(let index=0;index<data.data.length;index++){
          this.expDeviceIndexList[index]=false;
        }
        this.deviceExpTableDataSource="soonToExpire";
        this.dataRenovationsLoaded=true;//para no volverlos a cargar una vez se ha cargado por primera vez la vista de renovaciones
      }else{
        this.distributorsByTotalExpGps=[];
        this.distributorsByTotalExpGpsSorted=[];
      }
      this.getDevicesByExpDateRange(startDateExpDateRange,endDateExpDateRange,undefined,undefined,fromInput);
      if(!this.isCompleteLoadRenovations)
       this.utils.hideLoading(()=>this.loading = false); 
    }).catch((error)=>{console.log(error);this.utils.hideLoading(()=>this.loading = false);});
  }
  getDevicesByExpDateRange(startDate?:any,endDate?:any,userId?:any,page?:any,fromInput:any=false,selectBy:string=""){
    if(selectBy !="" && typeof userId !="undefined"){
      this.showuserDataLoading();
    }
    //selectBy hace referencia a la fuente de datos de resultado que se selecciona, sies los expirados o los prontos a expirar, es usada para cuando se consulta las de n solo cliente
    let data="?action=getDevicesByExpirationDateRange&user_api_hash="+this.user.hash;
    
    if(fromInput!=false && ((startDate ===null || startDate ===false) || (endDate ===false || endDate ===null))){
      this.utils.showMsg("","No ha seleccionado un rango de fechas válida");
     this.utils.hideLoading(()=>this.loading = false);
      return;
    }
    if(typeof page !="undefined"){
      data += "&page="+page;
    }
    if(typeof userId !="undefined"){
      data += "&user_id="+userId;
    }
    if((typeof startDate !="undefined" && typeof endDate !="undefined")&& ((startDate!==null && startDate !=false) && (endDate !=false && endDate !=null))){
      let dateRange=this.billingService.getDateRange(startDate,endDate);
      startDate=dateRange.start;
      endDate=dateRange.end;
      data += "&start_date="+startDate+"&end_date="+endDate;
    }
    this.api.getData("device",data,true).then((data:any)=>{
      if(data.status==1){
        if(typeof userId =="undefined"){
          this.allDevicesWithExpDate =data.data;
          this.devicesWithExpDate=data.data.devicesSoonToExpire.slice();
          this.devicesWithExpDateSorted=data.data.devicesSoonToExpire.slice(); 
        }else{
          if(selectBy !=""){
            switch(selectBy){
              case "expired":
                this.userDevicesWithExpDate=data.data.devicesExpired;
                this.userDevicesWithExpDateSorted=data.data.devicesExpired.slice();
                break;
              case "soonToExpire":
                this.userDevicesWithExpDate=data.data.devicesSoonToExpire;
                this.userDevicesWithExpDateSorted=data.data.devicesSoonToExpire.slice(); 
              break;
              default:
                break;
            }
            const dialogRef = this.userDevicesdialog.open(DialogUserDevicesByExpirationComponent,{data:{userDevices:this.userDevicesWithExpDate,title:(selectBy=="expired"?"expirados":"próximos a expirar")}});
            dialogRef.afterClosed().subscribe(dataExport => {
              if(dataExport){
                this.exportFile(this.userDevicesWithExpDate,["plate_number","name","imei","last_connection","expiration_date"],this.distributorEmailByExpDate);
                this.distributorEmailByExpDate="";
              }
            }); 
          }
        }
      }else{
        
        this.allDevicesWithExpDate =[];
        this.devicesWithExpDate=[];
        this.devicesWithExpDateSorted=[]; 
      
        this.userDevicesWithExpDate=[];
        this.userDevicesWithExpDateSorted=[]; 
      }
      if(fromInput){
        this.utils.hideLoading(()=>this.loadingExpDeviceList = false);
      }
     this.utils.hideLoading(()=>this.loading = false);
      
    }).catch((error)=>{console.log(error);this.utils.hideLoading(()=>this.loading = false);});

  }
  filterDistByExpRange(startDate:any,endDate:any){
    this.loading = true;
    this.isUserExpDevicesSelected=false;
    this.getDistExpirationDataSummary(startDate,endDate,undefined,true);    
  }
  loadUserDevicesByExpiration(startDate:any,endDate:any,distributorId:any,selectBy:string,distributorName:string){
    this.distributorEmailByExpDate=distributorName;
    this.getDevicesByExpDateRange(startDate,endDate,distributorId,undefined,true,selectBy);
  }
  search(dataSourceName: string){
    let dataToSearch:string="";
    if(dataSourceName =="distributorsByTotalExpGps"){
      dataToSearch= this.searchEmailByExpirations.toLowerCase();
    }
    if(dataToSearch.length >0){
        if(dataSourceName =="distributorsByTotalExpGps"){
          this.distributorsByTotalExpGpsSorted =this.distributorsByTotalExpGps.filter((user:any)=>{
            return user.email.toLowerCase().includes(dataToSearch) || user.name.toLowerCase().includes(dataToSearch);
          });
        }
    }
    else{
        if(dataSourceName =="distributorsByTotalExpGps"){
          this.distributorsByTotalExpGpsSorted =this.distributorsByTotalExpGps.slice();
        }
    }
  }
  sortData(sort: Sort,nameDataSource:string) {
    let data:any=[];
   if(nameDataSource =="distributorsByTotalExpGps"){
      data=this.distributorsByTotalExpGps.slice();
    }
    else if(nameDataSource =="devicesWithExpDate"){
      data=this.devicesWithExpDate.slice();
    }

    if (nameDataSource =="devicesWithExpDate" && (!sort.active || sort.direction === '')) {
      this.devicesWithExpDateSorted = data;
      return;
    }
    else if (nameDataSource =="distributorsByTotalExpGps" && (!sort.active || sort.direction === '')) {
      this.distributorsByTotalExpGpsSorted = data;
      return;
    }
    switch (nameDataSource) {
      case "devicesWithExpDate":
          this.devicesWithExpDateSorted =this.billingService.getDataSorted(data,sort);
        return;
      case "distributorsByTotalExpGps":
          this.distributorsByTotalExpGpsSorted =this.billingService.getDataSorted(data,sort);
        return;
      default:
        return;
    }
  }
  exportFile(source:any,sourceHeaders:any,fileName:string="export_"){
    let data= source;
    let headers=sourceHeaders;
    if(this.isUserDevicesSelected){//variable usada en la vista vinculaciones
      headers=["plate_number","name","imei","expiration_date","created_at","server_time","observation"];//los campos a exportar cuando se selecciones los datos de un usuario
      //cuando se seleccione los dispositivos de un usuario este se nombrara con la parte del nombre que tenga asignado en el email
      fileName=this.UserEmailSelected;
    }
    fileName=fileName.split("@")[0];
    console.log();
    if(data.length==0){
      this.utils.showMsg("","No hay datos para exportar");
      return;
    }
    this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
      this.loading = true;
      setTimeout(() => {
        const date=new Date(); 
        fileName=fileName+"_"+ date.getFullYear()+(this.utils.evaluateDateNumber(date.getMonth()+1))+(this.utils.evaluateDateNumber(date.getDate()))+"_"+date.getHours()+date.getMinutes()+date.getSeconds();
        this.utils.downloadFileAsCSV(data,  fileName, headers);
        this.loading = false;
        this.utils.showMsg("","Datos exportados con éxito");
      }, 2500);
    });
  }
  closeComponent(){
    this.close.emit(true);
  }
  toggleLoadExpirationTableDate(){
    let devicesExpired=this.allDevicesWithExpDate.devicesExpired; 
    let devicesSoonToExpire=this.allDevicesWithExpDate.devicesSoonToExpire;
    if( (typeof devicesSoonToExpire =="undefined"|| devicesSoonToExpire.length<=0) && this.deviceExpTableDataSource=="expired"){
      this.utils.showMsg("","No hay datos disponibles");
      return;
    }
    if( (typeof devicesExpired =="undefined"|| devicesExpired.length<=0) && this.deviceExpTableDataSource=="soonToExpire"){
      this.utils.showMsg("","No hay datos disponibles");
      return;
    }
    this.loadingExpDeviceList = true;
    let data:any=[];
    let source:string="";
    if(this.deviceExpTableDataSource=="soonToExpire"){
      data=this.allDevicesWithExpDate.devicesExpired.slice();
      source="expired";
    }else{
        data=this.allDevicesWithExpDate.devicesSoonToExpire.slice();
        source="soonToExpire";
    }
    this.devicesWithExpDate=data.slice();
    this.devicesWithExpDateSorted=data.slice();
    this.deviceExpTableDataSource=source;
    this.utils.hideLoading(()=>this.loadingExpDeviceList = false);
  }
  showuserDataLoading(){
    Swal.fire({
      title: 'Obteniendo Datos...',
      html: '<i class="fa-solid fa-clipboard-list fa-flip fa-3x"></i>',
      allowOutsideClick: false,
      timer: 900,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}