import { DevicePlanType, TimeUnit } from "../../../shared/enums/common-enums.enum"
import { IPermissionsIntgps } from "./ipermissions-intgps.model"

export enum UserSettingDisplayPaymentOptionInBilling  { BANK_QR_CODE ="bank_qr_code", PAYMENT_OPTIONS_LIST = "payment_options_list"}; 
export interface IUserIntgps{
    
        id: number,
        active: number,
        group_id: number,
        manager_id: number,
        billing_plan_id: number | null,
        map_id: number | null,
        devices_limit: number | null,
        email: string,
        phone_number: number | null,
        available_maps: number[],
        week_start_day: number,
        top_toolbar_open: number,
        unit_of_altitude: string,
        unit_of_distance: string,
        unit_of_capacity: string,
        timezone_id: number | null,
        sms_gateway: number,
        sms_gateway_url: string,
        lang: string,
        sms_gateway_app_date: string | null,
        subscription_expiration: string | null,
        loged_at: string,
        api_hash_expire: string | null,
        created_at: string,
        updated_at: string | null,
        manager?: {id: number,email: string},
}

export interface IUserSgc{
    id: number,
    created_at: string,
    updated_at: string | null,
    name: string | null,
    birth_date: string | Date | null,
    document_number: string | null,
    
    address: string | null,
    deleted: number | null,
    country_id: number | null,
    city_id: number | null,
    contact_name: string | null,

    logo_url: string | null,
    sign_url: string | null,
    
    personalized_plan_id: number | null,
    
    platform_plan_id: number | null,
    identification_document_type_id: number | null,
    courtesy_title: string | null,
    
    legal_personality_type: string | null,
    phone_prefix: string | null,
    notify_tasks: number | null,
    
    last_tasks_notification: string | null,
    bank_qr_code_filename: string | null,
    
    personalized_plan: {
        id: number | null,
        unit_price: number  | null,
        payday: number | null,
        billing_frequency_id: number | null,
        monetary_unit_id: number | null,
        disabled: null| 1|0
    },
    devices_responsible: {device:number}[],
    plan_type?: DevicePlanType,
    user_setting?: IUserSetting | null
}
export interface IUserSgcFileToUpload{    
    bank_qr_code_filename: string | null,
    bank_qr_code_file: File | null,
    temp_bank_qr_code_file: string | ArrayBuffer | null,
    delete_bank_qr_code_file: boolean,
    
    logo_url: string | null,
    temp_logo_url: string | ArrayBuffer | null,
    logo_file: File | null,
    delete_logo_url: boolean,
    
    sign_url: string | null,
    sign_file: File | null,
    temp_sign_url: string | ArrayBuffer | null,
    delete_sign_url: boolean
}
/**
 * Datos de usuario intgps y sgc
 */
export interface IUserGeneral extends IUserSgc,IUserIntgps{
    /** Campo usado a conveniencia para validar la presencia de datos en algunos campos determinados de la estructura de datos*/
    complete_data?:boolean,
    password?: string,
    password_confirmation?: string,
    permissions?: IPermissionsIntgps[]
    /** Permisos de INTGPs para actualizacion. CREAR INTERFACE PARA GESTIONARLA */
    permissions_to_update?: any,
    /**Usado en el formulario para indicar si se realizara procesode actualizacion de contraseña */
    is_update_password?: boolean
}
export interface IUserSetting{
    user_id: number,
    invoice_due_time_quantity: number | null,
    invoice_due_time_unit: TimeUnit | null,
    display_payment_option_in_billing: UserSettingDisplayPaymentOptionInBilling | null
}
export interface IBasicUserData{
    logo_url:string|File,
    sign_url:string|File,
    address:string,
    business_contact_information: IBusinessContactInformation,
    [propName:string]:any
}
export interface IUserContactInfo{
    id: number,
    email: string,
    name?: string,
    phone_number?: string
}
export interface IBusinessContactInformation{
    whatsapp: {
        country_code: string,
        number: string
      },
      social_media:ISocialMedia,
      commercial_contact_email:string,
      tracking_website_url:string
}
export interface ISocialMedia{
    facebook:string,
    twitter:string,
    instagram:string,
    youtube:string,
    linkedin:string
}

export interface IUserNote{
    id: number,
    description: string,
    user_id: number, 
    created_at: string,
    updated_at: string 
    creation_date: string
}