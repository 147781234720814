import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { DataService } from '../../core/services/data/data.service';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { IDeviceBasicData } from 'src/app/core/models/interfaces/idevice.model';
import { IApiRequestData, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { FormGroup } from '@angular/forms';

export interface IApiRequestDataForReport extends IApiRequestData{
   device_id_list?:number[],
   driver_id?:number,
   start_date:string,
   end_date:string,
   order:{
    by:string|null,
    direction:string|null
  }
}

@Injectable({
  providedIn: 'root'
})
export class PvsReportsService {
  user!:IUserSessionData;
  public readonly NUMBERING_COLUMN_HEADER:{NAME:string,LABEL:string} = {NAME:"nro.",LABEL:"#"};
  constructor(
    private api:ApiService,
    private utils:UtilsService,
    private dataService:DataService
  ) { 
    this.user = this.dataService.getData("user");
  }
  async getAvailableDevicesList(){
    let data = "?action=getBasicDeviceData&user_api_hash="+this.user.hash;    
    try{
      let devicesData:any = await this.api.getData("device",data,true);
      return devicesData;
    }catch(error:any){
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de dispositivos",false,false,"GET"));
      return {status:false};
    }
  }
  isAllDevicesSelected(devicesSelected:number[],availableDeviceList:IDeviceBasicData[],allSelectedDevices:boolean,optionSelectAllDevicesId:number):{allSelectedDevices:boolean,devicesSelected:number[]}{   
    let index = devicesSelected.findIndex((item:number)=>item == optionSelectAllDevicesId);
    let devicesSelectedCopy = devicesSelected.slice();
    if(!(devicesSelected.filter((item:number)=> item != optionSelectAllDevicesId).length == availableDeviceList.length)){
      if(index !=-1)
        devicesSelectedCopy.splice(index,1);        
      devicesSelected = devicesSelectedCopy; 
    }else{
      index == -1? devicesSelected.unshift(optionSelectAllDevicesId):null;
      devicesSelected = devicesSelectedCopy;
      allSelectedDevices = true;
    }
    return {allSelectedDevices:allSelectedDevices,devicesSelected:devicesSelected}
  }
  async getDataForDeviceSummaryQuery(action:"getRouteSummary"|"getEventsSummary",sort:ISort,availableDeviceList:IDeviceBasicData[],optionSelectAllDevicesId:number,devicesSelected:number[],dateRangeToConsult:FormGroup,dateToConsult:Date,pageSize:number,currentPage:number):Promise<{status:boolean,request_data?:IApiRequestDataForReport,available_device_list?:IDeviceBasicData[],devices_selected?:number[]}>{
    let dataTosend:IApiRequestDataForReport = { user_api_hash:this.user.hash, action:action,device_id_list:[],start_date:"",end_date:"",order:{by:null,direction:null} };
    if(sort.by!=""){
      dataTosend.order.by = sort.by;
      dataTosend.order.direction = sort.direction;
    }
    if(availableDeviceList.length<=0){
      let response = await this.getAvailableDevicesList();
      availableDeviceList = response.status? response.data:[]; 
      availableDeviceList.forEach((element:IDeviceBasicData) => { if(element.id != optionSelectAllDevicesId) {dataTosend["device_id_list"]!.push(element.id);}});
      devicesSelected = dataTosend["device_id_list"]!.slice();
      devicesSelected.unshift(optionSelectAllDevicesId);
    }else 
      devicesSelected.forEach((element:number) => { if(element != optionSelectAllDevicesId) {dataTosend["device_id_list"]!.push(element);}});
    dataTosend.device_id_list = dataTosend.device_id_list!.slice(currentPage*pageSize,currentPage*pageSize +pageSize);
    let dateRange:{start:string,end:string};
    if(devicesSelected.length <= 1){
      
      if( ((dateRangeToConsult.value.start === null || dateRangeToConsult.value.start ===false))){
        this.utils.showMsg("","No ha seleccionado un rango de fechas válida");  
        return {status:false};
      }
      dateRange = this.utils.getDateRange(dateRangeToConsult.value.start,(dateRangeToConsult.value.end ===false || dateRangeToConsult.value.end === null) ? dateRangeToConsult.value.start : dateRangeToConsult.value.end);
      dateRange.start = dateRange.start+ " 00:00:00";
      dateRange.end = dateRange.end+ " 23:59:59"; 
      if(new Date(dateRangeToConsult.value.start).getTime() > new Date(dateRange.end).getTime()){
        this.utils.showMsg("","La fecha final debe ser mayor a la fecha inicial");  
        return {status:false};
      }
    }
    else
      dateRange = {start:this.utils.getDatetime(dateToConsult).split(" ")[0]+" 00:00:00", end:this.utils.getDatetime(dateToConsult).split(" ")[0]+" 23:59:59"};
    dataTosend.start_date =dateRange.start;
    dataTosend.end_date = dateRange.end;
    return {status:true,request_data:dataTosend,available_device_list:availableDeviceList,devices_selected:devicesSelected};
  }
}
