import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import * as IWebTemplate from "./models/interfaces/iweb-template.model";
import { WebTemplate } from './models/classes/web-template.model';
import { IViewsAvailable } from "../../shared/models/interfaces/views-available";
import { WebsiteInitializationService } from './website-initialization/website-initialization.service';
import { ValidationService } from '../../core/services/validation/validation.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IResponseApi } from "../../core/models/interfaces/iresponse-api.model";
import { WebTemplatePageLabels, WebTemplatePages } from './website.types';

interface IResultFileUploadedApi{
  about_us:IResponseApi,
  our_services:IResponseApi,
  home:IResponseApi,
  contact_us:IResponseApi,
  global_image_filenames:IResponseApi
}
interface IResultFileDeletedApi extends IResponseApi{
  [propName:string] : any;
}

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {
  
  moduleId:number=14;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  permissionsDataIntgps:any=[]//permisos INTGPS
  /** Indicador si se muestra el elemento loading */
  loading:boolean=false;
  
  templateInitial:any=JSON.parse(JSON.stringify(null));
  typefaces: any =[];
  user:any=this.dataService.getData("user");
  existsData:boolean=false;//si el cliente tiene datos registrados sobre plantilla web

  webTemplate!:IWebTemplate.IWebTemplate;
  /** Almacena la lista de vistas disponibles en el componente */
  viewsAvailable:IViewsAvailable={
    basic_data:{name:"basic_data",show_view:true},
    design:{name:"design",show_view:false},  
    website_template:{name:"website_template",show_view:false}
  };
  /** El dominio base del sitio web @type {string}  */
  domainWebsite:string = "https://corporate.internationalgpsonline.com";
  /** La direccion web para acceso al sitio web del usuario @type {SafeResourceUrl} */
  userWebAddress:SafeResourceUrl ="";
  /** La direccion web como string para acceso al sitio web del usuario @type {string} */
  userWebAddressStr:string ="";
  
  constructor(
    public utils: UtilsService,
    private api: ApiService,
    public dataService: DataService,
    public validationService: ValidationService,
    private websiteInitializationService:WebsiteInitializationService,
    private sanitizer:DomSanitizer
    ) { }

    ngOnInit(): void {
      this.user=this.dataService.getData("user");
      this.webTemplate = this.websiteInitializationService.initializeWebsite(this.user.id);
      this.typefaces = this.dataService.typefaces;
      this.getWebTemplate();
    }
    getWebTemplate(){
      this.loading =true;
      this.api.getData("webTemplate","?action=getWebTemplate&user_api_hash="+this.user.hash,true).then((data: any) => {
        this.webTemplate = (data.status==1?data.data as IWebTemplate.IWebTemplate:this.websiteInitializationService.initializeWebsite(this.user.id));
        this.webTemplate = {...this.websiteInitializationService.initializeWebsite(this.user.id),...this.webTemplate}; 
        this.userWebAddressStr =(this.domainWebsite+"/"+(!this.validationService.isNullOrEmpty(this.webTemplate.url_identifier)?this.webTemplate.url_identifier:this.webTemplate.user_id))+"/"; 
        this.userWebAddress =this.defineSecureUrl(this.userWebAddressStr);
      this.utils.hideLoading((response:boolean)=>this.loading=response);
      console.log("dato obtenido",this.webTemplate);
      }).catch((error:any)=>{
        console.log(error);
        this.userWebAddress="";
        this.utils.hideLoading((response:boolean)=>this.loading=response);
        this.utils.showMsg("","No se pudo obtener datos de plantilla web<br><small>intente nuevamente. En caso de persistir el error contacte al administrador</small>");
      });
    }
    updateBasicData(basicData:IWebTemplate.IBasicWebTemplateData){
      this.webTemplate = {...this.webTemplate,...basicData};
      this.webTemplate.custom_css = basicData.custom_css;
      this.webTemplate.custom_js = basicData.custom_js;
      this.webTemplate.metadata = basicData.metadata;
      this.webTemplate.contact_us = basicData.contact_us;
      this.updateData(basicData);
    }  
    updateDesignData(designData:{design_data:IWebTemplate.IWebTemplateDesignData,website_file:IWebTemplate.IWebsiteFile}){
      this.updateData({...designData.design_data,website_file:designData.website_file},this.api.CONTENT_TYPE.FORM_DATA);
    } 
    updateWebTemplatePageData(data:{data:IWebTemplate.IWebWemplatePageDataEdition,refresh_iframe:Function}){
      this.updateData(data.data,undefined,data.refresh_iframe);
    } 
    updateData(data:IWebTemplate.IBasicWebTemplateData|IWebTemplate.IWebTemplateDesignData|IWebTemplate.IWebTemplate|IWebTemplate.IWebWemplatePageDataEdition|IWebTemplate.IWebTemplateDesignData & IWebTemplate.IWebsiteFile,contentType:string=this.api.CONTENT_TYPE.JSON,refreshIframe:Function=()=>{}){
      let dataToSend:any = {...{user_api_hash:this.user.hash,action:this.validationService.isNullOrEmpty(this.webTemplate.id)?"add":"update",id:this.webTemplate.id??null},...data};
      if(contentType==this.api.CONTENT_TYPE.FORM_DATA){
        const formData = new FormData();
        this.utils.fillFormData(formData,dataToSend,true);
        dataToSend = formData;
      }
        //console.log("UPDATE DATA",dataToSend,contentType);
      this.api.createData(dataToSend, "webTemplate",true,contentType).then((data: any) => {
        //console.log("response ",data);
        this.utils.hideLoading((response:boolean)=>this.loading=response);
        let filesmessage = "";
        let filesDeletedWithError:IResultFileDeletedApi|any = typeof data.result_files_deleted !="undefined"?data.result_files_deleted as IResultFileDeletedApi:{};
        filesmessage = Object.entries(filesDeletedWithError).length>0 && !filesDeletedWithError.status?filesDeletedWithError.message:"";
        
        let filesUploadedWithError:IResultFileUploadedApi|{} = typeof data.result_files_uploaded !="undefined"?data.result_files_uploaded as IResultFileUploadedApi:{};
        if(Object.values(filesUploadedWithError).length>0 && filesmessage!="")  
          filesmessage +="<br><hr><br>";
        Object.values(filesUploadedWithError).forEach((item:IResponseApi)=>filesmessage += " "+(!item.status?(item.message+(this.validationService.isNullOrEmpty(item.data)?"":": "+item.data)+".<br> "):""));
        
        filesmessage = (filesmessage!=""?"<br><br><small class='text-start d-block'>"+filesmessage+"</small>":"");
        if(data.status==1)
          this.utils.showResultRequest("success","","Plantilla web "+(this.validationService.isNullOrEmpty(this.webTemplate.id)?"creada":"actualizada")+" con éxito"+filesmessage);
         else 
          this.utils.showResultRequest("error","No se pudo registrar los cambios",data.message+filesmessage);
        if(data.status==1 && (this.validationService.isNullOrEmpty(this.webTemplate.id) ||this.viewsAvailable["basic_data"].show_view || this.viewsAvailable["design"].show_view))
          this.getWebTemplate();
        if(typeof refreshIframe !="undefined")
          refreshIframe();
        this.utils.hideLoading((response:boolean)=>this.loading=response);
      }).catch((err: any) => {
        console.log("error update",err);
        this.utils.hideLoading((response:boolean)=>this.loading=response);
        this.utils.showResultRequest("error","","Ha surgido un error al registrar datos de la plantilla web<br><small>intente nuevamente. En caso de persistir el error contacte al administrador</small>");
      });
    }
    defineSecureUrl(element:string){
      return (this.sanitizer.bypassSecurityTrustResourceUrl(element));
    }
}