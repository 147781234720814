import * as IwebTemplate from "../interfaces/iweb-template.model";
import { ColorManipulationService } from "../../../../core/services/color-manipulation/color-manipulation.service";
import { IBaseColors } from "src/app/shared/models/interfaces/color-palette.model";
export class WebTemplate {
constructor(){}

      //colores por defecto de la plataforma 
    static getDefaultCssCustomProperties(){
        return `
        --primary-color: #32c24d;
        --secondary-color: #0E140F;
        --tertiary-color: #0E140F;
        --accent-primary-color: #57aa66;
        --accent-secondary-color: #0E140F;
        --accent-tertiary-color: #0E140F;
        --accent-text-primary-color:#ffffff;
        --accent-text-secondary-color:#ffffff;
        --accent-text-tertiary-color:#ffffff;
        --content-form-color:#ebebeb;
        --content-box-color:#efefef;
        --scrollbar-color:#aed2ae;
        --main-header-background-color:#ffffff;
        --menu-background-color:#ffffff;
        --menu-text-color:#0E140F;
        --menu-icon-color:#808080;
        --accent--menu-item-color:#f3f3f9;
        --main-header-text-color:#0E140F;
        --text-color: #0E140F;
        --login-background-color: #ffffff;
        --title-color: #0E140F;
        --title-typography: "Prompt";
        --text-typography: "Prompt";
        --speed-transition: 0.25s;
        `;
    }
    static fillDataForHomeContent(home:IwebTemplate.IHomeContent,newData:any):IwebTemplate.IHomeContent{
        home.top.title =newData.homeTitle;
        home.top.description = newData.homeDescription;
        home.top.btn_cta = newData.homeBtnCta;

        home.middle.title = newData.middleTitle;
        home.middle.subtitle = newData.middleSubtitle;
        home.middle.content =newData.middleSectionContent.join("\n");
        
        home.bottom.title = newData.bottomTitle;
        home.bottom.subtitle = newData.bottomSubtitle;
        home.bottom.content = newData.bottomSectionContent.join("\n");
        return home;
    }
    static fillDataForAboutusContent(aboutus:IwebTemplate.IAboutusContent,newData:any):IwebTemplate.IAboutusContent{
        aboutus.title = newData.aboutusTitle;
        aboutus.description = newData.aboutUsDescription;
        aboutus.btn_cta = newData.aboutUsBtnCta;
        aboutus.corporate_identity.mission.title = newData.aboutUsMissionTitle;
        aboutus.corporate_identity.mission.description = newData.aboutUsMissionDescription;
        aboutus.corporate_identity.mission.action_button = newData.aboutUsMissionActioButton;

        aboutus.corporate_identity.vision.title = newData.aboutUsVisionTitle;
        aboutus.corporate_identity.vision.description = newData.aboutUsVisionDescription;
        aboutus.corporate_identity.vision.action_button = newData.aboutUsVisionActioButton;

        aboutus.corporate_identity.values.title = newData.aboutUsValuesTitle;
        aboutus.corporate_identity.values.description = newData.aboutUsValuesDescription;
        return aboutus;
    }
    static fillDataForOurServicesContent(ourServices:IwebTemplate.IOurServices,newData:any):IwebTemplate.IOurServices{
        ourServices.title = newData.ourServicesTitle;
        ourServices.description = newData.ourServicesDescription;
        ourServices.btn_cta = newData.ourServicesBtnCta;
        ourServices.important_data_content = newData.importantDataContent.join("\n");
        ourServices.our_services_content.about_our_services = newData.aboutOurServices.join("\n");
        ourServices.our_services_content.more_of_our_services = newData.moreOfOurServices.join("\n");
        return ourServices;
    }
    static fillDataForContactusContent(contactus:IwebTemplate.IContactusContent,newData:any):IwebTemplate.IContactusContent{
        contactus.title =newData.contactUsTitle;
        contactus.description =newData.contactUsDescription;
        contactus.additional_Information.title = newData.contactUsTitleAdditionalInformation;
        contactus.additional_Information.description = newData.contactUsDescriptionAdditionalInformation;
        
        contactus.office_hours.title = newData.contactUstitleOfficeHours;
        contactus.office_hours.content.week.title = newData.contactUsWeeklyOfficeHoursTitle;
        contactus.office_hours.content.week.content = newData.contactUsWeeklyOfficeHoursContent;
        
        contactus.office_hours.content.saturday.title = newData.contactUsSaturdayOfficeHoursTitle;
        contactus.office_hours.content.saturday.content = newData.contactUsSaturdayOfficeHoursContent;

        contactus.office_hours.content.sunday.title = newData.contactUsSundayOfficeHoursTitle;
        contactus.office_hours.content.sunday.content = newData.contactUsSundayOfficeHoursContent;

        return contactus;
    }
    static getColorPalette(colorManipulationService:ColorManipulationService,colorsList:IBaseColors,textColor:string,titleColor:string):IwebTemplate.IColorPalette{
        let ColorVariations= colorManipulationService.getColorVariations(colorsList);
        return {
            primary_color:colorsList.primary,
            dark_primary_color:ColorVariations.primary.dark,
            light_primary_color:ColorVariations.primary.light,
            contrast_primary_color:ColorVariations.primary.contrast,
            
            secondary_color:colorsList.secondary,
            dark_secondary_color:ColorVariations.secondary.dark,
            light_secondary_color:ColorVariations.secondary.light,
            contrast_secondary_color:ColorVariations.secondary.contrast,
      
            accent_color:colorsList.accent,
            dark_accent_color:ColorVariations.accent.dark,
            light_accent_color:ColorVariations.accent.light,
            contrast_accent_color:ColorVariations.accent.contrast,
            
            text_color:textColor,
            title_color:titleColor,
        };
    }
}
