import { Injectable } from '@angular/core';
import { IBaseColors, IColorPalette, IColorVariant } from '../../../shared/models/interfaces/color-palette.model';
import { ObjectInitializationService } from "../object-initialization/object-initialization.service";
@Injectable({
  providedIn: 'root'
})

export class ColorManipulationService {
  /** @description Lista de colores, predeterminados, en hexadecimal */
  readonly DEFAULT_COLORS:string[] = ["#44aa44", "#423f40", "#60E1E0", "#CEFDFF", "#508991",'#037171','#77A6B6']; 
  constructor(private objectInitializationService:ObjectInitializationService) { }
  //PARA REFCTORIZAR, USADO PARA EDICION DE COLOR DE SITIO WEB
  /**
   * @description Retorna el valor hsl indicado o el valor hsl como un string separadaos por coma
   * @param hex El valor hexadecimal a evaluar
   * @param specificItem Si solo se retorna un valor concreto de hsl
   * @param valuesOnly si solo se retorna los valores hsl o se retorna de la forma hsl(int,int,int)
   * @returns el valor hsl
   */
  hexToCssHsl(hex:string, specificItem:"h"|"s"|"l"|""="",valuesOnly:any = false) {
    let result:any = /^#?([a-fA-f\d]{2})([a-fA-f\d]{2})([a-fA-f\d]{2})$/i.exec(hex);
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    let cssString:any = '';
    r /= 255, g /= 255, b /= 255;
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h=0, s=0, l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch(max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }
    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);
    cssString = h + ',' + s + ',' + l + '';//podria colocarse tambien con el simbolo de porcentaje
    cssString = !valuesOnly ? 'hsl(' + cssString + ')' : cssString;

    if(typeof specificItem !="undefined" && specificItem !==""){
      let color:any ={
        cssString:cssString,
        h:h,
        s:s,
        l:l
      };
      cssString= parseInt(color[specificItem]);
    }
    return cssString;
  }
  /**
   * @description Retorna el valor hexadecimal de color HSL indicado
   * @param {string} hslStr cadena de valores numericos hsl "int,int,int"
   * @param {number} h valor del tono
   * @param {number} s valor de la saturación
   * @param {number} l valor de la luminosidad
   * @returns {string} el valor hexadecimal del color
   */
  hslToHex(hslStr:string|null=null,h:number=0, s:number=0, l:number=0):string {
    if(typeof hslStr !=="undefined" && hslStr !==null)
      [l,h,s] = hslStr.split(",").map((item:string)=>parseInt(item));
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = (n:any) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }
  lightness(hex:string){
    return  this.hexToCssHsl(hex,"l");
  }
  saturation(hex:string){
    let s:any = this.hexToCssHsl(hex,"s");
    return s;
  }
  private changeBrightness(hex:string,value:number,isIncrease:boolean){
    let l:any =this.hexToCssHsl(hex,"l");
    let h:any = this.hexToCssHsl(hex,"h");
    let s:any = this.hexToCssHsl(hex,"s");
    let lightness=isIncrease?(l+value):(l-value);
    lightness=lightness >100?100:(lightness<0?0:lightness);
    let cssString =this.hslToHex(undefined,h,s,lightness);
    return cssString;
  }
  lighten(hex:string,increase:number){
    return this.changeBrightness(hex,increase,true);
  }
  darken(hex:string,decrease:number){
    return this.changeBrightness(hex,decrease,false);
  }
  private changeSaturation(hex:any,value:number,isIncrease:boolean){
    let s:any = this.hexToCssHsl(hex,"s");
    let h:any = this.hexToCssHsl(hex,"h");
    let l:any =this.hexToCssHsl(hex,"l");
    let saturation=isIncrease?(s+value):(s-value);
    saturation=saturation >100?100:(saturation<0?0:saturation);
    let cssString =this.hslToHex(undefined,h,saturation,l);
    return cssString;
  }
  saturate(hex:string,increase:number){
    return this.changeSaturation(hex,increase,true);
  }
  desaturate(hex:string,decrease:number){
    return this.changeSaturation(hex,decrease,false);
  }
  //devuelve la lista de variaciones de colors primario,secundario y de acentuacion, 
  /**
   * @Retorna la lista de colores con sus variaciones
   * @param {} colorsList Los colores a evaluar
   * @param {} templateInitial 
   * @returns {}
   */
  getColorVariations(colorsList:IBaseColors):IColorPalette{
    let baseColors:IColorPalette= this.objectInitializationService.initializeColorPalette();
    let colors: { [key: string]: IColorVariant }={};
    let color: keyof IBaseColors; // Definimos color como una clave válida de IBaseColors
    for(color of Object.keys(this.objectInitializationService.initializeBaseColors()) as Array<keyof IBaseColors>){
      let colorToEvaluate:string = colorsList[color];
      let lightness:number =this.lightness(colorToEvaluate);
      colorToEvaluate = this.hexToCssHsl(colorToEvaluate,"",true).split(",").map((item:string)=>parseInt(item));
      colors[color] = {
        base : colorToEvaluate,
        dark : this.hslToHex(null,parseInt(colorToEvaluate[0]),parseInt(colorToEvaluate[1]),5),
        light : this.hslToHex(null,parseInt(colorToEvaluate[0]),parseInt(colorToEvaluate[1]),94),
        contrast : this[lightness<=55?"lighten":"darken"](colorsList[color],lightness<=55?(95 - lightness):(6 - lightness)),
      };
    }
    return { ...baseColors, ...colors };
  }
}