import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data/data.service';
import { ValidationService } from '../../core/services/validation/validation.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ICountry } from 'src/app/core/models/interfaces/country.model';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { SpecialUser, UserType } from 'src/app/shared/types/common-types.type';
import { URLLoginSectionForUserTypes } from 'src/app/shared/enums/common-enums.enum';


interface IDriverData{
  document_number:number|null,
  country_id:number|null,
  password:string|null
}
/**
 * @description Componente usado para mostrar login personalizado para usuarios diferentes a los usuarios convencionales de INTGPS
 */
@Component({
  selector: 'app-special-user-login',
  templateUrl: './special-user-login.component.html',
  styleUrls: ['./special-user-login.component.scss']//,'../../../styles.scss'
})
export class SpecialUserLoginComponent implements OnInit {

  loading:boolean = false;
  readonly USER_TYPES:UserType[] = [this.dataService.SPECIAL_USER.user_driver]; 
  selectedUserType:UserType = this.dataService.USER_TYPE.user; 

  readonly USER_TYPE_LOGIN:Record<UserType,string> = {user_driver:"driver",user:""};
  
  /** @type {IDriverData}  Los datos del usuario conductor a loguear */
  driverData:IDriverData = {document_number:null,country_id:null,password:null};
  
  countriesList: ICountry[] = [];
  /**@type { "password"|"text" } Indica como se muestra el campo de contraseña en el formulario*/
  typePassword:"password"|"text" = "password";
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService,
    private authService:AuthService,
    private validationService:ValidationService,
    private utils: UtilsService
  ){
    this.activatedRoute.params.subscribe((params:{user_type?:URLLoginSectionForUserTypes}) => {
      if(params.user_type == "driver")
        this.selectedUserType = this.dataService.SPECIAL_USER.user_driver;
      else
        this.goToMainLogin();
    });
  }
  ngOnInit(): void {
    this.getCountries();
  }
  async getCountries(): Promise<void>{
    let response:unknown = await this.dataService.getCountries();
    if(this.validationService.isResponseApi(response))
      this.countriesList = response.status ==1 ? response.data as ICountry[] : [];
  }
  goToMainLogin(){
    this.router.navigate(['/login']);
  }
  changeTypePasswordInput(){
    this.typePassword = this.authService.changeTypePasswordInput(this.typePassword)
  }
  async login():Promise<void>{
    this.loading = true;
    if(this.selectedUserType == this.dataService.SPECIAL_USER.user_driver){
      let response:unknown =  await this.authService.logInSgc(this.driverData.document_number != null ? this.driverData.document_number.toString() : "","",this.driverData.country_id,this.driverData.password??"",this.dataService.SPECIAL_USER.user_driver);
      if(this.authService.isAPIResponseLoginSgc(response) && response.status == 1){
        this.dataService.setUserTypeLogged(this.dataService.USER_TYPE.user_driver);
      }
    }
    this.utils.hideLoading(()=> this.loading = false);
  }
}