
<section class="form-box-container animate__animated animate__fadeIn" *ngIf="!isVisibleModal">

  <section class="form-box-container__header">
      <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-sitemap"></i></span>Integraciones</h2>
      <div  class="form-box-container__header-options">                     
        <mat-checkbox 
        matTooltip="Ver mis suscripciones" 
        color="primary"
        class="scale-75"
        name="filterStatus"
        value="0"
        [checked]="isServiceStatusChecked(serviceFilterOptions.subscribed)"
        (change)="changeCompleteServiceInclusion($event,serviceFilterOptions.subscribed)"
        >Mis suscripciones</mat-checkbox>
        <mat-checkbox 
        matTooltip="ver otros servicios disponibles" 
        color="primary"
        class="scale-75"
        name="filterStatus"
        value="-1"
        [checked]="isServiceStatusChecked(serviceFilterOptions.others)"
        (change)="changeCompleteServiceInclusion($event,serviceFilterOptions.others)"
        >Disponibles +</mat-checkbox>
      </div>
  </section>
  <div class="form-box two-panel two-panel--automatic-orientation flex-wrap animate__animated animate__fadeIn">
    <div class="d-none d-md-flex form-box__content form-box__content-shadow form-box__content--vertical-centered transparent-scroll-vertical">
      <article class="ilustration animate__animated animate__bounceIn">
        <dotlottie-player autoplay count="2" loop mode="normal"
        src="../../../assets/img/search-data.lottie"
        ></dotlottie-player>
        <span class="description">
          Descubre y suscribete a nuestros servicios disponibles en <strong class="text-primary">{{distributorCountry}}</strong> que ayudarán a potenciar tu negocio. <br> En este modulo encontraras herramientas tecnologicas para una gestión eficiente e integral de tus dispositivos.   
        </span>
      </article>
    </div>
    <div class="form-box__content form-box__content-auto form-box__content-flex-basis-medium transparent-scroll-vertical order-mobile-first">
      <div class="form-box__content-header form-box__content-shadow sticky-top">
        <h3 class="form-box__content-title"><strong>Lista de servicios</strong></h3>
      </div>
      <div class="form-box__content-section">
        <mat-selection-list #paymentsOptList [multiple]="false" >

          <mat-list-option *ngFor="let service of availableServicesListFiltered" (click)="selectOptionToShow(service)"  [value]="service.id">
              <div class="d-flex flex-row justify-content-between align-items-center">
                <span  class="d-flex flex-row justify-content-start align-items-center gap-1"><img src="{{webServiceImagePath.path+service.id+webServiceImagePath.type}}" class="avatar avatar-sm"> <span>{{service.name}}</span></span>
                <div class="d-flex flex-row justify-content-end align-items-center gap-2">
                  <!--<mat-checkbox  
                  (click)="$event.stopPropagation()"
                  matTooltipPosition="left"
                  matTooltip="{{service.name}}" 
                  color="primary"
                  ></mat-checkbox>-->
                  <button 
                  *ngIf="isServiceEnabled(service.module_id) && (service['require_authentication'] != requireAuthentication.no_authentication) && (utils.isNullOrEmpty(service['url_external']) || service['url_external']=='')"
                  class="btn btn-outline-gray"
                  matTooltip="Actualizar credenciales"
                  matTooltipPosition="left"
                  (click)="changeCredential(service);$event.stopPropagation()">
                    <i class="fa-solid fa-key"></i>
                  </button>
                </div>
            </div>
          </mat-list-option>  
                  
          <mat-list-option *ngIf="availableServicesListFiltered.length==0" class="empty">
            Sin datos
          </mat-list-option>
          <div class="loader" *ngIf="loadingServiceList">
            <img src="../../../assets/loader.svg">
        </div>
        </mat-selection-list>
      </div>
    </div>
  </div>
  <div class="loader" *ngIf="loading">
      <img src="../../../assets/loader.svg">
  </div>
</section>
<ng-container *ngIf="isVisibleModal && !utils.isNullOrEmpty(serviceSelected) && serviceSelected.id==WEB_SERVICES.CONTROL_T">
  <app-controlt [data]="serviceSelected" [webServiceId]="serviceSelected.id" (closeModal)="closeModal($event)"></app-controlt>
</ng-container >

<ng-container *ngIf="isVisibleModal && !utils.isNullOrEmpty(serviceSelected) && serviceSelected.id==WEB_SERVICES.ATU">
  <app-atu-web-service [data]="serviceSelected" [webServiceId]="serviceSelected.id" (closeModal)="closeModal($event)"></app-atu-web-service>
</ng-container >

<ng-container *ngIf="isVisibleModal && !utils.isNullOrEmpty(serviceSelected) && serviceSelected.id==WEB_SERVICES.PEGASO">
  <app-pegaso [data]="serviceSelected" [webServiceId]="serviceSelected.id" (closeModal)="closeModal($event)"></app-pegaso>
</ng-container >

<ng-container *ngIf="isVisibleModal && !utils.isNullOrEmpty(serviceSelected) && serviceSelected.id==WEB_SERVICES.CASERONES_OWL">
  <app-caserones-owl-web-service [data]="serviceSelected" [webServiceId]="serviceSelected.id" (closeModal)="closeModal($event)"></app-caserones-owl-web-service>
</ng-container >

<ng-container *ngIf="isVisibleModal && !utils.isNullOrEmpty(serviceSelected) && serviceSelected.id==WEB_SERVICES.WIALON_IPS_TRANSMISSION">
  <app-wialon-ips-web-service [data]="serviceSelected" [webServiceId]="serviceSelected.id" (closeModal)="closeModal($event)"></app-wialon-ips-web-service>
</ng-container >
<ng-container  *ngIf="isVisibleModal && !utils.isNullOrEmpty(serviceSelected) && serviceSelected.id==WEB_SERVICES.BTRAK">
  <app-btrak-web-service [data]="serviceSelected" [webServiceId]="serviceSelected.id" (closeModal)="closeModal($event)"></app-btrak-web-service>
</ng-container>