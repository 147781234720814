<h2 class="border-bottom-secondary" mat-dialog-title *ngIf="viewsAvailable['invoice_record'].show_view">{{(requestedAction==invoiceRecordComponentActions.APPLY?'Abonar':'Edición de registro')}}  </h2>
<mat-dialog-content class="form-box-container">

    <ng-container *ngIf="viewsAvailable['invoice_record'].show_view">
        <div class="form-box bg-transparent animate__animated animate__fadeIn">
            <div class="form-box__content form-box__content-h-auto">
                <div class="form-box__content-header">
                    <div class="form-box__content-title sticky-top d-flex gap-2 justify-content-between flex-wrap">
                        <span><strong>Cliente: </strong> {{ selectedInvoice.client_name |titlecase}}</span>
                        <small>{{'Factura #'+selectedInvoice.id}}</small>
                    </div>
                    <div class="form-box__content-subtitle d-flex justify-content-between flex-wrap" *ngIf="!actionOnInvoice">
                        <span>{{ (selectedDetail.device_plate_number || selectedDetail.device_name || selectedDetail.device_imei || '') | titlecase}}</span>
                        <small>
                            {{(!actionOnInvoice?", Detalle #"+selectedDetail.id:"")}}
                        </small>
                    </div>
                </div>
                <mat-accordion multi>
                    <mat-expansion-panel [expanded]="requestedAction != invoiceRecordComponentActions.APPLY"  *ngIf="actionOnInvoice">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-success">request_quote</mat-icon> Facturación
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <article class="form-box__content-section">
                            <div class="form-box__content-group-fields form-box__content-group-fields--min-width-x-small">
                                <mat-form-field appearance="standard">
                                    <mat-label>Generada el: </mat-label>
                                    <input type="text" [value]="selectedInvoice.created_at" name="invoice_created_at" matInput readonly="">
                                    <mat-icon matSuffix>today</mat-icon>
                                </mat-form-field>
                                <mat-form-field appearance="standard" floatLabel="always">
                                    <mat-label>Fecha de pago </mat-label>
                                    <input [value]="selectedInvoice.payment_date" name="invoice_payment_date" matInput readonly="">
                                    <mat-icon matSuffix>today</mat-icon>
                                    <mat-hint align="start">Se Asigna automáticamente una vez se confirme el pago</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="fill" class="fill">
                                    <mat-label>Fecha de vencimiento</mat-label>
                                    <input matInput  [min]="minDateExpirationDate" [value]="invoiceExpirationDate" (dateInput)="setInvoiceExpirationDate($event)"[matDatepicker]="dExpirationDate" readonly="">
                                    <mat-datepicker-toggle matSuffix [for]="dExpirationDate"></mat-datepicker-toggle>
                                    <mat-datepicker  touchUi #dExpirationDate [disabled]="requestedAction != invoiceRecordComponentActions.EDIT"></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="fill" floatLabel="always"  class="mat-number-spinner">
                                    <mat-label>Tiempo de vencimiento de la factura</mat-label>
                                    <input matInput type="text" placeholder="0"  spellcheck="false" [(ngModel)]="invoiceSelectedDueTime.quantity" readonly="">
                                    <span  matRipple matPrefix *ngIf="requestedAction == invoiceRecordComponentActions.EDIT" (mousedown)="intervalChangeExpirationTimeAmount(false)" (mouseup)="clearIntervalChangeExpirationTimeAmount()" (click)="$event.stopPropagation();changeExpirationTimeAmount(false)"  class="mat-number-spinner__button mat-number-spinner__button--decrement">-</span>
                                    <span  matRipple matSuffix *ngIf="requestedAction == invoiceRecordComponentActions.EDIT" (mousedown)="intervalChangeExpirationTimeAmount(true)" (mouseup)="clearIntervalChangeExpirationTimeAmount()" (click)="$event.stopPropagation();changeExpirationTimeAmount(true)" class="mat-number-spinner__button mat-number-spinner__button--increment">+</span>
                                    <mat-hint align="start">Indica la cantidad de tiempo</mat-hint>
                                </mat-form-field>
                                
                                <mat-form-field appearance="fill">
                                    <mat-label>Unidad de tiempo</mat-label>
                                    <mat-select name="timeUnit" [disabled]="requestedAction != invoiceRecordComponentActions.EDIT" [(ngModel)]="invoiceSelectedDueTime.time_unit" (selectionChange)="calculateExpirationDate()">
                                      <mat-option *ngFor="let item of timeUnitArray" [value]="item.value">
                                        {{item.label |titlecase}}
                                      </mat-option>
                                    </mat-select>
                                    <mat-hint align="start">La unidad de tiempo para calcular la fecha de vencimiento</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="fill" floatLabel="always">
                                    <mat-label>Total a pagar</mat-label>
                                    <input matInput type="text" placeholder="0"  spellcheck="false" value="{{selectedInvoice.total_price | currency:InvoicemonetaryUnit}}" readonly="">
                                    <span matPrefix>$&nbsp;</span>
                                </mat-form-field>
                                <mat-form-field appearance="fill" floatLabel="always">
                                    <mat-label>Total abonado</mat-label>
                                    <input matInput 
                                    value="{{selectedInvoice.total_amount_paid | currency:InvoicemonetaryUnit}}" spellcheck="false" name="totalAmountPaid"
                                    type="text" placeholder="0"  readonly="">
                                    <span matPrefix>$&nbsp;</span>
                                </mat-form-field>
                            </div>
                        </article>
                    </mat-expansion-panel>
                    <mat-expansion-panel expanded *ngIf="!actionOnInvoice">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="text-info">info</mat-icon> Información
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <article class="form-box__content-section">
                            <div class="form-box__content-group-fields form-box__content-group-fields--min-width-x-small">
                                <mat-form-field appearance="standard">
                                    <mat-label>Placa</mat-label>
                                    <input matInput type="text" name="detail_device_plate_number" [(ngModel)]="selectedDetail.device_plate_number" readonly="">
                                </mat-form-field>
                                <mat-form-field appearance="standard">
                                    <mat-label>Imei</mat-label>
                                    <input matInput type="text" name="detail_device_imei" [(ngModel)]="selectedDetail.device_imei" readonly="">
                                </mat-form-field>
                                <mat-form-field appearance="standard">
                                    <mat-label>Nombre</mat-label>
                                    <input matInput type="text" name="detail_device_name" [(ngModel)]="selectedDetail.device_name" readonly="">
                                </mat-form-field>
                                <mat-form-field appearance="standard">
                                    <mat-label>Tipo de vehículo</mat-label>
                                    <input matInput type="text" name="detail_device_vehicle_type_name" [(ngModel)]="selectedDetail.vehicle_type_name" readonly="">
                                </mat-form-field>
                                <mat-form-field appearance="fill" *ngIf="requestedAction==invoiceRecordComponentActions.EDIT" class="fill" floatLabel="always">
                                    <mat-label>Precio a pagar ({{selectedDetail.monetary_unit_code}})</mat-label>
                                    <input matInput type="number" placeholder="0"  spellcheck="false" name="price" [(ngModel)]="selectedDetail.price">
                                    <span matPrefix>$&nbsp;</span>
                                </mat-form-field>
                            </div>
                        </article>
                    </mat-expansion-panel>
                    <mat-expansion-panel *ngIf="actionOnInvoice">
                        <mat-expansion-panel-header >
                            <mat-panel-title>
                                <mat-icon class="text-info">format_list_numbered</mat-icon> Detalles
                            </mat-panel-title>
                            <mat-panel-description>
                                Dispositivos
                              </mat-panel-description>
                        </mat-expansion-panel-header>
    
                        <div class="w-100 overflow-auto">
                            <table mat-table  [dataSource]="selectedInvoice.details??[]">
                                <ng-container matColumnDef="device_imei" >
                                  <th mat-header-cell *matHeaderCellDef> Imei </th>
                                  <td mat-cell *matCellDef="let element"> {{element.device_imei}} </td>
                                </ng-container>
                                <ng-container matColumnDef="device_name" >
                                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                    <td mat-cell *matCellDef="let element"> {{element.device_name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="vehicle_type_name" >
                                    <th mat-header-cell *matHeaderCellDef> Tipo de vehículo </th>
                                    <td mat-cell *matCellDef="let element"> {{element.vehicle_type_name || 'Sin definir'}} </td>
                                </ng-container>
                                <ng-container matColumnDef="price">
                                  <th mat-header-cell *matHeaderCellDef class="mat-header-cell-end"> Precio </th>
                                  <td mat-cell *matCellDef="let element" class="text-end"> {{element.price | currency:element.monetary_unit_code}} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="detailsTableDisplayedColumn"></tr>
                                <tr mat-row *matRowDef="let row; columns: detailsTableDisplayedColumn;"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell empty py-1" [attr.colspan]="detailsTableDisplayedColumn.length">Sin datos</td>
                                </tr>
                            </table>
                        </div>
                    </mat-expansion-panel>
    
                    <mat-expansion-panel expanded  *ngIf="requestedAction==invoiceRecordComponentActions.APPLY" >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>attach_money</mat-icon> Aplicar
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <article class="form-box__content-section">
                            <section class="d-block alert alert-light-info">
                                <p class="text-small m-0">Cuando los pagos son inferiores al total a pagar o al saldo pendiente, entonces debe de generarse un acuerdo de pago (disponible en esta sección, en caso de ser requerido)</p>
                            </section>
                            <div class="form-box__content-group-fields form-box__content-group-fields--min-width-x-small">
                                <mat-form-field appearance="fill" floatLabel="always">
                                    <mat-label>Total a abonar</mat-label>
                                    <input matInput 
                                    [(ngModel)]="payment.amount" spellcheck="false" name="paymentAmount"
                                    type="text" required="" inputmode="decimal" (input)="!isValidAmountToPay($event) ? $event.preventDefault() : null" min="0" [max]="maximumAmountToPay" (mousewheel)="$event.preventDefault()" [pattern]="validationService.isValidPositiveNumberStr" placeholder="0">
                                    <span matPrefix>$&nbsp;</span>
                                    <mat-hint align="start" class="text-warning" *ngIf="payment.amount < maximumAmountToPay">En la seccion inferior, debes definir un acuerdo de pago</mat-hint>
                                </mat-form-field>
                                
                                <mat-form-field appearance="fill" *ngIf="actionOnInvoice">
                                    <mat-label>Método de pago</mat-label>
                                    <mat-select name="paymentMethodId" [(ngModel)]="payment.payment_method_id">
                                      <mat-option *ngFor="let item of paymentMethodList"[value]="item.id">
                                        {{item.name |titlecase}}
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Medio de pago</mat-label>
                                    <mat-select name="paymentMediumId" [(ngModel)]="payment.payment_medium_id">
                                      <mat-option *ngFor="let item of paymentMediumList"[value]="item.id">
                                        {{item.name |titlecase}}
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="fill d-flex justify-content-center align-items-center gutter-1" *ngIf="isAFinancialProductSelected() != false">
                                    <mat-form-field appearance="fill" class="fill">
                                        <mat-label>Entidad financiera</mat-label>
                                        <mat-select name="financialInstitutionId" [(ngModel)]="payment.financial_institution_id">
                                          <mat-option *ngFor="let item of financialInstitutionList" [value]="item.id">
                                            {{item.name |titlecase}}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                <button class="btn btn-primary"matTooltip="Agregar otra entidad financiera" (click)=" showViewPaymentOption()" ><i class="fa-solid fa-plus"></i></button>
                                
                                </div>
                            </div>  
                            <fieldset class="form-box__content-fieldset mt-1" *ngIf="payment.amount < maximumAmountToPay">
                                <legend>Acuerdo de pago</legend>
                                <div  class="form-box__content-inputs">
                                    <div class="form-box__content-group-fields form-box__content-group-fields--min-width-x-small">
                                        <mat-form-field appearance="fill" class="fill">
                                            <mat-label>Fecha acuerdo de pago</mat-label>
                                            <input matInput name="paymentAgreementDate" disabled="" [(ngModel)]="paymentAgreement.agreement_datetime" [min]="currentDate" [matDatepicker]="paymentAgreementDate" required="">
                                            <mat-datepicker-toggle matSuffix [for]="paymentAgreementDate"></mat-datepicker-toggle>
                                            <mat-datepicker #paymentAgreementDate disabled="false"></mat-datepicker>
                                            <mat-hint align="start">Selecciona la fecha en la cual se acuerda realizar el próximo pago</mat-hint>
                                            <!--
                                                <mat-icon matPrefix class="text-info" matTooltip="Selecciona la fecha en la cual se acuerda realizar el próximo pago">help</mat-icon>
                                            -->                                
                                        </mat-form-field>
                                        <mat-form-field appearance="fill" hintLabel="Max {{maxPaymentAgreementFieldLength.comment}} carácteres">
                                            <mat-label>Descripción</mat-label>
                                            <textarea  matInput maxlength="{{maxPaymentAgreementFieldLength.comment}}" name="paymentAgreementComment" [(ngModel)]="paymentAgreement.comment" placeholder="Agrega una descripcion adicional sobre el acuerdo de pago (opcional)"></textarea>
                                            <mat-hint align="end">{{paymentAgreement.comment?.length || 0}}/{{maxPaymentAgreementFieldLength.comment}}</mat-hint>
                                        </mat-form-field>
                                        <span class="fill">
                                            <mat-checkbox  
                                                color="primary" name="activateDevices"
                                                [(ngModel)]="paymentAgreement.activate_devices">
                                                Activar dispositivos
                                                <mat-icon class="icon" matTooltip="Activa aquellos dispositivos que hacen parte del registro de facturación y que están inactivos.">help</mat-icon> 
                                            </mat-checkbox>  
                                          </span> 
                                    </div>
                                </div>
                            </fieldset>  

                        </article>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <div class="loader" *ngIf="loading">
            <img src="../../../assets/loader.svg">
        </div>
    </ng-container>
    
    <app-payment-options-basic-data 
    (close)="showViewInvoiceRecord()"
    *ngIf="viewsAvailable['payment_option'].show_view" [addOnlyPaymentOptionName]="true"></app-payment-options-basic-data>

</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="viewsAvailable['invoice_record'].show_view">
    <button mat-button [mat-dialog-close]="false" class="btn btn-outline-secondary" style="margin-right: 0.2rem;">Cerrar</button>
    <button mat-button class="btn btn-primary" *ngIf="!loading" (click)="updateRecord()">{{requestedAction==invoiceRecordComponentActions.APPLY?'Confirmar':'Actualizar'}}</button>
</mat-dialog-actions>