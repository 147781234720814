import { Component,OnInit,Inject } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from 'src/app/core/services/api/api.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { DataService } from '../../../../core/services/data/data.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit{
  //userDevices:any=[];
  //title:string="";
  user:any=[];
  employeesList:any=[];
  headers=["name","email","phone","phone_prefix"];//listade campos para exportar los datos
  isUpdateEmployee:boolean=false;
  employeeModal:boolean=false;//para indicar cuando se abre el modal de regsitros o actualizacion de empleado
  
  newEmployee:any={
    id:null,
    name:'',
    phone:'',
    email:'',
    phone_prefix:'',
    user_id:'',
    user_api_hash:''
  };

  displayedColumns:string[] = ["index","name","email","phone","phone_prefix","actions"];

  moduleId:number=8;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data:any    
  ){
  }
  ngOnInit(): void {
    //this.title =this.data.title;
    //this.userDevices=this.data.userDevices;
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.user=this.dataService.getData("user");
      this.getList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });

  }

  //se obtendra la lista de empleados
  getList(){
    this.getEmployees();
  }
  getEmployees(){
    let data= "?action=getEmployees&user_api_hash="+this.user.hash;
    
    //this.showLoading(true,"tasksTable");
    this.api.getData("user",data,true).then((employeesData:any)=>{
      console.log("employees ",employeesData);
      if(employeesData.status==1){
        this.employeesList=employeesData.data;
      }else{
        this.employeesList=[];
      }

      //this.showLoading(false,"tasksTable");
    }).catch((error:any)=>{console.log("error getemployees, ",error);});
  }
  openUpdateEmployeeModal(employee:any){
    this.newEmployee=JSON.parse(JSON.stringify(employee));
    this.isUpdateEmployee=true;
    this.employeeModal=true;
  }
  openCreateEmployeeModal(){
    this.isUpdateEmployee=false;
    this.employeeModal=true;
  }

  validateFields(newUser:any){
    let error="";
    console.log(newUser);
    this.newEmployee.name=this.newEmployee.name.trim();
    this.newEmployee.email=this.newEmployee.email.trim().toLowerCase();
    this.newEmployee.phone=this.newEmployee.phone.trim();
    this.newEmployee.phone_prefix=this.newEmployee.phone_prefix.trim();
    console.log(this.newEmployee);
    if(newUser.name==""){
      error="Falta asignarle un nombre al usuario";
    }else if(newUser.email=="" && newUser.phone=="" && newUser.phone_prefix ==""){
      error="Es necesario especificar al menos un dato de contacto. Email o teléfono y prefijo";
    }else if(newUser.phone !="" && newUser.phone_prefix ==""){
      error="Se necesita especificar un prefijo telefónico";
    }else if(newUser.phone !="" &&  !this.utils.isOnlyNumbers(newUser.phone)){
      error="El campo telefono solo acepta números";
    }
    return error;
  }
  createEmployee(){
    let error=this.validateFields(this.newEmployee);
    if(error !=""){
      this.utils.showMsg("datos incompletos",error);
      return;
    }
    this.newEmployee.user_api_hash=this.user.hash;
    this.newEmployee["action"]="addEmployee";
    this.api.createData(this.newEmployee, "user",true).then((data: any) => {
      console.log("respuesta ",data);
      if(data.status==1){
        this.utils.showMsg("","Usuario registrado");
        this.getList();
        this.closeEmployeeModal();

      } else {
        this.utils.showMsg("",data.message);
      }
      //this.getList(this.currentPage);
    }).catch((err: any) => {
      console.log(err);
      this.utils.showMsg("","Se ha presentado un error al registrar al usuario");
    });

  }
  updateEmployee(){
    console.log("actualizar ",this.newEmployee);
    let error=this.validateFields(this.newEmployee);
    if(error !=""){
      this.utils.showMsg("datos incompletos",error);
      return;
    }
    this.newEmployee.user_api_hash=this.user.hash;
    this.newEmployee["action"]="editEmployee";

    //realizo la eliminacion usando metodo post
    this.api.createData(this.newEmployee, "user",true).then((data: any) => {
      console.log("respuesta ",data);
      if(data.status==1){
        this.utils.showMsg("","Usuario actualizado");
        this.getList();
        this.closeEmployeeModal();
      } else {
        this.utils.showMsg("","No se pudo actualizar al usuario");
      }
      //this.getList(this.currentPage);
    }).catch((err: any) => {
      console.log(err);
      this.utils.showMsg("","Se ha presentado un error al actualizar al usuario");
    });

  }
  deleteEmployee(employeeId:number){
    this.utils.showConfirm("Confirmar Acción","¿Esta seguro de eliminar al usuario?","Eliminar","Cancelar").then(()=>{
      let data={
        user_api_hash:this.user.hash,
        action:"deleteEmployee",
        employee_id:employeeId
      };
      this.api.createData(data, "user",true).then((data: any) => {
        console.log("respuesta ",data);
        if(data.status==1){
          this.utils.showMsg("","Usuario eliminado");
          this.getList();
          this.closeEmployeeModal();
  
        } else {
          this.utils.showMsg("","No se pudo eliminar al usuario");
        }
        //this.getList(this.currentPage);
      }).catch((err: any) => {
        console.log(err);
        this.utils.showMsg("","Se ha presentado un error al eliminar al usuario");
      });
    });
  }
  closeEmployeeModal(){
    this.employeeModal=false;
    this.isUpdateEmployee=false;
    this.clearEmployeeObject();
  }
  clearEmployeeObject(){
    this.newEmployee={
      id:null,
      name:'',
      phone:'',
      email:'',
      phone_prefix:'',
      user_id:this.user.id
    };
  }
  exportFile(){
    this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
      //this.loading = true;
      this.showLoadingDataExport();
      setTimeout(() => {
        this.utils.downloadFileAsCSV(this.employeesList,  "employeesList_" + new Date().getTime().toString(), this.headers);
        //this.loading = false;
        this.utils.showMsg("","Datos exportados con éxito");
      }, 1500);
    });
  }
  showLoadingDataExport(){
    Swal.fire({
      title:  'Exportando datos...',
      html: '<i class="fa-solid fa-download fa-2x fa-beat-fade"></i>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}
