import { Component,OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from 'src/app/core/services/api/api.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { DataService } from '../../../../core/services/data/data.service';
import { Router } from '@angular/router';
import { IInvoice, IInvoiceDetail } from 'src/app/core/models/interfaces/iinvoice.model';
import { IFinancialInstitution, IPayment, IPaymentAgreement, IPaymentMedium, IPaymentMethod } from 'src/app/core/models/interfaces/ipayment.model';
import { ObjectInitializationService } from '../../../../core/services/object-initialization/object-initialization.service';
import { IUserPlanModule, IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { RequestMethodHTTP, TimeUnit } from 'src/app/shared/enums/common-enums.enum';
import { ITimeUnit, ITimeUnitItem } from 'src/app/shared/models/interfaces/iutil.model';
import { ValidationService } from '../../../../core/services/validation/validation.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { MAX_PAYMENT_AGREEMENT_FIELD_LENGTH } from '../payment-agreement/payment-agreement.component';

type MaxPaymentAgreementFieldLength = Pick <IPaymentAgreement, "comment" >;

type TimeUnitForInvoice = Pick<ITimeUnit,"day"|"week"|"month"|"year">;
enum InvoiceRecordComponentActions  {EDIT= "edit", APPLY = "apply"};
interface IInvoiceDueTime{
  quantity: number ,
  time_unit: TimeUnit | null,
  change_interval_id: NodeJS.Timeout | null
}
@Component({
  selector: 'app-invoice-record',
  templateUrl: './invoice-record.component.html',
  styleUrls: ['./invoice-record.component.scss']
})

export class InvoiceRecordComponent implements OnInit{
  user:IUserSessionData;
  loading: boolean = false;

  currentDate: Date = new Date();
  /** Almacena la lista de vistas disponibles en el componente */
  viewsAvailable:IViewsAvailable={
    payment_option:{name:"payment_option",show_view:false},
    invoice_record:{name:"invoice_record",show_view:true}
  };

  invoiceRecordComponentActions: typeof InvoiceRecordComponentActions = InvoiceRecordComponentActions;
  /** @type {string[]} Columnas a mostrar en la tabla de detalles */
  detailsTableDisplayedColumn: string[] = ["vehicle_type_name","device_name","device_imei","price"];
  
  timeUnit: TimeUnitForInvoice;
  timeUnitArray: ITimeUnitItem[];
  /**Teimpo de vencimiento para factura seleccionada */
  invoiceSelectedDueTime: IInvoiceDueTime = {quantity:0, time_unit: null, change_interval_id: null};
  selectedInvoice: IInvoice;//factura a administrar
  selectedDetail: IInvoiceDetail;

  /**
   * @type {IPaymentAgreement} Indica los dato de acuerdo de pago a aplicar a la factura en caso de no realizarse un pago completo. usado para cuando el usuario va a aplicar un pago
   */
  paymentAgreement: IPaymentAgreement;
  readonly maxPaymentAgreementFieldLength!: Record<keyof MaxPaymentAgreementFieldLength,number>

  /**
   * Indica si la accion sera aplicada sobre la factura, de lo contrario se entenderá como aplicado sobre un detalle
   */
  actionOnInvoice: boolean=true;
  requestedAction: InvoiceRecordComponentActions = InvoiceRecordComponentActions.EDIT;//indica si el modal se abre para edicion o como accion del boton aplicar pago
  /**
   * @type {string} Indica la cantidad máxima que puede indicar el cliebte al aplicar pago sobre factura
   */
  maximumAmountToPay:number = 0;
  payment: IPayment;
  invoiceEdit:Pick <IInvoice, "id" | "expiration_date">={ id:0, expiration_date: ""};
  invoiceExpirationDate: Date | null = null;//fecha de expiracion de la factura
  minDateExpirationDate: Date | null = null;//asigna la fecha minima para el calendario de fecha de expiracion
  paymentMediumList: IPaymentMedium[] = [];
  paymentMethodList: IPaymentMethod[] = [];
  financialInstitutionList: IFinancialInstitution[] = [];
  detailAmountCurrrentlyPaid: number = 0;
  
  InvoicemonetaryUnit:string = '';

  moduleId:number = 7;
  /**  @type {IUserPlanModule} Permisos sobre el módulo */
  permissionsDataSgc:IUserPlanModule;
  constructor(
    private api: ApiService,
    public utils: UtilsService,
    public dataService: DataService,
    private router:Router,
    public validationService: ValidationService,
    private objectInitializationService: ObjectInitializationService,
    private dialogRef: MatDialogRef<InvoiceRecordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {action: InvoiceRecordComponentActions, invoice:IInvoice, selected_invoice_detail?: IInvoiceDetail}
  ){
    setInterval(() => this.currentDate = new Date(), this.dataService.OneMinute);
    this.permissionsDataSgc = this.objectInitializationService.initializeIUserPlanModule();
    
    this.selectedDetail = this.objectInitializationService.initializeIInvoiceDetail();
    this.selectedInvoice = JSON.parse(JSON.stringify(this.data.invoice));
    
    let timeUnit: ITimeUnit = this.objectInitializationService.initializeITimeUnit();
    this.timeUnit = {day:timeUnit.day,week: timeUnit.week ,month:timeUnit.month,year: timeUnit.year};
    this.timeUnitArray = Object.values(this.timeUnit);
    
    this.user=this.dataService.getData("user");
    this.payment = this.objectInitializationService.initializeIPayment(0,0);
    this.maxPaymentAgreementFieldLength = MAX_PAYMENT_AGREEMENT_FIELD_LENGTH;
    this.paymentAgreement = this.objectInitializationService.initializeIPaymentAgreement(this.selectedInvoice.id);
    this.paymentAgreement.activate_devices = true;
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: IUserPlanModule) => {
      this.permissionsDataSgc = permissions;
      this.payment.invoice_id=this.selectedInvoice.id;
      this.InvoicemonetaryUnit=this.selectedInvoice.details![0].monetary_unit_code;
      this.requestedAction =this.data.action;
      if(typeof this.data.selected_invoice_detail !="undefined"){
        this.selectedDetail =JSON.parse(JSON.stringify(this.data.selected_invoice_detail));
        this.actionOnInvoice = false;
        this.payment.invoice_detail_id = this.data.selected_invoice_detail.id;
      }
      this.invoiceExpirationDate = (this.selectedInvoice.expiration_date !=null)? new Date(this.selectedInvoice.expiration_date) : null;
      this.invoiceEdit = {id: this.selectedInvoice.id, expiration_date: this.selectedInvoice.expiration_date};
      this.minDateExpirationDate = new Date(this.selectedInvoice.created_at);
      this.determinateExpirationTimeUnit();
      this.getList();
      if(this.requestedAction == this.invoiceRecordComponentActions.APPLY){
        if(this.actionOnInvoice)
          this.maximumAmountToPay = this.payment.amount = this.selectedInvoice.total_outstanding_balance ?? 0;
        else
          this.maximumAmountToPay = this.payment.amount = this.selectedDetail.outstanding_balance ?? 0;
      }
      this.payment.payment_method_id = this.selectedInvoice.payment_method_id;
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  showViewInvoiceRecord(){
    this.getFinancialInstitutionList();
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["invoice_record"].name);
  }
  showViewPaymentOption(){
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["payment_option"].name);
  }
  async getFinancialInstitutionList(): Promise<void> {
    try {
      const financialInstitutionList = await this.api.getData("financialInstitution","?action=get&user_api_hash="+this.user.hash,true);
      if(this.validationService.isResponseApi(financialInstitutionList) && financialInstitutionList.status == 1)
        this.financialInstitutionList = financialInstitutionList.data as IFinancialInstitution[];
      else throw new Error("la lista de instituciones financieras");
    } catch (error:unknown) {
      if(error instanceof Error)
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(error.message,true, false,RequestMethodHTTP.GET));
    }
  }
  /** Obtiene la lista de valores para las selecciones, requeridos para el formulario */
  async getList(){
    try {
      const [paymentMediumList, paymentMethodList, financialInstitutionList] = await Promise.all([this.api.getData("paymentMedium","?action=get&user_api_hash="+this.user.hash,true), 
        this.api.getData("paymentMethod","?action=get&user_api_hash="+this.user.hash,true), this.api.getData("financialInstitution","?action=get&user_api_hash="+this.user.hash,true)]);
      if(this.validationService.isResponseApi(paymentMediumList) && paymentMediumList.status == 1)
        this.paymentMediumList = paymentMediumList.data as IPaymentMedium[];
      else throw new Error("la lista de medios de pagos");
      if(this.validationService.isResponseApi(paymentMethodList) && paymentMethodList.status == 1)
        this.paymentMethodList = paymentMethodList.data as IPaymentMethod[];
      else throw new Error("la lista de métodos de pagos");
      if(this.validationService.isResponseApi(financialInstitutionList) && financialInstitutionList.status == 1)
        this.financialInstitutionList = financialInstitutionList.data as IFinancialInstitution[];
      else throw new Error("la lista de instituciones financieras");
    } catch (error:unknown) {
      if(error instanceof Error)
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(error.message,true, false,RequestMethodHTTP.GET));
    }    
  }
  isAFinancialProductSelected(){
    const noFinancialProductsId:any=[1,null];//efectivo:id 1,
    if((this.payment.payment_medium_id !=null && !noFinancialProductsId.some((item:number)=>this.payment.payment_medium_id==item)))
      return true;
    this.payment.financial_institution_id = null;
    return false;
  }
  updateRecord(){
    this.utils.showConfirm("Confirmar acción","¿Esta seguro de "+(this.requestedAction== InvoiceRecordComponentActions.APPLY?"confirmar el pago?":"de editar este registro?"),"Confirmar","Cancelar").then(()=>{
      this.loading = true;
      let errorMessage: string = "";
      let object = this.requestedAction == InvoiceRecordComponentActions.EDIT ? "invoice" : "payment";
      let dataTosend:IApiRequestData={
        user_api_hash:this.user.hash,
        action: this.requestedAction == InvoiceRecordComponentActions.APPLY ? "confirmPayment" : (this.requestedAction == InvoiceRecordComponentActions.EDIT ? "update" : undefined),
        apply_to: this.actionOnInvoice ? "invoice" : "invoiceDetail"
      };
      if(this.requestedAction == InvoiceRecordComponentActions.APPLY){//de confirmar un pago
        dataTosend["payment"] = this.payment;
        if(this.payment.amount < 0.1 || this.payment.amount > this.maximumAmountToPay)
          errorMessage =  this.payment.amount < 0.1 ? "El abono debe ser superior a cero" : "El abono no puede exceder el total adeudado";
        
        else if(this.payment.amount < this.maximumAmountToPay){
          if( this.validationService.isNullOrEmpty(this.paymentAgreement.agreement_datetime) || isNaN(Date.parse(this.paymentAgreement.agreement_datetime)))
            errorMessage = "Debe de indicar una fecha para el acuerdo de pago";
          else{
            let paymentAgreement = this.utils.copyObject(this.paymentAgreement)as IPaymentAgreement;
            let paymentAgreementDate: string = "";
            if(!isNaN(Date.parse(paymentAgreement.agreement_datetime))){
              paymentAgreementDate =  this.utils.getDatetime(new Date(paymentAgreement.agreement_datetime)).split(" ")[0] + " 23:59:59";
              paymentAgreementDate = this.utils.getUTCDatetime(new Date(paymentAgreementDate));
            }
            paymentAgreement.agreement_datetime = paymentAgreementDate;
            dataTosend["payment_agreement"] = paymentAgreement;
          }
        }
      }
      else if(this.requestedAction == InvoiceRecordComponentActions.EDIT)
        dataTosend["id"] = this.invoiceEdit.id;
      if(this.actionOnInvoice){//accion sobre factura, en caso contrario es sobre un detalle
        dataTosend["expiration_date"] = this.utils.getUTCDatetime(new Date(this.invoiceEdit.expiration_date));
      }else{
        dataTosend["invoice_id"] = this.selectedInvoice.id;
        dataTosend["price"] = this.selectedDetail.price;
        dataTosend["detail_id"] = this.selectedDetail.id;
      }
      if(errorMessage != ""){
        this.utils.hideLoading(()=>this.loading = false);
        return this.utils.showResultRequest("error","Información", errorMessage);
      }
      this.api.createData(dataTosend, object,true).then((data: unknown) => {
        if(this.validationService.isResponseApi(data)){
          this.utils.showResultRequest(data.status==1 ? "success" : "error","Información",data.message);
          if(data.status == 1)
          this.dialogRef.close(true);
        }
      }).catch((err: unknown) => {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("los datos de facturación",true, false,RequestMethodHTTP.POST));
      }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
    });
  }
  clearIntervalChangeExpirationTimeAmount(){
    clearInterval(this.invoiceSelectedDueTime.change_interval_id!);
  }
  isValidAmountToPay(event: any): boolean {
    if(this.validationService.isValidPositiveNumber((event.target.value??'').toString()) && this.payment.amount <= this.maximumAmountToPay){
      event.target.value = this.payment.amount =  Number(event.target.value);
      return true;
    }
    if ( ((event.target.value??'').toString()).includes("-")){
      event.target.value = this.payment.amount =  0;
      return false;
    }
    else if(!this.validationService.isValidPositiveNumber((event.target.value??'').toString())){
      let amount = (Number(this.payment.amount.toString().slice(0,this.payment.amount.toString().length-1))); 
      event.target.value = this.payment.amount =  amount;
      return false;
    }else if(this.payment.amount > this.maximumAmountToPay){
      event.target.value = this.payment.amount =  this.maximumAmountToPay;
    }
    return true;
  }
  intervalChangeExpirationTimeAmount(increment:boolean){
    this.invoiceSelectedDueTime.change_interval_id = setInterval(()=>{
      this.changeExpirationTimeAmount(increment);
    },100);
  }
  changeExpirationTimeAmount(increment:boolean){
    if(!increment)
      this.invoiceSelectedDueTime.quantity = (this.invoiceSelectedDueTime.quantity < 2 ) ? 0 : (--this.invoiceSelectedDueTime.quantity);
    else
      ++this.invoiceSelectedDueTime.quantity;
    this.calculateExpirationDate();
  }
  /** Determina los datos sobre la unidad que se ha usado para el calculo de la fecha de expiracion de la factura*/
   determinateExpirationTimeUnit(){
    let selectedInvoiceCreatedAt:Date = new Date(this.utils.getDatetime(new Date(this.selectedInvoice.created_at)).split(" ")[0]+" 00:00:00");
    this.invoiceSelectedDueTime.quantity = 0;
    this.invoiceSelectedDueTime.time_unit = null;
    if(!this.validationService.isNullOrEmpty(this.invoiceEdit.expiration_date)){
      let expirationDate: Date = new Date(this.utils.getDatetime(new Date(this.invoiceEdit.expiration_date!)).split(" ")[0]+" 00:00:00");  
      const differenceInMonths = (expirationDate.getFullYear() - selectedInvoiceCreatedAt.getFullYear()) * 12 +
        expirationDate.getMonth() - selectedInvoiceCreatedAt.getMonth();
      let differenceInDays:number = expirationDate.getDate() - selectedInvoiceCreatedAt.getDate();
      if(differenceInMonths != 0 && differenceInDays == 0 && (differenceInMonths % 12 == 0 || differenceInMonths % 1 == 0)){
        this.invoiceSelectedDueTime.quantity = differenceInMonths % 12 == 0 ? (expirationDate.getFullYear() - selectedInvoiceCreatedAt.getFullYear()) : differenceInMonths;
        this.invoiceSelectedDueTime.time_unit = differenceInMonths % 12 == 0 ? TimeUnit.YEAR : TimeUnit.MONTH;
        return;
      }
      differenceInDays = (expirationDate.getTime() - selectedInvoiceCreatedAt.getTime()) / this.dataService.OneDay;
      if(differenceInDays !=0 && (differenceInDays % 7 == 0 || differenceInDays % 1 == 0)){
        this.invoiceSelectedDueTime.quantity = differenceInDays % 7 == 0 ? (differenceInDays / 7 ) : differenceInDays;
        this.invoiceSelectedDueTime.time_unit = differenceInDays % 7 == 0 ? TimeUnit.WEEK : TimeUnit.DAY;
      }
    }
  }
  calculateExpirationDate(){
    let selectedInvoiceCreatedAt:Date = new Date(this.selectedInvoice.created_at);
    if(this.invoiceSelectedDueTime.time_unit == TimeUnit.DAY || this.invoiceSelectedDueTime.time_unit == TimeUnit.WEEK)
      selectedInvoiceCreatedAt.setDate(selectedInvoiceCreatedAt.getDate() + (this.invoiceSelectedDueTime.quantity * (this.invoiceSelectedDueTime.time_unit == TimeUnit.WEEK ? 7 : 1 )));  
    else if(this.invoiceSelectedDueTime.time_unit == TimeUnit.MONTH)
      selectedInvoiceCreatedAt.setMonth(selectedInvoiceCreatedAt.getMonth() + this.invoiceSelectedDueTime.quantity);
    else if(this.invoiceSelectedDueTime.time_unit == TimeUnit.YEAR)
      selectedInvoiceCreatedAt.setFullYear(selectedInvoiceCreatedAt.getFullYear() + this.invoiceSelectedDueTime.quantity);
    if(this.invoiceSelectedDueTime.time_unit != null && [TimeUnit.DAY,TimeUnit.WEEK,TimeUnit.MONTH,TimeUnit.YEAR].includes(this.invoiceSelectedDueTime.time_unit!)){
      this.invoiceExpirationDate = selectedInvoiceCreatedAt;
      this.invoiceEdit.expiration_date = this.utils.getDatetime(new Date(this.utils.getDatetime(this.invoiceExpirationDate).split(" ")[0] + " " + this.utils.getDatetime().split(" ")[1]));
    }
  }
  setInvoiceExpirationDate(event:MatDatepickerInputEvent<any, any>){
      let expirationDate= new Date(event.value);
      const currentDate= new Date();
      this.invoiceEdit.expiration_date= expirationDate.getFullYear()+"-"+this.utils.evaluateDateNumber(expirationDate.getMonth()+1)+"-"+this.utils.evaluateDateNumber(expirationDate.getDate())+" "+this.utils.evaluateDateNumber(currentDate.getHours())+":"+this.utils.evaluateDateNumber(currentDate.getMinutes())+":"+this.utils.evaluateDateNumber(currentDate.getSeconds());
      this.determinateExpirationTimeUnit();
  }
}