import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ROUTES_TO_LOGIN } from '../core/services/auth/auth.service';
import { DataService } from '../core/services/data/data.service';
import { UserType } from '../shared/types/common-types.type';
import { AssetFileName } from '../core/constant/asset-file-name';
import { RouteConstant, WebDomainKey } from '../core/constant/RouteConstant';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  typePassword:"password"|"text"="password";//para indicar el tipo de input que es el input de contraseña
  passwordVisible:boolean=false;
  loading: boolean = false;
  error: boolean = false;
  modal:boolean=false;
  userData: any = {
    email: "",
    password: ""
  }
  /**
   * @type {string} El nombre del archivo de imagen a mostrar en el login
   */
  fileNameToLoad: string = AssetFileName.MAIN_IMG_LOGIN;
  constructor(
    private authService:AuthService,
    private route: Router,
    public dataService: DataService
  ) {
    if(this.dataService.accessHostNameKey == WebDomainKey.UNIVERSAL_TRACKING)
      this.fileNameToLoad = AssetFileName.MAIN_IMG_LOGIN_UNIVERSAL_TRACKINg;
   }
  ngOnInit(): void {}
  changeTypePasswordInput(){
    this.typePassword = this.authService.changeTypePasswordInput(this.typePassword)
  }
  async login($event:any):Promise<1|0>{
    $event.preventDefault();
    const formData = new FormData($event.target);
    this.loading = true;
    try{
      let response: unknown = await this.authService.logInIntgps(formData.get("email") as string,formData.get("password") as string);
      if(this.authService.isAPIResponseLoginIntgps(response)){
        if(response.status == 1){
          let responseSGC:unknown = await this.authService.logInSgc(this.userData.email,response.user_api_hash!,null,"");
          if(this.authService.isAPIResponseLoginSgc(responseSGC) && responseSGC.status == 1){
            this.dataService.setUserTypeLogged(this.dataService.USER_TYPE.user);
            return responseSGC.status;
          }
        }
      }
      this.loading = false;
      return 0;
    }catch(error:any){
      this.loading = false;
      return 0;
    }
  }
  goToSpecialLogin(userType:UserType){
    let routeToLogin:string = ROUTES_TO_LOGIN.user;
    if( userType == this.dataService.SPECIAL_USER.user_driver)
      routeToLogin = ROUTES_TO_LOGIN[userType];
    this.route.navigate([routeToLogin]);
  }
}