import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { MessageBoxService } from 'src/app/core/services/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/services/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { ApiEndpointSgc } from 'src/app/core/enums/api-endpoint-sgc.enum';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IApiRequestData, IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IAutomation, IAutomationTargetUser, IUserAssociatedWithAutomation } from 'src/app/core/models/interfaces/iautomation.model';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { AUTOMATION_TYPE_LIST } from '../../automations.component';

type TableColumnName = Pick<IUserAssociatedWithAutomation, "active" | "name" | "email" | "phone_number" | "id"> & { "actions": boolean, "select": boolean };
enum SUBSCRIPTION_STATUSES { SUBSCRIBED = 1, NOT_SUBSCRIBED = 0 };

@Component({
  selector: 'app-automation-target-user',
  templateUrl: './automation-target-user.component.html',
  styleUrls: ['./automation-target-user.component.scss']
})
export class AutomationTargetUserComponent implements OnInit {

  @Output() close = new EventEmitter<boolean>();

  readonly BILLING_AUTOMATIONS_IDS: AUTOMATION_TYPE_LIST[] = [AUTOMATION_TYPE_LIST.PORTFOLIO, AUTOMATION_TYPE_LIST.BILLING];
  isSelectedAutomationTypeInBillingAutomation: boolean = false;
  AUTOMATION_TYPE_NAME_LIST = {
    [AUTOMATION_TYPE_LIST.PORTFOLIO]: "Cartera",
    [AUTOMATION_TYPE_LIST.BILLING]: "Facturación",
    [AUTOMATION_TYPE_LIST.AFTER_SALE]: "Post-venta"

  }

  /** @type {IUserSessionData} Datos del usuario logueado */
  user: IUserSessionData;
  loading: boolean = false;

  /** @type {IUserAssociatedWithAutomation[]} la lista de usuarios de asociados a la automatizacion */
  usersList: IUserAssociatedWithAutomation[] = [];

  /** @type {number[]} la lista de usuarios a actualizar su estado en de vinculacion a la automatizacion */
  userListToUpdate: number[] = [];

  searchTimer!: NodeJS.Timeout;
  pagination: IPagination;

  displayedColumns: (keyof TableColumnName)[] = ['select','active', 'email', 'name', 'phone_number'];
  /** indica los datos para el ordenamiento de la tabla */
  sort: ISort;

  searchValue: string = "";

  @Input() selectedAutomationId: number = -1;
  @Input() selectedAutomationType: AUTOMATION_TYPE_LIST | null = null;

  readonly SUBSCRIPTION_STATUSES = SUBSCRIPTION_STATUSES;
  subscriptionStatusFilter: Record<SUBSCRIPTION_STATUSES, boolean> = { [SUBSCRIPTION_STATUSES.SUBSCRIBED]: true, [SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED]: true }
  constructor(
    private dataService: DataService,
    public utils: UtilsService,
    private api: ApiService,
    private validationService: ValidationService,
    private objectInitializationService: ObjectInitializationService,
    public messageBox: MessageBoxService,
  ) {
    this.sort = this.objectInitializationService.initializeISort();
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION.slice());
    this.pagination.page_size = this.pagination.page_size_options[0];
    this.user = this.dataService.getData("user");
  }

  ngOnInit(): void {
    this.getUserList();
    this.isSelectedAutomationTypeInBillingAutomation = this.BILLING_AUTOMATIONS_IDS.some((id: number) => {return this.selectedAutomationType == id});
  }
  changeUsersSubscriptionFilter(subscriptionStatuses: SUBSCRIPTION_STATUSES, event: MatCheckboxChange) {
    this.pagination.current_page = 0;
    this.subscriptionStatusFilter[subscriptionStatuses] = event.checked;
    this.getUserList();
  }
  search() {
    this.pagination.current_page = 0;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {this.getUserList();}, this.dataService.DATA_TABLE_SEARCH_TIMEOUT);
  }
  /**
   * @descriptiob obtiene la lista de usuarios asociados a la automatizacion indicada
   */
  getUserList() {
    this.loading = true;
    this.usersList = [];
    this.pagination.length_page = 0;
    let data:IApiRequestData & {active_field_values?: number[], automation_id?: number }= this.api.structureParametersForConsult(RequestMethodHTTP.POST,"getUsersAssociatedWithAutomation",this.user.hash,this.searchValue,(this.pagination.apply_pagination ? this.pagination.current_page : undefined), (this.pagination.apply_pagination ?  this.pagination.page_size : undefined),this.sort) as IApiRequestData;
    data["automation_id"] = this.selectedAutomationId;
    data["active_field_values"] = [];
    if(this.subscriptionStatusFilter[SUBSCRIPTION_STATUSES.SUBSCRIBED])
      data["active_field_values"].push(SUBSCRIPTION_STATUSES.SUBSCRIBED);
    if(this.subscriptionStatusFilter[SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED])
      data["active_field_values"].push(SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED);
    this.api.getDataPost(ApiEndpointSgc.USER,data,true).then((data: unknown)=>{
      if(this.validationService.isResponseApi(data) && data.status == 1){
        this.pagination.length_page = data.total ?? 0;
        this.usersList = data.data as IUserAssociatedWithAutomation[];   
        
        this.pagination.page_size_options = this.dataService.PAGE_SIZE_OPTION.slice();
        if(this.pagination.length_page > (this.dataService.PAGE_SIZE_OPTION[this.dataService.PAGE_SIZE_OPTION.length - 1 ] ))
          this.pagination.page_size_options.push(this.pagination.length_page);
        if(!this.pagination.page_size_options.includes(this.pagination.page_size))
          this.pagination.page_size =  this.pagination.length_page;
      }
    }).catch((error:unknown)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de usuarios asociados a la automatización",false,false,"GET"));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
  /**
   * @param user Indica el usaurio asociado a actualizar
   * @param event Indica el evento de seleccion de estado
   * @param selection indica si se va a asignar un estado de asociacion especifica
   * @param boolean revertToPreviousValue si se va a aplicar una reversion del dato actual segun la seleccion que se aplicó  para que el registro vuelva a tener el valor anteriormente almacenado 
   */
  toggleSelection(user: IUserAssociatedWithAutomation, event?: MatSlideToggleChange, selection?: SUBSCRIPTION_STATUSES, revertToPreviousValue?: boolean){
    if(typeof revertToPreviousValue =="boolean" && revertToPreviousValue && typeof user.old_active != "undefined"){
      user.active = user.old_active;
    }
    else if(typeof event != "undefined"){
      user.old_active = user.active;
      user.active =  (event?.checked) ? this.SUBSCRIPTION_STATUSES.SUBSCRIBED : this.SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED;
    }
    else if(typeof selection != "undefined"){
      user.old_active = user.active;
      user.active =  selection;
    }
    if(user.automation_id != null)
      user.action_request = "update";
    else if(user.active == this.SUBSCRIPTION_STATUSES.SUBSCRIBED)
      user.action_request = "add";
    else{
      delete user.old_active;     
      delete user.action_request;     
    }
  }
  isSelectedUser(userToEvaluate: IUserAssociatedWithAutomation): boolean{
    return typeof userToEvaluate.active != "undefined" && userToEvaluate.active == SUBSCRIPTION_STATUSES.SUBSCRIBED;
  }
  compareUserSelected(selectedUser: IUserAssociatedWithAutomation, valueUser: IUserAssociatedWithAutomation){
    let response = valueUser.id == selectedUser.id;
    if(response){
      selectedUser.active = selectedUser.active == SUBSCRIPTION_STATUSES.SUBSCRIBED ? SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED : SUBSCRIPTION_STATUSES.SUBSCRIBED; 
      return true;
    }
    return false;
  }
  async requestDataUpdate(user?: IUserAssociatedWithAutomation){
    let confirm:unknown;
    if(typeof user == "undefined")
      try {
        confirm = await this.utils.showConfirm("¿Quieres actualizar el conjunto de usuarios seleccionados?", "","Actualizar","Cancelar");
      } catch (error) {}
    else
      confirm = true;
    if(typeof confirm =="undefined" || !confirm){
      this.toggleAllRows(true);    
    }
    if(typeof confirm !="undefined" && confirm){
      if(typeof user == "undefined"){
        this.loading = true;
      }
      let dataToSend: IApiRequestData & {users?: ({user_id: number, active: SUBSCRIPTION_STATUSES})[] } = {
        action: "update",
        user_api_hash: this.user.hash,
        automation_id: this.selectedAutomationId
      };
      if(typeof user != "undefined"){
        dataToSend.action = user.action_request;
        dataToSend['user_id'] = user.id;
        dataToSend['active'] = user.active;
      }else{
        dataToSend["users"] = []; 
        for(let user of this.usersList){
          if(typeof user.action_request != "undefined"){
            dataToSend["users"].push({user_id:user.id, active: user.active});
          }
        }
      }
      this.api.createData(dataToSend, ApiEndpointSgc.AUTOMATION_TARGET_USER,true).then((response: unknown) => {
        if(this.validationService.isResponseApi(response)){
          if(typeof user != "undefined"){
            this.messageBox.openSnackBar( response.status == 1 ? "asociación actualizada" : "No se pudo actualizar la asociación","Aceptar");
          }else{
            this.utils.showResultRequest(response.status == 1 ? "success" : "error", "Información", response.message ?? "")
            response.status == 1 ? this.getUserList() : null;
            if(response.status != 1)
              this.toggleAllRows(true);    
          }
      }
      }).catch((error: unknown) => {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la asociación de usuario con la automatización",true, false,RequestMethodHTTP.POST));
      }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    let noSubscribed:boolean = this.usersList.some((user: IUserAssociatedWithAutomation) => user.active != SUBSCRIPTION_STATUSES.SUBSCRIBED);
    return !noSubscribed;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(revertToPreviousValue?: boolean) {
    if (this.isAllSelected()) {
      for(let user of this.usersList){
        this.toggleSelection(user,undefined, SUBSCRIPTION_STATUSES.NOT_SUBSCRIBED,revertToPreviousValue);
      }
      return;
    }
    for(let user of this.usersList){
      this.toggleSelection(user,undefined, SUBSCRIPTION_STATUSES.SUBSCRIBED, revertToPreviousValue);
    }
  }

  checkboxLabel(user?: IUserAssociatedWithAutomation): string {
    if (!user)
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${ user.active == SUBSCRIPTION_STATUSES.SUBSCRIBED ? 'deselect' : 'select'} row - user: ${user.email}`;
  }

  paginator(event: PageEvent){
    this.pagination.page_size = event.pageSize;
    this.pagination.current_page = event.pageIndex;
    this.pagination.apply_pagination = this.pagination.page_size <= this.dataService.PAGE_SIZE_OPTION[this.dataService.PAGE_SIZE_OPTION.length -1];
    this.getUserList();
  }
  sortData(sort: Sort) {
    this.sort.direction = sort.direction;
    this.sort.by = sort.active;
    this.getUserList();
  }
  closeComponent(){
    this.close.emit(true);
  }
}