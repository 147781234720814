<section class="form-box-container animate__animated animate__fadeIn" >
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="close()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-satellite"></i></span> Registros - Btrak </h2>
        <div  class="form-box-container__header-options">                     
          <div class="search">
            <input id="inputSearch" type="search" placeholder="Buscar" (input)="searchValueInData()"
              [(ngModel)]="searchValue">
            <label for="inputsearch"><i class="fa-solid fa-magnifying-glass fa-sm"></i></label>
          </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
  
        <div class="form-box__content form-box__content-h-auto">
          <div class="w100 overflow-auto">
            <table mat-table matSort (matSortChange)="sortData($event)"  [dataSource]="devices">
              <ng-container matColumnDef="device_imei">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Imei </th>
                <td mat-cell *matCellDef="let element"> {{element.device_imei}} </td>
              </ng-container>  
              <ng-container matColumnDef="device_plate_number">
                <th mat-header-cell mat-sort-header *matHeaderCellDef > Placa </th>
                <td mat-cell *matCellDef="let element"> {{element.device_plate_number}} </td>
              </ng-container>
    
              <ng-container matColumnDef="created_at">
                <th mat-header-cell mat-sort-header *matHeaderCellDef  matTooltip="Fecha en que se registra la emisión del evento en la plataforma hacia el web service"> Fecha de registro  <i class="fa-regular fa-circle-question icon"></i> </th>
                <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
              </ng-container>
    
              <ng-container matColumnDef="device_time">
                <th mat-header-cell *matHeaderCellDef matTooltip="Fecha de última conexión conocida del dispositivo al momento de enviar los datos al web service" > Fecha del dispositivo <i class="fa-regular fa-circle-question icon"></i></th>
                <td mat-cell *matCellDef="let element"> {{element.device_time}} </td>
              </ng-container>
              <ng-container matColumnDef="result">
                <th mat-header-cell *matHeaderCellDef matTooltip="Respuesta dada por Btrak" > Respuesta <i class="fa-regular fa-circle-question icon"></i></th>
                <td mat-cell *matCellDef="let element"> {{element.result}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
            </tr>
            </table>
          </div>
          <mat-paginator [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
            [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
            aria-label="Selecciona pagina de la lista de dispositivos">
          </mat-paginator>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
      <img src="../../../../../../assets/loader.svg">
    </div>
  </section>