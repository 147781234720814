import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { IUserSessionData } from '../../../core/models/interfaces/iuser-session-data.model';
import { IUserNote } from '../../../core/models/interfaces/user-data.model';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-notes',
  templateUrl: './user-notes.component.html',
  styleUrls: ['./user-notes.component.scss']
})
export class UserNotesComponent implements OnInit{

  loading: boolean = false;
  user : IUserSessionData;
  @Output() close = new EventEmitter<boolean>();

  displayedColumns: string[] = ["creation_date","description","actions"];
  /** @type {number} El id del usuario a consultar notas */
  @Input() userId: number | null = null;
  userNotes: IUserNote[] =  [];

  constructor(
    private api: ApiService,
    public validationService: ValidationService,
    public dataService: DataService,
    public utils: UtilsService,
    private renderer: Renderer2
  ){
    this.user = this.dataService.getData("user");
  }
  ngOnInit(): void {
    this.getUserNotes();
  }

  addNoteToUser(){
    Swal.fire({
      title: 'Agregar nota',
      input: 'textarea',
      html:'<div class="text-start"><label for="dateNote" title="Toma la fecha actual de forma predeterminada">Fecha de novedad<span class="information"> (opcional)</span></label></div><input id="dateNote" class="form-control" type="datetime-local">',
      inputPlaceholder: 'Ejemplo: Al dispositivo se le ha realizado cambio de SimCard debido a que la simCard anterior...',
      showCancelButton: true,
    }).then((result)=>{
      let dateNoteInput:any =this.renderer.selectRootElement("#dateNote",true).value;
      if(result.isConfirmed ){
        if(result.value !=""){
          if(dateNoteInput ==""){
            dateNoteInput =this.utils.getDatetime();
          }else{
            let date= new Date(dateNoteInput);
            dateNoteInput =this.utils.getDatetime(date);
          }
          let dataToSend={
            user_api_hash:this.user.hash,
            user_id:this.userId,
            description:result.value,
            creation_date:dateNoteInput,
            action:"addNote"
          };
          this.api.createData(dataToSend, "user",true).then((data:any)=>{
            if(this.validationService.isResponseApi(data)){
              if(data.status==1)
                this.getUserNotes();
              this.utils.showResultRequest(data.status != 1 ? "error" : "success","Información",data.message??"");
            }
          }).catch((error )=>{console.log(error);}).finally(()=> this.utils.hideLoading(() => this.loading = false));
        }else{
          this.utils.showMsg("","Ingrese nota a registrar");
          this.utils.hideLoading(() => this.loading = false);
        }
      }
    }).catch(()=>{}).finally(()=> this.utils.hideLoading(() => this.loading = false));
  }
  getUserNotes(){
    this.loading = true;
    this.userNotes=[];    
    this.api.getData("user","?action=getUserNotes&user_api_hash="+this.user.hash+"&user_id="+this.userId,true).then((userNotes:any)=>{
      if(this.validationService.isResponseApi(userNotes) && userNotes.status == 1)
        this.userNotes=userNotes.data;
    }).finally(()=>this.utils.hideLoading(()=>{this.loading = false}));
  }
  deleteUserNote(noteId:number){
    this.utils.showConfirm("Confirmar accion","Esta seguro de eliminar esta nota","Eliminar","Cancelar").then(()=>{
      let data="?action=deleteUserNote&user_api_hash="+this.user.hash+"&note_id="+noteId;
    this.api.deleteData("user",data,true).then((data: any) => {
      if(this.validationService.isResponseApi(data) && data.status == 1){
        if(data.status==1)
            this.getUserNotes();
        this.utils.showResultRequest(data.status != 1 ? "error" : "success","Información",data.message??"");
      }
    }).catch((err: any) => {
      this.utils.showMsg("Acción no completada","Se presentó problemas al eliminar la nota");
    }).finally(()=>this.utils.hideLoading(()=>{this.loading = false}));
    }).catch(()=>{}).finally(()=>this.utils.hideLoading(()=>{this.loading = false}));;
  }

  closeComponent(){
    this.close.emit(true);
  }
}
