import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { DataService } from '../../services/data/data.service';
import { IUserPlanModule } from "src/app/core/models/interfaces/iuser-session-data.model";
import { UtilsService } from "../../services/utils/utils.service";
import { AuthService, ROUTES_TO_LOGIN } from "../../services/auth/auth.service";
import { ObjectInitializationService } from '../../services/object-initialization/object-initialization.service';
export const accessControlGuard:CanActivateFn = (route,state)=>{
  
  const router = inject(Router);
  const authService = inject(AuthService);
  const dataService= inject(DataService);
  const objectInitializationService = inject(ObjectInitializationService);
  let response:{status:boolean,data:IUserPlanModule}  = authService.checkPermissionModule(route.data["componentId"]);
  dataService.currentSelectedModulePermissionsSgc = response.status ? response.data : objectInitializationService.initializeIUserPlanModule();
  if(!response.status)
    router.navigate([ROUTES_TO_LOGIN.user_driver]);
  return response.status;
}