import { Injectable } from '@angular/core';
import { IBaseColors, IColorPalette, IColorVariant } from '../../../shared/models/interfaces/color-palette.model';
import { IBasicUserData, IUserGeneral, IUserIntgps, IUserSetting, IUserSgc, IUserSgcFileToUpload } from 'src/app/core/models/interfaces/user-data.model';
import { IPermissionsIntgps } from 'src/app/core/models/interfaces/ipermissions-intgps.model';
import { IDriverGeneral } from 'src/app/core/models/interfaces/idriver.model';
import { IDeviceStatusOption, IDeviceStatusRecord } from 'src/app/core/models/interfaces/idevice-status-option.model';
import { IDeviceChecklist, IDeviceChecklistAnswerAnswered, IDeviceChecklistAnswerOption, IDeviceChecklistAnswered, IDeviceChecklistGridAnswerOption, IDeviceChecklistGridSubquestion, IDeviceChecklistQuestion } from 'src/app/core/models/interfaces/idevice-checklist.model';
import { IUserPlanModule, IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { IDeviceBasicData, IDeviceGeneral, IDeviceIntgps, IDevicePersonalizedPlan, IDeviceSgc, IDeviceWithBillingPlan } from 'src/app/core/models/interfaces/idevice.model';
import { ITrip } from 'src/app/core/models/interfaces/itrip.model';
import { IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { ISupportedFiles } from 'src/app/shared/models/interfaces/isupportedFiles.model';
import { IPayment } from 'src/app/core/models/interfaces/ipayment.model';
import { IChartConfigData, IColumnStructureToDisplayInTable, ITimeUnit } from 'src/app/shared/models/interfaces/iutil.model';
import { AlignDataTable, ChartTypeChartjs, DataStatesTypes, DevicePlanType, PipeTypes, TimeUnit, USER_ROLES_ID } from 'src/app/shared/enums/common-enums.enum';
import { IDistributorPaymentOption, IIdentificationDocumentType } from 'src/app/core/models/interfaces/iutils-data.model';
import { ChartData, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { UtilsService } from '../../services/utils/utils.service';
import { AutomationTimeUnit, IAutomation, IAutomationTargetUser, IAutomationNotificationSendLog, IAutomationType, IUserAssociatedWithAutomation } from 'src/app/core/models/interfaces/iautomation.model';
import { IUserMessagingServiceApiPhoneLine, IUserMessagingServiceApiPhoneLineQrCode, UserMessagingServicePhoneLineActivationStatus, UserMessagingServicePhoneLineConnectionValues } from 'src/app/core/models/interfaces/imessaging-service-api.model';
import { IReport } from 'src/app/core/models/interfaces/ireport.model';
import { IInvoiceNote } from 'src/app/core/models/interfaces/iinvoice.model';
import { IWebService, IWebServiceCredential } from 'src/app/views/integrations/models/interfaces/iweb-service.model';
@Injectable({
  providedIn: 'root'
})
export class ObjectInitializationService {

  constructor(
    private utils: UtilsService
  ) { }

  initializeColorPalette():IColorPalette{
    let colorVariant:IColorVariant = {light:"",dark:"",base:"",contrast:""};
    return {
      primary:{...colorVariant},secondary:{...colorVariant},accent:{...colorVariant}
    };
  }
  initializeBaseColors():IBaseColors{
    return {primary:"",secondary:"",accent:""};
  }
  initializeIBasicUserData():IBasicUserData{
    return {
      logo_url:"",
      sign_url:"",
      address:"",
      business_contact_information: {
        whatsapp: {
          country_code: "",
          number: ""
        },
        social_media:{
          facebook:"",
          twitter:"",
          instagram:"",
          youtube:"",
          linkedin:""
        },
        commercial_contact_email:"",
        tracking_website_url:""
      }
    };
  }
  initializeIDriver(createdBy:number,countryId:number|null):IDriverGeneral{
    return {
        id:null,
        user_id:createdBy,
        device_id:null,
        device_port:null,
        name:"",
        email:"",
        phone:"",
        rfid:"",
        description:"",
        address:"",
        created_at:"",
        updated_at:"",
        devices:[],
        current:false,
        emergency_phone_number:"",
        document_number:"",
        boold_type:"",
        password:"",
        password_confirmation:"",
        api_hash:"",
        country_id:countryId    
    };    
  }
  /**
   * 
   * @param userId 
   * @param userRole El rol del usuario a asiignar los permisos predeterminados 
   * @param {boolean} allDisabled Indica si todas los permisos se iniciaran en deshabilitado, Por defecto es false, lo cual dara efecto a aplicar valores segun el rol del usuario 
   * @returns 
   */
  initializeIPermissionsIntgps(userId:number|null=null, userRole: USER_ROLES_ID | null = null,allDisabled: boolean = false ):IPermissionsIntgps[]{
    return [
      {
          user_id: userId,
          name: "devices",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: 0,
          remove: 0
      },
      {
          user_id: userId,
          name: "alerts",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "geofences",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "routes",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "poi",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "reports",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "drivers",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "custom_events",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "user_gprs_templates",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "user_sms_templates",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "sms_gateway",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: 0,
          remove: 0
      },
      {
          user_id: userId,
          name: "send_command",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "history",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: 0,
          remove: 0
      },
      {
          user_id: userId,
          name: "maintenance",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: 0,
          remove: 0
      },
      {
          user_id: userId,
          name: "camera",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "device_camera",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "tasks",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "chat",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "device.imei",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "device.sim_number",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "device.forward",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "device.protocol",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: 0,
          remove: 0
      },
      {
          user_id: userId,
          name: "device.expiration_date",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: 0,
          remove: 0
      },
      {
          user_id: userId,
          name: "device.installation_date",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "device.sim_activation_date",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "device.sim_expiration_date",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "device.msisdn",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "sharing",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0)
      },
      {
          user_id: userId,
          name: "device_configuration",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: 0,
          remove: 0
      },
      {
          user_id: userId,
          name: "device_route_types",
          view: 0,
          edit: 0,
          remove: 0
      },
      {
          user_id: userId,
          name: "call_actions",
          view: 0,
          edit: 0,
          remove: 0
      },
      {
          user_id: userId,
          name: "users",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      },
      {
          user_id: userId,
          name: "user.client_id",
          view: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          edit: allDisabled ? 0 : (userRole == USER_ROLES_ID.MANAGER ? 1 : 0),
          remove: 0
      }
  ];
  }
  initializeIPayment(invoiceId: number = 0,  amount: number = 0,paymentMediumId: number | null = null, paymentMethodId: number | null = null, financialInstitutionId: number | null = null, invoiceDetailId: number | null = null): IPayment{
    return {
      id:  0, 
      invoice_id: invoiceId,
      payment_medium_id: paymentMediumId, 
      payment_method_id: paymentMethodId,
      created_at: "", 
      amount: amount ,
      financial_institution_id: financialInstitutionId, 
      invoice_detail_id: invoiceDetailId
    };
  }
  initializeIPaymentAgreement(invoiceId: number = 0,){
    return {
      id: 0,
      invoice_id: invoiceId,
      agreement_datetime: "",
      comment: "",
      created_at: "",
      updated_at: null
    }
  }
  initializeIINvoiceNote(invoiceId: number): IInvoiceNote{
    return {
      id: 0,
      invoice_id: invoiceId,
      note: "",
      created_at: ""
    };
  }
  initializeIInvoiceDetail(invoiceId:number = 0, deviceId:number = 0, price:number = 0, monetaryUnitId: number = 0){
    return{
      id: 0,
      invoice_id: invoiceId,
      device_id: deviceId,
      price: price,
      monetary_unit_id: monetaryUnitId,
      old_device_expiration_date: "",
      created_at: "",
      updated_at: "",
      device_plate_number: "",
      device_name: "",
      device_imei: "",
      vehicle_type_name: "",
      device_plan_periodicity_name: "",
      monetary_unit_code: "",
      observation: "",
      payment_details: [],
    }
  }
  initializeIDeviceStatusRecord(driverId:number,deviceId:number, deviceTripId:number| null):IDeviceStatusRecord{
    return {
      id:0,
      device_trip_id: deviceTripId,
      driver_id: driverId,
      device_id:deviceId,
      device_status_option_id: 0,
      created_at:"",
      updated_at: ""
    };
  }
  initializeIDeviceStatusOption(userId:number):IDeviceStatusOption{
    return {
        id:null,
        name:"",
        description:"",
        user_id:userId,
        device_status_option_configuration: {
            device_status_option_id:0,
            user_id:userId,
            position:0,
            enabled:1,
            custom_name:"",
            custom_description:""
        }
    }
  }
  initializeITrip(deviceId:number|0 = 0,driverId:number|0 = 0,deviceName:string = "", devicePlateNumber: string = ""):ITrip{
    return {
        id:0,
        title:"",
        description:"",
        driver_id:driverId,
        device_id:deviceId,
        start_datetime:"",
        end_datetime:"",
        
        start_address:"",
        end_address:"",
    
        created_at:"",
        updated_at:"",
        device:{
          id:deviceId,
          name:deviceName,
          plate_number:devicePlateNumber
      }
    }
  }

  initializeIIdentificationDocumentType():IIdentificationDocumentType{
    return {
      id: 0,
      name: "",
      acronym: ""
    };
  }
  initializeIDeviceChecklist(userId:number):IDeviceChecklist{
    return{
        id: 0,
        name: "", 
        description: "", 
        available: 1, 
        created_by:userId, 
        created_at: "",
        updated_at: ""
    };
  }
  initializeIDeviceChecklistAnswerAnswered( deviceChecklistQuestionId:number,deviceChecklistAnsweredId:number, deviceChecklistridSubquestionId:number | null = null,deviceChecklistGridAnswerOptionId: number | null = null,deviceChecklistAnswerOptionId:number | null = null ,formControlActionTypeId:number | null = null, observation: string | null = null, attachedFileName:string = "",attachedFile:File | ArrayBuffer | null = null):IDeviceChecklistAnswerAnswered{
    return{
      device_checklist_answered_id: deviceChecklistAnsweredId,
      device_checklist_question_id: deviceChecklistQuestionId, 
      device_checklist_grid_subquestion_id: deviceChecklistridSubquestionId, 
      device_checklist_grid_answer_option_id: deviceChecklistGridAnswerOptionId, 
      device_checklist_answer_option_id: deviceChecklistAnswerOptionId,
      observation: observation,
      attached_file_name: attachedFileName,
      form_control_action_type_id: formControlActionTypeId,
      attached_file: attachedFile
    }
  }
  initializeIDeviceChecklistAnswered(userDriverId?:number,driverName?: string,deviceId?: number, deviceName?: string,devicePlateNumber?: string , deviceChecklistId?:number,deviceChecklistName?: string, createdAt?: string): IDeviceChecklistAnswered{
    return {
      id: 0, 
      user_driver_id: userDriverId??0, 
      device_id: deviceId??0, 
      device_checklist_id: deviceChecklistId??0, 
      kilometer_count: null,
      observation: "", 
      signature_file_name:"",
      /** Asignada de forma recursiva para cuando se asigna en el formulario se pueda mostrar la fecha actual en que se diligencia */
      created_at: createdAt??"", 
      updated_at: "",
      device_name: deviceName??"",
      device_plate_number: devicePlateNumber??"",
      driver_name: driverName??"",
      device_checklist_name: deviceChecklistName??"",  
    };
  }
  initializeISort():ISort{
    return {direction:"",by:""};
  }
  initializeIPagination(pageSizeOptions:number[], applyPagination: boolean = true):IPagination{
    return {
        length_page: 0,
        page_size: 0,
        page_size_options:pageSizeOptions,
        page_event:null,
        current_page:0,
        apply_pagination: applyPagination
    };
  }
  initializeIColumnStructureToDisplayInTable(name:string, label: string, pipeTypes?: PipeTypes, fieldTooltip?: string, tooltipDataStateType?: DataStatesTypes,sorted: boolean = false, alignHeader: AlignDataTable = AlignDataTable.LEFT, alignData: AlignDataTable = AlignDataTable.LEFT, isActionColumn: boolean = false, visible: boolean = true , initialTooltipText: string = "",pipeOption: string ="", toolTipFunction: Function | null = null, headerHelpTooltipText: string  = '' ):IColumnStructureToDisplayInTable{
    return {
      name: name,
      label: label,
      sorted:sorted, 
      align_header: alignHeader, 
      align_data: alignData, 
      is_action_column: isActionColumn,
      visible: visible,
      pipe_type: pipeTypes,
      pipe_option: pipeOption,
      field_tooltip: fieldTooltip,
      initial_tooltip_text: initialTooltipText,
      tooltip_data_state_type: tooltipDataStateType,
      tooltip_action: toolTipFunction,
      header_help_tooltip_text: headerHelpTooltipText
      };
  }
  initializeIUserSessionData():IUserSessionData{
    return{
        id: 0,
        role_id:null,
        role_name: "",
        hash: "",
        email: "",
        manager_id: 0,
        name: "",
        platform_plan_id: null,
        country_id:  null,
        country_name: "",
        platform_plan: null,
        notify_tasks: 0,
    };
  }
  initializeIUserPlanModule():IUserPlanModule{
    return {
      module_id: 0,
      module_name: "",
      permissions: []
    }
  }
  initializeIReport():IReport{
    return {
      id: 0,
      report_type_id: null,
      title: "",
      group_by_device: false,
      start_datetime: null,
      end_datetime: null,
      format: null,
      devices: []
    };
  }
  initializeIDistributorPaymentOption(distributorId: number):IDistributorPaymentOption{
    return {
      id: 0,
      name: "",
      account_type: null, 
      account_number:  null, 
      payment_proccess: [] , 
      distributor_id: distributorId,
      step_actual: ""
    };
  }
  initializeIDeviceChecklistAnswerOption(position:number,questionId?:number, actionTypeId?:number|null,value?:string):IDeviceChecklistAnswerOption{
    return{
        id: null, 
        device_checklist_question_id:questionId??0, 
        value: value??"", 
        action_type_id:actionTypeId??null, 
        position:position, 
        destination_category_id:null,
        created_at:"", 
        updated_at: ""
    };
  }

  initializeIDeviceChecklistGridSubquestion(position:number,questionId?:number,value?:string):IDeviceChecklistGridSubquestion{
    return{
        id: null, 
        device_checklist_question_id: questionId??0, 
        value: value??"", 
        position: position, 
        created_at: "", 
        updated_at: ""
    };
  }
  initializeIDeviceChecklistGridAnswerOption(position:number,actionTypeId:number|null,questionId?:number,value?:string):IDeviceChecklistGridAnswerOption{
    return{
        id: null, 
        device_checklist_question_id: questionId??0, 
        value: value??"", 
        position: position, 
        created_at: "", 
        updated_at: "",
        action_type_id: actionTypeId 
    };
  }
  initializeIDeviceWithBillingPlan(): IDeviceWithBillingPlan{
    return {
      id: 0,
      imei:"",
      name: "",
      plate_number: "", 
      unit_price:  null,
      personalized_plan_id: 0, 
      monetary_unit_id: null,
      monetary_unit_code: null,
      payday: 1,
      billing_frequency_id: null,
      billing_frequency:  null,
      first_billing_date: null,
      billing_plan_disabled: null,
      user_responsible_email:  null,
      user_responsible_id: null
    };
  }
  initializeIDeviceBasicData(): IDeviceBasicData{
    return {
        id:0,
        plate_number:"",
        name:"",
        imei:"",
    };
  }
  initializeIDevicePersonalizedPlan(id: number | null,unitPrice: number,payday: number, billingFrequencyId: number, monetaryUnitId: number , firstBillingDate: string, disabled: 1 | 0 | null = null):IDevicePersonalizedPlan{
    return {
      id: id,
      unit_price: unitPrice,
      payday: payday,
      billing_frequency_id: billingFrequencyId,
      monetary_unit_id:  monetaryUnitId,
      first_billing_date: firstBillingDate,
      disabled: disabled
    };
  }
  initializeIDeviceGeneral(): IDeviceGeneral{
    return Object.assign(this.initializeIDeviceSgc(),this.initializeIDeviceIntgps());
  }
  initializeIDeviceIntgps(): IDeviceIntgps{
    return{
      id: 0,
      current_driver_id: null,
      timezone_id: null,
      traccar_device_id: 0,
      icon_id: 0,
      icon_colors: {
        moving: "",
        stopped: "",
        offline: "",
        engine: ""
      },
      active: 1,
      deleted: 0,
      name: "",
      imei: "",
      fuel_measurement_id: 0,
      fuel_quantity: 0,
      fuel_price: 0,
      fuel_per_km: 0,
      fuel_per_h: 0,
      sim_number: "",
      msisdn: "",
      device_model: "",
      plate_number: "",
      vin:  "",
      registration_number: "",
      object_owner: "",
      additional_notes: "",
      sim_expiration_date: "",
      sim_activation_date: "",
      installation_date: "",
      tail_color: "",
      tail_length: 0,
      engine_hours: "",
      detect_engine: "",
      detect_speed: "",
      detect_distance:  null,
      min_moving_speed: 0,
      min_fuel_fillings: 0,
      min_fuel_thefts: 0,
      snap_to_road: 0,
      gprs_templates_only: 0,
      valid_by_avg_speed: 0,
      parameters: [],
      currents: null,
      forward: null,
      device_type_id: null,
      app_uuid: null,
      app_tracker_login: "",
      expiration_date: "",
      created_at: "",
      updated_at: "",
      group_id: 0,
      users: [],


    };
  }
  initializeIDeviceSgc(): IDeviceSgc{
    return {
      id: 0,
      warranty_date:  null,
      cylinder_capacity:  null,
      payload_capacity_in_tons: null,
      vehicle_model: null,
      vehicle_color: null,
      vehicle_type: null,
      vehicle_brand: null,
      owner_phone: null,
      trailer_plate_number: null,
      telephone_operator_id: null,
      personalized_plan_id: null,
      personalized_plan:{
        id:null,
        monetary_unit_id:null,
        billing_frequency_id:null,
        payday:null,
        first_billing_date:"",
        unit_price:null,
        disabled: null
      },
      responsible_user: {id:0,email:""},
      created_at: "",
      updated_at: ""
    }
  }
  /**
   * 
   * @param {number} id El id de la automatizacion
   * @param {number} active El indicador de activo o inactivo ( 1 | 0)
   * @param {number} createdBy El id del usuario que realiza el registro de la automatizacion
   * @param automationTypeId El tipo de automatizacion usada 
   * @param name El nombre de la automatización
   * @param smsTemplate La plantilla de mensaje usada para el envio de la automatizacion
   * @param timeQuantity la cantidad de tiempo a usar para la verificaicon de los procesamientos a realizar según el tipo de automatizacion
   * @param timeUnit la unidad de tiempo usada para la revision de envio de notificaciones 
   * @param {number} periodicExecution el indicador de si es una ejecucion periodica segun el proposito de cada tipo de automatizacion (1 | 0) 
   * @returns {IAutomation}
   */
  initializeIAutomation(id: number = 0,active: 1|0 = 1,createdBy: number, automationTypeId: number, name: string = "" , smsTemplate: string | null = "",timeQuantity: number | null = null, timeUnit: AutomationTimeUnit | null = null, periodicExecution: 1 | 0 | null = null): IAutomation{
    return {
      id: id, 
      created_by: createdBy, 
      name: name,
      active: active, 
      automation_type_id: automationTypeId, 
      sms_template:  smsTemplate, 
      time_quantity:  timeQuantity,
      time_unit: timeUnit, 
      periodic_execution: periodicExecution, 
      created_at: "",
      updated_at:  null
    };
  }
  initializeIUserMessagingServicePhoneLine(userMessagingServiceApiCredentialId: number = 0, connected: UserMessagingServicePhoneLineConnectionValues = UserMessagingServicePhoneLineConnectionValues.DISCONNECTED):IUserMessagingServiceApiPhoneLine{
    return{
      id: 0,
      name: "",
      activation_status: UserMessagingServicePhoneLineActivationStatus.PENDING,
      api_phone_number_identifier: null,
      phone_number: "",
      user_messaging_service_api_credential_id: userMessagingServiceApiCredentialId,
      connected: connected,
      sent_message_count:0,
      max_message_quota: 0,
      available_message_count: 0,
      created_at: "",
      updated_at: ""
    }
  }
  initializeIUserMessagingServiceApiPhoneLineQrCode(): IUserMessagingServiceApiPhoneLineQrCode{
    return {
      qr_url: "",
      qr_duration: 0
    }
  }
  /**
   * 
   * @param userId el id del usuario a quien se le envia la la notificacion
   * @param automationId el ID de la automatizacion usada para el envio de la notificacion
   * @param userMessagingServicePhoneLineId La linea de telefono de api de mensajeria usada para el envio de la notificacion 
   * @returns {IAutomationNotificationSendLog}
   */
  initializeIAutomationNotificationSendLog(userId: number,automationId: number = 0, userMessagingServicePhoneLineId: number = 0): IAutomationNotificationSendLog{
    return {
      id: 0, 
      user_messaging_service_phone_line_id: userMessagingServicePhoneLineId, 
      automation_id: automationId, 
      user_id: userId,
      sender_phone_number: "", 
      recipient_phone_number: "", 
      message: "", 
      result_message: null, 
      delivery_success: null, 
      sent_at: null, 
      created_at: ""
    };
  }
  /**
   * @param userId el id del usuario asociado
   * @param createdBy El id del usuario que registra la asociacion
   * @param automationId El id de la automatizacion asociada
   * @param active El estado de desponibilidad de la asociacion
   * @returns {IAutomationTargetUser}
   */
  initializeIAutomationTargetUser(userId: number, createdBy: number, automationId: number, active: 1 | 0 = 1): IAutomationTargetUser{
    return {
      user_id: userId, 
      automation_id: automationId, 
      deactivation_datetime: null, 
      reactivation_datetime: null, 
      active: active, 
      created_by: createdBy, 
      updated_by:  null, 
      created_at: "", 
      updated_at:  null
    };
  }
  /**
   * 
   * @param name El nombre de la automatizacion
   * @param description La descripcion de la automatizacion
   * @returns {IAutomationType}
   */
  initializeIAutomationType(name: string = "", description: string = ""): IAutomationType{
    return {
      id: 0, 
      name: name, 
      description:  description, 
      created_at: ""
    };
  }
  /**
   * 
   * @param userId El id del usuario asociado
   * @param createdBy El id del usaurio que registra la asociacion
   * @param automationId El id de la automatizacion a asociar al usuario
   * @param phoneNumber El numbero de telfono del usuario asciado
   * @param name El nombre del usuario asociado
   * @param email el email de usuario asociado
   * @returns {IUserAssociatedWithAutomation}
   */
  initializeIUserAssociatedWithAutomation(userId: number, createdBy: number,automationId: number,phoneNumber: string | null = null, name: string | null = null, email: string | null = null, ): IUserAssociatedWithAutomation{
    return {
      ...{
        id: userId,
        name: name,
        email: "",
        phone_number: phoneNumber
      },
      ... this.initializeIAutomationTargetUser(userId,createdBy,automationId)
    };
  }
  initializeIUserSgcFileToUpload(): IUserSgcFileToUpload{
    return {
      bank_qr_code_filename: null,
      bank_qr_code_file: null,
      temp_bank_qr_code_file:  null,
      delete_bank_qr_code_file: false,
      
      logo_url: null,
      temp_logo_url:  null,
      logo_file:  null,
      delete_logo_url: false,
      
      sign_url: null,
      sign_file:  null,
      temp_sign_url:  null,
      delete_sign_url: false
    }
  }
  initializeIUserGeneral(managerId:number, availableMaps:number[], devicePlanType: DevicePlanType, userId: number = 0): IUserGeneral{
    return Object.assign(
      this.initializeIUserSgc(devicePlanType, userId),
      this.initializeIUserIntgps(managerId, availableMaps, userId),
      {
        complete_data:false,
        password: "",
        password_confirmation: "",
        permissions: this.initializeIPermissionsIntgps(),
        permissions_to_update: [],
        is_update_password: false,
      }
      );
  }
  initializeIUserSetting(userId:number):IUserSetting{
    return {
      user_id: userId,
      invoice_due_time_quantity:  null,
      invoice_due_time_unit:  null,
      display_payment_option_in_billing: null
    }
  }
  /**
   * 
   * @param {DevicePlanType} devicePlanType el tipo de plan asignado al usuario
   * @param {number} userId El id del usuario inicializado 
   * @returns 
   */
  initializeIUserSgc(devicePlanType?: DevicePlanType, userId: number = 0):IUserSgc{
    return {
      id: userId,
      created_at: "",
      updated_at: null,
      birth_date: "",
      name: null,
      document_number: null,
      address: null,
      deleted: null,
      country_id: null,
      city_id: null,
      contact_name: null,
      logo_url: null,
      sign_url: null,
      personalized_plan_id: null,
      platform_plan_id: null,
      identification_document_type_id:  null,
      courtesy_title:  null,
      legal_personality_type:  null,
      phone_prefix:  null,
      notify_tasks:  null,
      last_tasks_notification:  null,
      bank_qr_code_filename: null,
      personalized_plan: {
          id:  null,
          unit_price:  null,
          payday:  null,
          billing_frequency_id:  null,
          monetary_unit_id:  null,
          disabled:null
      },
      devices_responsible: [],
      plan_type: devicePlanType,
      user_setting: this.initializeIUserSetting(userId)
    };
  }
  initializeIUserIntgps(managerId:number, availableMaps:number[],userId:number = 0):IUserIntgps{
    return{

      id: userId,
      active: 1,
      group_id: 0,
      manager_id: managerId,
      billing_plan_id: null,
      map_id: null,
      devices_limit:  null,
      email: "",
      phone_number: null,
      available_maps: availableMaps,
      week_start_day: 1,
      top_toolbar_open: 1,
      unit_of_altitude: "mt",
      unit_of_distance: "km",
      unit_of_capacity: "lt",
      timezone_id: null,
      sms_gateway: 0,
      sms_gateway_url: "",
      lang: "",
      sms_gateway_app_date:  null,
      subscription_expiration:  null,
      loged_at: "",
      api_hash_expire:  null,
      created_at: "",
      updated_at:  null
    };
  }
  initializeIDeviceChecklistQuestion(deviceChecklistId:number,questionCategory:number, formControlType:number):IDeviceChecklistQuestion{
    return{
        id: null,
        device_checklist_id: deviceChecklistId,
        device_checklist_question_category_id:questionCategory, 
        form_control_type_id: formControlType, 
        label:"", 
        description: "", 
        is_required:1, 
        enabled: 1,
        position: 1, 
        created_at:"",
        updated_at:"",
        answer_option: [],
        grid_answer_option: [],
        grid_subquestion: []
    };
  }
  initializeITimeUnit():ITimeUnit{
    return{
      minute:{
          value: TimeUnit.MINUTE,
          label: "Segundo"
      },
      hour:{
          value: TimeUnit.HOUR,
          label: "Hora"
      },
      day:{
          value: TimeUnit.DAY,
          label: "Día"
      },
      week:{
        value: TimeUnit.WEEK,
        label: "Semana"
      },
      month:{
          value: TimeUnit.MONTH,
          label: "Mes"
      },
      year:{
          value: TimeUnit.YEAR,
          label: "Año"
      }
    };
  }
  /**
   * 
   * @param addDefaultAcceptValue Agrega los valores de fyle_type por defecto a accept_value y accept_label
   */
  initializeISupportedFiles(addDefaultAcceptValue:boolean = true,acceptType:{jpeg:boolean,jpg:boolean,png:boolean,webp:boolean,gif:boolean,ico:boolean} = {jpeg:true,jpg:true,png:true,webp:true,gif:false,ico:false}):ISupportedFiles{
    let item: ISupportedFiles = {
      accept_value:"",
      accept_label:"",
      max_image_size:"",
      max_favicon_size:"",
      crop_orientation:{
        portrait:"vertical",
        landscape:"horizontal"
      },
      crop_aspect_ratio:{
        r3_2:{
          label:"3:2",
          mat_icon:"crop_3_2"
        },
        r5_4:{
          label:"5:4",
          mat_icon:"crop_5_4"
        },
        r1_1:{
          label:"1:1",
          mat_icon:"crop_square"
        }
      },
      list_accept_file_type:[],
      file_type:{
          jpeg:{
              label:"jpeg",value:"image/jpeg"
          },
          jpg:{
              label:"jpg",value:"image/jpg"
          },
          png:{
              label:"png",value:"image/png"
          },
          webp:{
              label:"webp",value:".webp"
          },
          gif:{
              label:"gif",value:".gif"
          },
          ico:{
            label:"ico",value:".ico"
          }
      }
    }
    if(addDefaultAcceptValue){
      item.list_accept_file_type = [];
      let acceptLabel: string[] = [];
      let acceptValue: string[] = [];
      if(acceptType.jpg){
        item.list_accept_file_type.push(item.file_type.jpg.label);
        acceptValue.push(item.file_type.jpg.value)
        acceptLabel.push(item.file_type.jpg.label)
      }
      if(acceptType.jpeg){
        item.list_accept_file_type.push(item.file_type.jpeg.label);
        acceptValue.push(item.file_type.jpeg.value)
        acceptLabel.push(item.file_type.jpeg.label)
      }
      if(acceptType.png){
        item.list_accept_file_type.push(item.file_type.png.label);
        acceptValue.push(item.file_type.png.value)
        acceptLabel.push(item.file_type.png.label)
      }
      if(acceptType.webp){
        item.list_accept_file_type.push(item.file_type.webp.label);
        acceptValue.push(item.file_type.webp.value)
        acceptLabel.push(item.file_type.webp.label)
      }
      item.accept_label = acceptLabel.join(", ");
      item.accept_value = acceptValue.join(", ");
    }
    return item;
  }
  initializeIChartConfigData(title: string,ChartType: ChartTypeChartjs, data: ChartData, updatedChart: boolean, displayLegend: boolean = true):IChartConfigData{
    let response: IChartConfigData = {
    title:title,
    chart_type: ChartType,
    data:data,
    updated_chart: updatedChart
    };
    if(ChartTypeChartjs.DOUGHTNUT == ChartType || ChartTypeChartjs.PIE == ChartType){
      response.chart_options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { 
            display: displayLegend,
            //position: 'right', //  ajusta la posición de la leyenda 
            align: 'start', // Alinea las etiquetas al principio de la leyenda (usar 'center' o 'end' tambien)
           },
          datalabels: {
            anchor: 'center',
            align: 'center',
            backgroundColor:"#fff",
            borderRadius:2, 
            formatter: (value:any) => {
              return value==0 ?null:(value + '%');
            }
          },
        }
      }
    }
    else if(ChartTypeChartjs.BAR == ChartType || ChartTypeChartjs.LINE == ChartType){
      response.chart_options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {},
          y: {
            min: 0,
    
            ticks: {
              stepSize: 5
            }
          }
        },
        plugins: {
          legend: { display: displayLegend ,align: "start"},
          datalabels: {
            anchor: 'end',
            align: 'end'
          }
        },
      }
    }
    response.chart_plugins = [DataLabelsPlugin, this.utils.adjustLegendMarginChartjs()];
    return response;
  }

  
  initializeIWebServiceCredential(userId: number, webServiceId: number): IWebServiceCredential{
    return {
      created_at: "",
      updated_at: "",
      last_login: "",
      id: 0,
      user_id: userId,
      web_service_id: webServiceId,
      active_credential: 1,
      username: "",
      access_token: "",
      api_key: "",
      password: "",
      configurations: null
    };
  }

  initializeIWebService(): IWebService{

    return {
      id:0,
      name: "",
      description: "",
      module_id: 0,
      country_id: 0,
      created_at: "",
      updated_at: "",
      status: 0,
      version: "",
      require_authentication: "",
      url_external: "",
      user_credential:null,
    };
  }
}
