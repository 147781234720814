import { Injectable } from '@angular/core';
import { IUserMessagingServiceApiPhoneLine, IUserMessagingServiceApiPhoneLineQrCode, IUserMessagingServiceApiPhoneLineSummaryByActivationStatus, IUserMessagingServiceApiPhoneLineSummaryByConnection } from 'src/app/core/models/interfaces/imessaging-service-api.model';

type MaxFieldLength = Pick <IUserMessagingServiceApiPhoneLine,"name" | "phone_number">;

interface IAccountPendingActivationMessage{
  title: string,
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class MessagingServiceApiPhoneLineService {

  readonly MAX_FIELD_LENGTH: Record<keyof MaxFieldLength,number>;
  /**
   * @type {number} El numero de cuentas que un usuario puede tener pendientes
   */
  readonly PENDING_ACTIVATION_PHONE_LINE_LIMIT: number = 2;
  /**
   * @type {IAccountPendingActivationMessage} Indica el mensaje de estado de cuenta pendiente de activación, Posee contenido HTML
   */
  readonly ACCOUNT_PENDING_ACTIVATION_MESSAGE: IAccountPendingActivationMessage = {
    title:"Muy pronto podrás vincular tu cuenta con WhatsApp",
    message: "<p class='fs-small'>Tu solicitud de creación de cuenta se ha enviado con éxito. Te avisaremos una vez esté activa para que escanees el código QR de tu cuenta de WhatsApp. Este proceso puede tomar entre 24 y 48 horas.</p>"
  };
  constructor() { 
    this.MAX_FIELD_LENGTH = {name:80, phone_number:15};
  }
  isIUserMessagingServiceApiPhoneLineQrCode(obj: any): obj is IUserMessagingServiceApiPhoneLineQrCode{
    return typeof obj.qr_duration =="number" && typeof obj.qr_url =="string";
  }
  isISummaryByConnection(obj: any): obj is IUserMessagingServiceApiPhoneLineSummaryByConnection{
    return typeof obj.connected != "undefined" && typeof obj.total != "undefined"; 
  }
  isISummaryByActivationStatus(obj: any): obj is IUserMessagingServiceApiPhoneLineSummaryByActivationStatus{
    return typeof obj.activation_status != "undefined" && typeof obj.total != "undefined"; 
  }
}
