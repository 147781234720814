import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { ColorManipulationService } from 'src/app/core/services/color-manipulation/color-manipulation.service';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

/**
 * Configuracion para grafico lineal o de barra
 */
export interface IBarLineChartConfigData{
  /** @description Indica el titulo del grafico */
  title:string,
  /** @description Tipo de grafico */
  chart_type:"bar"|"line",
  /** @description Conjunto de datos a mostrar */
  data:ChartData,
  /** @description Indica si el gráfico esta actualizado*/
  updated_chart:boolean,
}
@Component({
  selector: 'app-bar-line-chart',
  templateUrl: './bar-line-chart.component.html',
  styleUrls: ['./bar-line-chart.component.scss']
})
export class BarLineChartComponent implements OnInit{

  @Input() barLineChartConfigData:IBarLineChartConfigData = {
    title:"",chart_type:"line",data:{labels:["Sin datos"],datasets:[{data:[0],label:"",backgroundColor:this.colorManipulationService.DEFAULT_COLORS}]},updated_chart:true
  };

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {},
      y: {
        min: 0,
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: { display: true, },
      datalabels: {
        anchor: 'end',
        align: 'end',
        labels: {
          value: {
            color: '#404040',
            backgroundColor: 'rgba(255,255,255,0.8)',
            borderColor: 'rgba(255,255,255,0.6)',
            borderWidth: 2,
            borderRadius: 2,
            padding: 2,
            align: 'bottom',
          }
        }
      }
    }
  };
   barChartType: ChartType = 'bar';
   barChartPlugins = [DataLabelsPlugin];

  constructor(
    private colorManipulationService:ColorManipulationService
  ){
    this.barChartType = this.barLineChartConfigData.chart_type;
  }
  ngOnInit(): void {
    this.barChartType = this.barLineChartConfigData.chart_type;
  }
}