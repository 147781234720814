<div class="border-bottom-secondary d-flex justify-content-between ">
    <h2 > {{viewsAvailable['registration_form'].show_view ? "": "Historial de "}} Acuerdos de pago de factura # {{invoiceId}} </h2>
  </div>
  <mat-dialog-content class="mat-typography form-box-container">
  <ng-container>
    <section class="form-box animate__animated animate__fadeIn">
      <div class="form-box__content form-box__content-h-auto g-0">
        <section class="d-block alert alert-light-info">
            <p class="text-small m-0">{{MAX_AGREEMENTS_PER_INVOICE_LEGEND}}</p>
        </section>
        <section class="d-block alert alert-light-danger" *ngIf="paymentAgreementList.length >= MAX_AGREEMENTS_PER_INVOICE">
          <p class="text-small m-0"> 
            <i class="fa-solid fa-circle-exclamation alert-icon"></i> Has alcanzado la cantidad máxima de acuerdos de pagos posibles a registrar a la factura
          </p>
      </section>
        <div  class="form-box__content-inputs mb-1" *ngIf="viewsAvailable['registration_form'].show_view && consultedPaymentAgreements && paymentAgreementList.length < MAX_AGREEMENTS_PER_INVOICE">
            <div class="form-box__content-group-fields form-box__content-group-fields--min-width-x-small">
                <mat-form-field appearance="fill" class="fill">
                    <mat-label>Fecha acuerdo de pago</mat-label>
                    <input matInput name="paymentAgreementDate" disabled="" [(ngModel)]="paymentAgreement.agreement_datetime" [min]="currentDate" [matDatepicker]="paymentAgreementDate" required="">
                    <mat-datepicker-toggle matSuffix [for]="paymentAgreementDate"></mat-datepicker-toggle>
                    <mat-datepicker #paymentAgreementDate disabled="false"></mat-datepicker>
                    <mat-hint align="start">Selecciona la fecha en la cual se acuerda realizar el próximo pago</mat-hint>                              
                </mat-form-field>                                    
                <mat-form-field appearance="fill" hintLabel="Max {{MAX_PAYMENT_AGREEMENT_FIELD_LENGTH.comment}} carácteres">
                    <mat-label>Descripción</mat-label>
                    <textarea  matInput maxlength="{{MAX_PAYMENT_AGREEMENT_FIELD_LENGTH.comment}}" name="paymentAgreementComment" [(ngModel)]="paymentAgreement.comment" placeholder="Agrega una descripcion adicional sobre el acuerdo de pago (opcional)"></textarea>
                    <mat-hint align="end">{{paymentAgreement.comment?.length || 0}}/{{MAX_PAYMENT_AGREEMENT_FIELD_LENGTH.comment}}</mat-hint>
                </mat-form-field>
                <span class="fill">
                  <mat-checkbox  
                    color="primary" name="activateDevices"
                    [(ngModel)]="paymentAgreement.activate_devices">
                    {{ACTIVATE_DEVICES_FIELD.TITLE}}
                    <mat-icon class="icon" [matTooltip]="ACTIVATE_DEVICES_FIELD.TOOLTIP_TEXT">help</mat-icon> 
                  </mat-checkbox>  
                </span>  
            </div>
        </div>
        
        <section class="form-box__content-section">
          <div class="overflow-auto w-100">
            <table mat-table [dataSource]="paymentAgreementList">
    
              <ng-container *ngFor="let field of columnStructureToDisplay">
                <ng-container *ngIf="field.visible"  matColumnDef="{{field.name}}">
                    <ng-container *ngIf="!field.sorted">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="{'mat-header-cell-center': field.align_header == alignDataTable.CENTER, 'mat-header-cell-end': field.align_header == alignDataTable.RIGHT}" matTooltip="{{field.label.length>dataService.maxColumLabelLength?field.label:''}}"> {{field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength):field.label}}</th>
                    </ng-container>
                    <ng-container *ngIf="field.sorted">
                      <th mat-header-cell *matHeaderCellDef [ngClass]="{'mat-header-cell-center': field.align_header == alignDataTable.CENTER, 'mat-header-cell-end': field.align_header == alignDataTable.RIGHT}" mat-sort-header sortActionDescription="Ordenar por {{field.label}}" matTooltip="{{field.label.length>dataService.maxColumLabelLength?field.label:''}}">{{field.label.length>dataService.maxColumLabelLength ? field.label.substring(0,dataService.maxColumLabelLength)+"...":field.label}}</th>
                    </ng-container>
                    <td mat-cell *matCellDef="let invoice;let i = index" [ngClass]="{'text-center': field.align_data == alignDataTable.CENTER,'text-end': field.align_data == alignDataTable.RIGHT}"  > 
                      <span>{{ field.pipe_type == pipeTypes.DATE ? (invoice[field.name] | date: field.pipe_option) :  invoice[field.name] }}</span>
                    </td>
                  </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsInTable"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsInTable;"></tr>
            <tr *matNoDataRow>
              <td class="mat-cell empty py-1" [attr.colspan]="displayedColumnsInTable.length">Sin datos</td>
            </tr>
            </table>   
          </div>
        </section>
      </div>
    </section>
      <div class="loader" *ngIf="loading">
          <img src="../../../../../assets/loader.svg">
      </div>
  </ng-container>

  </mat-dialog-content>
  <mat-dialog-actions align="end" >
      <button  class="btn btn-primary me-1" (click)="addPaymentAgreement()" *ngIf="consultedPaymentAgreements && viewsAvailable['registration_form'].show_view && paymentAgreementList.length < MAX_AGREEMENTS_PER_INVOICE" [disabled]="loading" >Registrar</button>  
      <button mat-button [mat-dialog-close]="paymentAgreementCreated" class="btn btn-outline-secondary">Cerrar</button>  
  </mat-dialog-actions>
  
  