import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ICountry } from 'src/app/core/models/interfaces/country.model';
import { IResponseApi } from 'src/app/core/models/interfaces/iresponse-api.model';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { FormControlTypes } from 'src/app/shared/enums/common-enums.enum';
import { IException } from 'src/app/shared/models/interfaces/IException.model';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  isEmptyObject(object:Object){
    return Object.entries(object).length<1;
  }
  isNullOrEmpty(value:any):boolean{
    return typeof value =="undefined" ||value ==null || value =="";
  }
  isClipboardApiAvailable():boolean {
    return !!navigator && !!navigator.clipboard && !!navigator.clipboard.writeText;
  };

  public isIException(obj:any):obj is IException{
    return typeof obj !="undefined" && obj != null && (typeof obj.message == "string");
  }
  public isResponseApi(obj:any):obj is IResponseApi{
    return typeof obj !="undefined" && obj != null && (typeof obj.status != "undefined");
  }

  isValidIpv4AddressAndPortStr = "^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9]):([1-9][0-9]{0,4})$";
  /**
   * @description Valida si un valor es una direcccion IPv4 sguida de un puerto
   * El formato debe ser: "xxx.xxx.xxx.xxx:yyyy"
   * - xxx: Un número entre 0 y 255 que representa cada octeto de la dirección IPv4.
   * - yyyy: Un número entre 1 y 65535 que representa el puerto. 
   * 
   * @param {string} value el valor a evaluar
   * @returns {boolean} indica true si cumple con el formato
   */
  isValidIpv4AddressAndPort(value: string): boolean {
    //^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9]):([1-9][0-9]{0,4})$
    return /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9]):([1-9][0-9]{0,4})$/.test(value);
  }
  isValidNetworkPort(value: string): boolean{
    return /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/.test(value);
  }
  public isBlob(obj:any):obj is Blob{
    return obj instanceof Blob;
  }
  isValidDateTimeFormatStr = "^(19|20|[2-9]\d)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5]\d):([0-5]\d)$";
  public isValidDateTimeFormat(value: string){
    return /^(19|20|[2-9]\d)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5]\d):([0-5]\d)$/.test(value);
  }
  /**
   * @description Indica si un valor es de tipo FormControlTypes
   * @param value El valor a evaluar
   * @returns 
   */
  public isFormControlTypes(value:any):value is typeof FormControlTypes{
    for(let type in FormControlTypes){
      if(value == type)
        return true;
    }
    return false;
  }
  public isApiRequestData(obj:any):obj is IApiRequestData{
    return (typeof obj.user_api_hash == "string" || typeof obj.action == "string");
  }
  readonly isValidPositiveNumberStr = "^\\d*\\.?\\d+$";
  public isValidPositiveNumber(value:string):boolean{
    return /^\d*\.?\d+$/.test(value);
  }
  readonly isOnlyNumbersStr:string = "^(\\d){1,}$";
  /** Determina si una variable contiene solo numeros */
  public isOnlyNumbers(numbers:any){
    ///^(\d){1,}$/
    return /^(\d){1,}$/.test(numbers);
  }
  onlyNumbers(value:string|number|null){
    
    return value !=null? value.toString().replace(/[^0-9]/g, ''):"";
  }
  isPhoneValid(numbers:any){
    return /^(\d){9,}$/.test(numbers);
  }
  readonly onlyCharactersStr = "^[\\d\\w]{1,}$";
  onlyCharacters(value:string){
    return /^[\d\w]{1,}$/.test(value);
  }
  /** Indica si solo se acepta caracteres con una cantidad minima de caracteres */
  onlyCharactersStrWithMinimum(minCharactersQuantity:number){
    return "^[a-zA-Z0-9]{"+minCharactersQuantity+",}$";
  }
  HasSpecialCharacter(value:string):boolean{
    let regex = /[!@#$%^&*()\-_=+[{\]}\\|:;"'<>,\/¿?`~.¨°¬´]/;
    return regex.test(value);
  }
  isValidPasswordStr(minLength:number=8){
    /**Contiene minimo una letra minuscula, mayuscula, numero y un caracter especial, no debe de contener espacios en blanco y su longitud minima es segun la indicada */
    return `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#\$%^&*()\\-_=+\\[\\]{}\\|:;"'<>,/¿?~.¨°¬´])[A-Za-z\\d!@#\$%^&*()\\-_=+\\[\\]{}\\|:;"'<>,/¿?~.¨°¬´]{${minLength},}$`;
  }
  isValidPassword(value:string,minLength:number=8){
    let regex =new RegExp(this.isValidPasswordStr(minLength));
    return regex.test(value);
  }
  hasUppercase(value:string):boolean{
    return /[A-Z]/.test(value);
  }
  hasLowercase(value:string):boolean{
    return /[a-z]/.test(value);
  }
  readonly hasWhitespaceStr = "\\s";
  hasWhitespace(value:string):boolean{
    return /\s/.test(value);
  }

  hasDigit(value:string):boolean{
    return /\d/.test(value);
  }

  isNumeric(value:any):value is number {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  readonly isValidPhoneStr:string = "^(\\d){9,}$";
  isValidPhone(value:string):boolean{
    return /^(\d){9,}$/.test(value);
  }
  readonly isValidEmailStr:string = "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$";
  isValidEmail(value:string):boolean{
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  }

  readonly isWebAddressValidStr:string ="^(https?:\\/\\/)?([a-zA-Z0-9]+([a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,4}([.][a-zA-Z]{2})?(\\/[^\\/\\s]*)*$";
  //expresion regular que acepta una url https://www.dominio.com/ruta/sub?param1=11&param2=22
  isWebAddressValid(address:string){
    return /^(https?:\/\/)?([a-zA-Z0-9]+([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,4}([.][a-zA-Z]{2})?(\/[^\/\s]*)*$/.test(address);
  }
  isWebDomainValid(domain:string){
    return /^(?=.{1,253}\.?$)(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.?)+(?:[A-Za-z]{2,}|xn--[A-Za-z0-9-]{2,})$/.test(domain);
  }
  isGoogleAnalyticsIdValid(id:string){
    return /^UA-\d{4,}-\d{1,}$/gm.test(id);
  }
  isGoogleSearchConsoleIdValid(id:string){
    return /^[a-zA-Z0-9_-]{43}$/.test(id);
  }
  //para determinar si la cadena usada en la personalizacion de la url para el sitio web personalzado es valido
  isUrlIdentifierValid(value:string):boolean{
    return /^(?!.*--)(?!-)[a-z0-9-]{5,}(?<!-)$/.test(value);
  }

  isGoogleMapsHtmlValid(htmlCode: string): boolean {
    // Crear un elemento temporal para analizar el código HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlCode;
    // Buscar elementos <iframe> en el código
    const iframes = Array.from(tempElement.getElementsByTagName('iframe'));
    // Verificar si se encontró al menos un <iframe> válido
    if (iframes.length > 0 && iframes.length<2) {//solo aceptar un iframe
      // Iterar a través de los iframes y verificar atributos importantes
      for (const iframe of iframes) {
        const src = iframe.getAttribute('src');
        const allowfullscreen = iframe.getAttribute('allowfullscreen');
        const loading = iframe.getAttribute('loading');
        // Verificar si los atributos son válidos
        if (src!=null && src.includes('google.com/maps') && allowfullscreen === '' && loading === 'lazy') {
          return true; // Código HTML de Google Maps válido
        }
      }
    }
    return false; // No se encontró código HTML de Google Maps válido
  }  
  
}

