import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/core/services/data/data.service';
import { MessageBoxService } from 'src/app/core/services/message-box/message-box.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { IAboutUsFileToUpload, ICommonFileStructure, IFileGlobal, IHomeFileToUpload, IPagesFileToUpload, IWebTemplateDesignData, IWebsiteFile } from '../../models/interfaces/iweb-template.model';
import { ColorManipulationService } from "../../../../core/services/color-manipulation/color-manipulation.service";
import { WebTemplate } from '../../models/classes/web-template.model';
import { IBaseColors } from 'src/app/shared/models/interfaces/color-palette.model';
import { WebsiteInitializationService } from "../../website-initialization/website-initialization.service";

interface IUrlAsset{
  base:string,
  favicon:string,
  background:string,
  logos:string,
  corporate_identity:string,
  gallery:string
}
interface ISupportedFiles{
  accept_value:string,
  accept_label:string,
  max_image_size:string,
  max_favicon_size:string,
  crop_orientation:{
    portrait:"vertical",
    landscape:"horizontal"
  },
  crop_aspect_ratio:{
    r3_2:{
      label:"3:2",
      mat_icon:"crop_3_2"
    },
    r5_4:{
      label:"5:4",
      mat_icon:"crop_5_4"
    },
    r1_1:{
      label:"1:1",
      mat_icon:"crop_square"
    }
  }
}
@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent implements OnInit {

  @Input() isUpdate:boolean =false;
  @Input() designData!:IWebTemplateDesignData;
  @Output() updateData = new EventEmitter<{design_data:IWebTemplateDesignData, website_file:IWebsiteFile}>;

  readonly supported_files:ISupportedFiles={
    accept_value:"image/jpg,image/jpeg,image/png,.gif,.webp",
    accept_label:"(jpg, jpeg, png, webp, gif)",
    max_image_size:"300 KB",
    max_favicon_size:"100 KB",
    crop_orientation:{
      portrait:"vertical",
      landscape:"horizontal"
    },
    crop_aspect_ratio:{r3_2:{label:"3:2",mat_icon:"crop_3_2"},
                r5_4:{label:"5:4",mat_icon:"crop_5_4"},
                r1_1:{label:"1:1",mat_icon:"crop_square"}
    }

    
  };
  typefaces!:string[];
  urlAssets: IUrlAsset={
    base:"https://corporate.internationalgpsonline.com/public/img/",
    background:"background-images/",
    logos:"logos/",
    favicon:"favicon/",
    corporate_identity:"corporate-identity/",
    gallery:"gallery/"
  };

  imageManager:IWebsiteFile = this.websiteInitializationService.initializeFileToUpload();
  constructor(
    public utils: UtilsService,
    public validationService:ValidationService,
    private dataService:DataService,
    private colorManipulationService:ColorManipulationService,
    private websiteInitializationService:WebsiteInitializationService
  ){}
  colorPersonalization(){
    let color:IBaseColors = {primary:this.designData.color_palette.primary_color,secondary:this.designData.color_palette.secondary_color,accent:this.designData.color_palette.accent_color};
      this.designData.color_palette = WebTemplate.getColorPalette(this.colorManipulationService,color,this.designData.color_palette.text_color,this.designData.color_palette.title_color);
  } 
  ngOnInit(): void {
    this.typefaces = this.dataService.typefaces;
  }
  deleteImg(page:keyof IPagesFileToUpload,name:keyof ICommonFileStructure,tempName:keyof (ICommonFileStructure)){ 
    this.imageManager.pages[page][name] = this.imageManager.pages[page][tempName] = null;
    this.imageManager.pages[page].delete_top_section_bg_image = true;
  }
  deleteGlobalFile(name:keyof IFileGlobal,tempName:keyof IFileGlobal){ 
    this.imageManager.global[name] = this.imageManager.global[tempName] = null;
    this.imageManager.global[name=="favicon"?"delete_favicon":"delete_logo"] = true;
    //this.designData.global_image_filenames[name=="favicon"?"favicon":"logo"] = "";
  }
  uploadGlobalFile($event: any,name:keyof IFileGlobal,tempName:keyof IFileGlobal){
    let file = $event.target.files[0];
    if(name=="favicon" && file.type.split("/")[1] !=="x-icon")
      return this.utils.showMsg("","El favicon debe ser una imagen con formato <strong>.ico</strong>");
    this.imageManager.global[name]=file;//almacena el objeto file que se enviara a la API
    this.loadTempFile(file,this.imageManager,"global",name,tempName);
  }
  uploadImage($event: any,page:keyof IPagesFileToUpload,name:keyof (ICommonFileStructure),tempName:keyof ICommonFileStructure){
    let file = $event.target.files[0];   
    this.imageManager.pages[page][name]=file;//almacena el objeto file que se enviara a la API
    this.loadTempFile(file,this.imageManager,"pages",page,tempName);
  }
  deleteAboutUsCorporateIdentityImg(
    name:"corporate_identity_image_mission"|"corporate_identity_image_vision"|"corporate_identity_image_values",
    tempName:"temp_corporate_identity_image_mission"|"temp_corporate_identity_image_vision"|"temp_corporate_identity_image_values",deleteName:"delete_corporate_identity_image_mission"|"delete_corporate_identity_image_vision"|"delete_corporate_identity_image_values"){ 
    this.imageManager.pages.about_us[name] = this.imageManager.pages.about_us[tempName] = null;
    this.imageManager.pages.about_us[deleteName] = true;
  }
  uploadAboutUsCorporateIdentityImg($event: any,name:keyof IAboutUsFileToUpload,tempName:keyof IAboutUsFileToUpload,deleteName:"delete_corporate_identity_image_mission"|"delete_corporate_identity_image_vision"|"delete_corporate_identity_image_values"){
    let file = this.imageManager.pages.about_us[name] = $event.target.files[0];//almacena el objeto file que se enviara a la API
    this.loadTempFile(file,this.imageManager,"pages","about_us",tempName,deleteName);
  }
  deleteHomeImg(name:"top_section_presentation_image",tempName:"temp_top_section_presentation_image",deleteName:"delete_top_section_presentation_image"){ 
    this.imageManager.pages.home[name] = this.imageManager.pages.home[tempName] = null;
    this.imageManager.pages.home[deleteName] = true;
  }
  uploadHomeImg($event: any,name:keyof IHomeFileToUpload,tempName:keyof IHomeFileToUpload,deleteName:"delete_top_section_presentation_image"){
    let file = this.imageManager.pages.home[name] = $event.target.files[0];//almacena el objeto file que se enviara a la API
    this.loadTempFile(file,this.imageManager,"pages","home",tempName,deleteName);
  }
  loadTempFile(file:File,imageManager:any,section:string,page:string|"",name:string,deleteName:string="delete_top_section_bg_image"){
    (section=="global")?(imageManager.global["delete_"+(page=="favicon"?"favicon":"logo")] = false):(imageManager[section][page][deleteName] = false);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => (section=="global")?(imageManager[section][name] = reader.result):(imageManager[section][page][name] = reader.result);
  }
  update(){
    this.colorPersonalization();
    this.updateData.emit({design_data:this.designData, website_file:this.imageManager});
  }
}