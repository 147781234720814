import { Injectable } from '@angular/core';
import { IFormDataValidationResponse } from 'src/app/shared/models/interfaces/iutil.model';
import { IDeviceWithBillingPlan } from '../../models/interfaces/idevice.model';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceBillingPlanService {

  constructor(
    private utils: UtilsService
  ) { }

  /**
   * @description Realiza la validacion de los datos plan de facturacion. Retorna el estado de vaildacion y los errores en los datos que se puedan presentar; 
   * en caso de no haber errores ella realiza los ajustes finales a deviceWithBillingPlan (modifica al objeto) para el envio de los datos al servicio API para su registro
   * @param deviceWithBillingPlan El plan a evaluar 
   * @param validateResponsibleId Inica si hay que validar el campo de usuario responsable asociado al dispositivo
   * @returns 
   */
  validateBillingPlanFormData(deviceWithBillingPlan: IDeviceWithBillingPlan, validateResponsibleId: boolean = false): IFormDataValidationResponse<keyof IDeviceWithBillingPlan> {
    let formDataValidationResponse: IFormDataValidationResponse<keyof IDeviceWithBillingPlan> = {status: true, errors: []};
    
    if(deviceWithBillingPlan.unit_price == null ||  deviceWithBillingPlan.unit_price <= 0)
      formDataValidationResponse.errors.push({field: "unit_price", message: "Debe de indicar un precio"});

    if(deviceWithBillingPlan.monetary_unit_id == null)
      formDataValidationResponse.errors.push({field: "monetary_unit_id", message: "Debe de indicar un tipo moneda"});

    if(deviceWithBillingPlan.payday == null)
      formDataValidationResponse.errors.push({field: "payday", message: "Debe de indicar el día de facturación"});
    else if( isNaN(deviceWithBillingPlan.payday) || deviceWithBillingPlan.payday > 31 || deviceWithBillingPlan.payday < 1 )
      formDataValidationResponse.errors.push({field: "payday", message: "Debe de indicar un día de facturación válido"});
    
    if(deviceWithBillingPlan.billing_frequency_id == null)
      formDataValidationResponse.errors.push({field: "billing_frequency_id", message: "Debe de indicar una frecuencia de facturación"});

    if(deviceWithBillingPlan.first_billing_date == null || deviceWithBillingPlan.first_billing_date == "")
      formDataValidationResponse.errors.push({field: "first_billing_date", message: "Debe de indicar el primer mes de facturación"});
    
    if( validateResponsibleId && (deviceWithBillingPlan.user_responsible_id == null || deviceWithBillingPlan.user_responsible_id <= 0))
      formDataValidationResponse.errors.push({field: "user_responsible_id", message: "Debe de indicar al usuario responsable"});
    
    formDataValidationResponse.status = formDataValidationResponse.errors.length == 0;

    if(formDataValidationResponse.status){
      if(deviceWithBillingPlan.first_billing_date instanceof Date){
        deviceWithBillingPlan.first_billing_date.setDate(1);
        deviceWithBillingPlan.first_billing_date = (this.utils.getDatetime(deviceWithBillingPlan.first_billing_date).split(" ")[0]);  
      }
    }
    return formDataValidationResponse;
  }
}