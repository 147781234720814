import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserType } from 'src/app/shared/types/common-types.type';
import { ColorManipulationService } from 'src/app/core/services/color-manipulation/color-manipulation.service';
import { ObjectInitializationService } from '../../core/services/object-initialization/object-initialization.service';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

})
export class HomeComponent implements OnInit {
 
  loading: boolean = false;//para loading
  user: IUserSessionData;

  userTypeLogged: UserType;

  moduleId: number = 1;
  permissionsDataSgc: any = [];//permisos sobre el modulo
  constructor(
    private api: ApiService,
    public dataService: DataService,
    private utils: UtilsService,
    private router: Router,
    public matDialog: MatDialog,
    private colorManipulationService:ColorManipulationService,
    private objectInitializationService: ObjectInitializationService
  ) {
    this.user = this.dataService.getData("user");
    this.userTypeLogged = this.dataService.getUserTypeLogged();
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
    }).catch((error:any) => {
      this.utils.showMsg("Página no autorizada", "No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
}