import { Component,Input,Output,OnInit,EventEmitter,Renderer2 } from '@angular/core';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { SgcPlatformPlanPermissions } from "../../../../core/models/classes/sgc-platform-plan-permissions.model";
@Component({
  selector: 'app-sgc-permissions',
  templateUrl: './sgc-permissions.component.html',
  styleUrls: ['./sgc-permissions.component.scss']
})
export class SgcPermissionsComponent extends SgcPlatformPlanPermissions implements OnInit{

  user:any={};
  loading:boolean=false;
  @Input() distributorId!:number;
  @Output() closeUpdateModal = new EventEmitter<boolean>();

  constructor(
    private dataService:DataService,
    public  utils:UtilsService,
    private api:ApiService
  ){  super();}
  ngOnInit(): void {
      this.user = this.dataService.getData("user");
      this.getList();
  }
  getList(){
    this.api.getData("module","?action=getModules&user_api_hash="+this.user.hash,true).then((data: any) => {
      this.modules = data.status==1?data.data:[];
      this.filteredModules =  this.utils.copyObject(this.modules);
      this.api.getData("platformPlan", "?action=getPlatformPlan&user_api_hash="+this.user.hash+"&user_id="+this.distributorId,true).then((data: any) => {
        this.newPlatformPlan = data.status==1?data.data:{};
        this.userHasAPlatformPlan = !!data.status;
        this.newPlatformPlanCopy=this.utils.copyObject(this.newPlatformPlan);
        let selectedPlanRoles:any = [];
        if(Array.isArray(this.newPlatformPlan.platform_plan_role))
          this.newPlatformPlan.platform_plan_role.forEach((item:any)=>selectedPlanRoles.push(item.role_id));
        this.filteredModules = this.modules.filter((module:any)=>{
          if(this.newPlatformPlan.modules.every((moduleWithPermissions:any)=>moduleWithPermissions.module_id!=module.id)){
              let response = selectedPlanRoles.map((roleId:number)=>module.module_roles.includes(Number(roleId)));
              return response.every((item:boolean)=>item);  
          }
          return false;
        });
      }).catch((error:any)=>{console.log(error);});
    }).catch((error:any)=>{console.log(error);});
  }
  addNewModuleToPlatformPlanWithPermissions(){
    let error:any=this.isValidmodulePermissionsToAddToPlatformPlan(this.selectedModule,this.modulePermissionsToAddToPlatformPlan);
    if(!error["status"]){
      this.utils.showMsg("",error["message"]);
      return;
    }
    let dataToSend={
      permissions:this.modulePermissionsToAddToPlatformPlan,
      user_api_hash:this.user.hash,
      action:"add"
    };
    this.api.createData(dataToSend, "permission",true).then((data: any) => {
      if(data.status){
        this.utils.showResultRequest("success","Información",data.message);
        this.modulePermissionsToAddToPlatformPlan=[];
        this.selectedModule =[];
        this.selectedModulePermissions=[];
        this.getList();
      } else {
        this.utils.showResultRequest("error","Información",data.message);
      }
    }).catch((err: any) => {
      this.utils.showResultRequest("error","Permisos no registrados","Se ha presentado un error mientras se intentaba registrar los permisos");
    });
  }
  deleteModulePermissions(module:any){
    this.utils.showConfirm("Confirmar acción","Esta seguro de eliminar los permisos a dicho modulo para este perfil","Confirmar","Cancelar").then(()=>{
      let data={user_api_hash:this.user.hash,action:"delete", module:module};
      this.api.createData(data, "permission",true).then((data: any) => {
      this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);

      }).catch((err: any) => {
        this.utils.showResultRequest("error","Información","Se ha presentado un error al eliminar los permisos indicados");
      });
    }).catch(()=>{});
  }
  override prepareModulePermissionsToPlatformPlan(){
    this.selectedModulePermissions = [];
    super.prepareModulePermissionsToPlatformPlan(this.selectedModule,this.selectedModulePermissions,this.modulePermissionsToAddToPlatformPlan,undefined,this.distributorId);
  }
  updatePermissions(){
    this.utils.showConfirm("Confirmar actualización de permisos","","Confirmar","Cancelar").then(()=>{
      this.prepareModulePlanToSend(this.newPlatformPlanCopy,this.newPlatformPlan,this.distributorId);
      
      this.newPlatformPlanCopy.description =this.newPlatformPlan.description.trim();
      this.newPlatformPlanCopy["action"]="update";
      this.newPlatformPlanCopy["user_api_hash"]=this.user.hash;
      this.newPlatformPlanCopy.platform_plan_role = this.newPlatformPlan.platform_plan_role.map((platformPlanRole:any)=> ({id:platformPlanRole.role_id}));
      this.api.createData(this.newPlatformPlanCopy, "platformPlan",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        (data.status==1)?this.getList():"";
      }).catch((err: any) => {
        this.utils.showResultRequest("error","Información","Se ha presentado un error al actualizar el plan.<br><small>Intentalo nuevamente. si el error persiste, contacta al administrador</small>");
      });
    }).catch(()=>{});
  }
  close() {
    this.modalEditPlatformPlan=false;
    this.newPlatformPlan = this.newPlatformPlanCopyEmpty;
    this.modulePermissionsToAddToPlatformPlan=[];
    this.newPlatformPlanCopy=[];
    this.isNewPlatformPlanModified=false;
    this.selectedModulePermissions=[];
    this.filteredModules=[];
    this.selectedModule={};
    this.closeUpdateModal.emit(true);
  }
}