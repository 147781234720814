import { Component, OnInit,Inject, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { MessageBoxService } from 'src/app/core/services/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/services/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { ApiEndpointSgc } from 'src/app/core/enums/api-endpoint-sgc.enum';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IApiRequestData, IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { IBillingFrequency, IMonetaryunit } from 'src/app/core/models/interfaces/iutils-data.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { IUserContactInfo } from 'src/app/core/models/interfaces/user-data.model';
import { MatSelectChange } from '@angular/material/select';
import { MatDatepicker } from '@angular/material/datepicker';
import { IDevicePersonalizedPlan, IDeviceWithBillingPlan } from 'src/app/core/models/interfaces/idevice.model';
import { IResponseApi } from 'src/app/core/models/interfaces/iresponse-api.model';
import { IFormDataValidationResponse } from 'src/app/shared/models/interfaces/iutil.model';
import { DeviceBillingPlanService } from '../../../../core/services/device-billing-plan/device-billing-plan.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
/**
 * Este componente, para la seccion de edicion de plan, habrá que validarlo para ser usado de forma reutilizable en el formulario de dispositivos y en este componente
 */

@Component({
  selector: 'app-billing-plan-device-details',
  templateUrl: './billing-plan-device-details.component.html',
  styleUrls: ['./billing-plan-device-details.component.scss']
})
export class BillingPlanDeviceDetailsComponent implements OnInit {
  
  viewsAvailable:IViewsAvailable={
    list:{name:"list",show_view:true},
    form:{name:"form",show_view:false}
  };

  loading:boolean = false;
  currentDate: string;

  user: IUserSessionData;
  
  baseFiltersForQuery: IApiRequestData;
  sort: ISort;
  pagination:IPagination;
  
  displayedColumns: string[] = [
    "imei","name", 
    "unit_price","payday","billing_frequency_name","first_billing_date", "billing_plan_disabled",
    "user_responsible_email", "actions"
  ];
  devices: IDeviceWithBillingPlan[] = [];

  //propiedades para la edicion de plan
  deviceWithBillingPlanToEdit: IDeviceWithBillingPlan;//inicializar PLAN
  /**
   * @type {boolean} Indica cuando se han actualizado datos de planes
   */
  updatedPlans: boolean = false;
  configFirstBillingDate: { min_value: Date | null, value: Date | null } = { min_value: null, value: null };

  userList:IUserContactInfo[] = [];
  userListFiltered:IUserContactInfo[] = [];
  userEmailToSearch: string = "";
  
  monetaryUnitsList: IMonetaryunit[] = [];
  billingFrequenciesList: IBillingFrequency[] = [];
  //fin - propiedades para la edicion de plan
  
  constructor(
    private deviceBillingPlanService: DeviceBillingPlanService,
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<BillingPlanDeviceDetailsComponent>,
    public utils:UtilsService,    
    private api: ApiService,
    public dataService: DataService,
    private objectInitializationService: ObjectInitializationService,
    private validationService: ValidationService,
    public messageBox: MessageBoxService,
    @Inject(MAT_DIALOG_DATA) public data: {query_filters: IApiRequestData},
  ){
    this.deviceWithBillingPlanToEdit = this.objectInitializationService.initializeIDeviceWithBillingPlan();
    this.baseFiltersForQuery = this.data.query_filters;
    this.currentDate = utils.getDatetime();
    this.user = this.dataService.getData("user") as IUserSessionData;

    this.sort = this.objectInitializationService.initializeISort();
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION.slice());
    this.pagination.page_size = this.dataService.PAGE_SIZE_OPTION[0];
    this.getDevicesByBillingplanPrice();
  }
  ngOnInit(): void {
    this.dataService.fillMonetaryunits(this.monetaryUnitsList);
    this.dataService.fillBillingFrequencies(this.billingFrequenciesList);
    //this.cdRef.detectChanges(); // Forzar detección de cambios
  }

  paginator(event:PageEvent):void{
    this.pagination.current_page = event.pageIndex;    
    this.pagination.page_size = event.pageSize;
    this.getDevicesByBillingplanPrice();
  }
  sortData(sort: Sort) {
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getDevicesByBillingplanPrice();
  }
  async getDevicesByBillingplanPrice(closeIfNoData: boolean = false){
    this.loading = true;
    let dataToSend: IApiRequestData = this.getQueryParams();
    this.devices = [];
    this.pagination.length_page = 0;
    try {
      let response:unknown = await this.api.getDataPost( ApiEndpointSgc.DEVICE , dataToSend, true);
      if(this.validationService.isResponseApi(response)){
        this.devices = Array.isArray(response.data ?? null) ? response.data as IDeviceWithBillingPlan[] : [];
        this.pagination.length_page = response.total ?? 0;
      }
    }catch (error: unknown) {
      if(error instanceof Error)
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(error.message??"los dispositivos",true, false,RequestMethodHTTP.GET));
    }finally{
      if(closeIfNoData && this.devices.length == 0)
        setTimeout(() => {this.dialogRef.close(this.updatedPlans); } , this.dataService.OneSecond * 2);
      this.utils.hideLoading( () => this.loading =false);
    } 
  }
  /**
   * Obtiene los parametros de consulta para la obtencion de datos
   */
  getQueryParams(addPagination: boolean = true){
    let columnsToGet: string[] = [
      "imei","name",
      "unit_price","monetary_unit_id","payday","billing_frequency_id","first_billing_date","disabled",
      "user_responsible_email"
    ];
    let dataToSend: IApiRequestData = this.api.structureParametersForConsult(RequestMethodHTTP.POST,"getDevicesWithBillingPlan",this.user.hash,undefined,addPagination ? this.pagination.current_page : undefined,this.pagination.page_size,this.sort, undefined,columnsToGet) as IApiRequestData;
    dataToSend = {...dataToSend,...this.data.query_filters};
    return dataToSend;
  }
  exportFile(){
    this.utils.processFileDownload("devices_",
     async ()=>{
      let data: Partial<IDeviceWithBillingPlan>[] = [];
      try {
        let dataToSend: IApiRequestData = this.getQueryParams(false);
        let response:unknown = await this.api.getDataPost( ApiEndpointSgc.DEVICE , dataToSend, true);
        if(this.validationService.isResponseApi(response))
          data = response.status == 1 ? response.data : [];
      } catch (error:unknown) { 
        this.loading = false;
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de dispositivos",false,false,"GET"));
      }
      let headers = this.displayedColumns.slice();
      headers.splice(headers.findIndex((item: string)=>item == "actions"), 1);

      let UnitPriceIndex = headers.indexOf("unit_price");
      if(UnitPriceIndex >= 0)
        headers.splice(headers.indexOf("unit_price") + 1 , 0 , "monetary_unit_code");

      return {data:data,headers:headers};
    },
    (response:boolean)=>this.loading = response);
  }


  //metodos para la edicion de plan
  openEditForm(device: IDeviceWithBillingPlan){
    this.configFirstBillingDate.min_value = device.first_billing_date != null && device.first_billing_date != "" ? new Date(device.first_billing_date + " 00:00:00") : new Date(); 
    this.configFirstBillingDate.min_value.setDate(1);
    this.configFirstBillingDate.min_value.setHours(0);
    this.configFirstBillingDate.min_value.setMinutes(0);
    this.configFirstBillingDate.min_value.setSeconds(0);

    this.deviceWithBillingPlanToEdit = this.utils.copyObject(device) as IDeviceWithBillingPlan;
    this.deviceWithBillingPlanToEdit.first_billing_date = (this.deviceWithBillingPlanToEdit.first_billing_date != null && this.deviceWithBillingPlanToEdit.first_billing_date != "") ? (new Date(this.deviceWithBillingPlanToEdit.first_billing_date + " 00:00:00")) : null;

    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['form'].name);
    this.getUsersAssociatedWithDevice();
  }
  closeForm(updateDataTable: boolean = false){
    if(updateDataTable)
      this.getDevicesByBillingplanPrice(true);
    this.userListFiltered = this.userList = [];
    this.deviceWithBillingPlanToEdit = this.objectInitializationService.initializeIDeviceWithBillingPlan();
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['list'].name);
  }
  updateBillingPlanDisabled(event: MatSlideToggleChange){
    this.deviceWithBillingPlanToEdit.billing_plan_disabled = event.checked ? 1 : 0;
  }
  async updateBillingPlan(){
    let response: IResponseApi | null | unknown = null;
    try {
      let firstBillingDate = this.deviceWithBillingPlanToEdit.first_billing_date;
      let deviceWithBillingPlanToEdit = this.utils.copyObject(this.deviceWithBillingPlanToEdit) as IDeviceWithBillingPlan;
      deviceWithBillingPlanToEdit.first_billing_date = firstBillingDate;
      
      let planValidation = this.deviceBillingPlanService.validateBillingPlanFormData(deviceWithBillingPlanToEdit, true);
      if(!planValidation.status){
        let message = "<ul class='text-start fs-small lh-base'>";
        for(let item of planValidation.errors){
          message += "<li class=''><i class='text-danger icon fa-solid fa-circle-exclamation'></i> " + item.message + "</li>";
        }
        message += "</ul>";
        this.utils.showResultRequest("error","Error en los datos", message);
        return;
      }
      this.loading = true;

      let devicePersonalizedPlan = this.objectInitializationService.initializeIDevicePersonalizedPlan(deviceWithBillingPlanToEdit.personalized_plan_id,deviceWithBillingPlanToEdit.unit_price!,deviceWithBillingPlanToEdit.payday,deviceWithBillingPlanToEdit.billing_frequency_id!,deviceWithBillingPlanToEdit.monetary_unit_id!,deviceWithBillingPlanToEdit.first_billing_date as string, deviceWithBillingPlanToEdit.billing_plan_disabled);
      devicePersonalizedPlan.device_id = this.deviceWithBillingPlanToEdit.id;
      devicePersonalizedPlan.user_responsible_id = this.deviceWithBillingPlanToEdit.user_responsible_id;
      let dataToSend: IApiRequestData & IDevicePersonalizedPlan = { ...{user_api_hash: this.user.hash, action: this.deviceWithBillingPlanToEdit.personalized_plan_id != null ? "update" : "add"}, ...devicePersonalizedPlan };
      
      response = await this.api.createData(dataToSend, ApiEndpointSgc.PERSONALIZED_PLAN, true);   
    } catch (error: unknown) {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("del plan de facturación",this.deviceWithBillingPlanToEdit.personalized_plan_id != null, false, RequestMethodHTTP.POST));
    } finally {
      if (this.validationService.isResponseApi(response)) {
        if(response.status){
          this.updatedPlans = true;
          this.utils.showResultRequest("success","Actualización exitosa");
          this.closeForm(true);
        }else
          this.utils.showResultRequest("error",response.message ?? "");
      }
      this.utils.hideLoading(()=> this.loading = false);
    }
  }
  selectResponsibleUser(checked: MatSelectChange) {
    let user = this.userListFiltered.find((user: any) => user.id == checked.value);
    if(typeof user != "undefined"){
      this.deviceWithBillingPlanToEdit.user_responsible_id = user.id;
    }
  }
  clearSearchResponsibleEmail(){
    this.userEmailToSearch = "";
    this.searchResponsibleEmail();  
  }
  searchResponsibleEmail() {
    this.userEmailToSearch = this.userEmailToSearch.toLowerCase()??"";
    if (this.userEmailToSearch.length > 0)
      this.userListFiltered = this.userList.filter((user: IUserContactInfo) => user.email.toLowerCase().includes(this.userEmailToSearch));
    else 
      this.userListFiltered = this.utils.copyObject(this.userList) as IUserContactInfo[];
  }
  setFirstBillingMonth(date: Date, datepicker: MatDatepicker<any>) {
    date.setDate(1);
    this.configFirstBillingDate.value = date;
    this.deviceWithBillingPlanToEdit.first_billing_date = date;
    datepicker.close();
  }
  /**
   * @description Obtiene los datos de usuarios asociados a un dispositivo
   * @returns 
   */
  async getUsersAssociatedWithDevice(){
    this.loading = true;
    this.userListFiltered = this.userList = [];
    try {
      if(this.deviceWithBillingPlanToEdit.id <= 0)
        return;
      let dataToSend: IApiRequestData = this.api.structureParametersForConsult(RequestMethodHTTP.POST,"get",this.user.hash,undefined, undefined,undefined,undefined, undefined,["id","email","name"]) as IApiRequestData;
      dataToSend["filter_by_device_id"] = this.deviceWithBillingPlanToEdit.id;
      let response:unknown = await this.api.getDataPost( ApiEndpointSgc.USER , dataToSend, true);

      if(this.validationService.isResponseApi(response)){
        this.userListFiltered = this.userList = response.data as IUserContactInfo[];
      }
    }catch (error: unknown) {
      if(error instanceof Error)
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(error.message??"los dispositivos",true, false,RequestMethodHTTP.GET));
    }finally{
      this.utils.hideLoading( () => this.loading =false);
    } 
  }
}
