import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';

@Component({
  selector: 'app-platform-plan-form',
  templateUrl: './platform-plan-form.component.html',
  styleUrls: ['./platform-plan-form.component.scss']
})
export class PlatformPlanFormComponent implements OnInit {
  loading:boolean=false;//para mostrar SVG de carga
  user:any={};

  rolesList:any=[];//lista de roles permitidos en plataforma
  isNewPlatformPlanModified:boolean=false;//indica cuando se haya realizado alguna modificacion a los datos del "plan de plataforma" cargado para modificar
  
  @Input() isCreatePlatformPlan:boolean=false;
  @Input() newPlatformPlan: any = {
    id: 0,
    name: "",
    description: "",
    updated_at: '',
    modules: [],
    platform_plan_role:[]
  };

  @Output() closeForm = new EventEmitter<any>();
  newPlatformPlanCopy:any={};
  newPlatformPlanCopyEmpty= this.utils.copyObject(this.newPlatformPlan);
  modules: any = [];//modulos disponibles en la plataforma

  modulePermissionsToAddToPlatformPlan:any=[];//almacena los permisos seleccionados del select de permisos ya listos para agregar al plan
  selectedModulePermissions:any=[];//almcena la estrcutura de los permisos del modulo que se seleccione en el elemento Select de "modulo"
  displayedColumns = ["module_name","total_permissions","permissions","actions"];
  selectedModule:any={};//almacena el modulo que ha sido seleccionado en el componente que carga los modulos

  filteredModules:any=[];//almacena los modules filtrados exluyendo los que ya han sido agrgados al usuario
  
  selectedPlanRoles:any =[{id:null,name:""}];
  selectedPlanRolesCopy:any=[];
  constructor(
    private dataService:DataService,
    public utils:UtilsService,
    private api:ApiService,
  ){}
  ngOnInit(): void {
    this.user = this.dataService.getData("user");
    this.getRolesList();
    if(!this.isCreatePlatformPlan)
      this.getPlatformModules();
    if(this.isCreatePlatformPlan)
      this.newPlatformPlan = this.utils.copyObject(this.newPlatformPlanCopyEmpty);
    this.newPlatformPlanCopy =!this.isCreatePlatformPlan?this.utils.copyObject(this.newPlatformPlan):this.utils.copyObject(this.newPlatformPlanCopyEmpty);
  }
  getRolesList(){
    this.api.getData("role","?action=getRoles&user_api_hash="+this.user.hash,true).then((data: any) => {
      this.rolesList = data.status==1?data.data:[];
    }).catch((error:any)=>{console.log(error);});    
  }
  getPlatformModules(){
    this.showLoading(true);
    this.api.getData("module","?action=getModules&user_api_hash="+this.user.hash,true).then((data: any) => {
      this.modules = data.status==1?data.data:[];
      data.status==1?this.openPlanPermissionsManager(this.newPlatformPlan):null;
      this.showLoading(false);
    }).catch((error:any)=>{console.log(error);this.showLoading(false);});
  }
  updatePermissions(){
    if((this.newPlatformPlanCopy.name = this.newPlatformPlan.name.trim())=="")
      return this.utils.showMsg("Datos incompletos","Debes definir un nombre para el plan");
    this.utils.showConfirm(this.isCreatePlatformPlan?"Confirmar creación del plan":"Confirmar actualización de permisos","","Confirmar","Cancelar").then(()=>{
      this.showLoading(true);
      for(let module of this.newPlatformPlanCopy.modules){
        let moduleWithPermissionsModifed= this.newPlatformPlan.modules.find((moduleModified:any)=>moduleModified.module_id==module.module_id);
        for(let permission of module.permissions){
          if(moduleWithPermissionsModifed.permissions.length==0 || moduleWithPermissionsModifed.permissions.every((moduleOperation:any)=>(typeof moduleOperation.module_operation_id!="undefined"?moduleOperation.module_operation_id:moduleOperation.id)!=permission.module_operation_id))
            permission["action"]="delete";
        }
        for(let moduleOperation of moduleWithPermissionsModifed.permissions){
          if(module.permissions.length==0 || module.permissions.every((permission:any)=>permission.module_operation_id!=(typeof moduleOperation.module_operation_id!="undefined"?moduleOperation.module_operation_id:moduleOperation.id))){
            module.permissions.push({
              action:"add",
              module_id:moduleWithPermissionsModifed.module_id,
              module_operation_id:moduleOperation.id,
              platform_plan_id:this.newPlatformPlan.id
            });
          }
        }
      } 
      this.newPlatformPlanCopy.description =this.newPlatformPlan.description.trim();
      this.newPlatformPlanCopy["action"]=this.isCreatePlatformPlan?"add":("update");
      this.newPlatformPlanCopy["user_api_hash"]=this.user.hash;
  
      //platform_plan_role, obtiene los id de los roles 
      this.newPlatformPlanCopy.platform_plan_role = this.utils.copyObject(this.selectedPlanRoles);      
      this.api.createData(this.newPlatformPlanCopy, "platformPlan",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        if(data.status==1)
          this.isCreatePlatformPlan? this.closeModal(true,data.data):this.closeModal(true);
        this.showLoading(false);
      }).catch((err: any) => {
        this.showLoading(false);
        this.utils.showResultRequest("error","Información","Se ha presentado un error al actualizar el perfil.<br><small>Intentalo nuevamente. si el error persiste, contacta al administrador</small>");
      });
    }).catch(()=>{});
  }
  deleteModulePermissions(module:any){    
    this.utils.showConfirm("Confirmar acción","Esta seguro de eliminar los permisos a dicho modulo para este perfil","Confirmar","Cancelar").then(()=>{
      this.showLoading(true);
      let data={user_api_hash:this.user.hash,action:"delete", module:module};
      this.api.createData(data, "permission",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        data.status==1?this.closeModal(true):"";
        this.showLoading(false);
      }).catch((err: any) => {
        this.showLoading(false);
        this.utils.showResultRequest("error","Información","Se ha presentado un error al registrar al usuario");
      });
    }).catch(()=>{});
  }
  addNewModuleToPlatformPlanWithPermissions(){
    let error:any=this.isValidmodulePermissionsToAddToPlatformPlan();
    if(!error["status"])
      return this.utils.showMsg("",error["message"]);
    let dataToSend={
      permissions:this.modulePermissionsToAddToPlatformPlan,
      user_api_hash:this.user.hash,
      action:"add"
    };
    this.showLoading(true);
    this.api.createData(dataToSend, "permission",true).then((data: any) => {
      this.utils.showResultRequest(data.status?"success":"error","Información",data.message);
      data.status?this.closeModal(true,this.newPlatformPlan.id):"";
      this.showLoading(false);
    }).catch((err: any) => {
      this.showLoading(false);
      this.utils.showResultRequest("error","Permisos no registrados","Se ha presentado un error mientras se intentaba registrar los permisos");
    });
  }
  openPlanPermissionsManager(object:any,isCreate:boolean=false){
    this.selectedPlanRoles = [];
    this.newPlatformPlan.platform_plan_role.forEach((item:any)=>this.selectedPlanRoles.push({id:item.role_id}));
    this.selectedPlanRolesCopy = this.utils.copyObject(this.selectedPlanRoles);
    this.newPlatformPlan=isCreate?this.utils.copyObject(this.newPlatformPlanCopyEmpty):this.utils.copyObject(object);
    this.newPlatformPlanCopy=isCreate?this.utils.copyObject(this.newPlatformPlanCopyEmpty):this.utils.copyObject(object);
    this.filterModulesAvailable();   
  }

  /**Filtra los modulos disponibles para el plan con roles indicados */
  filterModulesAvailable(){
    this.filteredModules = this.modules.filter((module:any)=>{
        if(this.newPlatformPlan.modules.every((moduleWithPermissions:any)=>moduleWithPermissions.module_id!=module.id)){
            let response = (this.selectedPlanRoles.map((role:any)=> Number(role.id))).map((roleId:number)=>module.module_roles.includes(Number(roleId)));
            return response.every((item:boolean)=>item);  
        }
        return false;
      });
  }
  /**Indica si el plan esta disponible para el rol teniendo en cuenta que si ya hay un modulo no asignable al rol entonces queda no disponible, */
  isValidPlanForRole(roleId:number){
    let moduleIds = this.newPlatformPlan.modules.map((platformModule:any)=>Number(platformModule.module_id)); 
    let selectedModules = this.modules.filter((module:any)=>moduleIds.includes(Number(module.id)));
    return selectedModules.every((module:any)=>module.module_roles.includes(roleId));
  }
  //crea para el modulo seleccionado los posibles permisos que este puede tener registrados
  prepareModulePermissionsToPlatformPlan(){
    if(typeof this.selectedModule.operations !="undefined"){
      this.selectedModulePermissions=[];
      this.modulePermissionsToAddToPlatformPlan=[];
      this.selectedModule.operations.forEach((operation:any) => {
        this.selectedModulePermissions.push({
          id:null,
          name:operation.name,
          module_id:this.selectedModule.id,
          module_operation_id:operation.id,
          platform_plan_id:this.newPlatformPlan.id,
          testtico:true
        });
      });
    }
  }
  compareRolesSelected(role: any, platformRoles: any){
    return role.id==platformRoles.id;
  }
  roleAssociatedToPlan(role:any){
    return this.selectedPlanRoles.some((roleItem:any)=>roleItem.id==role.id);
  }
  modifyPlanRoles(event:any){
    this.utils.showConfirm(this.selectedPlanRoles.length<1?"Asignar el rol al plan":"¿Modificar roles asignados al plan?","La asignación de un rol implica que solo los módulos disponibles para ella podrán ser usados en el plan","Confirmar","Cancelar")
    .then(()=>{
      this.selectedPlanRolesCopy= this.utils.copyObject(this.selectedPlanRoles);
      this.filterModulesAvailable();
    })
    .catch(()=>{  
      this.selectedPlanRoles.splice(this.selectedPlanRoles.findIndex((item:any)=>this.selectedPlanRolesCopy.every((roleCopy:any)=>roleCopy.id!=item.id)),1);
      this.selectedPlanRoles = this.utils.copyObject(this.selectedPlanRoles);  
    });
  }
  comparePermissionsSelected(operation: any, permission: any){
    return operation.id == permission.module_operation_id;
  }
  getModulePermissions(modulePermissions:any){
    if(!this.utils.isNullOrEmpty(modulePermissions) && !this.utils.isNullOrEmpty(this.modules))
     return this.modules.find((module:any)=>module.id==modulePermissions.module_id).operations;
    return [];
  }
  isPermissionSelected(operationModule:any,modulePermission:any){
    return modulePermission.permissions.some((permission:any)=>permission.module_operation_id==operationModule.id);
  }
  isValidmodulePermissionsToAddToPlatformPlan(){
    if(Object.entries(this.selectedModule).length<1)
      return {status:false,message:"No ha seleccionado el modulo del cual establecerá permisos"};
    if(this.modulePermissionsToAddToPlatformPlan.length==0)
      return {status:false,message:"No ha indicado al menos un permiso sobre el módulo"};
    return {status:true};
  }
  closeModal(changeApplied:boolean=true,platformId:number=-1){
    this.closeForm.emit({close:true,changes_applied:changeApplied,platform_plan_id:platformId});
  }
  showLoading(show: boolean = true){
    if(show) this.loading = true;
    else setTimeout(() => { this.loading = false; }, 500);
  }
}