import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import {Sort} from '@angular/material/sort';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { UpcomingDeviceInvoicesComponent } from "../../dialogs/upcoming-device-invoices/upcoming-device-invoices.component";
import { SelectionModel } from '@angular/cdk/collections';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MessageBoxService } from 'src/app/core/services/message-box/message-box.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationSnackbarComponent, IMessageSnackBar } from "../confirmation-snackbar/confirmation-snackbar.component";
import { FolderRoute } from 'src/app/core/constant/FolderRouter';
import { RequestMethodHTTP } from '../../enums/common-enums.enum';
import { IPagination, ISort } from '../../models/interfaces/iapi-request-data.model';
import { ObjectInitializationService } from 'src/app/core/services/object-initialization/object-initialization.service';
import { IDeviceGeneral } from '../../../core/models/interfaces/idevice.model';
import { IViewsAvailable } from '../../models/interfaces/views-available';
import { IUserPlanModule, IUserSessionData } from '../../../core/models/interfaces/iuser-session-data.model';
import { ValidationService } from '../../../core/services/validation/validation.service';
import { PageEvent } from '@angular/material/paginator';
import { IUserContactInfo } from '../../../core/models/interfaces/user-data.model';
import { IPermissionsIntgps } from '../../../core/models/interfaces/ipermissions-intgps.model';
import { ApiEndpointSgc } from '../../../core/enums/api-endpoint-sgc.enum';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manager-devices-list',
  templateUrl: './manager-devices-list.component.html',
  styleUrls: ['./manager-devices-list.component.scss']
})
export class ManagerDevicesListComponent implements OnInit{

loading: boolean = false;//para loading
user:IUserSessionData;

@Output() close = new EventEmitter<boolean>();
@Input() isSelectedUser:boolean =false;//indica si se van a filtrar solo los datos de un usuario especifico
@Input() selectedUserId:number | null = null;//indica el id del usuario seleccionado para solo filtrar dispositivos de dicho usuario en concreto 
certificationEnabled:boolean = false;

pagination: IPagination;

displayedColumns: string[] = ["certificate","active","plate_number","sim_number","responsible_name","phone_number","imei","expiration_date","device_model","status","actions"];
displayedColumnsCopy: string[] = [...this.displayedColumns];
headers: string[]=["plate_number","sim_number","name","imei","expiration_date","device_model"];//las propiedades a exportar de los dispositivos

usersList: IUserContactInfo[] = [];//para la lista de usuarios, con email y id

devices: IDeviceGeneral[] = [];

isUpdateDevice: boolean = false;//indica si es para actualizar dispostivo. usado en el modal
newDevice!: IDeviceGeneral;//almacena los datos del dispositivo al que se va a realizar la actualizacion de datos
//almacena la lista de vistas disponibles en el componente
viewsAvailable: IViewsAvailable = {
  form:{name:"form",show_view:false},
  offline_devices:{name:"offline_devices",show_view:false},  
  device_list:{name:"device_list",show_view:true}
};
offlineDevicesView: boolean = false;//indica si se muestra la ventana modal 

/**Indica la lista de dipositivos a generar certificado */
devicesToGenerateCertificates = new SelectionModel<any>(true, []);
dateSelectedForCertificate: Date |null= null;

sort: ISort;
searchValue: string = "";
moduleId:number = 5;
  /**  @type {IUserPlanModule} Permisos sobre el módulo */
permissionsDataSgc:IUserPlanModule;
permissionsDataIntgps:IPermissionsIntgps[] =[]//permisos INTGPS
/** Almacena los permisos especificos para el modulo, un objeto con el nombrePermiso:identificador
 * @type {Object}
 */
moduleSpecificPermissions:Record<string,number>={
  seePaymentProjections:4
};

/** @type {NodeJS.Timeout} Indica el id del timer usado para enviar solicitud de busqueda de un valor determinado en los datos de la tabla */
timerIdSearchValueInTable!: NodeJS.Timeout;
constructor(
  private api: ApiService,
  public dataService: DataService,
  public utils:UtilsService,
  public matDialog: MatDialog,
  public messageBox: MessageBoxService,
  private snackBar: MatSnackBar,
  private objectInitializationService: ObjectInitializationService,
  private validationService: ValidationService,
  private route:Router
  ) {
    this.permissionsDataSgc = this.objectInitializationService.initializeIUserPlanModule();
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.pagination.page_size = this.dataService.PAGE_SIZE_OPTION[0];
    this.sort = this.objectInitializationService.initializeISort();
    this.user = this.dataService.getData("user");
  }
  ngOnInit(): void {
    this.dataService.checkPermissionIntgpsModule('devices').then((permissions: any) => {
      this.permissionsDataIntgps = permissions;
      this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
        this.permissionsDataSgc = permissions;
        this.getList();
        this.getUserList();
        this.displayedColumns = this.displayedColumns.slice(1,this.displayedColumns.length);
      }).catch(() => {
        this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
        this.route.navigate(['/']);
      });
    }).catch((error:any) => {
      this.utils.showMsg("Página no autorizada","Tu cuenta no tiene permisos para ver esta página, contacte al administrador");
      this.route.navigate(['/']);
    });
  }
  //obtiene datos de los usuarios. para la lista de checkbox en el formulario de actualizacion o registro
  async getUserList(){
    let data = { user_api_hash: this.user.hash, action: "get",columns_to_get:["id","email"] };
    try {
      let response: unknown = await this.api.getDataPost("user",data,true); 
      if(this.validationService.isResponseApi(response) && response.status == 1){
        this.usersList = response.data; 
        this.usersList.push({id:this.user.id,email:this.user.email});
      }else
        this.usersList = [];    
    } catch (error:unknown) {}
  }
  toggleMessageActive(activeDevice:number){
    return activeDevice == 1 ? "Desactivar dispositivo" : "Activar dispositivo";
  }
  //metodo para la tabla de dispositivos, que indica si el dispositivo esta activo o inactivo
  isActive(activedevice:number){
    return activedevice == 1;
  }
  //para cambiar el estado de activo/inactivo del dispositivo
  statusChange(event:any,device:IDeviceGeneral){
    let deviceData={
      user_api_hash:this.user.hash,
      id:device.id,
      active:event.checked?1:0,
      action:"changeStatus"
    };
    this.api.createData(deviceData, "device",true).then((data: any) => {
        this.utils.showMsg("", data.status==1 ? "Estado actualizado" : "No se pudo cambiar el estado");
    }).catch((err: any) => {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el estado",true, false,RequestMethodHTTP.POST));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));;
  }
  paginator(event:PageEvent):void{
    this.pagination.current_page = event.pageIndex;    
    this.pagination.page_size = event.pageSize;
    this.getList();
  }
  searchValueInData(){
    this.pagination.current_page = 0;
    clearTimeout(this.timerIdSearchValueInTable);
    this.timerIdSearchValueInTable = setTimeout(() => {this.getList();}, this.dataService.DATA_TABLE_SEARCH_TIMEOUT);
  }
  getList():void{
    this.loading = true;
    let data:string =  this.api.getCommonQueryParameters("get",this.user.hash,this.searchValue,this.pagination.current_page,this.pagination.page_size,this.sort.by!,this.sort.direction!);
    if(!this.validationService.isNullOrEmpty(this.isSelectedUser))
      data +="&user_id="+this.selectedUserId;
    this.api.getData("device",data,true).then((data: unknown)=>{
      if(this.validationService.isResponseApi(data)){
        if(data.status == 1){
          this.pagination.length_page = data.total??0;
          this.devices = data.data as IDeviceGeneral[];
          if(this.isSelectedUser && !this.certificationEnabled)
            this.devicesToGenerateCertificates.clear();     
        }else
          this.cleanData();
      }
    }).catch((error:any)=>{
      this.cleanData();
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de dispositivos",false,false,"GET"));
    }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
  }
  cleanData(){
    this.pagination.current_page = 0;
    this.pagination.length_page = 0;
    this.devices = [];
  }
  sortData(sort: Sort) {
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getList();
  }
  deleteDevice(device:any){
    this.utils.showConfirm("Confirmar acción","¿Está seguro de eliminar el dispositivo?","Eliminar","Cancelar").then(()=>{
      //1. elimino dispositivo de INTGPS
      let requestDataIntpg="?user_api_hash="+this.user.hash+"&device_id="+device.id;
      this.api.deleteData("api/destroy_device",requestDataIntpg).then((data: any) => {
        if(data.status>0){
          //2. elimino dispositivo de SGC
          let requestdataSGC="?action=delete&user_api_hash="+this.user.hash+"&device_id="+device.id;
          this.api.deleteData("device",requestdataSGC,true).then((dataSGC: any) => {
            if(dataSGC.status==1){
              this.utils.showMsg("Acción completada","Se ha eliminado al dispositivo");
              this.getList();
            }else{
              this.utils.showMsg("Información",dataSGC.message);
            }
            this.utils.hideLoading(()=>this.loading = false);
          }).catch((err: any) => {
            this.utils.hideLoading(()=>this.loading = false);
            this.utils.showMsg("Acción no completada","No se pudo eliminar el dispositivo en SGC");
          });
        }else{
          this.utils.showMsg("Información","No se pudo eliminar el dispositivo");
        }
        this.utils.hideLoading(()=>this.loading = false);
      }).catch((err: unknown) => {
        this.utils.hideLoading(()=>this.loading = false);
        this.utils.showMsg("Acción no completada","No se pudo eliminar el dispositivo");
      });

    }).catch(()=>{
      return;
    });
  }
  hasBillingPlan(device:IDeviceGeneral){
    return (device != null && device.personalized_plan_id != null) && device.responsible_user?.id != null;
  }
  previewOfUpcomingInvoices(device:IDeviceGeneral){
    const dialogRef = this.matDialog.open(UpcomingDeviceInvoicesComponent,{data:{"device":device,panelClass:'mat-dialog-width-responsive'}});
  }
  openUpdateDeviceModal(device:any):void{
    this.newDevice=JSON.parse(JSON.stringify(device));
    this.isUpdateDevice=true;
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['form'].name);
  }
  openCreateDeviceModal():void{
    this.newDevice = this.objectInitializationService.initializeIDeviceGeneral();
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['form'].name);
    this.isUpdateDevice = false;
  }
  openOfflineDevicesView(){
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['offline_devices'].name);
  }
  isDataSGCComplete(device:IDeviceGeneral):boolean{
    let deviceInSGC = device;
    device.complete_data = false;
      if(typeof deviceInSGC !== "undefined" && deviceInSGC.cylinder_capacity != null && deviceInSGC.vehicle_color != "" && deviceInSGC.vehicle_type != null && deviceInSGC.vehicle_brand != ""){
        if(device.plate_number !="" && device.device_model !="" && device.sim_number !="" && device.imei !=""){
          device.complete_data = true;
          return true;
        }
      }
    return false;
  }
  async exportFile(){
    
    this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(async () => {
      this.loading = true;
      
      const formdata:FormData =  new FormData();
      formdata.append("user_api_hash", this.user.hash);
      formdata.append("entity",ApiEndpointSgc.DEVICE);
      formdata.append("format",this.dataService.DATA_EXPORT_FORMAT.csv.code);
  
      this.loading = true;
      try {
        let response:unknown = await this.api.requestBlobData(formdata,ApiEndpointSgc.EXPORT);
        if(this.validationService.isBlob(response))
          this.utils.downloadFile(response ,  "devices-" + (this.utils.getDateTimeNumericString()), this.dataService.DATA_EXPORT_FORMAT.csv.code);
        else
          this.messageBox.openSnackBar("Error en el procesamiento de la solicitud del archivo","Aceptar");
      } catch (error) {
        this.messageBox.openSnackBar("Error al generar archivo","Aceptar");
      }finally{
        this.utils.hideLoading(()=> this.loading = false);
      }
      this.utils.showMsg("","Datos exportados con éxito");
    });
  }
  /*exportFile(){
    this.utils.processFileDownload("devices_",
     async ()=>{
      let data: Partial<IDeviceGeneral>[] = [];
      try {
        let response:unknown = await this.api.getData("device","?columns_to_get=id,name,sim_number,plate_number,name,imei,expiration_date,device_model&action=get&user_api_hash="+this.user.hash,true);
        if(this.validationService.isResponseApi(response))
          data = response.status == 1 ? response.data : [];
      } catch (error:unknown) { 
        this.loading = false;
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de dispositivos",false,false,"GET"));
      }
      return {data:data,headers:this.headers};
    },
    (response:boolean)=>this.loading = response);
  }*/
  closeModal(event:{close:boolean,updated:boolean}) {
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['device_list'].name);
    if(event.updated)
      this.getList();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.devicesToGenerateCertificates.selected.length;
    const numRows = this.devices.length;
    return numSelected >0 && numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.devicesToGenerateCertificates.clear();
      return;
    }
    this.devicesToGenerateCertificates.select(...this.devices.map((device:any)=>device.id));
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) 
      return `${this.isAllSelected() ? 'Deseleccionar' : 'Certificar'} toda la lista`;
    return `${this.devicesToGenerateCertificates.isSelected(row.id) ? 'Deseleccionar' : 'Generar certificado a '} dispositivo ${row.name}`;
  }
  enableCertificate(){
    this.certificationEnabled = true;
    this.displayedColumns = [...this.displayedColumnsCopy];
  }
  showCertificateValidityDatePicker(picker:MatDatepicker<Date>){
    this.messageBox.openSnackBar('Seleccione la fecha limite de validez del certificado','Definir',{duration:3000},()=>setTimeout(() => {
      picker.open();
    }, 50));
  }
  generateCertificate(event?:MatDatepickerInputEvent<any, any>){
    let deviceIds = this.devicesToGenerateCertificates.selected.map((deviceId:number)=>deviceId);
    let url = this.api.apiRouteSGC+ FolderRoute.FILE_GENERATOR_DEVICE_CERTIFICATE + "?certificate_validity_end_date="+this.utils.getDatetime(event?.value).split(" ")[0]+"&device_ids="+deviceIds.join(",");
    url +="&user_id="+this.selectedUserId+"&add_warranty_date=";
    setTimeout(() => {this.dateSelectedForCertificate =null;}, 20);
    const snackBarRef = this.snackBar.openFromComponent(ConfirmationSnackbarComponent,{
      data:{title:"¿Agregar fecha de garantía?",yes_text:"Sí",no_text:"No",url:url,onYes() {
        snackBarRef.dismiss();
        window.open(this.url +=true);
      },onNo() {
        snackBarRef.dismiss();
        window.open(this.url +=false);
      },}
    });
  }
  cancelCertification(){
    this.devicesToGenerateCertificates.clear();
    this.certificationEnabled = false;
    this.displayedColumns = this.displayedColumns.slice(1,this.displayedColumns.length);
  }
  back(){
    this.close.emit(true);
  }
}