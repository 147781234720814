<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['user_list'].show_view">
    <section class="form-box-container__header">
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-users"></i></span>Clientes</h2>
        <div  class="form-box-container__header-options">                     
            <button matTooltip="crear usuario" class="btn btn-primary" (click)="openCreateUserModal()"><i
                class="fa-solid fa-user-plus icon"></i><span class="description"> Agregar</span></button>
            <button matTooltip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i
                class="fa-solid fa-file-export icon"></i><span class="description"> Exportar</span></button>
            <div class="search">
                <input id="inputSearch" type="search" placeholder="Buscar" (input)="search()" name="searchText" [(ngModel)]="searchText">
                <label for="inputsearch"><i class="fa-solid fa-magnifying-glass"></i></label>
            </div>
        </div>
    </section>
    <div class="form-box animate__animated animate__fadeIn">
        <div class="form-box__content form-box__content-h-auto">
            <div class="w100 overflow-auto">
            <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="users">
                
                <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de activación"> Activo </th>
                <td mat-cell *matCellDef="let client" class="text-center"> 
                    <mat-slide-toggle
                    [matTooltip]="toggleMessageActive(client.active)"
                        [checked]="isActive(client.active)"
                        color="primary"
                        class="z-index-last"
                        (change)="statusChange($event,client)">
                    </mat-slide-toggle>
                </td>
                </ng-container>
                <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por correo"> Correo </th>
                <td mat-cell *matCellDef="let client"> {{client.email}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Contacto"> Contacto </th>
                <td mat-cell *matCellDef="let client"> {{client.name}} </td>
                </ng-container>
                <ng-container matColumnDef="document_number">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Documento"> Documento </th>
                <td mat-cell *matCellDef="let client"> {{client.document_number}} </td>
                </ng-container>
                <ng-container matColumnDef="phone_number">
                <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por Teléfono"> Teléfono </th>
                <td mat-cell *matCellDef="let client"> {{client.phone_number}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Estado </th>
                <td mat-cell *matCellDef="let client" class="text-center"> 
                    <div class="status complete" *ngIf="isDataUserComplete(client)" matTooltip="datos completos"><i class="fa-solid fa-circle-check"></i></div>
                    <div class="status incomplete" *ngIf="!isDataUserComplete(client)" matTooltip="datos incompletos"><i class="fa-solid fa-circle-xmark"></i></div>    
                </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
                <td mat-cell *matCellDef="let client" class="text-center"> 
                    <div class="actions-container">
                        <button matTooltip="Editar usuario" (click)="openUpdateUserModal(client)"><i class="fa-solid fa-pen-to-square fa-sm"></i></button>
                        <button matTooltip="Eliminar usuario" (click)="deleteUser(client)"><i class="fa-solid fa-trash-can fa-sm"></i></button>
                        <button matTooltip="mostrar dispositivos" (click)="getUserDevices(client)"><i class="fa-solid fa-list-ul  fa-sm"></i></button>
                    </div>
                </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                </tr>
            </table>
            </div>
            <mat-paginator [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
                [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
                aria-label="Selecciona pagina de la lista de clientes">
            </mat-paginator>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>
</section>
<app-manager-devices-list [selectedUserId]="newUser.id" [isSelectedUser]="!utils.isNullOrEmpty(newUser.id)" (close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['user_list'].name);objectInitialization.initializeIUserGeneral(user.id,availableMapIds,devicePlanType.PERSONALIZED,newUser.id)"  *ngIf="viewsAvailable['selected_user_devices'].show_view"></app-manager-devices-list>

<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['user_form'].show_view" >
    <div class="form-box animate__animated animate__fadeIn" >
        <form  class="form-box__content form-box__content-h-auto" id="registrationForm" (submit)="adminUser($event,isUpdate)">
            <mat-tab-group dynamicHeight (selectedTabChange)="validateComponentToShow($event)">
                <mat-tab label="Principal">
                    <div class="form-box__content-inputs mt-2">
                        <div class="form-box__content-group-fields">
                            <mat-form-field appearance="fill">
                                <mat-label>País</mat-label>
                                <mat-select  id="inputCountry" (selectionChange)="getIdentificationDocumentTypes();getCitiesList()" name="country_id" [(ngModel)]="newUser.country_id" >
                                    <ng-container   *ngFor="let item of countriesList">
                                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field id="inputCity" appearance="fill">
                                <mat-label>Ciudad</mat-label>
                                <mat-select (closed)="clearSearchCity()" [(ngModel)]="newUser.city_id"name="city_id" placeholder="Buscar...">
                                    <mat-option>
                                    <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar ciudad..." [(ngModel)]="cityToSearch" name="cityToSearch" (keyup)="searchCity()">
                                        <mat-icon (click)="clearSearchCity()" ngxMatSelectSearchClear>close</mat-icon>
                                    </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option value="" *ngIf="newUser.country_id == null">-- {{newUser.country_id == null ? 'Seleccione primero un país' : 'Seleccione una opción' }} -- </mat-option>
                                    <mat-option *ngFor="let city of citiesListFilter" [value]="city.id" color="primary">
                                        {{city.name}}                   
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Dirección</mat-label>
                                <input matInput type="text" name="address" [(ngModel)]="newUser.address">
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Tipo de documento de identificación</mat-label>
                                <mat-select  name="identification_document_type_id" (selectionChange)="showIdentificationDocumentType()" id="inputIdentificationDocumentType" [(ngModel)]="newUser.identification_document_type_id" >
                                    <mat-option value="">-- {{validationService.isNullOrEmpty(newUser.country_id)?'Seleccione primero un país':'Seleccione una opción' }} -- </mat-option>
                                    <ng-container   *ngFor="let item of identificationDocumentTypeList">
                                        <mat-option [value]="item.id">{{item.name +(validationService.isNullOrEmpty(item.acronym)?"":(" - "+item.acronym))}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>{{newUserIdentificationDocumentType.id <= 0 ? "Número documento":(newUserIdentificationDocumentType.name.length > 40 ? newUserIdentificationDocumentType.acronym : newUserIdentificationDocumentType.name ) }}</mat-label>
                                <input matInput autocomplete="off" name="document_number" [(ngModel)]="newUser.document_number"
                                type="text" id="inputDocumentNumber">
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>Tipo de personalidad legal</mat-label>
                                <mat-select  name="legal_personality_type"   [(ngModel)]="newUser.legal_personality_type" id="inputlegalPersonalityType" >
                                    <ng-container   *ngFor="let item of dataService.legal_personality_types_list">
                                        <mat-option [value]="item.value">{{item.value}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>{{newUser.legal_personality_type==dataService.legal_personality_types.legal_person || newUser.legal_personality_type==dataService.legal_personality_types.natural_person_trader?"Razón social":"Nombre"}}</mat-label>
                                <input matInput name="name"autocomplete="off" [(ngModel)]="newUser.name"
                                type="text" id="inputName">
                            </mat-form-field>
                            <mat-form-field appearance="fill" *ngIf="newUser.legal_personality_type==dataService.legal_personality_types.legal_person || newUser.legal_personality_type==dataService.legal_personality_types.natural_person_trader">
                                <mat-label>Nombre de contacto</mat-label>
                                <input matInput name="contact_name" type="text" [(ngModel)]="newUser.contact_name" id="inputContactName">
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>Tratamiento</mat-label>
                                <mat-select   name="courtesy_title" [(ngModel)]="newUser.courtesy_title" id="inputCourtesyTitle" >
                                    <ng-container   *ngFor="let item of dataService.courtesy_title">
                                        <mat-option [value]="item.value">{{item.value}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            
                            <mat-form-field appearance="fill">
                                <mat-label>Fecha de nacimiento</mat-label>
                                <input matInput [matDatepicker]="birthDatepicker" [max]="minDateForBirthDate" (click)="birthDatepicker.open();$event.preventDefault()" name="birth_date"  [(ngModel)]="newUser.birth_date" readonly="">
                                <mat-datepicker-toggle matSuffix [for]="birthDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #birthDatepicker startView="multi-year" disabled="false"></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Prefijo Telefónico</mat-label>
                                <mat-select   name="phone_prefix"   [(ngModel)]="newUser.phone_prefix" id="inputPhonePrefix">
                                    <ng-container   *ngFor="let item of dataService.phone_prefix">
                                        <mat-option [value]="item.code">{{item.code +" - "+item.country}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>Número de contacto</mat-label>
                                <input matInput matTooltip="ingrese solo numeros" autocomplete="off" pattern="^[0-9]{1,20}$" required type="text" name="phone_number" id="inputPhoneNumber"
                                [(ngModel)]="newUser.phone_number">
                            </mat-form-field>
    
                            <mat-form-field appearance="fill">
                                <mat-label>Email</mat-label>
                                <input matInput  required type="email" [pattern]="validationService.isValidEmailStr" name="email" autocomplete="off"id="inputEmail"
                                [(ngModel)]="newUser.email">
                                <mat-error>Email de contacto inválido. Ejemplo válido. {{'usuario@dominio.com'}}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" *ngIf="!isUpdate || (isUpdate && newUser.is_update_password)">
                                <mat-label>Contraseña</mat-label>
                                <input matInput   autocomplete="off" required type="password" name="password" id="inputPassword"  [(ngModel)]="newUser.password">
                            </mat-form-field>
                            <mat-form-field appearance="fill" *ngIf="!isUpdate || (isUpdate && newUser.is_update_password)">
                                <mat-label>Confirmar Contraseña</mat-label>
                                <input matInput required="" autocomplete="off"name="password_confirmation" [(ngModel)]="newUser.password_confirmation"
                                type="password" id="inputPasswordConfirmation">
                            </mat-form-field>
                            <mat-checkbox class="fill" color="primary" *ngIf="isUpdate" name="check_enable_password_update" [(ngModel)]="newUser.is_update_password">Actualizar Contraseña</mat-checkbox>
    
                        </div>
                        <fieldset class="form-box__content-fieldset mt-1">
                            <legend>Mapas disponibles:</legend>
                            
                            <div class="form-box__content-group-fields">
                                <input name="available_maps" type="hidden">
                                <ng-container *ngFor="let map of availableMaps">
                                    <mat-checkbox color="primary" *ngIf="!isUpdate" checked="checked" (change)="modifyAvailableMaps($event,map.id)" id="{{map.name.replace(' ','_')}}" name="available_maps[]" value="{{map.id}}">{{map.name}}</mat-checkbox>
                                    <mat-checkbox color="primary" *ngIf="isUpdate" [checked]="isMapSelected(newUser.available_maps,map.id)" (change)="modifyAvailableMaps($event,map.id)" id="{{map.name.replace(' ','_')}}" name="available_maps[]" value="{{map.id}}">{{map.name}}</mat-checkbox>    
                                </ng-container>
                            </div>
                        </fieldset>
                    </div>
    
                </mat-tab>
                <mat-tab label="Configuraciones">
                    <div class="form-box__content-inputs mt-2">
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="!hasAccessToAutomation">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <i class="fa-solid fa-money-bill icon icon-xl text-primary"></i>Facturación
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                              <ng-container>
                                <section class="d-block alert alert-light-info">
                                    <p class="text-small">Esta sección permite definir el tiempo de vencimiento de las facturas que se le generen al cliente. los valores indicados solo tendrán funcionalidad si el cliente tiene algún dispositívo asignado como responsable de pago</p>
                                </section>
                                <div class="form-box__content-group-fields mb-2">
                                    <mat-form-field appearance="fill" floatLabel="always"  class="mat-number-spinner">
                                        <mat-label>tiempo de vencimiento de la factura</mat-label>
                                        <input matInput type="text" placeholder="0"  spellcheck="false" name="invoice_due_time_quantity" [(ngModel)]="newUser.user_setting!.invoice_due_time_quantity" readonly="">
                                        <span  matRipple matPrefix (mousedown)="intervalChangeExpirationTimeAmount(false)" (mouseup)="clearIntervalChangeExpirationTimeAmount()" (click)="$event.stopPropagation();changeExpirationTimeAmount(false)"  class="mat-number-spinner__button mat-number-spinner__button--decrement">-</span>
                                        <span  matRipple matSuffix (mousedown)="intervalChangeExpirationTimeAmount(true)" (mouseup)="clearIntervalChangeExpirationTimeAmount()" (click)="$event.stopPropagation();changeExpirationTimeAmount(true)" class="mat-number-spinner__button mat-number-spinner__button--increment">+</span>
                                        <mat-hint align="start">La cantidad de tiempo para las facturas generadas a su nombre</mat-hint>
                                    </mat-form-field>
                                    
                                    <mat-form-field appearance="fill">
                                        <mat-label>Unidad de tiempo</mat-label>
                                        <mat-select name="invoice_due_time_unit"  [(ngModel)]="newUser.user_setting!.invoice_due_time_unit">
                                          <mat-option *ngFor="let item of timeUnitArray" [value]="item.value">
                                            {{item.label |titlecase}}
                                          </mat-option>
                                        </mat-select>
                                        <mat-hint align="start">La unidad de tiempo para calcular la fecha de vencimiento de sus facturas</mat-hint>
                                    </mat-form-field>                    
                                </div>
                              </ng-container>
                              
                            </mat-expansion-panel>
                            <mat-expansion-panel *ngIf="hasAccessToAutomation && newUser.id > 0">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="fa-solid fa-robot icon icon-xl text-primary"></i>Automatizaciones
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <!-- Seccion para la configuracion de las automatizaciones en cuanto a gestion directa de habilitacion o deshabilitacion sobre el usuario -->
                              <ng-container>
                                <section class="d-block alert alert-light-info">
                                    <p class="text-small">Puedes configurar si el usuario estará habilitado para los procesos de las automatizaciones disponibles y configuradas actualmente</p>
                                </section>
                                <div class="form-box__content-group-fields mb-2">
                                    <mat-selection-list color="primary">
                                        <mat-list-option 
                                        *ngFor="let automation of automationsList" 
                                        [selected]="automation.active_user == 1"
                                        (click)="updateUserAssociationWithAutomation(automation)">
                                          {{automation.name }}
                                        </mat-list-option>
                                      </mat-selection-list>                                                    
                                </div>
                              </ng-container>
                            </mat-expansion-panel>
                          </mat-accordion>
                    </div>
                </mat-tab>
                <mat-tab label="Permisos">
                    <mat-tab-group dynamicHeight>
                        <mat-tab label="Plataforma GPS">
                            <table class="table mt-2">
                                <thead>
                                    <tr>
                                        <th style="text-align: left">Permiso</th>
                                        <th style="text-align: center">Ver</th>
                                        <th style="text-align: center">Editar</th>
                                        <th style="text-align: center">Eliminar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let permission of defaultGpsPlatformPermissions">
                                        <tr *ngIf="!permission.name.includes('device.') && !permission.name.includes('user.')"  class="group-main">
                                            <td>{{permission.label}}</td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_view"[disabled]="isPermissionSelected(isUpdate,permission.name ,['edit','remove'],newUser.permissions ) || isPermissionDisabled(permission.name,'view')" (change)="modifySelection($event,'view')" [checked]="isPermissionSelected(isUpdate,permission.name,'view',newUser.permissions )"
                                                        name="perms[{{permission.name}}][view]" [(ngModel)]="newUser['permissions_to_update'][permission.name]['view']" type="checkbox" value="1">
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_edit" name="perms[{{permission.name}}][edit]" [(ngModel)]="newUser['permissions_to_update'][permission.name]['edit']"  [disabled]="isPermissionDisabled(permission.name,'edit')" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,permission.name,'edit',newUser.permissions )"
                                                        type="checkbox" value="1">
                                                </div>
                                            </td>
                                            <td style="text-align: center">
                                                <div class="checkbox">
                                                    <input form="registrationForm" class="perm_checkbox perm_remove" name="perms[{{permission.name}}][remove]"  [(ngModel)]="newUser['permissions_to_update'][permission.name]['remove']" [disabled]="isPermissionDisabled(permission.name,'remove')"(change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,permission.name,'remove',newUser.permissions )"
                                                        type="checkbox" value="1">
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr *ngIf="defaultGpsPlatformPermissions.length == 0">
                                        <td class="mat-cell text-center empty py-1" [attr.colspan]="4">Sin datos</td>
                                    </tr>
                                </tbody>
                            </table>
                
                            <details class="device-permissions-container" open>
                                <summary class="list-style:none">Device</summary>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="text-align: left">Permiso</th>
                                            <th style="text-align: center">Ver</th>
                                            <th style="text-align: center">Editar</th>
                                            <th style="text-align: center">Eliminar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let permission of defaultGpsPlatformPermissions">
                                            <tr *ngIf="permission.name.includes('device.')"  class="group-device collapse">
                                                <td>{{permission.label}}</td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"[disabled]="isPermissionSelected(isUpdate,permission.name ,['edit','remove'],newUser.permissions ) ||isPermissionDisabled(permission.name,'view')" (change)="modifySelection($event,'view')" [checked]="isPermissionSelected(isUpdate,permission.name,'view',newUser.permissions )"
                                                            name="perms[{{permission.name}}][view]" [(ngModel)]="newUser['permissions_to_update'][permission.name]['view']" type="checkbox" value="1">
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit" name="perms[{{permission.name}}][edit]" [(ngModel)]="newUser['permissions_to_update'][permission.name]['edit']"  [disabled]="isPermissionDisabled(permission.name,'edit')" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,permission.name,'edit',newUser.permissions )"
                                                            type="checkbox" value="1">
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove" name="perms[{{permission.name}}][remove]"  [(ngModel)]="newUser['permissions_to_update'][permission.name]['remove']" [disabled]="isPermissionDisabled(permission.name,'remove')"(change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,permission.name,'remove',newUser.permissions )"
                                                            type="checkbox" value="1">
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </details>
                            <details class="device-permissions-container">
                                <summary class="list-style:none">User</summary>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="text-align: left">Permiso</th>
                                            <th style="text-align: center">Ver</th>
                                            <th style="text-align: center">Editar</th>
                                            <th style="text-align: center">Eliminar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let permission of defaultGpsPlatformPermissions">
                                            <tr *ngIf="permission.name.includes('user.')"  class="group-device collapse">
                                                <td>{{permission.label}}</td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_view"[disabled]="isPermissionSelected(isUpdate,permission.name ,['edit','remove'],newUser.permissions ) ||isPermissionDisabled(permission.name,'view')" (change)="modifySelection($event,'view')" [checked]="isPermissionSelected(isUpdate,permission.name,'view',newUser.permissions )"
                                                            name="perms[{{permission.name}}][view]" [(ngModel)]="newUser['permissions_to_update'][permission.name]['view']" type="checkbox" value="1">
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_edit" name="perms[{{permission.name}}][edit]" [(ngModel)]="newUser['permissions_to_update'][permission.name]['edit']"  [disabled]="isPermissionDisabled(permission.name,'edit')" (change)="modifySelection($event,'edit')" [checked]="isPermissionSelected(isUpdate,permission.name,'edit',newUser.permissions )"
                                                            type="checkbox" value="1">
                                                    </div>
                                                </td>
                                                <td style="text-align: center">
                                                    <div class="checkbox">
                                                        <input form="registrationForm" class="perm_checkbox perm_remove" name="perms[{{permission.name}}][remove]"  [(ngModel)]="newUser['permissions_to_update'][permission.name]['remove']" [disabled]="isPermissionDisabled(permission.name,'remove')"(change)="modifySelection($event,'remove')" [checked]="isPermissionSelected(isUpdate,permission.name,'remove',newUser.permissions )"
                                                            type="checkbox" value="1">
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </details>
                            <div class="loader" *ngIf="permissionLoadingStatus == LOAD_STATE.LOADING">
                                <img src="../../../assets/loader.svg">
                            </div>
                        </mat-tab>

                        <mat-tab label="SGC" *ngIf="isUpdate">
                            <span class="form-text mt-2">Ten en cuenta que los permisos al usuario se ven afectados por el plan al cual usted se encuentre suscrito en el momento.</span>
                            <app-clients-sgc-permissions  [userId]="newUser.id"></app-clients-sgc-permissions>
                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
                <mat-tab label="Notas de usuario" *ngIf="isUpdate">
                    <!--Para la agregacion de notas al usuario-->
                </mat-tab>
            </mat-tab-group>
            <div class="form-box__content-actions">
                <button type="submit" class="btn btn-primary" [disabled]="loading || permissionLoadingStatus != LOAD_STATE.SUCCESS" > {{ isUpdate ? 'Actualizar' : 'Registrar' }} </button>
                <button class="btn btn-outline-secondary " (click)="closeModal()">cancelar</button>                        
            </div>
        </form>
    
    
    </div>
    <div class="loader" *ngIf="loading">
        <img src="../../../../assets/loader.svg">
    </div>
</section >

<app-user-notes *ngIf="viewsAvailable['user_notes'].show_view" [userId]="newUser.id" (close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['user_form'].name)"></app-user-notes>