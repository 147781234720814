import { Component, Input,OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ValidationService } from '../../../core/services/validation/validation.service';

export interface IDataSummary{
  [propName:string]:IItemsSummary
}
export interface IItemsSummary{
  label:string,
  value:string|null|number
}

export interface IIcon{
  classes:string,
  icon:string,
  icon_type:"font_awesome"|"material_icon"
}
@Component({
  selector: 'app-detail-popover',
  templateUrl: './detail-popover.component.html',
  styleUrls: ['./detail-popover.component.scss']
})
export class DetailPopoverComponent  implements OnInit {
  @Input() icon:IIcon={
    classes:"",
    icon:"info",
    icon_type:"material_icon"
  };

  @Input() dataSummary:IDataSummary={};
  @Input() title!:IItemsSummary| null;
  @Input() subtitle:string="";

  dataList:IItemsSummary[]=[];
  constructor(public validationService:ValidationService){
  }
  ngOnInit(): void {
    this.dataList = Object.values(this.dataSummary??{});
  }
  showMenu(trigger: MatMenuTrigger): void {
    trigger.openMenu();
  }

  hideMenu(trigger: MatMenuTrigger): void {
    trigger.closeMenu();
  }
}
