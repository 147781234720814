import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { MessageBoxService } from 'src/app/core/services/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/services/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { IUserMessagingServiceApiPhoneLine, IUserMessagingServiceApiPhoneLineSummaryByActivationStatus, IUserMessagingServiceApiPhoneLineSummaryByConnection, UserMessagingServicePhoneLineActivationStatus, UserMessagingServicePhoneLineConnectionValues } from 'src/app/core/models/interfaces/imessaging-service-api.model';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { PhoneLineIntegrationDetailsComponent } from './phone-line-integration-details/phone-line-integration-details.component';
import { RequestMethodHTTP, swalIcon } from 'src/app/shared/enums/common-enums.enum';
import { PhoneLineRegistrationConfirmationComponent } from './phone-line-registration-confirmation/phone-line-registration-confirmation.component';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { IResponseApi } from 'src/app/core/models/interfaces/iresponse-api.model';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { ApiEndpointSgc } from 'src/app/core/enums/api-endpoint-sgc.enum';
import { MessagingServiceApiPhoneLineService } from './services/messaging-service-api-phone-line/messaging-service-api-phone-line.service';


interface ISummaryCardByConnection{
  [UserMessagingServicePhoneLineConnectionValues.CONNECTED]: IAccountSummaryCard
  //[UserMessagingServicePhoneLineConnectionValues.DISCONNECTED]: IAccountSummaryCard
}

interface ISummaryCardByAtivationstatus{
  [UserMessagingServicePhoneLineActivationStatus.PENDING]: IAccountSummaryCard
}

interface IAccountSummaryCard{
  name: string,
  total: number,
  class_css: string,
  class_css_animate_delay: string,
  icon: string
}
@Component({
  selector: 'app-messaging-service-account-setting',
  templateUrl: './messaging-service-account-setting.component.html',
  styleUrls: ['./messaging-service-account-setting.component.scss']
})
export class MessagingServiceAccountSettingComponent implements OnInit {

  /** Almacena la lista de vistas disponibles en el componente */
  viewsAvailable:IViewsAvailable = {
    phone_line_registration_form:{name:"phone_line_registration_form",show_view:true},
    phone_line_list:{name:"phone_line_list",show_view:false}
  };

  user:IUserSessionData;
  loading: boolean = false;

  accountSummaryCardsByConnection: ISummaryCardByConnection;
  accountSummaryCardsByActivationstatus: ISummaryCardByAtivationstatus;
  accountSummaryCards: IAccountSummaryCard[] = [];

  userMessagingServicePhoneLine: IUserMessagingServiceApiPhoneLine;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
    public messageBox: MessageBoxService,
    private objectInitializationService: ObjectInitializationService,
    private validationService: ValidationService,
    public dialog: MatDialog,
    public messagingServiceApiPhoneLineService: MessagingServiceApiPhoneLineService
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.userMessagingServicePhoneLine = this.objectInitializationService.initializeIUserMessagingServicePhoneLine();
    //[UserMessagingServicePhoneLineConnectionValues.DISCONNECTED]: { total: 0, icon: "fa-solid fa-square-phone", name: "Disponibles", class_css: "count-panel-item__info", class_css_animate_delay: "custom-animate__delay-400ms" }
    this.accountSummaryCardsByConnection ={
      [UserMessagingServicePhoneLineConnectionValues.CONNECTED]:{ total: 0, icon: "fa-solid fa-signal", name: "Conectadas", class_css: "count-panel-item__success", class_css_animate_delay: "custom-animate__delay-400ms" }
    }
    this.accountSummaryCardsByActivationstatus ={
      [UserMessagingServicePhoneLineActivationStatus.PENDING]: { total: 0, icon: "fa-solid fa-clock", name: "Pendientes", class_css: "count-panel-item__warning", class_css_animate_delay: "custom-animate__delay-200ms" }
    }
    let items:IAccountSummaryCard[] = Object.values(this.accountSummaryCardsByConnection) as IAccountSummaryCard[];
    this.accountSummaryCards.push(...items,this.accountSummaryCardsByActivationstatus[UserMessagingServicePhoneLineActivationStatus.PENDING]);

  }
  ngOnInit(): void {
    this.getSummayPhoneLines();
  }
  async addMobileLine(){
    const dialogRef = this.dialog.open(PhoneLineRegistrationConfirmationComponent,{panelClass:'mat-dialog-width-responsive--small'});
    dialogRef.afterClosed().subscribe(async (response:unknown) => {
      if(typeof response != "boolean" || !response)
        return;
      this.loading = true;
      let requestResponse: IResponseApi | null | unknown = null;
      try {
        let dataToSend: IApiRequestData & Pick<IUserMessagingServiceApiPhoneLine,"name">= { user_api_hash: this.user.hash, action: "add", name: this.userMessagingServicePhoneLine.name};
        requestResponse = await this.api.createData(dataToSend, ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE, true);   
      } catch (error: unknown) {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("cuenta de linea movil de mensajería",false, false, RequestMethodHTTP.POST));
      } finally {
        if (this.validationService.isResponseApi(requestResponse)) {
          this.utils.showResultRequest(requestResponse.status == 1 ? "info" : "error", requestResponse.status == 1 ? this.messagingServiceApiPhoneLineService.ACCOUNT_PENDING_ACTIVATION_MESSAGE.title : "Información", 
          requestResponse.status == 1 ? this.messagingServiceApiPhoneLineService.ACCOUNT_PENDING_ACTIVATION_MESSAGE.message : (requestResponse.message ?? ''));
  
          if(requestResponse.status == 1){
            this.userMessagingServicePhoneLine.name = "";
            this.getSummayPhoneLines(false,true); 
          }
        }
        this.utils.hideLoading(() => this.loading = false);
      }
    }); 
  }
  openIntegrationDetails(){
    const dialogRef = this.dialog.open(PhoneLineIntegrationDetailsComponent,{panelClass:'mat-dialog-width-responsive'});
    dialogRef.afterClosed().subscribe((response:unknown) => {}); 
  }
  openPhoneLinesListView(){
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["phone_line_list"].name)
  }
  /**
   * @descripiton Obtiene la lista de resumen de totales para los campos de conecxion y estado de activacion 
   */
  async getSummayPhoneLines(requestSummaryByConnection:boolean = true,requestSummaryByActivacionstatus:boolean = true){
   this.loading = true;
   let data:string =  this.api.getCommonQueryParameters("getSummary",this.user.hash);
   try {

    let responseSummaryByConnection:unknown, responseSummaryByActivacionstatus:unknown;
    if(requestSummaryByActivacionstatus && requestSummaryByConnection){
      [responseSummaryByConnection, responseSummaryByActivacionstatus] = await Promise.all([
        this.api.getData( ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE, data + "&field=connected", true), 
        this.api.getData( ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE, data + "&field=activation_status", true),
      ]);
    }else if(requestSummaryByConnection)
      responseSummaryByConnection = await this.api.getData( ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE, data + "&field=connected", true);
    else if(requestSummaryByActivacionstatus)
      responseSummaryByActivacionstatus = await this.api.getData( ApiEndpointSgc.USER_MESSAGING_SERVICE_API_PHONE_LINE, data + "&field=activation_status", true);
    
    if(requestSummaryByConnection && this.validationService.isResponseApi(responseSummaryByConnection) && this.messagingServiceApiPhoneLineService.isISummaryByConnection(responseSummaryByConnection.data[0]??[])){
      let data = responseSummaryByConnection.data as IUserMessagingServiceApiPhoneLineSummaryByConnection[];
      if(Array.isArray(data)){
        let item: IUserMessagingServiceApiPhoneLineSummaryByConnection;
        for(item of responseSummaryByConnection.data){
          if(item.connected == UserMessagingServicePhoneLineConnectionValues.CONNECTED && typeof this.accountSummaryCardsByConnection[item.connected] != "undefined")
            this.accountSummaryCardsByConnection[item.connected].total = item.total;  
        }
      }
    }else if(requestSummaryByConnection)
      throw new Error("el resumen de datos según estado de conexión");
    if(requestSummaryByActivacionstatus && this.validationService.isResponseApi(responseSummaryByActivacionstatus) && this.messagingServiceApiPhoneLineService.isISummaryByActivationStatus(responseSummaryByActivacionstatus.data[0])){
      let data = responseSummaryByActivacionstatus.data as IUserMessagingServiceApiPhoneLineSummaryByActivationStatus[];
      if(Array.isArray(data)){
        let item: IUserMessagingServiceApiPhoneLineSummaryByActivationStatus;
        for(item of responseSummaryByActivacionstatus.data){
          if(item.activation_status == UserMessagingServicePhoneLineActivationStatus.PENDING && typeof this.accountSummaryCardsByActivationstatus[item.activation_status] != "undefined")
            this.accountSummaryCardsByActivationstatus[item.activation_status].total = item.total;  
        }
      }
    }else if(requestSummaryByActivacionstatus)
      throw new Error("el resumen de datos según estado de activación de la cuenta");
  
   } catch (error) {
    if(error instanceof Error)
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage(error.message,true, false,RequestMethodHTTP.GET));
   }
   finally{
      this.utils.hideLoading(() => this.loading = false);
   }
 }
  closeComponent(){
    this.close.emit(true);
  }
}