import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ColorManipulationService } from 'src/app/core/services/color-manipulation/color-manipulation.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { ObjectInitializationService } from 'src/app/core/services/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { Sort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { IChartConfigData } from 'src/app/shared/models/interfaces/iutil.model';
import { ChartTypeChartjs } from 'src/app/shared/enums/common-enums.enum';
import { IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { PageEvent } from '@angular/material/paginator';
import { ValidationService } from '../../../../core/services/validation/validation.service';

interface IManagerDataSummary{
    email: string,
    name: string,
    platform_plan_name: string,
    total_users: number,
    total_devices: number
}
@Component({
  selector: 'app-home-admin-user',
  templateUrl: './home-admin-user.component.html',
  styleUrls: ['./home-admin-user.component.scss']
})
export class HomeAdminUserComponent implements OnInit {

  //para rango de fecha. usado en vista de grafico de admin
  range: any = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  /** @type {NodeJS.Timeout} Indica el id del timer usado para enviar solicitud de busqueda de un valor determinado en los datos de la tabla */
  timerIdSearchValueInTable!: NodeJS.Timeout;
  loading: boolean = false;
  user: IUserSessionData;

  pagination: IPagination;
  sort: ISort;
  /**Valr a buscar en la tabla */
  searchText: string = "";
  distributorsTotal: number = 0;//usada en el modal del admin
  distributorsData: IManagerDataSummary[] = [];//para almacenar los datos de los distribuidores, usada en el modal del admin

  configDistibutorsCharts!:IChartConfigData;

  displayedColumnsInDistributortable = ["name","platform_plan_name","total_users","total_devices"];
  //inicio grafico de barras. para admin
  annotation: string = "(histórico)";
  distributorsQuantityChartView: boolean = false;

  constructor(
    private api: ApiService,
    public dataService: DataService,
    private utils: UtilsService,
    public matDialog: MatDialog,
    private colorManipulationService: ColorManipulationService,
    private objectInitializationService: ObjectInitializationService,
    private validationService: ValidationService
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.pagination.page_size = this.dataService.PAGE_SIZE_OPTION[0];
    this.sort = this.objectInitializationService.initializeISort();
  }
  ngOnInit(): void {
    this.configDistibutorsCharts = this.objectInitializationService.initializeIChartConfigData("Distribuidores según el mes de registro", ChartTypeChartjs.BAR,
    {labels:['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    datasets:[{ data: [], label: 'Distribuidores según el mes de registro', backgroundColor: '#5CD371', hoverBackgroundColor: "#3FB854", hoverBorderColor: "#3FB854" }]}, false, false);
    
    this.getList();
    this.filterDistributorsBydateRange();
  }
  getList() {
    this.loading = true;
    let distributorsData:IManagerDataSummary[] = [];
    let lengthPage:number = 0;
    let data:string =  this.api.getCommonQueryParameters("getTotalDevicesAndUsersOfManagerUsers",this.user.hash,this.searchText,this.pagination.current_page,this.pagination.page_size,this.sort.by!,this.sort.direction!);
    this.api.getData("user", data, true).then((data: any) => {
      if(this.validationService.isResponseApi(data)){
        lengthPage = data.status == 1 ? (data.total??0) : 0;
        this.pagination.length_page = lengthPage;
        distributorsData = data.status == 1 ? data.data : [];
      }
    }).catch((error: any) =>{})
    .finally(()=>{
      this.distributorsTotal = lengthPage;
      this.pagination.length_page = this.distributorsTotal;
      this.distributorsData = distributorsData;
      this.utils.hideLoading(()=> this.loading = false );
    });
  }
  search() {
    this.pagination.current_page = 0;
    clearTimeout(this.timerIdSearchValueInTable);
    this.timerIdSearchValueInTable = setTimeout(() => {this.getList();}, this.dataService.DATA_TABLE_SEARCH_TIMEOUT);
  }
  filterDistributorsBydateRange(startDate: any = false, endDate: any = false, fromInput = false) {
    if (fromInput != false && ((startDate === null || startDate === false) || (endDate === false || endDate === null))) {
      this.utils.showMsg("", "No ha seleccionado un rango de fechas válidas");
      return;
    }
    if (fromInput != false && (startDate !== null && startDate !== false) && (endDate !== false && endDate !== null)) {

      let start = new Date(startDate);
      let end = new Date(endDate);
      let newStart = start.getFullYear() + "-" + this.utils.evaluateDateNumber(start.getMonth() + 1) + "-" + this.utils.evaluateDateNumber(start.getDate());
      let newEnd = end.getFullYear() + "-" + this.utils.evaluateDateNumber(end.getMonth() + 1) + "-" + this.utils.evaluateDateNumber(end.getDate());
      startDate = newStart;
      endDate = newEnd;
    }
    //this.loading = true;
    let data = "?&user_api_hash=" + this.user.hash + "&action=getDistributorsByCreationDateRange";
    if (fromInput == true && startDate != null && startDate != false && endDate != null && endDate != false) {
      data += "&startDate=" + startDate + "&endDate=" + endDate;
    }
    this.configDistibutorsCharts.updated_chart = false;
    this.api.getData("user", data, true).then((data: any) => {
      if (data.status == 1) {
        if (fromInput) {
          this.annotation = "(" + startDate + " al " + endDate + ")";
        }
        let setMonths = new Set();

        for (let item of data.data) {
          setMonths.add(item.created_at_month);
        }

        let arrayMonths = [...setMonths];
        //se le resta uno para que el array pueda posteriormente almacenar valores desde la posicion 0, dado que desde BD se devuelve el mes de enero como 1
        let months = arrayMonths.map((item: any) => { return (parseInt(item) - 1); });
        let chartData: any = [];
        for (let month of months) {
          chartData[month] = 0;
          for (let distributorData of data.data) {
            if (parseInt(distributorData.created_at_month) - 1 == month) {//se le resta 1 para que coincidan con las posiciones del array
              chartData[month]++;
            }
          }
        }
        this.configDistibutorsCharts.data.datasets[0].data = chartData;
        this.configDistibutorsCharts.updated_chart = true;
        //this.utils.hideLoading( () => this.loading = false);
      }
      else {
        this.utils.showMsg("", "No se pudo obtener datos del rango de fecha");
        this.utils.hideLoading( () => this.loading =false);
        this.configDistibutorsCharts.updated_chart = true;
      }
    });//.finally(()=>this.utils.hideLoading(()=> this.loading = false ));
  }

  paginator(event:PageEvent):void{
    this.pagination.current_page = event.pageIndex;    
    this.pagination.page_size = event.pageSize;
    this.getList();
  }
  sortData(sort: Sort) {
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getList();
  }
}