import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { MessageBoxService } from 'src/app/core/services/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/services/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { DeviceChecklistAnsweredAvailableFilterFields } from 'src/app/shared/components/device-checklist-answered-history/device-checklist-answered.enum';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IDeviceChecklist } from 'src/app/core/models/interfaces/idevice-checklist.model';
import { IResponseApi } from 'src/app/core/models/interfaces/iresponse-api.model';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';

@Component({
  selector: 'app-device-checklist-management',
  templateUrl: './device-checklist-management.component.html',
  styleUrls: ['./device-checklist-management.component.scss']
})
export class DeviceChecklistManagementComponent implements OnInit {
  loading:boolean=false;
  user!:IUserSessionData;

  /** @description Almacena la lista de vistas disponibles en el componente */
  viewsAvailable:IViewsAvailable={
    device_checklist_management:{name:"device_checklist_management",show_view:true},
    device_checklist_form:{name:"device_checklist_form",show_view:false},
    device_checklist_answered_history:{name:"device_checklist_answered_history",show_view:false}
  };
  
  checklistList:IDeviceChecklist[] = [];
  /** @description El checklist seleccionado para editar*/
  checklistListSelected!:IDeviceChecklist;
  
  /** @type {DeviceChecklistAnsweredAvailableFilterFields} Indica el campo por el cual se va a filtrar en el historial de busqueda */
  searchFieldForChecklistHistory: DeviceChecklistAnsweredAvailableFilterFields | null = DeviceChecklistAnsweredAvailableFilterFields.DEVICE_CHECKLIST_ID;
  /** Indica el valor que se requiere buscar para el campo de referenia de busqueda en el historial de respuesta de checklist */
  searchFieldValueForChecklistHistory: number | null = null; 
  /** @description Retorna hacia la vista de administración de flota */
  //back:()=>void;
  /** @description Obtiene la lista de checklist del usuario */
  getDeviceChecklistList:()=>void;
  moduleId=18;
  permissionsDataSgc:any=[];//permisos sobre el modulo

  constructor(
    private router:Router,
    public utils: UtilsService,
    public validationService:ValidationService,
    private api:ApiService,
    private dataService:DataService,
    private initializationService:ObjectInitializationService
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    //this.back = () => {this.router.navigate(["/dashboard/fleet-control"])};
    this.getDeviceChecklistList = () => {this.makeRequest({},"get")};
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.getDeviceChecklistList();    
    }).catch(():void => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/dashboard/fleet-control']);
    });
  }
  /** @description Realiza cambio de disponibilidad del formulario*/
  async changeAvailable(event:MatSlideToggleChange,checklist:IDeviceChecklist):Promise<void>{
    const success:boolean = await this.makeRequest({id:checklist.id,available:event.checked?1:0},"update");
    checklist.available = !success?checklist.available?0:1:checklist.available;
  }
  openChecklistEditForm(checklist?:IDeviceChecklist):void{
    this.checklistListSelected = typeof checklist !="undefined"?checklist:this.initializationService.initializeIDeviceChecklist(this.user.id);
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["device_checklist_form"].name);
  }
  /**@description Permite carga la vista de respuests dadas al checklist indicado */
  viewDeviceChecklistAnswers(checklist?:IDeviceChecklist):void{
    let checklistExists: boolean =  typeof checklist !="undefined";
    this.searchFieldForChecklistHistory = checklistExists ? DeviceChecklistAnsweredAvailableFilterFields.DEVICE_CHECKLIST_ID : null;
    this.searchFieldValueForChecklistHistory = checklistExists ? checklist!.id : null;
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable["device_checklist_answered_history"].name);
  }
  deleteCheckList(checklist:IDeviceChecklist):void{
    this.utils.showConfirm("¿Eliminar checklist?","","Confirmar","Cancelar").then(()=>{
      this.makeRequest({id:checklist.id},"delete");
    }).catch(()=>{ });
  }
  async makeRequest(dataToSend:{id?:number,available?:1|0},action:"delete"|"update"|"get"){
    this.loading = true;
    let data: IApiRequestData & {id?:number} = { user_api_hash:this.user.hash, action:action,...dataToSend};
    let success:boolean = false;
    try{
      let response:any = await this.api.createData(data, "deviceChecklist",true);
      success = response.status==1;
        if(action=="get")
          this.checklistList = response.status==1 ? response.data as IDeviceChecklist[]:[];
        else{
          response.status==1?this.getDeviceChecklistList():null;
          this.utils.showResultRequest(response.status==1?"success":"error","Información",response.message);
        }
        this.utils.hideLoading(()=>this.loading=false);
    }catch(error:any){
      this.utils.hideLoading(()=>this.loading=false);
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage( action == "get" ? "la colección de listas de verificación":"el checklist",action=="update",false,action=="get"?"GET":(action=="update"?"POST":"DELETE")));
    }
    return success;
  }
  closeForm(event:{update_data:boolean}):void{
    this.utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['device_checklist_management'].name);
    if(event.update_data)
    this.getDeviceChecklistList();
  }
}