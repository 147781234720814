import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IVersion, IWebTemplateEditMenu } from 'src/app/views/website/models/interfaces/iweb-template-edit-menu.model';
import { IGlobalDesignVersions, IWebWemplatePageDataEdition } from 'src/app/views/website/models/interfaces/iweb-template.model';
import { WebTemplatePages } from 'src/app/views/website/website.types';
import { WebMenuManagementService } from '../../../core/services/web-menu-management/web-menu-management.service';
import { WebsiteInitializationService } from 'src/app/views/website/website-initialization/website-initialization.service';
import { Breakpoints } from 'src/app/shared/enums/common-enums.enum';

@Component({
  selector: 'app-web-template-edit-menu',
  templateUrl: './web-template-edit-menu.component.html',
  styleUrls: ['./web-template-edit-menu.component.scss']
})
export class WebTemplateEditMenuComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if(typeof window != "undefined" &&  window.innerWidth < Breakpoints.MEDIUM){
      this.toggleActionOpenSidebar(true);
    }
  }
  /** @type {boolean} Indica si se cierra el menu sidebar */
  @Input() isOpenSidebar:boolean = true;
    /** @type {boolean} Indica si el menu sidebar esta abierto. en tamaños considerado movil */
  @Input() isOpenSidebarMobile:boolean = true;
  logoutButtonTitle: string = "Salir de plantilla";
  @Output() actionOpenSidebar:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() actionOpenSidebarMobile:EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleActionOpenSidebar(value?: boolean){
    this.isOpenSidebar = typeof value != "undefined" ? value : !this.isOpenSidebar;
    this.actionOpenSidebar.emit(this.isOpenSidebar);
  }
  toggleActionOpenSidebarMobile(){
    this.isOpenSidebarMobile = !this.isOpenSidebarMobile;
    this.actionOpenSidebar.emit(this.isOpenSidebarMobile);
  }
  webWemplatePageDataEdition!: IWebWemplatePageDataEdition;

  menuWebTemplate!:IWebTemplateEditMenu[];
  actualMenuWebTemplateName:string="";
  actualMenuNameWebTemplateRemote:string="";//la pagina actual del sitio web que se edita

  barMenu:boolean=false;
  constructor(
    public webMenuManagementService: WebMenuManagementService,
    private websiteInitialization:WebsiteInitializationService
  ){}
  ngOnInit(): void {
    this.menuWebTemplate = this.websiteInitialization.initializeIWebTemplateEditMenu();
    this.isOpenSidebar= true;
    this.isOpenSidebarMobile = true;
  }
  toggleBarMenu(){
    this.barMenu = !this.barMenu; 
  }
  activeItemMenu(item: any, sub: boolean = false){
    this.webMenuManagementService.activeItemMenu(item,sub);
  }
  isVersionSelected(pageName:WebTemplatePages,sectionName:keyof IGlobalDesignVersions|null|string,version:IVersion):boolean{
    return this.webMenuManagementService.isVersionSelected(pageName,sectionName,version);
  }
  update(){
    this.webMenuManagementService.callBackUpdateData(this.webMenuManagementService.webWemplatePageDataEdition);
  }
  //para aplicar versiones disponibles para el sitio web
  applyVersion(pageName:WebTemplatePages,sectionName:keyof IGlobalDesignVersions|null|string,version:IVersion){
    this.webMenuManagementService.applyVersion(pageName,sectionName,version);
  }
   logout(){
    this.webMenuManagementService.isActiveWebMenu = false;
   }
}