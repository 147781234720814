import { Component, ElementRef, OnInit, ViewChild, Renderer2, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { WebsiteInitializationService } from '../../website-initialization/website-initialization.service';
import {IWebWemplatePageDataEdition } from '../../models/interfaces/iweb-template.model';
import { WebTemplatePages } from '../../website.types';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { WebMenuManagementService } from 'src/app/core/services/web-menu-management/web-menu-management.service';
import { WebTemplate } from '../../models/classes/web-template.model';
import { SafeResourceUrl } from '@angular/platform-browser';

const WEB_TEMPLATE_EDITING_PAGES: Record<WebTemplatePages,WebTemplatePages> ={
  about_us:"about_us",
  global:"global",
  contact_us:"contact_us",
  home:"home",
  our_services:"our_services"
};
type ViewTemplate = "desktop"|"mobile"|"tablet";
const VIEW_TEMPLATE:Record<ViewTemplate,ViewTemplate>= {
  desktop:"desktop",
  tablet:"tablet",
  mobile:"mobile"
}
const VIEW_TEMPLATE_SIZE_OPTIONS: Record<ViewTemplate,ITemplateViewSizeOptions> ={
  mobile:{name:"mobile",label:"Móvil",value:"380px",icon:"fa-mobile"},
  tablet:{name:"tablet",label:"Tablet",value:"750px",icon:"fa-tablet-button"},
  desktop:{name:"desktop",label:"Escritorio",value:"1050px",icon:"fa-desktop"}
}
interface ITemplateViewSizeOptions{
  label:string,
  value:string,
  icon:string,
  name:ViewTemplate
}
@Component({
  selector: 'app-website-template',
  templateUrl: './website-template.component.html',
  styleUrls: ['./website-template.component.scss']
})
export class WebsiteTemplateComponent implements OnInit {

   //variables para la vista template
   @ViewChild("frameWebTemplate") frameWebTemplate!: ElementRef<HTMLIFrameElement>; //REFERENCIAMOS AL ELEMENTO QUE QUEREMOS MODIFICAR
  
   @Output() updateData = new EventEmitter<{data:IWebWemplatePageDataEdition,refresh_iframe:Function}>;
  
   @Input() webWemplatePageDataEdition!: IWebWemplatePageDataEdition;

   /** el dominio del sitio web.  ex corporate.internationalgpsonline.com @type {string} */
   @Input() domainWebsite!:string;
   @Input() userWebAddress:SafeResourceUrl ="";
   /** La direccion web como string para acceso al sitio web del usuario @type {string} */
   @Input() userWebAddressStr:string ="";
  /**indica la direccion completa direccionando hacia la pagina web. ex www.domicion.com/user/page  @type {SafeResourceUrl} */
   webPageAddress:SafeResourceUrl="";

   /**tamaña, en px, inicial de la vista de iframe */
   templateViewSize!:string;
   templateViewSizeOptions!:ITemplateViewSizeOptions[];

   editFrameTemplate:boolean=false;//indica si se edita el sitio web desde el frame
   frameLoaded:boolean=false;

   constructor(
    public utils: UtilsService,
    private api: ApiService,
    public dataService: DataService,
    private renderer:Renderer2,
    private websiteInitialization:WebsiteInitializationService,
    public validation: ValidationService,
    public webMenuManagementService:WebMenuManagementService
   ){
    let templateViewSize = Object.values(VIEW_TEMPLATE_SIZE_OPTIONS).find((item:ITemplateViewSizeOptions)=>item.name==VIEW_TEMPLATE.desktop)?.value;
    this.templateViewSize = typeof templateViewSize !="undefined"?templateViewSize:"1000px";
    this.templateViewSizeOptions = Object.values(VIEW_TEMPLATE_SIZE_OPTIONS);
  }
   ngOnInit(): void {
    this.webMenuManagementService.editFrameTemplate= this.editFrameTemplate;
    this.webMenuManagementService.userWebAddressStr = this.userWebAddressStr; 
    this.webMenuManagementService.webWemplatePageDataEdition = this.webWemplatePageDataEdition;
    this.webMenuManagementService.callBackUpdateData =(response:IWebWemplatePageDataEdition,refreshIFrame:Function)=>this.updateData.emit({data:response,refresh_iframe:refreshIFrame});
    setTimeout(() => {
      this.webMenuManagementService.isActiveWebMenu = true;
    }, 500);

   }
   templateOptionSize(){
    const iframe = this.frameWebTemplate.nativeElement;
    const iframeDoc = iframe.contentWindow?.document;
    const bodyEl = iframeDoc?.querySelector('body');
    //console.log(bodyEl);
    //bodyEl!.style.transform="scale(0.6);";
    //this.refreshIframeWebTemplate();
    //this.renderer.setStyle(this.frameWebTemplate.nativeElement, 'transform', 'scale(0.8)');
  }
  setFrameWebTemplate(){
    this.webMenuManagementService.frameWebTemplate= this.frameWebTemplate.nativeElement;
  }
  refreshIframeWebTemplate(pageName?:string){
    let frame =this.frameWebTemplate.nativeElement as HTMLIFrameElement;
    if(typeof frame!="undefined" && frame.contentWindow!=null){
      //console.log("refresh",this.userWebAddressStr,this.webMenuManagementService.actualMenuWebTemplateName);
      frame.src =this.userWebAddressStr+(typeof pageName !="undefined"?pageName:this.webMenuManagementService.actualMenuWebTemplateName);
    }
  }
  editFrame(event?:any,isEdit?:boolean){
    //console.log(event,isEdit);
    
    if(typeof isEdit!="undefined")
      this.editFrameTemplate= isEdit;
    else if(typeof event !="undefined")
      this.editFrameTemplate =event.checked;
    else
      return;
    //let frame =this.frameWebTemplate.nativeElement as HTMLIFrameElement;
    const iframe = this.renderer.selectRootElement("#frameWebTemplate");
    let dataToSend = {
      utils_data:true,
      page:this.webMenuManagementService.actualMenuWebTemplateName, 
      action: this.editFrameTemplate?'enableEditing':'disableEditing', 
      selector: '.editable',
      querySelectorAll:true, 
      property: 'contentEditable', 
      value: this.editFrameTemplate };

    this.webMenuManagementService.editFrameTemplate= this.editFrameTemplate;
    iframe.contentWindow.postMessage(dataToSend, this.userWebAddressStr);

    this.renderer.listen("window","message",(event)=>{
      // Verificar que el mensaje provenga del origen esperado
      if (event.origin !== "https://corporate.internationalgpsonline.com") {
        return;
      }
      let data = event.data;
      //console.log("recibido",data);
      if( typeof data.utils_data !="undefined" && data.utils_data){
        data.page_source = data.page_source.replace("-","_"); 
        this.webMenuManagementService.actualMenuWebTemplateName = data.page_source; 
        //console.log("datos a organizar",data);
        if(data.page_source=="home")
          this.webWemplatePageDataEdition.home =WebTemplate.fillDataForHomeContent(this.webWemplatePageDataEdition.home,data);
        if(data.page_source=="about_us")
          this.webWemplatePageDataEdition.about_us =WebTemplate.fillDataForAboutusContent(this.webWemplatePageDataEdition.about_us,data);
        if(data.page_source=="our_services")
          this.webWemplatePageDataEdition.our_services =WebTemplate.fillDataForOurServicesContent(this.webWemplatePageDataEdition.our_services,data);
        if(data.page_source=="contact_us")
          this.webWemplatePageDataEdition.contact_us =WebTemplate.fillDataForContactusContent(this.webWemplatePageDataEdition.contact_us,data);
        }
        this.webMenuManagementService.webWemplatePageDataEdition = this.webWemplatePageDataEdition; 
    });
    
  }
  update(){
    this.updateData.emit({data:this.webWemplatePageDataEdition,refresh_iframe:this.refreshIframeWebTemplate});
  }
}