
<div class="border-bottom-secondary d-flex justify-content-between " [ngClass]="{'flex-direction-column': viewsAvailable['form'].show_view}">
    <ng-container *ngIf="viewsAvailable['list'].show_view; else elseHeader">
        <h2 mat-dialog-title>{{ viewsAvailable['list'].show_view ? 'Detalles' : 'Edición de plan'}}</h2>
        <small>Fecha de revisión: {{ (currentDate | date:"longDate") }}</small>
    </ng-container>
    <ng-template #elseHeader>
        <h2 mat-dialog-title>Edición de plan</h2>
        <h3 class="mat-dialog-subtitle">{{ 'Dispositivo: ' + deviceWithBillingPlanToEdit.name}}</h3>
    </ng-template>   
</div>
<mat-dialog-content class="mat-typography form-box-container">

    <ng-container *ngIf="viewsAvailable['list'].show_view">
        <div class="w100 overflow-auto animate__animated animate__fadeIn">
            <table mat-table [dataSource]="devices" matSort (matSortChange)="sortData($event)">
        
                <ng-container matColumnDef="imei">
                    <th mat-header-cell *matHeaderCellDef> Imei </th>
                    <td mat-cell *matCellDef="let device"> {{device.imei}} </td>
                </ng-container>
                <ng-container matColumnDef="name" sticky>
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let device"> {{device.name}} </td>
                </ng-container>
                <ng-container matColumnDef="unit_price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por precio"> Precio </th>
                    <td mat-cell *matCellDef="let device; index as count">                 
                        {{device.monetary_unit_id != null ? (device.unit_price | currency: device.monetary_unit_code) : ""}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="payday">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por dia de facturación"> Día de facturación </th>
                    <td mat-cell class="text-end" *matCellDef="let device;index as count"> 
                        {{device.payday}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="billing_frequency_name">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por frecuencia de pago"> Frecuencia de pago </th>
                    <td mat-cell *matCellDef="let device; index as count"> 
                        {{device.billing_frequency_name}} 
                    </td>
                </ng-container>
                <ng-container matColumnDef="first_billing_date">
                    <th mat-header-cell *matHeaderCellDef matTooltip="El mes indicado en el plan de facturación"> Primer mes de facturación  <i class="fa-regular fa-circle-question icon"></i> </th>
                    <td mat-cell *matCellDef="let device;index as counter"> 
                        {{device.first_billing_date | date:'MMMM, y'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="billing_plan_disabled">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por estado de habilitación del plan">  Habilitado </th>
                    <td mat-cell class="text-center" *matCellDef="let device;index as counter">
                        <div class="d-flex justify-content-center align-items-center">
                            <strong class="badge" matTooltipPosition="left" [matTooltip]="'Plan ' + (device.billing_plan_disabled == 1 || device.unit_price == null ? 'inactivo' : 'activo')" [ngClass]="{'text-bg-light-warning': device.billing_plan_disabled == 1 ,'text-bg-light-success':device.billing_plan_disabled != 1 && device.unit_price != null, 'text-bg-light-danger':device.unit_price == null}">
                                <i class="fa-solid fa-circle-check"  *ngIf="device.billing_plan_disabled != 1 && device.unit_price != null; else elsePlanDisabled"></i>
                                <ng-template #elsePlanDisabled>
                                    <i class="fa-solid fa-circle-xmark"></i>
                                </ng-template>                                    
                            </strong>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="user_responsible_email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por email de usuario responsable"> Usuario responsable </th>
                    <td mat-cell *matCellDef="let device"> {{device.user_responsible_email}} </td>
                </ng-container>
        
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
                    <td mat-cell *matCellDef="let device" class="text-center"> 
                      <div class="actions-container">
                        <button class="text-primary" matTooltipPosition="left" matTooltip="Editar plan" (click)="openEditForm(device);" [disabled]="loading"><mat-icon>edit</mat-icon></button>          
                      </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
                </tr>
            </table> 
        </div>
        <mat-paginator class="animate__animated animate__fadeIn" [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
        [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
        aria-label="Selecciona pagina de la lista de dispositivos">
        </mat-paginator>
    </ng-container>
    
    <ng-container  *ngIf="viewsAvailable['form'].show_view">
        <div class="form-box bg-transparent animate__animated animate__fadeIn">
            <div class="form-box__content form-box__content-h-auto">
            <div class="form-box-container-close animate__animated" role="button"(click)="closeForm()" matTooltip="Cerrar formulario"></div>

                <article class="form-box__content-section">
                    <form id="billingPlanForm" (submit)="updateBillingPlan()" class="form-box__content-group-fields form-box__content-group-fields--min-width-x-small">
                        <div class="fill">
                            <mat-slide-toggle
                                [checked]="deviceWithBillingPlanToEdit.billing_plan_disabled == 1"
                                name="disabled"
                                (change)="updateBillingPlanDisabled($event)"
                                color="primary"
                                class="z-index-last">Deshabilitar plan
                            </mat-slide-toggle>
                        </div>
                        <mat-form-field appearance="fill">
                            <mat-label>Precio</mat-label>
                            <input matInput 
                            class="hide-form-input-spinner" 
                            required="" 
                            type="number" 
                            name="unit_price" 
                            [(ngModel)]="deviceWithBillingPlanToEdit.unit_price" 
                            min="0" 
                            (mousewheel)="$event.preventDefault()">
                            <span matPrefix>$&nbsp;</span>
                            <mat-error>Indique un precio</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Tipo de moneda</mat-label>
                            <mat-select 
                                required=""
                                [(ngModel)]="deviceWithBillingPlanToEdit.monetary_unit_id" 
                                name="{{'monetary_unit_id_'}}">
                                <mat-option *ngFor="let currency of monetaryUnitsList" [value]="currency.id">
                                    {{currency.code + " - " + currency.currency}}
                                </mat-option>
                            </mat-select>
                            <mat-error>Seleccione una opción</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Día de facturación</mat-label>
                            <input 
                            matInput 
                            required=""
                            type="number" 
                            class="hide-form-input-spinner" 
                            [(ngModel)]="deviceWithBillingPlanToEdit.payday" 
                            name="payday" 
                            min="1"
                            max="31" 
                            (mousewheel)="$event.preventDefault()"> 
                            <mat-error>Indique un valor</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Frecuencia de pago</mat-label>
                            <mat-select 
                            required=""
                            [(ngModel)]="deviceWithBillingPlanToEdit.billing_frequency_id" 
                            name="{{'billing_frequency_id'}}">
                                <mat-option  *ngFor="let frequency of billingFrequenciesList" [value]="frequency.id">
                                  {{frequency.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error>Seleccione una opción</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Primer mes de facturación</mat-label>
                            <input 
                            matInput 
                            required=""
                            [min]="configFirstBillingDate.min_value"
                            [matDatepicker]="pickerBillinFirstMonth" 
                            (click)="pickerBillinFirstMonth.open();$event.preventDefault()" 
                            name="first_billing_date"  
                            [(ngModel)]="deviceWithBillingPlanToEdit.first_billing_date" 
                            >
                            
                            <mat-datepicker-toggle matSuffix [for]="pickerBillinFirstMonth"></mat-datepicker-toggle>
                            <mat-datepicker 
                            #pickerBillinFirstMonth
                            (monthSelected)="setFirstBillingMonth($event, pickerBillinFirstMonth)" 
                            startView="year"
                            disabled="false"></mat-datepicker>
                            <mat-error>Debe de indicar una fecha</mat-error>
                        </mat-form-field>

                        <mat-form-field id="inputResposible" appearance="fill">
                            <mat-label>Responsable</mat-label>
                            <mat-select 
                            required=""
                            (closed)="clearSearchResponsibleEmail()" 
                            [(ngModel)]="deviceWithBillingPlanToEdit.user_responsible_id" 
                            name="user_responsible_id"
                            placeholder="Buscar..." 
                            (selectionChange)="selectResponsibleUser($event)">
                                <mat-option>
                                <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar cliente..." [(ngModel)]="userEmailToSearch" name="responsableEmailToSearch" (keyup)="searchResponsibleEmail()">
                                    <mat-icon (click)="clearSearchResponsibleEmail()" ngxMatSelectSearchClear>close</mat-icon>
                                </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let potentialResponsible of userListFiltered" [value]="potentialResponsible.id" color="primary">
                                    {{potentialResponsible.email}} <small *ngIf="!utils.isNullOrEmpty(potentialResponsible.name)">{{" - "+potentialResponsible.name}}</small>
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="text-info" matTooltip="Selecciona un único usuario a quien se le generará la facturación por el dispositivo">help</mat-icon>
                            <mat-error>Seleccione al usuario responsable</mat-error>
                        </mat-form-field>
                    </form>
                </article>

            </div>
        </div>
    </ng-container>

    <div class="loader" *ngIf="loading">
        <img src="../../../../../assets/loader.svg">
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <ng-container *ngIf="viewsAvailable['list'].show_view">
        <button mat-button [mat-dialog-close]="this.updatedPlans" class="me-1">Salir</button>
        <button mat-button  (click)="exportFile()" cdkFocusInitial class="btn btn-primary animate__animated animate__fadeIn" [disabled]="loading">Exportar</button>
    </ng-container>    
    
    <ng-container *ngIf="viewsAvailable['form'].show_view">
        <button mat-button (click)="closeForm()"  class="me-1"><mat-icon>arrow_back</mat-icon> Volver</button>
        <button mat-button form="billingPlanForm" type="submit" cdkFocusInitial class="btn btn-primary animate__animated animate__fadeIn" [disabled]="loading">Actualizar</button>
    </ng-container>
</mat-dialog-actions>