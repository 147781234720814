import { Component,OnInit,Renderer2 } from '@angular/core';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { Router } from '@angular/router';
import { IDeviceGroupWebService, IWebService } from './models/interfaces/iweb-service.model';
import { ControltComponent } from "./web-services-list/controlt/controlt.component";
import Swal from 'sweetalert2';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { IDeviceGroup } from 'src/app/core/models/interfaces/idevice.model';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { ObjectInitializationService } from '../../core/services/object-initialization/object-initialization.service';

/**
 * **************************************************************************************
 * ANALIZAR LOS  COMPONENTES DE web-services-list, ya que entonces si no van a ver muchas 
 * variaciones podria entonces crearse un componente comun o una clase base y usarlo para cargar los datos 
 * en relacion al web service a visualizar
 * **************************************************************************************
 */

export enum SUBSCRIPTION_STATUSES { SUBSCRIBED = 1, NOT_SUBSCRIBED = 0 };

export interface IWebserviceDevice{
    id: number,
    imei: string,
    plate_number: string | null,
    name: string,
    device_model: string | null,
    device_id: number | null,
    web_service_id: number | null,
    created_at: string,
    deactivation_date: string,
    reactivation_date: string,
    activated: number | null,
    user_id: number | null,
    expiration_date: string
}
/**
 * @description almacena la lista de web services disponibles
 */
const WEB_SERVICES = {
  CONTROL_T: 1,
  MAQUINARIA_AMARILLA: 2,
  PEGASO: 3,
  ATU: 4,
  CASERONES_OWL: 5,
  WIALON_IPS_TRANSMISSION: 6,
  BTRAK: 7
};
@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit{
  user:IUserSessionData;

  /**Almacena la lista de credenciales del usuario para los servicio en lo que se encuentra alojado */
  UserWebServicesCredentials:any=[];


  readonly WEB_SERVICES = WEB_SERVICES;
  /**Indica los filtros para que servicios se muestran en el panel general */
  serviceFilterOptions:any={
    subscribed:"subscribed",//indica los servicios a los que esta suscrito
    others:"others"//indica los servicios a los que no esta suscrito
  };
  loadingServiceList:boolean=false;
  availableServicesList:IWebService[]=[];
  availableServicesListFiltered:IWebService[]=[];
  includeInServicesList:Set<string> =new Set([this.serviceFilterOptions.subscribed,this.serviceFilterOptions.others]);//suscritos y disponibles(muestra todos los )

  distributorCountry:string="";
  loading:boolean=false;
  requireAuthentication: {username_and_password: string, no_authentication: string, access_token: string, api_key: string}={
    username_and_password:"username and password",
    no_authentication:"no authentication",
    access_token:"access token",
    api_key: "api key"
  };
  webServiceImagePath:any={
    path:"../../../assets/img/web-services/web_service_",
    type:".png"
  };
  /**
   * Almacena el servicio web seleccionado por el usuario 
   * @type {IWebService}
   * */
  serviceSelected!:IWebService;
  isVisibleModal = false;
  moduleId:number=11;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    public dataService:DataService,
    public  utils:UtilsService,
    private api:ApiService,
    private router:Router,
    private renderer:Renderer2,
    private objectInitializationService: ObjectInitializationService,
  ){
    this.user = this.dataService.getData("user");
  }

  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.distributorCountry = this.user["country_name"]; 
      this.getWebServices();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  /**
   * @description evalua si el modulo esta disponible en el plan del usuario
   * @param {number} moduleId Indica el modulo a evaluar
   * @return {boolean} true si el modulo esta disponible en el plan del usuario o false en caso contrario
   */
  isServiceEnabled(moduleId:Number):boolean{
    let module= this.dataService.getData("userSubscriptionPlan").modules.find((module:any)=>{
      if(module.module_id==moduleId)return module.permissions;});
    return typeof module !="undefined"?true:false;
  }
  /**
   * Obtiene la lista de web services disponibles en plataforma
   */
  getWebServices(){
    this.loading = true;
    this.api.getDataPost("webService",{action:"get",user_api_hash:this.user.hash},true).then((response:any)=>{
      if(response.status){
        this.availableServicesList= response.data;
        this.availableServicesListFiltered = response.data.slice(); 
        this.getUserWebServicesCredentials();
      }else{
        this.availableServicesList=[];
        this.availableServicesListFiltered=[];
      }
      this.utils.hideLoading(()=>this.loading = false);
    }).catch((error:any)=>{
      this.utils.hideLoading(()=>this.loading = false);
      this.utils.showMsg("Información","Ha surgido un error mientras se intentaba obtener los servicios");
    });
  }

  changeCredential(service:IWebService){
    console.log(service);
    let username: string | null = "";
    let password: string | null = "";
    let accessToken: string | null = "";
    let apiKey: string | null = "";
    let existsCredential = typeof service.user_credential != "undefined" && service.user_credential != null && Object.entries(service.user_credential!).length>0;
    if(existsCredential){
      if(service.require_authentication == this.requireAuthentication.username_and_password){
        username = service.user_credential!.username;
        password = service.user_credential!.password;
      }else if (service.require_authentication == this.requireAuthentication.access_token)
        accessToken = service.user_credential!.access_token;
      else if (service.require_authentication == this.requireAuthentication.api_key)
        apiKey = service.user_credential!.api_key;
    }
    let inputOptions:string =  `<small>Ingresa las credenciales proporcionadas para ser usadas en las funciones del servicio web</small><br>`;
    if(service.require_authentication == this.requireAuthentication.username_and_password){
      inputOptions += `<div class='input-with-icon'  ><label class="icon"><i class="fa-solid fa-user"></i> </label><input type='text' placeholder='Ingrese el nombre de usuario'  id='inputUsername' value='`+username+`'></div>
      <div class='input-with-icon'  ><label class="icon"><i class="fa-solid fa-key"></i> </label><input type='text' placeholder='Ingrese la contraseña'  id='inputPassword' value='`+password+`'></div>`;
    }else if(service.require_authentication == this.requireAuthentication.access_token)
      inputOptions +=`<div class='input-with-icon'  ><label class="icon"><i class="fa-solid fa-key"></i> </label><input type='text' placeholder='Ingrese el token de acceso'  id='inputAccessToken' value='`+accessToken+`'></div>`;
    else if(service.require_authentication == this.requireAuthentication.api_key)
        inputOptions +=`<div class='input-with-icon'  ><label class="icon"><i class="fa-solid fa-key"></i> </label><input type='text' placeholder='Ingrese el api key'  id='inputApiKey' value='`+apiKey+`'></div>`;
      
    Swal.fire({
      title: 'Actualización de credenciales',
      html:inputOptions,
      confirmButtonText: "Actualizar",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar"
    }).then((result)=>{
      let username:string = "";
      let password: string = "";
      let accessToken: string = "";
      let apiKey: string = "";
      if(service.require_authentication == this.requireAuthentication.username_and_password){
        username =this.renderer.selectRootElement("#inputUsername",true).value.trim();          
        password =this.renderer.selectRootElement("#inputPassword",true).value.trim();
      }else if (service.require_authentication == this.requireAuthentication.access_token)
        accessToken = this.renderer.selectRootElement("#inputAccessToken",true).value.trim();
      else if (service.require_authentication == this.requireAuthentication.api_key)
        apiKey = this.renderer.selectRootElement("#inputApiKey",true).value.trim();
          
      if(result.isConfirmed ){
        if(
          (service.require_authentication == this.requireAuthentication.api_key && apiKey == "") ||
          (service.require_authentication == this.requireAuthentication.access_token && accessToken == "") ||
          (service.require_authentication == this.requireAuthentication.username_and_password && (username=="" || password==""))){
          this.utils.showResultRequest("info","Información","Ingrese los datos de credenciales");
          return;
        }
        this.loading = true;
          let dataToSend:IApiRequestData={
            user_api_hash:this.user.hash,
            action:existsCredential?"update":"add",
            web_service_id:service.id
          };
          if(service.require_authentication == this.requireAuthentication.username_and_password){
            dataToSend["password"] = password;
            dataToSend["username"] = username;
          }else if (service.require_authentication == this.requireAuthentication.access_token)
            dataToSend["access_token"] = accessToken;
          else if (service.require_authentication == this.requireAuthentication.api_key)
            dataToSend["api_key"] = apiKey;

          if(existsCredential)
            dataToSend["id"] = service.user_credential!.id;

          this.api.createData(dataToSend, "userWebServiceCredential",true).then((data:any)=>{
            this.getUserWebServicesCredentials();
            this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
            this.utils.hideLoading(()=>this.loading = false);
          }).catch((error:any)=>{console.log(error);this.utils.hideLoading(()=>this.loading = false);});
      }
    }).catch(()=>{this.utils.hideLoading(()=>this.loading = false);});
  }
   getUserWebServicesCredentials(){
    let dataToSend={
      user_api_hash:this.user.hash,
      action:"get"
    };
    this.api.getDataPost("userWebServiceCredential",dataToSend,true).then((response:any)=>{
      let credentials = typeof response.data !="undefined"?response.data:[];
      for(let webService of this.availableServicesList){
        let index = credentials.findIndex((credential:any)=>credential.web_service_id==webService.id);
        webService["user_credential"] = (index !=-1)?credentials[index]:{};
      }
    }).catch((error)=>{});
  }
  selectOptionToShow(service:IWebService){
    let selection:any;
    if(!this.isServiceEnabled(service.module_id)){
      this.utils.showResultRequest("","","<p>"+service.description+"</p><span class='text-small'><strong>"+(parseInt(service.status.toString())?"¡El servicio web esta listo para ser usado!":"Web service en construcción. ¡Pronto estará disponible!")+"</strong><br> "+(parseInt(service.status.toString())?"Contacta con el administrador para obtener detalles de acceso":"")+"</span>",null,
      {
        imageUrl:this.webServiceImagePath.path+service.id+this.webServiceImagePath.type,
        imageWidth:180,
        imageHeight:"auto",
        imageAlt:"logo del web service"
      });
    }
    else if(!this.utils.isNullOrEmpty(service.url_external)){
      this.utils.showResultRequest("info","Dirigirse al siguiente enlace","<a target='_blank' href='"+service.url_external+"'>Click aquí para dirigirse al sitio web del servicio <i class='fa-solid fa-angles-right'></i></a>");
    }
    else if(typeof (selection = this.availableServicesList.find((item:IWebService)=>item.id==service.id))!="undefined"){
      this.isVisibleModal=true;
      this.serviceSelected = selection;
    }
  }
  isServiceStatusChecked(include:string):boolean{
    return this.includeInServicesList.has(include);
  }
  changeCompleteServiceInclusion(event:any,value:string){
    this.loadingServiceList=true;
    setTimeout(() => {
      this.includeInServicesList[event.checked?"add":"delete"](value);
      let list=[];
      for(let service of this.availableServicesList){
        if((this.includeInServicesList.has(this.serviceFilterOptions.others) && !this.isServiceEnabled(service.module_id)) || (this.includeInServicesList.has(this.serviceFilterOptions.subscribed)&& this.isServiceEnabled(service.module_id)))
          list.push(service);
      }
      this.availableServicesListFiltered =list;
      this.loadingServiceList=false;
    }, 500);
  }
  closeModal(event:any){
    this.isVisibleModal =false;
    this.serviceSelected = this.objectInitializationService.initializeIWebService();
  }
}