import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';

interface IFilterOption {
  date_range:"date_range",
  quantity_time:"quantity_time"
};
type FilterOption = "quantity_time"|"date_range";
@Component({
  selector: 'app-disconnected-devices-list',
  templateUrl: './disconnected-devices-list.component.html',
  styleUrls: ['./disconnected-devices-list.component.scss']
})
export class DisconnectedDevicesListComponent implements OnInit {
  @Output() close= new EventEmitter<void>;
  user:any;
  /**@type {number} el id del intervalo que consulta los datos de los dispositivos desconectados*/
  intervalId!:number;

  loading: boolean = false;//para svg de loading

  deviceStatus:any ={
    offline:"offline",
  };
  
  sort={
    by:"server_time",
    direction:"desc"
  };
  selectedTimeUnit:string="hours";//unidad de tiempo seleccionada para el filtro de ultima conexion
  timeSelectedAmount:number=1;//usado para filtro de tiempo de ultima conexion de dispositivos
  timeUnits:any =[];

  displayedColumns: string[] = ["name","imei","sim_number","server_time"];
  footerData:any={};
  headers =  ["name","imei","sim_number","server_time"];//datos a exportar
  //para paginacion
  lengthPage=0;
  pageSize=20;
  pageSizeOptions:any=[this.pageSize];
  pageEvent:any;
  currentPage=0;
  
  searchValue="";
  sortedDevicesData: any=[];//almacena la lista ordenada de datos. se usa con angular material para ordenar la tabla

  filterOption:IFilterOption = {  
    date_range:"date_range",
    quantity_time:"quantity_time"
  };
  filterBy:FilterOption = "quantity_time";
  //usado para el calendario de filtro de dispositivos desconectados
  dateRangeToConsult!:FormGroup;
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
  ){
    const date:Date = new Date();
   this.dateRangeToConsult = new FormGroup({
    start: new FormControl(new Date(date.getFullYear(),date.getMonth()-1,1)),
    end: new FormControl(new Date(date.getFullYear(),(date.getMonth()),0)),
  });
  }

  ngOnInit(): void {
    this.timeUnits = Object.values(this.dataService.TIME_UNITS);
    this.user= this.dataService.getData("user");
    this.applyFilter();
  }
  /*ngOnDestroy(): void {
   clearInterval(this.intervalId); 
  }*/
  applyFilter(filterBy:FilterOption=this.filterBy){
    this.filterBy = filterBy;
    this.footerData = {quantity:this.timeSelectedAmount,time_unit:this.dataService.TIME_UNITS[this.selectedTimeUnit][this.timeSelectedAmount>1?'plural_label':'label']};
    this.getDeviceList();
  }
  getparametersForGetDeviceList(page:number=this.currentPage,pageSize:number=this.pageSize,filterBy:FilterOption=this.filterBy){
    let data="?action=getUserDevices&user_api_hash="+this.user.hash+(page==-1?"":("&page="+page)+(pageSize==-1?"":("&page_size="+pageSize)));
    if(this.sort.by!="")
      data +="&order[by]="+this.sort.by+"&order[direction]="+this.sort.direction;
    if(this.searchValue !="")
      data +="&search_value="+this.searchValue;
    data +="&connection_status_data_only=true";
    if(filterBy=='quantity_time')
      data +="&quantity="+this.timeSelectedAmount+"&time_unit="+this.selectedTimeUnit;
    else{
      let dateRange=this.utils.getDateRange(this.dateRangeToConsult.value.start,this.dateRangeToConsult.value.end);
      this.footerData = {start_date:dateRange.start,end_date:dateRange.end};
      data +="&start_date="+dateRange.start +"&end_date="+dateRange.end;
    }
    return data;
  }
  getDeviceList(page:number=this.currentPage){
    this.loading = true;
    this.api.getData("device",this.getparametersForGetDeviceList(),true).then((data: any)=>{
      (data.status==1)?this.currentPage=page:this.currentPage = 0;
      this.sortedDevicesData=data.data??[];
      this.lengthPage =data.total??0;   
      this.utils.hideLoading((response:boolean)=>this.loading = response);
    }).catch((error:any)=>{
      this.utils.hideLoading((response:boolean)=>this.loading = response);
      this.currentPage=0;
      this.sortedDevicesData=[];
      this.utils.showMsg("","Error al obtener la lista de dispositivos<br><small>Intenta nuevamente. Si el error persiste comunicate con el administrador</small>");
    });
  }
  exportFile(){
    this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
      let data=this.getparametersForGetDeviceList(-1,-1);
      this.loading = true;
      this.api.getData("device",data,true).then((data: any)=>{
        if(data.status==1){
          setTimeout(() => {
            this.utils.downloadFileAsCSV(data.data,  "user_device_list_" + new Date().getTime().toString(), this.headers);
            this.utils.showMsg("","Datos exportados con éxito");
          }, 1500);
        }else
          this.utils.showMsg("","No se han encontrado dispositivos asociados");
        this.utils.hideLoading((response:boolean)=>this.loading = response);
      }).catch((error:any)=>{
        this.utils.hideLoading((response:boolean)=>this.loading = response);
        this.utils.showMsg("","No se han podido obtener la lista de dispositivos");
      });
    });
  }
  searchDevices(){
    setTimeout(() => {
      this.getDeviceList();
    }, 700);
  }
  paginator($event:PageEvent){
    this.pageSize = $event.pageSize;
    this.currentPage = $event.pageIndex;    
    this.getDeviceList();
  }
  sortData(sort: Sort) {
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getDeviceList();
  }
  closeComponent(){
    this.close.emit();
  }
}