import {SelectionModel} from '@angular/cdk/collections';
import {Sort} from '@angular/material/sort';
import { Component,Input,Output,OnInit,EventEmitter,Renderer2 } from '@angular/core';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { IWebService } from "../../models/interfaces/iweb-service.model";
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-pegaso',
  templateUrl: './pegaso.component.html',
  styleUrls: ['./pegaso.component.scss']
})
export class PegasoComponent {
  @Input() data!:IWebService;
  @Input() webServiceId!:number;
  @Output() closeModal = new EventEmitter<boolean>();
  viewsAvailable:any={
    logs:{name:"logs",show_view:false},
    device_list:{name:"device_list",show_view:true}
  };
  /** @type {Array} Lista de opciones de filtro en cuanto a si mostrar dispositivos suscritos(1) y/o no suscritos(0) */
  deviceSubscriptionStatus:any={
    subscribed:1,
    not_subscribed:0
  };
  subscriptionStatusFilter:any=[this.deviceSubscriptionStatus.subscribed,this.deviceSubscriptionStatus.not_subscribed];

  displayedColumns: string[] = ['select','activated', 'name', 'imei', 'plate_number', 'device_model','expiration_date'];
  selection = new SelectionModel<any>(true, []);
  /**Datos del usuario logueado */
  user:any=[];
  /**indica los datos para el ordenamiento de la tabla */
  sort={ by:"", direction:""};
  /**la lista de dispositivos a mostrar en la tabla */
  deviceList = [];
  /**el valor a buscar dentro de la tabla de dispositivos */
  searchDevice="";
  devices:any={
    activate:[],
    deactivate:[]
  };
  //lista de dispositivos vinculados con el web service 
  linkedDevices:any=[];
  //para paginacion
  lengthPage=0;
  pageSize=20;
  pageSizeOptions:any=[20];
  pageEvent:any;
  currentPage=0;
  loading:boolean=false;
  searchTimer:any;
  moduleId=15;
  constructor(
    private dataService:DataService,
    public utils:UtilsService,
    private api:ApiService
  ){}
  ngOnInit(): void {
    this.user= this.dataService.getData("user");
    this.getDeviceList();
  }
  isDeviceSubscriptionStatus(status:number){
    return this.subscriptionStatusFilter.some((item:any)=>item==status);
  }
  changeDeviceSubscriptionFilter(event:any){
    this.currentPage = 0;
    (event.checked)?this.subscriptionStatusFilter.push(parseInt(event.source.value)):this.subscriptionStatusFilter.splice(this.subscriptionStatusFilter.findIndex((item:number)=>item==parseInt(event.source.value)),1);
    this.getDeviceList();
  }
  getDeviceList():void{
    this.showLoading(true);
    this.devices.activate =[];
    this.devices.deactivate=[];
    let data="?web_service_id="+this.webServiceId+"&action=getWebServiceDevices&user_api_hash="+this.user.hash+"&page="+(this.currentPage);
    for(let item of this.subscriptionStatusFilter){
      data += "&activated_field_values[]=" + item;
    }
    if(this.sort.by!="")
      data +="&sort_by="+this.sort.by+"&sort_direction="+this.sort.direction;
    if(this.searchDevice !="")
      data +="&search_value="+this.searchDevice;
    this.api.getData("device",data,true).then((data: any) => {
      console.log(data);
    if(data.status==1){
      for(let device of data.data){
       if(Object.entries(device).length>0 && Number(device.activated))
        this.devices.activate.push(device);
       else
        this.devices.deactivate.push(device);
      }
      this.lengthPage =data.total??0;
    }
    this.deviceList= data.status?data.data:[];
    this.selection = new SelectionModel<any>(true, []);
    this.showLoading(false); 
    }).catch((error:any)=>{
      console.log(error);
      this.deviceList=[];
      this.utils.showMsg("","No se han podido obtener la lista de dispositivos");
      this.showLoading(false);
    });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.devices.activate.length;
    const numRows = this.deviceList.length;
    return numSelected >0 && numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    //console.log(this.devices);
    if (this.isAllSelected()) {
      this.devices.activate=[];
      this.devices.deactivate=this.utils.copyObject(this.deviceList);
      this.selection.clear();
      return;
    }
    this.devices.deactivate=[];
    this.devices.activate=this.utils.copyObject(this.deviceList);
    this.selection.select(...this.deviceList);
  }
  changeDeviceSelected(event:any,device:any){
    if(!this.utils.isNullOrEmpty(device))
      (event.checked)?this.toggleSelection("activate","deactivate",device):this.toggleSelection("deactivate","activate",device);
  }
  toggleSelection(property:string,antonymousProperty:string,device:any){
    let propertyIndex = this.devices[property].findIndex((item:any)=>item.id==device.id); 
    let antonymousPropertyIndex = this.devices[antonymousProperty].findIndex((item:any)=>item.id==device.id); 
      if(propertyIndex ==-1)
        this.devices[property].push(device);
      if(antonymousPropertyIndex !=-1)
          this.devices[antonymousProperty].splice(antonymousPropertyIndex,1);
  }
  checkboxLabel(row?: any): string {
    if (!row)
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  isSelectedDevice(row:any){
    return (this.devices.activate.some((item:any)=>item.id==row.id));
  }
  paginator($event:PageEvent){
    this.pageSize = $event.pageSize;
    this.currentPage = $event.pageIndex;    
    this.getDeviceList();
  }
  sortData(sort: Sort) {
    this.sort.direction=sort.direction;
    this.sort.by=sort.active;
    this.getDeviceList();
  }
  /** @description Actualiza la lista de dispositivos a reportar a Pegaso */
  updateDevicesSelect(){
    let deviceIdList:any={activate:[],deactivate:[]};
    this.devices.activate.forEach((item:any)=>deviceIdList.activate.push(item.id));
    this.devices.deactivate.forEach((item:any)=>deviceIdList.deactivate.push(item.id));
    this.utils.showConfirm("Confirma cambios", "Confirma la actualización de dispositivos a reportar a Pegaso", "Confirmar", "Cancelar").then(() => {
      this.api.createData({user_api_hash:this.user.hash,device_id_list:deviceIdList,action:"update",web_service_id:this.webServiceId}, "deviceWebService",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        this.showLoading(false);
        this.getDeviceList();
      }).catch((err: any) => {
        this.showLoading(false);
          this.utils.showResultRequest("error","Información","Se ha presentado un error al intentar realizar los cambios<br><small>Intenta nuevamente. En caso de error comunicate con el administrador</small>");
      });
    }).catch(()=>{});
  }
  searchValueInData(){
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.currentPage=0;
      this.getDeviceList();
    }, 800);
  }
  showLoading(show: boolean = true):void{
    if(show) this.loading = true;
    else setTimeout(() => { this.loading = false; }, 500);
  }
  close() {
    this.closeModal.emit(true);
  }
}
