import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { IDeviceNote } from '../../../core/models/interfaces/idevice.model';
import { IUserSessionData } from '../../../core/models/interfaces/iuser-session-data.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-device-notes',
  templateUrl: './device-notes.component.html',
  styleUrls: ['./device-notes.component.scss']
})
export class DeviceNotesComponent implements OnInit{
  loading: boolean = false;
  user : IUserSessionData;
  @Output() close = new EventEmitter<boolean>();

  displayedColumns: string[] = ["creation_date","description","actions"];
  /** @type {number} El id del dispositivo a consultar notas */
  @Input() deviceId: number| null = null;
  deviceNotes: IDeviceNote[] =  [];
  constructor(
    private api: ApiService,
    public validationService: ValidationService,
    public dataService: DataService,
    public utils: UtilsService,
    private renderer: Renderer2
  ){
    this.user = this.dataService.getData("user");
  }
  ngOnInit(): void {
    this.getDeviceNotes();
  }
  getDeviceNotes() {
    this.loading = true;
    let responseData: IDeviceNote[] = [];
    this.api.getData("device", "?action=getDeviceNotes&user_api_hash=" + this.user.hash + "&device_id=" + this.deviceId, true).then((deviceNotes: unknown) => {
      //console.log("notas del dispositivo ",deviceNotes);
      if(this.validationService.isResponseApi(deviceNotes) && deviceNotes.status == 1)
        responseData = deviceNotes.data;
    }).finally(()=>{
      this.deviceNotes = responseData;
      this.utils.hideLoading(() => this.loading = false)
    });
  }
  deleteDeviceNote(noteId: number) {
    this.utils.showConfirm("Confirmar accion", "Esta seguro de eliminar esta nota", "Eliminar", "Cancelar").then(() => {
      this.loading = true;
      this.api.deleteData("device", "?action=deleteDeviceNote&user_api_hash=" + this.user.hash + "&note_id=" + noteId, true).then((data: any) => {
      if(this.validationService.isResponseApi(data)){
        if(data.status == 1)
          this.getDeviceNotes();
        this.utils.showResultRequest(data.status == 1 ? "success" : "error","Información",data.message);
      }
      }).catch((err: unknown) => {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la nota de dispositivo",false,false,"DELETE"));
      });
    }).catch((error: unknown) => {})
    .finally(()=> this.utils.hideLoading(() => this.loading = false));
  }
  addNoteToDevice() {
    Swal.fire({
      title: 'Agregar nota',
      input: 'textarea',
      inputAttributes: { maxLength: "400" },
      html: '<div class="text-start"><label for="dateNote" title="Toma la fecha actual de forma predeterminada">Fecha de novedad<span class="information"> (opcional)</span></label></div><input id="dateNote" class="form-control" type="datetime-local">',
      inputPlaceholder: 'Ingresa una nota de máximo 400 carácteres',
      showCancelButton: true,
      confirmButtonText:"Agregar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      let dateNoteInput: string = this.renderer.selectRootElement("#dateNote", true).value;
      if (result.isConfirmed) {
        if (result.value != "") {
          this.loading = true;
          let dataToSend = {
            user_api_hash: this.user.hash,
            device_id: this.deviceId,
            description: result.value,
            creation_date: dateNoteInput == "" ?  this.utils.getDatetime() : this.utils.getDatetime(new Date(dateNoteInput)),
            action: "addNote"
          };
          this.api.createData(dataToSend, "device", true).then((data: unknown) => {
            if(this.validationService.isResponseApi(data)){
              if (data.status == 1)
                this.getDeviceNotes();
              this.utils.showResultRequest(data.status == 1 ? "success" : "error",data.message??"");
            }
          }).catch((error: unknown) => {
            this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la nota de dispositivo",false,false,"POST"));
          }).finally(()=> this.utils.hideLoading(() => this.loading = false));
        } else{
          this.utils.showResultRequest("info", "Ingrese nota a registrar");
          this.utils.hideLoading(() => this.loading = false);
        }
      }
    }).catch(() => { });
  }
  closeComponent(){
    this.close.emit(true);
  }
}