import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IChecklistQuestionGroupByCategory, IDeviceChecklist, IDeviceChecklistAnswerAnswered } from 'src/app/core/models/interfaces/idevice-checklist.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { IDeviceBasicData } from 'src/app/core/models/interfaces/idevice.model';
import { NgxImageCompressService } from 'ngx-image-compress';
/**
 * La estructura de datos para el archivo de firma solicitado al conductor
 */
enum DriverSignature {
  name = "driver_signature",
  file_type = "image/png",
  extension = "png"
};

@Component({
  selector: 'app-device-checklist-response-handler',
  templateUrl: './device-checklist-response-handler.component.html',
  styleUrls: ['./device-checklist-response-handler.component.scss']
})
export class DeviceChecklistResponseHandlerComponent implements OnInit,AfterViewInit {

  user!:IUserSessionData;

  /** @type {string} Almacena el nombre de la llave usada en el formData para identificar el id del checklist que se resuelve */
  deviceChecklistIdKeyName: string = "device_checklist_id";
  /**
   * @type {Blob} El archivo de firma del conuctor a enviar
   */
  driverSignatureFile!:Blob;
  driverHasSigned:boolean = false;
  
  /** Almacena los tipos de controles de formularios disponibles, el nombre y id */
  readonly FORM_CONTROL_TYPES = this.dataService.FORM_CONTROL_TYPES;
  /**
   * @type {boolean} Indica cuando los datos se estan enviando para su registro 
   */
  processingData:boolean = false;
  loading: boolean = false;
  /**
   * @type {boolean} Cuando se envia el checklist, indica si el registro ha sido exitoso
   */
  successfulRegistration:boolean = false;
  @ViewChild('signature')
  public signaturePad!: SignaturePadComponent;

  public signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 300
  };
  /**
   * @type {device_checklist_answer_answered} Las respuestas dadas al checlklist evaluado
   */
  deviceChecklistAnswerAnswered:IDeviceChecklistAnswerAnswered[];
  /** @type {IDeviceChecklist} Checklist evaluado */
  checklistSelected:IDeviceChecklist;
  /**@type {IChecklistQuestionGroupByCategory} Las preguntas agrupadas por categoria del checklist que se esta respondiendo */
  checklistQuestionsGroupedByCategories:IChecklistQuestionGroupByCategory[]= [];
  /**
   * @type {IDeviceBasicData} Indica el dispsitivo actual del usuario conductor
   */
  currentDevice:IDeviceBasicData;
  constructor(
    private dialogRef: MatDialogRef<DeviceChecklistResponseHandlerComponent>,
    private api:ApiService,
    public dataService: DataService,
    public validationService:ValidationService,
    public utils: UtilsService,
    private breakpointObserver: BreakpointObserver,
    private imageCompress: NgxImageCompressService,
    @Inject(MAT_DIALOG_DATA) public data:{device_checklist_answer_answered:IDeviceChecklistAnswerAnswered[],device_checklist:IDeviceChecklist,current_device:IDeviceBasicData}    
  ){
    this.initializeSignaturePadOptions();
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.checklistSelected = data.device_checklist;
    this.deviceChecklistAnswerAnswered = data.device_checklist_answer_answered;
    this.currentDevice = data.current_device;
  }
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.signaturePad.set('minWidth', 5);  
    this.signaturePad.clear();
  }
  drawComplete(event: MouseEvent | Touch | Event) {
    this.driverHasSigned = true;
  }
  drawStart(event: MouseEvent | Touch | Event) {}
  clearSignature(){
    this.driverHasSigned = false;
    this.signaturePad.clear(); 
  }
  /**
   * @description Almacena la lista de respuestas dada para el checklist
   */
  async saveAnswers():Promise<void>{
    let filenameElementPrefix: string = "answer";
    let filenameElementSeparator: string = "_";
    if(this.checklistSelected.id == null || isNaN(this.checklistSelected.id))
      return this.utils.showResultRequest("info","Indica el checklist al cual se está respondiendo","");
    /** EL archivo de firma el conductor */
    const signBlob = this.utils.dataURLtoBlob(this.signaturePad.toDataURL(DriverSignature.file_type));
    if(signBlob == null)
      return;
    let formData:any = new FormData();
    this.utils.fillFormData(formData,this.deviceChecklistAnswerAnswered,true,undefined,false);
    //se reailza proceso de compresion de firma
    let response =await this.imageCompress
    .compressFile(this.signaturePad.toDataURL(DriverSignature.file_type), this.imageCompress.DOC_ORIENTATION.NotDefined, 50, 50) // 50% ratio, 50% quality
    
    formData.append(DriverSignature.name,this.utils.dataURLtoBlob(response) as Blob,DriverSignature.name+"."+DriverSignature.extension);
    
    formData.append(this.deviceChecklistIdKeyName,this.checklistSelected.id.toString());
    formData.set("device_id",this.currentDevice.id);
    formData.set("device_checklist_id",this.checklistSelected.id);
    formData.set("user_api_hash",this.user.hash);
    formData.set("action","add");
    // Recorre el objeto de respuetas para agregar los archivos con un nombre identificador de la respesta seleccionada
    for(let answer of this.deviceChecklistAnswerAnswered){
      if(typeof answer.attached_file != "undefined" && answer.attached_file instanceof File){
        let fileName:string = filenameElementPrefix + filenameElementSeparator + answer.device_checklist_question_id + filenameElementSeparator; 
        if([this.FORM_CONTROL_TYPES.CHECKBOX, this.FORM_CONTROL_TYPES.RADIO].includes(answer.form_control_action_type_id??0)){
          fileName += answer.device_checklist_answer_option_id;
        }else if ([this.FORM_CONTROL_TYPES.CHECKBOX_GRID, this.FORM_CONTROL_TYPES.RADIO_GRID].includes(answer.form_control_action_type_id??0)){
          fileName += answer.device_checklist_grid_subquestion_id + filenameElementSeparator + answer.device_checklist_grid_answer_option_id;
        }
        let attachedileName: string[] = answer.attached_file.name.split(".");
        formData.append(fileName,answer.attached_file,fileName+"."+(attachedileName[attachedileName.length-1]));
      }
    }/*
    for(let obj of (formData) ){
      console.log(obj);
    }*/
    this.processingData = true;
    setTimeout(() => {
      this.api.createData(formData, "deviceChecklistAnswered",true).then((response: unknown) => {
        console.log("respuesta",response);
        if(this.validationService.isResponseApi(response)){          
          let timeInSecondsForTimer:number = 6;
          this.utils.showResultRequest(response.status == 1 ? "success":"error","",response.message??"",response.status == 0 ? undefined : {timer:timeInSecondsForTimer, timerProgressBar:false});
          this.utils.hideLoading((response:boolean)=>this.loading=response);
          this.successfulRegistration = response.status == 1;
          response.status == 1 ? setTimeout(() => {this.dialogRef.close(true)}, this.dataService.OneSecond*(timeInSecondsForTimer*1.5)) : null;
          this.processingData = false;
          return;
        }
        this.utils.showResultRequest("error","",this.api.getDefaultMessage("las respuestas de checklist",true,false,"POST"));
        this.processingData = false;
      }).catch((err: any) => {
        console.log(err);
        this.processingData = false;
        this.utils.hideLoading((response:boolean)=>this.loading = false);
        this.utils.showResultRequest("error","",this.api.getDefaultMessage("las respuestas de checklist",false,false,"POST"));
      });
    }, this.dataService.OneSecond*1.5);
  }
  private initializeSignaturePadOptions() {
    this.breakpointObserver.observe([ Breakpoints.XSmall]).subscribe(result => {
      if (result.matches) {
        //Extra Small screen
        this.signaturePadOptions = {
          minWidth: 5,
          canvasWidth: 260,
          canvasHeight: 200
        };
      } else {
        // Large screen
        this.signaturePadOptions = {
          minWidth: 5,
          canvasWidth: 500,
          canvasHeight: 300
        };
      }
    });
  }
}