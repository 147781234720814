<section class="form-box-container animate__animated animate__fadeIn">
    <ng-container *ngIf="!addOnlyPaymentOptionName">
        <section class="form-box-container__header">
            <span class="form-box-container__header-back" (click)="closeComponent()"><span class="description">Volver</span> </span>
            <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-microchip"></i></span>Opciones de pago</h2>
            <div class="form-box-container__header-options">
            </div>
        </section>
        <div class="form-box animate__animated animate__fadeIn">
            <div class="form-box__content form-box__content-h-auto">
            
                <mat-tab-group dynamicHeight (selectedTabChange)="changeTab($event)">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="text-success">format_list_numbered</mat-icon>
                            Lista de cuentas
                          </ng-template>
                          <div class="form-box two-panel two-panel--automatic-orientation flex-wrap animate__animated animate__fadeIn">
                            <div class="form-box__content form-box__content-shadow g-0">
                                <section class="d-block alert alert-light-info">
                                    <p class="text-small">Configura las opciones de pago disponibles para tus clientes. Ellas estarán también disponibles en <strong>Mi cartera</strong> para ser seleccionadas como entidades financieras, en el momento de confirmar los pagos de tus clientes </p>
                                </section>
                                <div  class="form-box__content-inputs">
                                    <div  class="form-box__content-group-fields mt-2">
                                        <section class="d-block fill">
                                            <mat-checkbox class="d-block" name="payment_options_list" [checked]="userSettingDisplayPaymentOptionInBilling.PAYMENT_OPTIONS_LIST == userSetting.display_payment_option_in_billing" (change)="changeDisplayPaymentOptionInBilling(userSettingDisplayPaymentOptionInBilling.PAYMENT_OPTIONS_LIST,$event,true)" [value]="userSettingDisplayPaymentOptionInBilling.PAYMENT_OPTIONS_LIST" color="primary">Mostrar lista vía código QR en documentos PDF de soportes de pago</mat-checkbox>
                                            <p class="text-small text-warning">Sólo puede enlazarse al documento alguna de las dos opciones: <strong>Lista de cuentas</strong> o <strong>Subir QR.</strong> La última seleccionada será la disponible </p>
                                        </section>
                                        <mat-form-field appearance="fill">
                                            <mat-label>Opcion de pago</mat-label>
                                            <input matInput placeholder="Ex. BBVA" name="name" [(ngModel)]="paymentOption.name">
                                        </mat-form-field>
                                        <mat-form-field appearance="fill">
                                            <mat-label>Numero de cuenta</mat-label>
                                            <input matInput placeholder="Ex. XXX-XXXXXX-X"name="accountNumber" [(ngModel)]="paymentOption.account_number" >
                                        </mat-form-field> 
                                        <mat-form-field appearance="fill">
                                            <mat-label>Tipo de cuenta</mat-label>
                                            <mat-select name="account_type"   [(value)]="paymentOption.account_type">
                                                <ng-container   *ngFor="let type of bankAccountTypesList">
                                                    <mat-option [value]="type">{{type}}</mat-option>
                                                </ng-container>  
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="fill" appearance="fill" >
                                            <mat-label>Agregar paso</mat-label>
                                            <input matInput placeholder="Indica un paso a seguir y oprime el boton de agregar"name="currentStep"  [(ngModel)]="paymentOption.step_actual">
                                            <button 
                                            matSuffix mat-icon-button 
                                            color="primary" 
                                            [disabled]="paymentOption.step_actual==''"  
                                            (click)="addStep();$event.stopPropagation()" 
                                            matTooltip="Agregar paso" 
                                            aria-label="Agregar paso a la opción de pago">
                                                <mat-icon>add</mat-icon>
                                            </button>
                        
                                        </mat-form-field>    
                                    </div>
                                </div>
                                <div class="form-box__content-section draggable-list" *ngIf="(paymentOption.payment_proccess?.length??0)>0" cdkDropList (cdkDropListDropped)="drop($event)">
                                    <div class="draggable-box" *ngFor="let step of paymentOption.payment_proccess;index as i"  cdkDrag>{{(i+1)+". "+step}}
                                        <button class="btn btn-outline-danger" aria-label="Eliminar paso" (click)="deleteStep(i)"><i class="fa-solid fa-trash"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-box__content form-box__content-auto form-box__content-flex-basis-medium transparent-scroll-vertical order-mobile-first">
                                <div class="form-box__content-header form-box__content-shadow sticky-top">
                                    <strong class="form-box__content-title">Opciones de pago registradas</strong>
                                </div>
                                <div class="form-box__content-section">
                                    <mat-selection-list #paymentsOptList [multiple]="false" class="overflow-auto">
                                        <mat-list-option *ngFor="let option of paymentOptionsList"  (click)="optionSelected(option)"[value]="option.id">
                                            <div class="d-flex flex-row justify-content-between align-items-center">
                                                {{option.name}}
                                                <button  class="btn btn-outline-danger"(click)="deletePaymentOption(option);$event.stopPropagation()">
                                                <i class="fa-solid fa-trash-can"></i>
                                                </button>
                                
                                            </div>
                                        </mat-list-option>
                                    </mat-selection-list>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="text-success">qr_code_2</mat-icon>
                            Subir QR
                          </ng-template>
                            <section class="d-block mt-2">
                                <mat-checkbox class="d-block" name="bank_qr_code" [checked]="userSettingDisplayPaymentOptionInBilling.BANK_QR_CODE == userSetting.display_payment_option_in_billing"  (change)="changeDisplayPaymentOptionInBilling(userSettingDisplayPaymentOptionInBilling.BANK_QR_CODE,$event, true)"  [value]="userSettingDisplayPaymentOptionInBilling.BANK_QR_CODE" color="primary">Mostrar código QR en documentos PDF de soportes de pago</mat-checkbox>
                                <p class="text-small text-warning">Sólo puede enlazarse al documento alguna de las dos opciones: <strong>Lista de cuentas</strong> o <strong>Subir QR.</strong> La última opción seleccionada será la disponible </p>
                            </section>
                            <section class="d-block alert alert-light-info">
                                <p class="text-small">El tamaño maximo del archivo es de {{maxQRImageSizeInKB}} KB</p>
                            </section>
                          <div class="container-inputs-file">
                            <ng-container>
                                <div 
                                *ngIf=" !userSgcFileToUpload.delete_bank_qr_code_file && (userSgcFileToUpload.temp_bank_qr_code_file != null || (userSgcFileToUpload.bank_qr_code_filename != null && userSgcFileToUpload.bank_qr_code_filename != '')); else imageQRElseBlock"
                                class="img-form" matTooltip="Código QR" matTooltipPosition="below">
                                    <img [src]="userSgcFileToUpload.bank_qr_code_filename != '' && userSgcFileToUpload.bank_qr_code_filename != null ? (urlBaseSgc + imageFolderRoute.USER_BANK_QR_CODE + userSgcFileToUpload.bank_qr_code_filename) : userSgcFileToUpload.temp_bank_qr_code_file" alt="Código QR a asociar a documentos de soportes de pago" class="animate__animated animate__bounceIn">
                                    <div class="trash p-1" (click)="deleteImgQR()"><i class="fa-regular fa-trash-can small"></i></div>
                                </div>
                                <ng-template #imageQRElseBlock>                                
                                    <div class="img-form">
                                        <i class="fa-solid fa-qrcode icon"></i>
                                        <div class="txt">Selecciona o arrastra la imagen de código QR <br>{{'('+supportedFiles.accept_label+')'}}</div>
                                        <input type="file"  name="homeTopSectionBg" [accept]="supportedFiles.accept_value" (change)="loadQRCode($event)">
                                    </div>
                                </ng-template>  
                            </ng-container>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                <div class="form-box__content-actions ">
                    <ng-container *ngIf="tabsAvailable['payment_option_list'].show_view">
                        <button class="btn btn-primary animate__animated animate__fadeIn" [disabled]="loading" (click)="adminPaymentOption()" mat-raised-button>Registrar</button>
                        <button class="btn btn-outline-secondary  animate__animated animate__fadeIn"matTooltip="Vaciar campos. inicia un nuevo registro" (click)="clearFields()" mat-raised-button>Limpiar</button>
                    </ng-container>
                    <ng-container *ngIf="tabsAvailable['user_bank_qr_code'].show_view">
                        <button class="btn btn-primary  animate__animated animate__fadeIn" [disabled]="loading" (click)="adminUserQrCode()" mat-raised-button>Actualizar</button>
                    </ng-container>
                    
                </div>
            </div>
        </div>
        
        <div class="loader" *ngIf="loading">
            <img src="../../../assets/loader.svg">
        </div>
    </ng-container>
    
    <section class="form-box" *ngIf="addOnlyPaymentOptionName">
        <div  class="form-box__content-inputs">
            <div  class="form-box__content-group-fields mt-2">
                <mat-form-field appearance="fill">
                    <mat-label>Opcion de pago</mat-label>
                    <input matInput placeholder="Ex. BBVA" name="name" [(ngModel)]="paymentOption.name">
                </mat-form-field>
            </div>
        <div class="form-box__content-actions">
            <button class="btn btn-primary" [disabled]="loading" (click)="adminPaymentOption()" mat-raised-button>Registrar</button>
            <button class="btn btn-outline-secondary" (click)="closeComponent()" mat-raised-button>Cancelar</button>
        </div>
        </div>  
    </section>
</section>