import { Component,Input,Output,OnInit,EventEmitter,Renderer2 } from '@angular/core';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { SgcPlatformPlanPermissions } from "../../../../core/models/classes/sgc-platform-plan-permissions.model";

//Administra los permisos que pueden ser otorgados por los usuarios distribuidores a sus usuarios finales
@Component({
  selector: 'app-assignable-module-operations',
  templateUrl: './assignable-module-operations.component.html',
  styleUrls: ['./assignable-module-operations.component.scss']
})
export class AssignableModuleOperationsComponent  extends SgcPlatformPlanPermissions implements OnInit{

  user:any={};
  loading:boolean=false;
  @Input() distributorId!:number;
  @Output() closeUpdateModal = new EventEmitter<boolean>();

  /** Los permisos que el distribuidor puede  otorgar a sus usuarios */
  permissionsGrantedToDistributor:any=[];
  /**Los modulos a los que se la ha otorgado permisos al distribuidor para gestionar a sus usuarios */
  modulesWithPermissionsGranted:number[] =[];
  /**  Almacena los permisos seleccionados del select de permisos ya listos para agregar a permisos otorgables */
  modulePermissionsToAddToPermissionsGranted:any =[];

  /** Los modulos del plan de la plataforma que tiene el usuario. Incluye los modulos extendidos que son permisos otorgables para los usuarios finales del distribuidor */
  distributorPlatoformPlanModules:any=[];
  constructor(
    private dataService:DataService,
    public  utils:UtilsService,
    private api:ApiService
  ){ super(); }
  ngOnInit(): void {
      this.user = this.dataService.getData("user");
      this.getList();
  }

  getList(){
    this.api.getData("module","?action=getModules&user_api_hash="+this.user.hash,true).then((data: any) => {
      this.modules = data.status==1?data.data:[];
      this.filteredModules =  this.utils.copyObject(this.modules);
      this.api.getData("managerAssignableModuleOperation", "?action=get&user_api_hash="+this.user.hash+"&user_id="+this.distributorId,true).then((data: any) => {
        this.permissionsGrantedToDistributor = data.status?data.data:[];
        this.api.getData("platformPlan", "?action=getPlatformPlan&user_api_hash="+this.user.hash+"&user_id="+this.distributorId,true).then((data: any) => {
          this.newPlatformPlan = data.status==1?data.data:{};
          this.distributorPlatoformPlanModules = this.utils.copyObject(this.newPlatformPlan.modules??[]);
          /*if(!data.status)
            return this.utils.showResultRequest("error","Información",data.message);*/
          let selectedPlanRoles:any = [];
          this.newPlatformPlan.platform_plan_role.forEach((item:any)=>selectedPlanRoles.push(Number(item.role_id)));
          //Se filtra solo los modulos que son asignables a un usuario de rol usuario
          this.newPlatformPlan.modules = this.newPlatformPlan.modules.filter((PlatformPlanModule:any)=>
          this.modules.some((module:any)=>module.id == PlatformPlanModule.module_id && module.module_roles.includes(this.dataService.USER_ROLES.USER)));
          this.newPlatformPlanCopy=this.utils.copyObject(this.newPlatformPlan);
          super.structurePermissionsGranted(this.modules,this.permissionsGrantedToDistributor,this.modulesWithPermissionsGranted,this.distributorPlatoformPlanModules,this.newPlatformPlanCopy,this.newPlatformPlan);
          //Se filtra solo los modulos que son asignables a un usuario de rol usuario
          this.filteredModules = this.modules.filter((module:any)=>{
            if(this.distributorPlatoformPlanModules.every((moduleWithPermissions:any)=>moduleWithPermissions.module_id!=module.id)){
                let response = selectedPlanRoles.map((roleId:number)=>module.module_roles.includes(this.dataService.USER_ROLES.USER) );
                return response.every((item:boolean)=>item);  
            }
            return false;
          });
        }).catch((error:any)=>{});
      }).catch((error:any)=>{});
    }).catch((error:any)=>{});
  }
  addNewPermissionsGranted(){
    let error:any=this.isValidmodulePermissionsToAddToPlatformPlan(this.selectedModule,this.modulePermissionsToAddToPermissionsGranted);
    if(!error["status"]){
      this.utils.showMsg("",error["message"]);
      return;
    }
    let dataToSend={
      permissions:this.modulePermissionsToAddToPermissionsGranted,
      user_api_hash:this.user.hash,
      action:"add"
    };
    this.loading=true;
    this.api.createData(dataToSend, "managerAssignableModuleOperation",true).then((data: any) => {
      if(data.status){
        this.utils.showResultRequest("success","Información",data.message);
        this.modulePermissionsToAddToPermissionsGranted=[];
        this.selectedModule =[];
        this.selectedModulePermissions=[];
        this.getList();
      } else {
        this.utils.showResultRequest("error","Información",data.message);
      }
      this.utils.hideLoading(()=>this.loading=false);
    }).catch((err: any) => {
      this.utils.hideLoading(()=>this.loading=false);
      this.utils.showResultRequest("error","Permisos no registrados","Se ha presentado un error mientras se intentaba registrar los permisos");
    });
  }
  deleteModulePermissions(module:any){
    this.utils.showConfirm("Confirmar acción","Esta seguro de eliminar los permisos otorgables del usuario","Confirmar","Cancelar").then(()=>{
      let data={user_api_hash:this.user.hash,action:"delete", module_id:module.module_id,user_id:this.distributorId};
      this.api.createData(data, "managerAssignableModuleOperation",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        if(data.status==1)
          this.getList();
      }).catch((err: any) => {
        this.utils.showResultRequest("error","Información","Se ha presentado un error al eliminar los permisos indicados");
      });
    }).catch(()=>{});
  }
  override prepareModulePermissionsToPlatformPlan(){
    this.selectedModulePermissions = [];
    super.prepareModulePermissionsToPlatformPlan(this.selectedModule,this.selectedModulePermissions,this.modulePermissionsToAddToPermissionsGranted,undefined,this.distributorId);
  }
  updatePermissions(){
    this.utils.showConfirm("Confirmar actualización de permisos que puede otorgar el distribuidor","","Confirmar","Cancelar").then(()=>{
      this.prepareModulePlanToSend(this.newPlatformPlanCopy,this.newPlatformPlan,this.distributorId);
      this.newPlatformPlanCopy["action"]="update";
      this.newPlatformPlanCopy["user_api_hash"]=this.user.hash;
      this.newPlatformPlanCopy["user_id"] = this.distributorId;
      this.api.createData(this.newPlatformPlanCopy, "managerAssignableModuleOperation",true).then((data: any) => {
        this.utils.showResultRequest(data.status==1?"success":"error","Información",data.message);
        (data.status==1)?this.getList():"";
      }).catch((err: any) => {
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("permisos otorgables por distribuidor",true,false,"POST"));
      });
    }).catch((error:any)=>{});
  }
  close() {
    this.closeUpdateModal.emit(true);
  }
}