import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ITrip } from 'src/app/core/models/interfaces/itrip.model';
import { ObjectInitializationService } from 'src/app/core/services/object-initialization/object-initialization.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { IDeviceBasicData } from 'src/app/core/models/interfaces/idevice.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { IResponseApi } from 'src/app/core/models/interfaces/iresponse-api.model';
import { IApiRequestData, IPagination, ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';

type MaxTripFieldLength = Pick <ITrip,"title"|"description">;

@Component({
  selector: 'app-device-trip',
  templateUrl: './device-trip.component.html',
  styleUrls: ['./device-trip.component.scss']
})
export class DeviceTripComponent implements OnInit {
  user!:IUserSessionData;

  loading:boolean = false;

  @ViewChild(MatMenuTrigger) tripForm!: MatMenuTrigger;
  
  /** @type {ITrip} La lista de viajes del conductor */
  driverTripList: ITrip[] = [];
  /** @type {ITrip} Almacena el viaje seleccionado por el conductor para ser editado o creado */
  tripSelected!:ITrip;
  /** @type {boolean} Indicador de carga de busqueda de datos de viaje seleccionado  */
  deviceTripSelectedLoading:boolean = false;
  @Input() lastTripInProcess!: ITrip;
  @Input() currentDrivenVehicle!:IDeviceBasicData;
  @Output() close = new EventEmitter<boolean>();

  /** @type {boolean} Indica si se el loading de actualizacion de viajes esta activo */
  updateTripLoading:boolean = false;

  /** @type {Record<keyof MaxFieldLength,number>}  Indica el tamaño maximo de los campos del viaje a actualizar*/
  readonly MaxTripFieldLength!: Record<keyof MaxTripFieldLength,number>
  /** @type {string} Valor a buscar en una consulta de viajes */
  searchValue:string = "";  
  pagination:IPagination;
  sort!:ISort;

  back:()=>void;
  closeComponent:()=>void;
  constructor(
    public utils: UtilsService,
    private dataService: DataService,
    private api: ApiService,
    public validationService: ValidationService,
    private router: Router,
    public objectInitializationService: ObjectInitializationService
  ){
    this.user = this.dataService.getData("user");
   
    this.pagination = this.objectInitializationService.initializeIPagination(this.dataService.PAGE_SIZE_OPTION);
    this.sort = this.objectInitializationService.initializeISort();
    this.MaxTripFieldLength = {title:120,description:200};

    this.closeComponent = () => this.close.emit(true);
    this.back = () => {this.router.navigate(["/dashboard/fleet-control/driver-device-state-management"])};
  }

  ngOnInit(): void {
    this.tripSelected =  this.objectInitializationService.initializeITrip(this.currentDrivenVehicle.id != 0 ? this.currentDrivenVehicle.id : 0,this.user.id,this.currentDrivenVehicle.id != 0 ? (this.currentDrivenVehicle.name??""):"",this.currentDrivenVehicle.id != 0 ? (this.currentDrivenVehicle.plate_number??""):"");
    this.getTripList();
  }
  /**
   * Valida campos del registro de viaje y retorna un string con informacion en caso de faltar información
   */
  validateFields(trip:ITrip):string{
    if(this.validationService.isNullOrEmpty(trip.title))
      return "El campo titulo es obligatorio";
    if(this.validationService.isNullOrEmpty(trip.start_datetime))
      return "Debe de indicar una fecha inicial";
    if(isNaN((new Date(trip.start_datetime)).getTime()))
      return "Fecha de inicio no valida";
    if((new Date(trip.start_datetime)).getTime() < new Date().getTime())
      return "La fecha inicial debe ser superior al tiempo actual";
    if(this.validationService.isNullOrEmpty(trip.start_address))
      return "debe de indicar la direccion inicial";
    if(this.validationService.isNullOrEmpty(trip.end_address))
      return "debe de indicar la direccion de destino";
  return "";
  }
  async updateTrip():Promise<void>{
    this.updateTripLoading = true;
    let trip = this.utils.copyObject(this.tripSelected) as ITrip;
    try{
      let error:string = this.validateFields(trip);
      if(error != ""){
        return this.utils.showResultRequest("error","Información",error);
      }
      if(!this.validationService.isNullOrEmpty(trip.start_datetime))
        trip.start_datetime = this.utils.getUTCDatetime(new Date(trip.start_datetime), false);
      if(!this.validationService.isNullOrEmpty(trip.end_datetime))
        trip.end_datetime = this.validationService.isNullOrEmpty(trip.end_datetime) ? null : this.utils.getUTCDatetime(new Date(trip.end_datetime!), false);
      let confirm:unknown = await this.utils.showConfirm("Confirmar "+(trip.id != 0 ? ("la actualización") : ("el registro")+ " del viaje"), "","Confirmar","Cancelar");
    if(typeof confirm !== "boolean"){
      this.utils.hideLoading(()=>this.updateTripLoading = false);
      return;
    }
    }catch(error:any){
      this.utils.hideLoading(()=>this.updateTripLoading = false);
      return;
    }
    try{
      let data:ITrip & IApiRequestData  = { user_api_hash:this.user.hash,action:trip.id == 0?"add":"update",
        ...trip
      };
      let response:unknown = await this.api.createData(data, "deviceTrip",true);
      if(this.validationService.isResponseApi(response)){
        this.utils.showResultRequest(response.status==1?"success":"error","Información",response.message);
        if(response.status == 1 )
          this.closeEditForm(this.tripForm);
          response.status == 1 ? this.getDriverTripList() : null;
      }
      }catch(error:any){
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el viaje",trip.id != 0,false,"POST"));
    }
    this.utils.hideLoading(()=>this.updateTripLoading = false);
  }
    /**
   * @description Obtiene la lista de viajes del conductor
   */
    getTripList(){
      this.loading = true;
      this.api.getData("deviceTrip","?action=get&user_api_hash="+this.user.hash,true).then((data: any)=>{
        this.driverTripList =data.status == 1 ? data.data as ITrip[]: [];
        this.utils.hideLoading(()=> this.loading = false);
      }).catch((error:any)=>{
        this.utils.hideLoading(()=> this.loading = false);
        this.utils.showMsg("","Ha surgido un error mientras se intentaba obtener el dispositivo conducido por el usuario");
      });
    }
  async deleteTrip(trip:ITrip):Promise<void>{
    try{
      let confirm:unknown = await this.utils.showConfirm("¿Estas seguro de querer eliminar el viaje?", "","Confirmar","Cancelar");
    if(typeof confirm !== "boolean")
      return;
    }catch(error:any){
      return;
    }
    this.loading = true;
    let data: IApiRequestData & {id?:number} = { user_api_hash:this.user.hash, action:"delete",id:trip.id};
    try{
      this.loading =false;
      let response:unknown = await this.api.createData(data, "deviceTrip",true); 
      if(this.validationService.isResponseApi(response)){
        this.utils.showResultRequest(response.status == 1 ? "success" : "error","Información",response.message??"");
        response.status == 1 ? this.getDriverTripList() : null;
      }
      this.utils.hideLoading(()=> this.loading = false);
    }catch(error: any){
      this.utils.hideLoading(()=> this.loading = false);
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage( "el viaje",false,false,"DELETE"));
    }
  }
  async getDriverTripList():Promise<void>{
    try{
      let data:IApiRequestData = this.api.structureParametersForConsult(RequestMethodHTTP.GET,"get",this.user.hash,this.searchValue,this.pagination.current_page,this.pagination.page_size,this.sort) as IApiRequestData;
      let response:unknown = await this.api.getData("deviceTrip",data,true);
      if(this.validationService.isResponseApi(response)){
        response.status== 0 ? this.utils.showResultRequest("error","Información",response.message) : null;
        this.driverTripList = response.status== 1 ? response.data : []; 
      }
      }catch(error:any){
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el viaje",false,false,"GET"));
        this.driverTripList = [];
    }
  }
  async openTripEditor(driverTrip:ITrip):Promise<void>{
    this.tripSelected = this.utils.copyObject(driverTrip) as ITrip;
    this.tripSelected.start_datetime = new Date(driverTrip.start_datetime);
    this.tripSelected.end_datetime = this.validationService.isNullOrEmpty(driverTrip.end_datetime??'') ? '' : new Date(driverTrip.end_datetime!);

    this.deviceTripSelectedLoading = true;
    try{
      let response:unknown = await this.dataService.getAvailableDevicesBasicDataList(driverTrip.device_id);
      if(this.validationService.isResponseApi(response)){
        let isValid:boolean =  response.status == 1;
        let data:IDeviceBasicData = isValid ? response.data[0] as IDeviceBasicData : this.objectInitializationService.initializeIDeviceBasicData();
        this.tripSelected.device = { id: isValid ? data.id : 0, name: isValid ? data.name : "", plate_number: isValid ? data.plate_number : ""};
        this.utils.hideLoading(()=>this.deviceTripSelectedLoading = false);
      }
    }catch(error:any){
      this.tripSelected.device = { id: 0, name: "", plate_number: ""};
      this.utils.hideLoading(()=>this.deviceTripSelectedLoading = false);
    }
  }
  openChecklistEditForm(trip?:ITrip):void{
    this.tripSelected =  typeof trip !="undefined" ? trip : this.objectInitializationService.initializeITrip(this.currentDrivenVehicle.id,this.user.id,this.currentDrivenVehicle.name??"",this.currentDrivenVehicle.plate_number??"");
  }
  closeEditForm(element: MatMenuTrigger): void {
    element.closeMenu();
  }
}
