import { Component, OnInit,ChangeDetectorRef   } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { EmployeesComponent } from './dialogs/employees/employees.component';
import { CommentsComponent } from "./dialogs/comments/comments.component";
import {MatDialog} from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';

import Swal from 'sweetalert2';
import {Sort} from '@angular/material/sort';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin ,{ Draggable } from '@fullcalendar/interaction';

import listPlugin  from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  //inicio calendar
  calendarVisible = true;
  currentEvents: EventApi[] = [];

  eventDataCalendar:any;
  calendarOptions: CalendarOptions = {
    timeZone:"local",
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin,timeGridPlugin,interactionPlugin,listPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select:this.openCreateTaskModal.bind(this),
    dateClick: this.openCreateTaskModal.bind(this), // MUST ensure `this` context is maintained
    eventClick:this.openUpdateTaskModal.bind(this),
    events: [],
    locale:esLocale
  };
  //fin calendario
  user:any=[];//los datos del usuario logueado
  taskModal:boolean=false;
  searchImei:string="";//para buscar imei en la tabla inicial

  headers:any=["imei","plate_number","employee","completed","created_at","expiration_date","title",];//las propiedades a exportar de las tareas

  tasksList:any=[];//almacena las tareas que han sido creadas y se muestran en la tabla
  tasksListSorted:any=[];
  displayedColumns:string[] = ["plate_number","imei","employee","expiration_date","title","completed","actions"];
  //para paginacion en la tabla que lista las tareas
  lengthPage=0;
  pageSize=20;
  pageSizeOptions:any=[20];
  pageEvent:any;
  pageIndex:number=0;
  currentPage:number=0;

  taskView:"calendar"|"table"="table";
  loading: boolean = false;//para loading
  loadings:any={
    modalView:false,//para la tabla o calendario
    general:false
  };
  isUpdateTask:boolean=false;
  isSeeTask:boolean=false;
  employeesList:any=[];//lista de empleados que pueden ser encargados para realizar la tarea
  employeesListFilter:any=[];
  imeiList:any=[];//lista de imeis del usuario, disponibles para realizar la asignacion de la tarea a dicho dispositivo
  imeiListFilter:any=[];
  //filtros destinados para la tabla principal
  filters:any=[0];//por defecto, las que estan en proceso se cargan
  //alamcena la tarea a ser actualizada o creada 
  newTask:any={
    id:null,
    device_id:null,
    device_name:"",
    device_imei:"",
    device_plate_number:"",
    is_new_device_id:false,//indica cuando se ha seleccionado un dispositivo para la tarea
    title:"",
    description:"",
    expiration_date:"",
    created_at:"",
    notify:false,
    completed:0,
    employee_id:null,
    start_date:"",
    all_day:false,
    user_id:null,
    comments:[],//lista de comentarios existentes
    comment:"",//comentario que se va a realizar,
    reminder:false,//indica si se le agrega recordatorio,
    step_to_reminder_time:0,
    max_reminder_time:'',
    reminder_time_unit:"",
    reminder_time:0,
    reminder_sent:0,
    text_helper_reminder:""//almacena el mensaje que se muestra en el input de recordatorio cuando se coloca un valor incorrecto
  };
  taskStatus={completed:1,in_progress:0,expired:-1};
  reminderTimeUnitList:any=[{name:"minutes",label:"Minutos"},{name:"hours",label:"Horas"},{name:"days",label:"Días"}];
  newTaskEmptyCopy:any=this.utils.copyObject(this.newTask);
  //para el input select de lista de dispositivos
  deviceImeiToSearchInSelect:string="";
  
  //para el input select de lista de empleados
  employeeToSearchInSelect:string="";
  moduleId:number=8;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    private changeDetector: ChangeDetectorRef,//fullcalendar
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
    public matDialog: MatDialog,
    private router:Router
  ){}
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.user= this.dataService.getData("user");
      this.getList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });
  }
  getList(){
    this.showLoading(true);
    this.getTasks();
    this.getImeiList();
    this.getEmployees();
  }
  openAddComment(task:any){
    const dialogRef = this.matDialog.open(CommentsComponent,{data:{task:task}});
    dialogRef.afterClosed().subscribe(updateTasks => {
      //console.log(`Dialog result: ${updateTasks}`);
      if(updateTasks){
        this.getTasks();
      }
    });
  }
  //obtiene la lista de tareas,
  getTasks(page:any=this.currentPage,updateCommentsToNewTask:boolean=false){
    let data= "?action=getTasks&user_api_hash="+this.user.hash+"&page="+page+"&search_imei="+this.searchImei;
    data +="&completed="+this.filters.join(",");
    this.showLoading(true,"modalView");
    this.api.getData("task",data,true).then((tasksData:any)=>{
      if(tasksData.status==1){
        this.tasksList=tasksData.data;
        //par agregar los eventos al calendario
        this.calendarOptions.events=[];
        
        for(let task of this.tasksList){
          
          task.all_day=task.all_day==1?true:false;
          let taskToCalendar:any={};
          taskToCalendar["id"]=task.id;
          taskToCalendar["title"]=task.title;
          if(task.all_day){
            taskToCalendar["date"]=this.utils.getDate(task.expiration_date);
          }else{
            taskToCalendar["start"]=task.start_date;
            taskToCalendar["end"]=task.expiration_date;
          }
          taskToCalendar["classNames"]="animate__animated animate__fadeIn";
          
          taskToCalendar["textColor"]="white";
          taskToCalendar["borderColor"]=task.completed==this.taskStatus.in_progress?"#F96E46":(task.completed==this.taskStatus.expired?"#000":"#44aa44");
          taskToCalendar["backgroundColor"]=task.completed==this.taskStatus.in_progress?"#F96E46":(task.completed==this.taskStatus.expired?"#000":"#44aa44");
          
          this.calendarOptions.events.push(taskToCalendar);
        }
        //fin para agregar los eventos al calendario
        if(updateCommentsToNewTask){//cuando se sta en la vista de actualizar tarea, para qeu se pueda actualizar los comentarios que alli aparecen
          let newTask =this.tasksList.find((item:any)=>item.id==this.newTask.id);
          this.newTask.comments=newTask.comments;
          this.newTask.comment="";
        }
        this.lengthPage =tasksData.total;
        this.currentPage=page;
        this.tasksListSorted=tasksData.data.slice();    
      }else{
        this.tasksListSorted=[];
        this.tasksList=[];
        this.calendarOptions.events=[];
      }
      this.showLoading(false,"modalView");
    }).catch((error:any)=>{console.log("error getTasks, ",error);this.utils.showMsg("Error","Se ha presentado un error mientras se intentaba obtener las tareas. <small>Contacte al administrador</small>");});
  }
  getImeiList(){
    let data= "?action=getUserImeiDevices&user_api_hash="+this.user.hash;    
    this.api.getData("device",data,true).then((devicesData:any)=>{
      //console.log("devices imeis ",devicesData);
      if(devicesData.status==1){
        this.imeiList=devicesData.data;
        this.imeiListFilter=this.imeiList.slice();
      }else{
        this.imeiList=[];
        this.imeiListFilter=[];
      }
    }).catch((error:any)=>{console.log("error getimeis, ",error);});
  }
  getEmployees(){
    let data= "?action=getEmployees&user_api_hash="+this.user.hash;
    
    this.api.getData("user",data,true).then((employeesData:any)=>{
      //console.log("employees ",employeesData);
      if(employeesData.status==1){
        this.employeesList=employeesData.data;
        this.employeesListFilter=this.employeesList.slice();
      }else{
        this.employeesList=[];
        this.employeesListFilter=[];
      }
    }).catch((error:any)=>{console.log("error getemployees, ",error);});
  }
  getEmployeeName(employeeId:number){
    let response="";
    if(typeof employeeId !="undefined" && employeeId !==null && typeof this.employeesList !=="undefined" && this.employeesList.length>0){
      let item =this.employeesList.find((item:any)=>item.id ==employeeId);
      if(typeof item !="undefined")
        response= item["name"];
    }
    return response;
  }
  getDeviceValue(deviceId:number,value:string){
    
    let response="";
    if(typeof deviceId !="undefined" && typeof this.imeiList !="undefined" && this.imeiList.length>0){
      let item = this.imeiList.find((item:any)=>item.id==deviceId);
      if(typeof item !="undefined")
        response=item[value];
    }
    return response;
  }
  validateFields(newTask:any){
    let error="";
    //espera los campos user_id,employee_id,completed,device_id,notify,
    //expiration_date,description,titlestart_date,all_day,
    newTask.description=newTask.description.trim();
    newTask.title=newTask.title.trim().toLowerCase();
    if(newTask.title==""){
      error="Falta asignarle un titulo a la tarea";
    }else if(newTask.description==""){
      error="Es necesario especificar una descripción";
    }
    else if(newTask.all_day==false && (newTask.expiration_date=="" || newTask.start_date=="")){
      error="Debe definir una fecha de inicio y fecha final";
    }
    else if(newTask.all_day==false && new Date(newTask.expiration_date).getTime()<new Date(newTask.start_date).getTime()){
      error="La fecha de vencimiento no puede ser menor que la fecha de inicio";
    }
    else if(newTask.expiration_date==""){
      error="Se necesita especificar una fecha de finalización";
    }
    else if(newTask.employee_id=="" || newTask.employee_id==null){
      error="seleccione al encargado de realizar la tarea";
    }
    else if(newTask.reminder){
      if(this.utils.isNullOrEmpty(newTask.reminder_time_unit))
        error="Debe de asignar una unidad de tiempo en la definición del recordatorio";
      else if(this.utils.isNullOrEmpty(newTask.reminder_time) || newTask.reminder_time<=0)
        error="Debe de asignar una cantidad de tiempo mayor a cero en la definición del recordatorio";
      else if(newTask.reminder_time_unit!=""){
        if(newTask.reminder_time_unit=="minutes"){
          if(newTask.reminder_time%10 != 0)
            error = "Se deben indicar minutos multiplos de 10";
        }else{
          if(newTask.reminder_time%1 != 0)
          error = "Se debe indicar una cantidad entera de "+(newTask.reminder_time_unit=="hours"?"horas":"días");
        }
      }
    }
    if(error==""){
      this.newTask.expiration_date=this.utils.getUTCDatetime(new Date(newTask.expiration_date));
      if(!newTask.all_day){
        this.newTask.start_date=this.utils.getUTCDatetime(new Date(newTask.start_date));
      }
    }
    return error;
  }
  adminTask(isUpdate:boolean){
    let error=this.validateFields(this.newTask);
    if(error !=""){
      this.utils.showMsg("Información",error);
      return;
    }
    this.utils.showMakingRequest((isUpdate?"Actualizando":"Creando")+" tarea...","task");
    this.loading = true;
    this.newTask["user_api_hash"]=this.user.hash;
    this.newTask["action"]=isUpdate?"update":"add";
    this.newTask.reminder_sent=isUpdate?0:parseInt(this.newTask.reminder_sent);
    if(typeof this.newTask.is_new_device_id !="undefined" && (this.newTask.is_new_device_id) || (this.utils.isNullOrEmpty(this.newTask.device_imei) &&this.newTask.device_id!=null)){
      let device = this.imeiList.find((device:any)=>device.id==this.newTask.device_id);
      this.newTask.device_name = device.name;
      this.newTask.device_imei = device.imei;
      this.newTask.device_plate_number = device.plate_number;
    }
    this.api.createData(this.newTask, "task",true).then((data: any) => {
      if(data.status==1){
        this.getList();
        this.closeTaskModal();
      }
      let message = typeof data.message!="undefined"?data.message:"";
      message += (typeof data.notify !="undefined"?("<small>"+data.notify.message+"</small>"):"");
      this.utils.showResultTask(isUpdate,data.status,message);
      this.utils.hideLoading((response:boolean)=>this.loading=response);

    }).catch((err: any) => {
      this.utils.hideLoading((response:boolean)=>this.loading=response);
      this.utils.showResultTask(isUpdate,false,"Se ha presentado un error al "+(isUpdate?("actualizar"):("registrar"))+" la tarea");
    });
  
  }
  changeTaskStatus($event:any,task:any){
    let taskData={
      id:task.id,
      completed:$event.target.value,
      action:'changeStatus',
      user_api_hash:this.user.hash
    };
    this.api.createData(taskData, "task",true).then((data: any) => {
      if(data.status==1){
        this.utils.showResultTask(true,true,"Cambio realizado");
        this.getTasks();
        this.closeTaskModal();
      } else {
        this.utils.showResultTask(true,false,"No se pudo cambiar el estado");

      }
    }).catch((err: any) => {
      //console.log(err);
      this.utils.showResultTask(true,false,"Se ha presentado un error al registrar el cambio");
    });
  }
  sortData(sort: Sort) {
    let data:any=this.tasksList.slice();
    if (!sort.active || sort.direction === '') {
      this.tasksListSorted = data;
      return;
    }

    this.tasksListSorted = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'plate_number':
            return this.compare(this.getDeviceValue(a.plate_number,"plate_number"), this.getDeviceValue(b.plate_number,"plate_number"), isAsc);
        case 'imei':
          return this.compare(this.getDeviceValue(a.imei,"imei"),this.getDeviceValue(a.imei,"imei"), isAsc);
        case 'expiration_date':
          return this.compare(new Date(a.expiration_date).getTime(), new Date(b.expiration_date).getTime(), isAsc);
        case 'title':
          return this.compare(a.title.toLowerCase(), b.title.toLowerCase(), isAsc);
        case 'completed':
          return this.compare(a.completed, b.completed, isAsc);
        case 'employee':
          return this.compare(this.getEmployeeName(a.employee_id).toLowerCase(), this.getEmployeeName(a.employee_id).toLowerCase(), isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  assignEmployee(event:any){
    this.newTask.employee_id=event.target.value;
  }
  //metodo usado en el calendario, para cuando se modifique las fechas del evento
  changeDateTask(dataCalendar?:any){
    // eldatacalendar devuelve un objeto con dos propiedades llamadas:
    //eventy oldevetn. el primero tiene las nuevas fechas a agregar
    let task= this.tasksList.find((item:any)=>item.id==dataCalendar.event._def.publicId);          
    this.newTask=task;
  }
  openCreateTaskModal(dataCalendar?:any):void{
    this.newTask=  this.utils.copyObject(this.newTaskEmptyCopy);
    if(typeof dataCalendar !="undefined"){
      this.eventDataCalendar=dataCalendar;
      //console.log("data calendar",dataCalendar);
      if(dataCalendar.allDay==true){
        if(typeof dataCalendar.end !="undefined"){
          this.newTask["start_date"]=dataCalendar.startStr+" 00:00:00";
          //dado que si selecciono un rango de fecha de dia 5 al 7 por ejemplo, este me devuelve como dia final el 8. le resto un milisegundo para que quede con la fecha del dia anterior
          let endDate=new Date(new  Date(dataCalendar.endStr).getTime()-(1));
          this.newTask["expiration_date"]=endDate.getFullYear()+"-"+this.utils.evaluateDateNumber((endDate.getMonth()+1))+"-"+this.utils.evaluateDateNumber(endDate.getDate())+" 23:59:59";
          this.newTask["all_day"]=false;
        }
        else{
          this.newTask["expiration_date"]=this.utils.getDate(dataCalendar.dateStr)+" 23:59:59";
          this.newTask["all_day"]=true;
        }
      }else{
        this.newTask.all_day=false;
        if(typeof dataCalendar.end !="undefined"){
          this.newTask["expiration_date"]=this.utils.getUTCDatetime(dataCalendar.end);
          this.newTask["start_date"]=this.utils.getUTCDatetime(dataCalendar.start);
        }else{
          this.newTask["start_date"]=this.utils.getUTCDatetime(dataCalendar.date);
          let time = new Date(dataCalendar.date).getTime()+1800000;
          this.newTask["expiration_date"]=this.utils.getUTCDatetime(new Date(time));
        }
      }
    }
    this.taskModal=true;
    this.isUpdateTask=false;
  }
  openUpdateTaskModal(task:any){
    this.newTask=  this.newTaskEmptyCopy;
    //identifico el ebjeto calendar usando la propiedad que esta trae. jsEvent
    if(typeof task.jsEvent !="undefined")//una vez obtenga los datos usando datelacendar, este lo paso a ña variable task que es argumento de esta
      task= this.tasksList.find((item:any)=>item.id==task.event._def.publicId);      
    if(typeof task !="undefined"){
      task["notify"]=task["notify"]==0?false:true;
      task["all_day"]=task["all_day"]==0?false:true;
    }
    if(typeof task !="undefined"){
      this.newTask=this.utils.copyObject(task);
      this.newTask.reminder= (!this.utils.isNullOrEmpty(this.newTask.reminder_time)  && !this.utils.isNullOrEmpty(this.newTask.reminder_time_unit));
      this.setStepForReminderTime(this.newTask.reminder_time_unit);
      this.newTask.comment="";
    }
    this.taskModal=true;
    this.isUpdateTask=true;
  }
  openEmployeesAdminModal(){
    const dialogRef = this.matDialog.open(EmployeesComponent);
    dialogRef.afterClosed().subscribe(dataExport => {
        this.getEmployees();
    }); 
  }
  openSeeTaskModal(task:any){
    this.newTask=JSON.parse(JSON.stringify(task));
    this.isSeeTask=true;
    this.isUpdateTask=false;
    this.taskModal=true;
  }
  isTaskStatusChecked(status:number){
    return this.filters.some((item:any)=>item==status);
  }
  changeCompleteTaskInclusion(event:any){
    (event.checked)?this.filters.push(parseInt(event.source.value)):this.filters.splice(this.filters.findIndex((item:number)=>item==parseInt(event.source.value)),1);
    this.getTasks();
  }
  deleteTask(taskId:number){
    this.utils.showConfirm("Confirmar Acción","¿Esta seguro de eliminar la tarea?","Eliminar","Cancelar").then(()=>{
      let data={
        user_api_hash:this.user.hash,
        action:"delete",
        id:taskId
      };
      this.api.createData(data, "task",true).then((data: any) => {
        //console.log("respuesta ",data);
        if(data.status==1){
          this.utils.showMsg("","tarea eliminada");
          this.getList();  
        } else {
          this.utils.showMsg("","No se pudo eliminar la tarea");
        }
      }).catch((err: any) => {
        //console.log(err);
        this.utils.showMsg("","Se ha presentado un error al eliminar la tarea");
      });
    });
  }
addComment(){
  if(this.newTask.comment ==""){
    this.utils.showMsg("Campo vacio","Por favor, ingrese un comentario para la tarea");
  }
  let data={
    task_id:this.newTask.id,
    description:this.newTask.comment,
    user_api_hash:this.user.hash,
    action:"addComment",
  };
  this.utils.showMakingRequest("Registrando comentario","comment");
  //console.log("datos a enviar",data);
  this.api.createData(data, "task",true).then((data: any) => {
    //console.log("respuesta ",data);
    if(data.status==1){
      this.newTask.comment="";
      this.utils.showResultTask(true,true,"Se ha agregado el comentario");
      this.getTasks(this.currentPage,true);
    } else {
      this.utils.showResultTask(true,true,"No se pudo agregar el comentario");
    }
  }).catch((err: any) => {
    //console.log(err);
    this.utils.showResultTask(true,true,"Se ha presentado un error al guardar el comentario");
  });
}
  exportFile(){
   this.utils.showConfirm("¿Está seguro de exportar los datos?", "Los datos pueden tomar un tiempo en procesarse", "Continuar", "Cancelar").then(() => {
    let dataToExport:any=JSON.parse(JSON.stringify(this.tasksList));
   for(let task of dataToExport){
    task["imei"]=this.getDeviceValue(task.device_id,'imei');
    task["plate_number"]=this.getDeviceValue(task.device_id,'plate_number');
    task["employee"]=this.getEmployeeName(task.employee_id);  
    task["completed"]=task["completed"]==1?"Finalizada":"En proceso";  
   }
    setTimeout(() => {
      this.utils.downloadFileAsCSV(dataToExport,  "tasks_" + new Date().getTime().toString(), this.headers);
      this.utils.showMsg("","Datos exportados con éxito");
    }, 1500);
  });
  }
  clearSearchDeviceImei(){
    this.deviceImeiToSearchInSelect = "";
    this.searchDeviceImei();
  }
  searchDeviceImei(){
    //console.log("buscando");
    this.deviceImeiToSearchInSelect = this.deviceImeiToSearchInSelect.toLowerCase();
    if(this.deviceImeiToSearchInSelect.length > 0){
        this.imeiListFilter = this.imeiList.filter((device: any) => {
          return device.imei.includes(this.deviceImeiToSearchInSelect) || device.plate_number.toLowerCase().includes(this.deviceImeiToSearchInSelect);
        });
    } else {
        this.imeiListFilter=this.utils.copyObject(this.imeiList);      
    }
  }
  setStepForReminderTime(value:any){
    this.newTask.step_to_reminder_time=(value=="minutes"?10:1);
    this.newTask.max_reminder_time = (value=="minutes"?40320:(value=="hours"?672:28));
  }
  toggleinputReminder(event:any){
    if(!event.checked){
      this.newTask.reminder_time=null;
      this.newTask.reminder_time_unit=null;
    }
  }
  clearSearchEmployee(){
    this.employeeToSearchInSelect = "";
    this.searchEmployee();  
  }
  searchEmployee(){
    this.employeeToSearchInSelect = this.employeeToSearchInSelect.toLowerCase();
    if(this.employeeToSearchInSelect.length > 0){
        this.employeesListFilter = this.employeesList.filter((employee: any) => {
          return employee.name.toLowerCase().includes(this.employeeToSearchInSelect);
        });
    } else
        this.employeesListFilter=this.utils.copyObject(this.employeesList);      
  }
  printEmployeeSelected(){
    let texto=""; 
    if(typeof this.newTask.employee_id !=="undefined" && this.newTask.employee_id !==null && typeof this.employeesList !=="undefined" && this.employeesList.length>0){
      let item =this.employeesList.find((item:any)=>item.id==this.newTask.employee_id);
      texto= item.name;
    }
    return texto;
  }
  seeViewCalendar(){this.taskView="calendar";}
  seeViewTable(){this.taskView='table';}
  
  closeTaskModal(){
    this.taskModal=false;
    this.isUpdateTask=false;
    this.isSeeTask=false;
    this.newTask=  this.utils.copyObject(this.newTaskEmptyCopy);
  }
  showLoading(show: boolean = true,nameLoading:any="general"):void{
    if(show) this.loadings[nameLoading] = true;
    else setTimeout(() => { this.loadings[nameLoading] = false; }, 500);
  }
  showMakingTask(title:string,icon:string){
    let message:any={
      title:  title,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showConfirmButton: false
    };
    if(icon=="download"){
      message["html"]='<i class="fa-solid fa-download fa-2x fa-beat-fade"></i>';
    }else if(icon=="task"){
      message["html"]='<i class="fa-solid fa-calendar-lines-pen fa-2x fa-beat-fade"></i>';
    }
    Swal.fire(message);
  }
}