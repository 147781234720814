import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Input, EventEmitter, Output, Renderer2, SecurityContext } from '@angular/core';
import * as ace from "ace-builds";
import { IBasicWebTemplateData, IMetadata } from '../../models/interfaces/iweb-template.model';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { MessageBoxService } from 'src/app/core/services/message-box/message-box.service';
import { DataService } from '../../../../core/services/data/data.service';

@Component({
  selector: 'app-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.scss']
})
export class BasicDataComponent  implements OnInit,AfterViewInit{

    //para editores de codigo Css y Js
    @ViewChild("editorCss") private editorCss!: ElementRef<HTMLElement>;
    @ViewChild("editorJs") private editorJs!: ElementRef<HTMLElement>;
  
    @Input() isUpdate:boolean =false;
    @Input() basicData!:IBasicWebTemplateData;
    @Input() userWebAddress:SafeResourceUrl ="";
    @Input() userWebAddressStr:string ="";

    @Output() updateData = new EventEmitter<IBasicWebTemplateData>;
    aceEditor:any={
      config:{
        theme:'ace/theme/monokai',
        mode:{
          css:'ace/mode/css',
          js:'ace/mode/javascript'
        }
      },
      js:{},
      css:{}
    };
    user:any={};
  constructor(
    private renderer: Renderer2,
    public utils: UtilsService,
    public validationService:ValidationService,
    private messageBox: MessageBoxService,
    public sanitizer:DomSanitizer,
    private dataService:DataService,
  ){}
  ngOnInit(): void {
    this.user = this.dataService.getData("user");
    setTimeout(() => {
      this.basicData.metadata.author = this.basicData.metadata.title = this.basicData.metadata.copyright = this.user.name ?? "";
      this.basicData.metadata.robots = 'robots,follow';

      this.aceEditor.css.setValue(this.basicData.custom_css);
      this.aceEditor.js.setValue(this.basicData.custom_js);
    }, 500);
  }
  ngAfterViewInit(): void {
    ace.config.set("fontSize", "16px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    this.aceEditor.js = ace.edit(this.editorJs.nativeElement);
    this.aceEditor.js.session.setValue(this.basicData.custom_js);
    this.aceEditor.js.setTheme(this.aceEditor.config.theme);
    this.aceEditor.js.session.setMode(this.aceEditor.config.mode.js);

    this.aceEditor.css = ace.edit(this.editorCss.nativeElement);
    this.aceEditor.css.session.setValue(this.basicData.custom_css);
    this.aceEditor.css.setTheme(this.aceEditor.config.theme);
    this.aceEditor.css.session.setMode(this.aceEditor.config.mode.css);
  }
  update(){
    this.basicData.custom_js = this.aceEditor.js.getValue();
    this.basicData.custom_css = this.aceEditor.css.getValue();
    if(this.basicData.contact_us.map_embed_html !="" && !this.validationService.isGoogleMapsHtmlValid(this.basicData.contact_us.map_embed_html)){
      return this.utils.showResultRequest("error","","El codigo HTML del mapa a incrustar no es válido. Debe tener la forma &lt;iframe src=&quot;...&quot; ,,,&gt;&lt;/iframe&gt;");
    }
    this.basicData.contact_us.map_embed_html = this.utils.getGoogleMapsformatted(this.basicData.contact_us.map_embed_html);
    if(!this.validationService.isNullOrEmpty(this.basicData.url_identifier) && !this.validationService.isUrlIdentifierValid(this.basicData.url_identifier))
      return this.utils.showResultRequest("error","","El identificador URL personalizado para acceder al sitio web es inválido");
    this.updateData.emit(this.basicData);
  }
  copyClientUrl(){
    let clientUrl= this.renderer.selectRootElement("#clientUrl",true);
    let value = clientUrl.textContent;
    this.utils.copyToClipboard(value).then(() => this.messageBox.openSnackBar("Enlace copiado","Aceptar")).catch((error: any) => {});
  }
}