import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';
import { environment } from 'src/environments/environment';
import { ISort } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { IApiRequestData } from '../../../shared/models/interfaces/iapi-request-data.model';
import { ValidationService } from '../validation/validation.service';

//type RequestMethod = "POST"|"GET"|"DELETE"|"PUT";
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http:HttpClient,
    private validationService: ValidationService
  ) { }
    readonly CONTENT_TYPE={
      FORM_DATA:"multipart/form-data",
      JSON:"application/json"
    };
    readonly RESPONSE_TYPE = {
      JSON:"json",
      BLOB:"blob",
      ARRAYBUFFER:"arraybuffer",
      TEXT:"text"
    };
    readonly urlBaseSgc: string = "https://sgc.internationalgpsonline.com/";
    readonly urlBaseIntgps :string = 'https://internationalgpsonline.com/'; 
    //para compilr en modo dev ng build --configuration=development
    apiRouteSGC=this.urlBaseSgc+"api/"+environment.routeApiSgc;
    apiRoute = this.urlBaseIntgps;
    
    readonly errorWarning ="intente nuevamente. En caso de persistir el error contacte al administrador";
    getDefaultMessage(name:string,isUpdate:boolean,success:boolean,method:"GET"|"POST"|"DELETE"):string{
      let methods ={get:"GET",post:"POST",delete:"DELETE"};
      if(method.toUpperCase()==methods.get)
        return success?("Datos "+(name!=""?"de "+name:"")+" obtenidos de forma exitosa"):("No se pudo obtener datos "+(name!=""?"de "+name:"")+"<br><small>intente nuevamente. En caso de persistir el error contacte al administrador</small>");
      if(method.toUpperCase()==methods.post)
        return success?((isUpdate?"Actualización exitosa ":"Registro exitoso ")+(name!=""?("de "+name):"")):("Ha surgido un error mientras "+(isUpdate?"se actualizaba ":"se creaba ")+(name!=""?name:"")+"<br><small>intente nuevamente. En caso de persistir el error contacte al administrador</small>");
      if(method.toUpperCase()==methods.delete)
        return success?(("Eliminación exitosa ")):("Ha surgido un error mientras eliminaba "+(name!=""?name:"el registro")+"<br><small>intente nuevamente. En caso de persistir el error contacte al administrador</small>");
      
      return "Ha surgido un error inesperado. <br><small>"+this.errorWarning+"</small>";
    }

    /**
     * @description Retorna el conjunto de parametros estructurados para realizacion de consultas
     * @param {string} action El tipo de accion a reailzar   
     * @param {string} userApiHash Api hash del usuario a consultar
     * @param {string} searchValue Valor a buscar en el filtro de datos
     * @param {number} page la pagina objetivo a consultar
     * @param {number} pageSize la cantidad de datos a recuperar en una consulta
     * @param {ISort.by} sortBy el campo por el cual ordenar los datos
     * @param {ISort.direction} sortDirection la direcion de ordenamiento de datos
     * @param {boolean} getTotal Indica si solo se va a recuperar el total de datos que representa la consulta
     * @returns 
     */
    getCommonQueryParameters(action:string,userApiHash:string,searchValue?:string,page?:number,pageSize?:number,sortBy?:string,sortDirection?:string,getTotal?: boolean):string{
      let data= "?action="+action+"&user_api_hash="+userApiHash+(typeof page=="undefined" || getTotal?"":("&page="+page)+(typeof pageSize =="undefined" || getTotal?"":("&page_size="+pageSize)));
      if(typeof sortBy!="undefined" && sortBy!="")
        data +="&order[by]="+sortBy+"&order[direction]="+(sortDirection??"asc");
      if( typeof searchValue!="undefined" && searchValue !="")
        data +="&search_value="+searchValue;
      if(getTotal)
        data +="&get_total="+getTotal;
      return data;
    }
    getData(url: string, data: any = "",isApiSGC:boolean=false) {
      return new Promise( (resolve, reject) => {
        //inicio para api SGC
        let apiRoute=this.apiRoute;
        if(isApiSGC){
          apiRoute=this.apiRouteSGC;
        }//fin lineas para api SGC
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        headers.set('Access-Control-Allow-Origin', '*');
        const requestOptions = { headers: headers };  
        return this.http.get(apiRoute + url + data, requestOptions)
          .subscribe({
            next:  ( result: any) => {
              resolve(result);
            }, 
            error: ( err: any ) =>{
              reject(err);
            }
          });
      })
    }
  
    //isApiSGC, para indicar si se usa la ruta a la API que se ha desarrollado para
    //manipular la base de datos SGC
     getDataPost(url: string, data: any,isApiSGC:boolean=false,contentType:string="application/json") {
      return new Promise( (resolve, reject) => {
        //inicio para api SGC
        let apiRoute=this.apiRoute;
        if(isApiSGC){
          apiRoute=this.apiRouteSGC;
        }//fin lineas para api SGC
        let headers = new HttpHeaders();

        headers.set('Content-Type', contentType);
        headers.set('Access-Control-Allow-Origin', '*');
        const requestOptions = { headers: headers };  
        return this.http.post(apiRoute + url, data, requestOptions )
          .subscribe({
            next:  ( result: any) => {
              resolve(result);
            }, 
            error: ( err: any ) =>{
              reject(err);
            }
          });
      })
    }
  
    createData(data: any, url: string,isApiSGC:boolean=false,contentType:string="application/json") {
      return new Promise( (resolve, reject) => {
        //inicio para api SGC
        let apiRoute=this.apiRoute;
        if(isApiSGC){
          apiRoute=this.apiRouteSGC;
        }//fin lineas para api SGC
        let headers = new HttpHeaders();
        headers.set('Content-Type', contentType);
        headers.set('Access-Control-Allow-Origin', '*');
        const requestOptions = { headers: headers };  
        return this.http.post(apiRoute + url, data, requestOptions)
          .subscribe({
            next:  ( result: any) => {
              resolve(result);
            }, 
            error: ( err: any ) =>{
              reject(err);
            }
          });
      })
    }
    deleteData(url:string, data:any, isApiSGC:boolean=false, contentType:string="application/json"){
      return new Promise( (resolve, reject) => {
        //inicio para api SGC
        let apiRoute=this.apiRoute;
        if(isApiSGC){
          apiRoute=this.apiRouteSGC;
        }//fin lineas para api SGC
        let headers = new HttpHeaders();
        headers.set('Content-Type', contentType);
        headers.set('Access-Control-Allow-Origin', '*');
        const requestOptions = { headers: headers };  
        return this.http.get(apiRoute+url+data, requestOptions)
          .subscribe({
            next:  ( result: any) => {
              resolve(result);
            }, 
            error: ( err: any ) =>{
              reject(err);
            }
          });
      })
    }

    updateDataPut(data: any, url: string,isApiSGC:boolean=false,contentType:string="application/json") {
      return new Promise( (resolve, reject) => {
        //inicio para api SGC
        let apiRoute=this.apiRoute;
        if(isApiSGC){
          apiRoute=this.apiRouteSGC;
        }//fin lineas para api SGC
        let headers = new HttpHeaders();
        headers.set('Content-Type', contentType);
        headers.set('Access-Control-Allow-Origin', '*');
        const requestOptions = { headers: headers };  
        return this.http.put(apiRoute + url, data, requestOptions)
          .subscribe({
            next:  ( result: any) => {
              resolve(result);
            }, 
            error: ( err: any ) =>{
              reject(err);
            }
          });
      })
    }

    structureParametersForConsult(method:RequestMethodHTTP,action:string,userApiHash:string,searchValue:string = "",page?:number,pageSize?:number,sort?:ISort,dateRange?:{start:string,end: string},columnsToGet?: string[]):string | IApiRequestData{
      let data:string;
      if(method == RequestMethodHTTP.GET){
        data = "?action="+action+"&user_api_hash="+userApiHash+(typeof page == "undefined" ?"":("&page="+page)+(typeof pageSize == "undefined" ?"":("&page_size="+pageSize)));
        if(typeof sort !== "undefined" && !this.validationService.isNullOrEmpty(sort.direction) && !this.validationService.isNullOrEmpty(sort.by))
          data +="&order[by]="+sort.by+"&order[direction]="+sort.direction;
        if(searchValue !="")
          data +="&search_value="+searchValue;      
        if(typeof dateRange != "undefined")
          data += "&start_date=" + dateRange.start + "&end_date=" + dateRange.end;
        if(Array.isArray(columnsToGet)){
          for(let field of columnsToGet){
            data +="&columns_to_get[]="+field;
          }
        }

        return data as string;
      }
      else{
        let data: IApiRequestData = {
          user_api_hash: userApiHash, action :action,sort:{direction:null,by:null},search_value:null,order:{by:null,direction:null},page:null, page_size : null};
        if(typeof sort !="undefined"){
          data.sort!.direction = data.order!.direction = sort.direction;
          data.sort!.by = data.order!.by = sort.by;
        }
        if(searchValue != "")
          data.search_value = searchValue;
        if(typeof page != "undefined")
          data.page = page;
        if(typeof pageSize != "undefined" && pageSize != Infinity)
          data.page_size = pageSize;
        if(typeof columnsToGet != "undefined" && Array.isArray(columnsToGet))
          data.columns_to_get = columnsToGet;
        return data as IApiRequestData;
      }
    }
    requestBlobData(formData: FormData,url:string) {
      return new Promise( (resolve, reject) => {
        this.http.post(this.apiRouteSGC+url, formData, { responseType: 'blob' }).subscribe(
          {
            next: (response: Blob) => {
              resolve(response);
            },
            error: (error: any) => {
              reject(false);
            }
          }
        );
      })
    }

}
