import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { ColorManipulationService } from '../../../../core/services/color-manipulation/color-manipulation.service';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { IChartConfigData } from 'src/app/shared/models/interfaces/iutil.model';
import { ChartTypeChartjs } from 'src/app/shared/enums/common-enums.enum';

@Component({
  selector: 'app-circular-chart',
  templateUrl: './circular-chart.component.html',
  styleUrls: ['./circular-chart.component.scss']
})
export class CircularChartComponent implements OnInit{

  @Input() circularChartConfigData:IChartConfigData = {
    title:"",chart_type:ChartTypeChartjs.DOUGHTNUT,data:{labels:["Sin datos"],datasets:[{data:[0],label:"",backgroundColor:this.colorManipulationService.DEFAULT_COLORS}]},updated_chart:true
  };

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { 
        display: true,
        //position: 'right', //  ajusta la posición de la leyenda 
        align: 'start', // Alinea las etiquetas al principio de la leyenda (usar 'center' o 'end' tambien)
       },
      datalabels: {
        anchor: 'center',
        align: 'center',
        backgroundColor:"#fff",
        borderRadius:2, 
        formatter: (value:any) => {
          return value==0 ?null:(value + '%');
        }
      },
    }
  };
  pieChartType!: ChartType;
  pieChartPlugins = [DataLabelsPlugin, this.utils.adjustLegendMarginChartjs()];
  constructor(
    private colorManipulationService:ColorManipulationService,
    private utils: UtilsService
  ){
    this.pieChartType = this.circularChartConfigData.chart_type;
  }
  ngOnInit(): void {
    this.pieChartType = this.circularChartConfigData.chart_type;
  
  }
}