import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { MessageBoxService } from 'src/app/core/services/message-box/message-box.service';
import { ObjectInitializationService } from 'src/app/core/services/object-initialization/object-initialization.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { AutomationTimeUnit, IAutomation, IAutomationType } from 'src/app/core/models/interfaces/iautomation.model';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { ApiEndpointSgc } from 'src/app/core/enums/api-endpoint-sgc.enum';
import { IResponseApi } from 'src/app/core/models/interfaces/iresponse-api.model';
import { ITimeUnit, ITimeUnitItem } from 'src/app/shared/models/interfaces/iutil.model';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { RequestMethodHTTP } from 'src/app/shared/enums/common-enums.enum';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IViewsAvailable } from 'src/app/shared/models/interfaces/views-available';
import { AUTOMATION_TYPE_LIST } from '../../automations.component';

type MaxFieldLength = Pick <IAutomation,"sms_template"| "name" | "time_quantity">;


type TimeUnitForAutomation = Pick<ITimeUnit,"day">;
@Component({
  selector: 'app-automation-form',
  templateUrl: './automation-form.component.html',
  styleUrls: ['./automation-form.component.scss']
})
export class AutomationFormComponent implements OnInit {

    //almacena la lista de vistas disponibles en el componente.
    viewsAvailable: IViewsAvailable = {
      automation_target_user: { name: "automation_target_user", show_view: false, index_in_tab: 1 },
      automation_form: { name: "automation_form", show_view: true }
    };
  
  user:IUserSessionData;
  loading: boolean = false;
  @Output() close = new EventEmitter<{updated:boolean, automation_result: IAutomation}>();
  /**
   * @type {boolean} Indica si la automatizacion manipulada ha sido correctamente modificada y almacenada
   */
  successfulModification: boolean = false;

  @Input() selectedAutomation: IAutomation;
 PERIODIC_EXECUTION_VALUES: {PERIODIC: 1, NO_PERIODIC: 0} = {PERIODIC: 1, NO_PERIODIC: 0}; 
  
  readonly AUTOMATION_TYPE_LIST = AUTOMATION_TYPE_LIST;
  automationTypesImageNames: Record<AUTOMATION_TYPE_LIST, string> = {
    [AUTOMATION_TYPE_LIST.BILLING] : "moneybag.svg",
    [AUTOMATION_TYPE_LIST.AFTER_SALE] : "marketing-campaign.svg",
    [AUTOMATION_TYPE_LIST.PORTFOLIO] : "money-transfer-two-panel.svg"
  }
  /**
   * @type {IAutomationType} Almacena la lista de tipos de automatizaciones disponibles
   */
  automationTypeList: IAutomationType[] = [];

  automationTypeForm = this._formBuilder.group({
    selectedAutomationType: ['', Validators.required],
    name: ['', Validators.required],
  });
  timeSettingForm: FormGroup;
  messagetTemplateForm = this._formBuilder.group({
    messageTemplate: ['', Validators.required],
  });

  /**
   * @type { string[]} Los marcadors de posicion usados para hacer referencia a los datos de usuario. Usado en la definicion de la plantila web
   */
  messageTemplateUserPlaceholder: string[] = ["[user.name]","[user.email]","[user.phone_number]"];
  timeUnit: TimeUnitForAutomation;
  timeUnitArray: ITimeUnitItem[];

  maxFieldslength!: Record<keyof MaxFieldLength,number>;

  isEditable = false;
  constructor(
    private api: ApiService,
    public dataService: DataService,
    public utils:UtilsService,
    public messageBox: MessageBoxService,
    private objectInitializationService: ObjectInitializationService,
    public validationService: ValidationService,
    private route:Router,
    private _formBuilder: FormBuilder
  ){
    this.maxFieldslength = {name:120, sms_template: 65535, time_quantity: 65535};

    this.user = this.dataService.getData("user") as IUserSessionData;
    this.selectedAutomation = this.objectInitializationService.initializeIAutomation(0,undefined,this.user.id, 0);
  
    let timeUnit: ITimeUnit = this.objectInitializationService.initializeITimeUnit();
    this.timeUnit = {day:timeUnit.day};
    this.timeUnitArray = Object.values(this.timeUnit);

    this.timeSettingForm = this._formBuilder.group({
      timeQuantity: ['', [Validators.required,Validators.min(1)]],
      timeUnit: [this.timeUnit.day.value, Validators.required],
      periodicExecution: [''],
    });
  }
  ngOnInit(): void {
    if(Number(this.selectedAutomation.id) > 0){
      
      this.automationTypeForm.controls.name.setValue(this.selectedAutomation.name);
      this.automationTypeForm.controls.selectedAutomationType.setValue(this.selectedAutomation.automation_type_id.toString());

      this.timeSettingForm.controls['timeQuantity'].setValue(this.selectedAutomation.time_quantity);
      this.timeSettingForm.controls['timeUnit'].setValue(this.selectedAutomation.time_unit);
      this.timeSettingForm.controls['periodicExecution'].setValue(Number(this.selectedAutomation.periodic_execution));
      
      this.messagetTemplateForm.controls.messageTemplate.setValue(this.selectedAutomation.sms_template);
    }
    this.getAutomationTypeList();
  }
  async getAutomationTypeList() : Promise<void>{
    this.loading = true;
    let responseData:IResponseApi = {status:0, data: []};
    try {
      let automationsType:IAutomationType[] = this.dataService.getData(this.dataService.UTILS_DATA_LIST.AUTOMATION_TYPE_LIST) as IAutomationType[];
      if(Object.entries(automationsType).length>0){
        responseData = {status:1, data: automationsType};
      }
      let response:unknown = await this.api.getData(ApiEndpointSgc.AUTOMATION_TYPE,"?action=get&user_api_hash=" + this.user.hash, true);  
      if(this.validationService.isResponseApi(response) && response.status == 1){
        responseData.data = response.status ==1 ? response.data as IAutomationType[] : [];
        responseData.status = response.status; 
        this.dataService.setData(this.dataService.UTILS_DATA_LIST.AUTOMATION_TYPE_LIST, automationsType);
      }
    } catch (error: unknown) {
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("tipos de automatizaciones",false,false,"GET"));
    }finally{
      if(responseData.status == 1){  
        let automationTypes: IAutomationType[] = responseData.data as IAutomationType [];
        let index: any;
        for(index in automationTypes ){
            automationTypes[index].illustrative_image_name = this.automationTypesImageNames[automationTypes[index].id as AUTOMATION_TYPE_LIST] ?? "";          
        }
      }
      this.automationTypeList = responseData.data!;
      this.utils.hideLoading( ()=> this.loading =  false );
    }
  }

  confirmAutomationEdit(){
    this.utils.showConfirm("Confirmar cambios", "Confirmar " + (this.selectedAutomation.id > 0 ? "actualización" : "registro") + "de la automatización", "Confirmar", "Cancelar").then(() => {
      this.loading = true;
      let errors:string[] = [];
      let dataToSend: IApiRequestData & IAutomation = {... this.selectedAutomation, ...{user_api_hash: this.user.hash, action: this.selectedAutomation.id > 0 ? 'update': 'add' } };
      if(this.automationTypeForm.valid && this.automationTypeValid(this.automationTypeForm.value["selectedAutomationType"])){
        let value = this.automationTypeForm.value["selectedAutomationType"];
          dataToSend.automation_type_id = Number(value);
      }else
        errors.push("tipo de automatización");
      if(this.automationTypeForm.valid && typeof this.automationTypeForm.value['name'] != "undefined" && this.automationTypeForm.value['name']!.trim().length > 0){
        dataToSend.name = this.automationTypeForm.value['name']!.trim();
      }else
        errors.push("nombre de la automatización");

      if(this.timeSettingForm.valid && this.automationTypeForm.value['selectedAutomationType'] != AUTOMATION_TYPE_LIST.BILLING.toString()){
        dataToSend.periodic_execution = this.timeSettingForm.value['periodicExecution'] == this.PERIODIC_EXECUTION_VALUES.PERIODIC ? 1 : 0; 
        dataToSend.time_quantity = this.timeSettingForm.value['timeQuantity']; 
        dataToSend.time_unit = this.timeSettingForm.value['timeUnit']; 
      }else if(this.automationTypeForm.value['selectedAutomationType'] != AUTOMATION_TYPE_LIST.BILLING.toString())
        errors.push("configuración de tiempo");
      if(this.messagetTemplateForm.valid){
        dataToSend.sms_template = this.messagetTemplateForm.value["messageTemplate"] ?? "";
      }else
        errors.push("plantilla de mensaje de texto");
      if(errors.length > 0){
        this.utils.hideLoading(()=>this.loading = false);
        return this.utils.showResultRequest( "error", "Información", "Debe de completar de forma válida las siguiente secciones: " + errors.join( ", "));
      }
      this.api.createData(dataToSend, ApiEndpointSgc.AUTOMATION, true).then((data: unknown) => {
        if(this.validationService.isResponseApi(data)){
          this.utils.showResultRequest(data.status == 1 ? "success" : "error", "Información", data.message);
          if(Number(data.status) == 1){
            this.successfulModification = true;
            if(dataToSend.action == "add"){
              this.selectedAutomation.id = data.data
            }
            let selectedAutomation = this.objectInitializationService.initializeIAutomation(this.selectedAutomation.id,  dataToSend.action == "update" ? this.selectedAutomation.active : 1, this.selectedAutomation.created_by, dataToSend.automation_type_id,  dataToSend.name, dataToSend.sms_template, dataToSend.time_quantity, dataToSend.time_unit, dataToSend.periodic_execution);
            Object.assign(this.selectedAutomation, selectedAutomation);
          }
        }
        console.log(data);
      }).catch((err: any) => {
        console.log(err);
        this.utils.showResultRequest("error", "Información", this.api.getDefaultMessage("los registros", true, false, RequestMethodHTTP.POST));
      }).finally(()=>this.utils.hideLoading(()=>this.loading = false));
    }).catch(()=>{this.utils.hideLoading(()=>this.loading = false);});
  }
  automationTypeValid(value: any): boolean{
    return value in AUTOMATION_TYPE_LIST;
  }
  copyMessageTemplatePlaceholder(placeholder: string){
    this.utils.copyToClipboard(placeholder).then(() => this.messageBox.openSnackBar("parametro copiado","",{duration: 2000})).catch((error: any) => {});
  }

  validateComponentToShow(event: MatTabChangeEvent) {
    if (event.index == this.viewsAvailable['automation_target_user'].index_in_tab)
      this.utils.setSelectedView(this.viewsAvailable, this.viewsAvailable['automation_target_user'].name);
  }
  closeComponent(){
    this.close.emit({updated: this.successfulModification, automation_result: this.selectedAutomation});
  }
}