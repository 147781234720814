import { CredentialType } from "../../../shared/enums/common-enums.enum"

export interface IMessagingServiceApi{
    id: number,
    name: string,
    active:  1 | 0,
    url: string | null,
    version: string | null,
    credential_type: CredentialType,
    created_at: string,
    updated_at: string | null    
}

export interface IUserMessagingServiceApiCredential{
    id: number,
    user_id: number,
    messaging_service_api_id: number,
    active: 1 | 0, 
    api_key:string | null,
    access_token: string | null,
    jwt_token:  string | null,
    secret_key: string | null,
    username: string | null,
    password: string | null,
    api_url: string | null,
    last_login: string | null,
    created_at: string,
    updated_at: string | null
}

export enum UserMessagingServicePhoneLineActivationStatus {
    PENDING="pending",
    ACTIVE = "active",
    INACTIVE="inactive"
};
export enum UserMessagingServicePhoneLineConnectionValues{
    CONNECTED=1 , DISCONNECTED=0
}
export interface IUserMessagingServiceApiPhoneLine{
    id: number,
    name: string,
    activation_status: UserMessagingServicePhoneLineActivationStatus,
    api_phone_number_identifier: string | null,
    phone_number: string,
    user_messaging_service_api_credential_id: number,
    max_message_quota: number,
    sent_message_count:number,
    available_message_count : number,
    connected: UserMessagingServicePhoneLineConnectionValues,
    created_at: string,
    updated_at: string | null
}

export interface IUserMessagingServiceApiPhoneLineQrCode{
    qr_url: string,
    /** La cantiadd de segundos que tiene validez el codigo QR  */
    qr_duration: number
} 

export interface IUserMessagingServiceApiPhoneLineSummaryByConnection{
    connected: UserMessagingServicePhoneLineConnectionValues,
    total:number
  }
 export interface IUserMessagingServiceApiPhoneLineSummaryByActivationStatus{
    activation_status: UserMessagingServicePhoneLineActivationStatus,
    total:number
  }