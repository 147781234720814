import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { IDeviceStatusOption, IDeviceStatusRecord } from 'src/app/core/models/interfaces/idevice-status-option.model';
import { DataService } from '../../../core/services/data/data.service';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ValidationService } from '../../../core/services/validation/validation.service';
import { Router } from '@angular/router';
import { ROUTES_TO_LOGIN } from 'src/app/core/services/auth/auth.service';
import { IDeviceBasicData } from 'src/app/core/models/interfaces/idevice.model';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { ObjectInitializationService } from '../../../core/services/object-initialization/object-initialization.service';
import { MatSelectChange } from '@angular/material/select';
import { IApiRequestData } from 'src/app/shared/models/interfaces/iapi-request-data.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DeviceStatusRecordSummaryPopoverService } from '../../../shared/components/device-status-record-summary-popover/device-status-record-summary-popover.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-driver-device-state-management',
  templateUrl: './driver-device-state-management.component.html',
  styleUrls: ['./driver-device-state-management.component.scss']
})
export class DriverDeviceStateManagementComponent implements OnInit {
  loading:boolean = false;

  user!:IUserSessionData;
  /** @type {IDeviceBasicData[]} Lista de dispositivos disponibles pora el usuario. Aquellos que han sido asignados a su lista de disponibles */
  availableDevicesList:IDeviceBasicData[] = [];
  availableDeviceListFiltered:IDeviceBasicData[] = [];

  /** @type {string} el valor a buscar dentro de la lista de dispositivos disponibles */
  dataToSearch:string = "";

  /** @type {NodeJS.Timeout} Almacena el timeout usado para filtro de busqueda de dispositivo a ocupar */
  timerIdSearchDevice!:NodeJS.Timeout;
  currentDrivenVehicle!:IDeviceBasicData;
  /** @type {IDeviceStatusOption[]} Lista de estados de dispositivos disponibles para el usuario */
  deviceStatusList:IDeviceStatusOption[] = [];

  /** @type {IDeviceStatusRecord} Almacena el ultimo registro de estado de dispositivo */
  lastDeviceStatusRecordSelected: IDeviceStatusRecord;

  moduleId=19;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  constructor(
    public utils: UtilsService,
    private dataService: DataService,
    private api: ApiService,
    public validationService: ValidationService,
    private router: Router,
    private objectInitializationService: ObjectInitializationService,
    private deviceStatusRecordSummaryPopoverService:DeviceStatusRecordSummaryPopoverService
    ){
      this.user = this.dataService.getData("user");
      this.lastDeviceStatusRecordSelected = this.objectInitializationService.initializeIDeviceStatusRecord(0,this.user.id,0);
      this.currentDrivenVehicle = this.objectInitializationService.initializeIDeviceBasicData();
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.getDeviceStatusConfigList();
      this.getAvailableDevicesBasicDataList();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate([ROUTES_TO_LOGIN.user_driver]);
    });
  }
  /**
   * Obtiene la lista de configuraciones de estados de dispositivo habilitadas para el usuario
   */
  getDeviceStatusConfigList(){
    let data:IApiRequestData = { user_api_hash:this.user.hash, action:"get" };
    this.loading = true;
    this.api.getDataPost("deviceStatusOption",data,true).then((data: any) => {  
      this.deviceStatusList = data.status == 1 ? data.data:[];
      this.utils.hideLoading(()=>this.loading=false);
    }).catch((error)=>{
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("la lista de estados de dispositivos",false,false,"GET"));
      this.utils.hideLoading(()=>this.loading=false);
    });
  }
  /**
   * @description Determina si el parametro asignado es de tipo IDeviceStatusRecord
   * @param {any} obj el objeto a evaluar si es de tipo IDeviceStatusRecord
   * @returns {boolean} 
   */
  isDeviceStatusRecord(obj:any): obj is IDeviceStatusRecord{
    return typeof obj?.id != "undefined" && typeof obj?.device_status_option_id != "undefined" && typeof obj?.device_trip_id != "undefined";
  }
  /**
   * @description Agrega un registro de estado de dispositivo
   */
  async addDeviceStatusRecords(event:MatSlideToggleChange,deviceStatusOption:IDeviceStatusOption){
    if(this.isLastRecordSelected(deviceStatusOption)){
      event.source.checked = true; 
      return;
    }
    try{
      try{
      let confirm:unknown = await this.utils.showConfirm("Confirmar selección de estado '"+this.getTitleDeviceStatusOption(deviceStatusOption)+"'", "" ,"Confirmar","Cancelar");
      if(typeof confirm !== "boolean"){
        event.source.checked = !event.source.checked; 
        return;
      }
      }catch(error:any){
        event.source.checked = !event.source.checked; 
        return;
      }
      this.loading = true;
      deviceStatusOption = this.utils.copyObject(deviceStatusOption);
      delete deviceStatusOption.device_status_option_configuration;
      let data:Partial<IDeviceStatusRecord> & IApiRequestData  = { user_api_hash:this.user.hash,action: "add",
      device_status_option_id:deviceStatusOption.id!,device_id:this.currentDrivenVehicle.id
      };
      let response:unknown = await this.api.createData(data, "deviceStatusRecord",true);
      if(this.validationService.isResponseApi(response)){
        this.utils.showResultRequest(response.status==1?"success":"error","Información",response.message);
          response.status != 1 ? (event.source.checked = !event.source.checked) : null;
          response.status == 1 ? this.getLastDeviceStatusRecord(): null;
      }
      }catch(error:any){
        event.source.checked = !event.source.checked; 
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage("el registro de estado",deviceStatusOption.id != 0,false,"POST"));
    }
    this.utils.hideLoading(()=>this.loading = false);
  }
  getDescriptionDeviceStatusOption(status: IDeviceStatusOption):string{
    return (status.user_id ==null ?(!this.validationService.isNullOrEmpty(status.device_status_option_configuration?.custom_description??"")?(status.device_status_option_configuration?.custom_description??''):(status.description??'')):(status.description??''));
  }
  getTitleDeviceStatusOption(status: IDeviceStatusOption):string{
    return (status.user_id ==null ?(!this.validationService.isNullOrEmpty(status.device_status_option_configuration?.custom_name??"")?(status.device_status_option_configuration?.custom_name??''):(status.name??'')):status.name??'');
  }
  isLastRecordSelected(status:IDeviceStatusOption){
    return this.lastDeviceStatusRecordSelected.id != 0 && this.lastDeviceStatusRecordSelected.device_status_option_id == status.id; 
  }
  /**
   * @description Obtiene el último registro que tiene el dispositivo asignado
   */
  getLastDeviceStatusRecord(){
    this.loading = true;
    let data:IApiRequestData = { user_api_hash:this.user.hash, action:"getLastDeviceStatusRecord",device_id: this.currentDrivenVehicle.id };
    this.api.getDataPost("deviceStatusRecord",data,true).then((data: unknown)=>{
      if(this.validationService.isResponseApi(data)){
        this.lastDeviceStatusRecordSelected = data.status == 1 ? data.data as IDeviceStatusRecord : this.objectInitializationService.initializeIDeviceStatusRecord(0,this.user.id,this.currentDrivenVehicle.id);
      }
    }).catch((error:any)=>{
      this.utils.hideLoading(()=>this.loading = false);
      this.lastDeviceStatusRecordSelected = this.objectInitializationService.initializeIDeviceStatusRecord(0,this.user.id,0);
      this.utils.showMsg("","Ha surgido un error mientras se intentaba obtener el último registro de estado");
    });
  }
  async getAvailableDevicesBasicDataList():Promise<void>{
    let response:unknown = await this.dataService.getAvailableDevicesBasicDataList();
    if(this.validationService.isResponseApi(response)){
      this.availableDevicesList = response.data  as IDeviceBasicData[];
      this.availableDeviceListFiltered = this.utils.copyObject(this.availableDevicesList);
    }
  }
}