import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { ManagerDevicesListComponent } from "../../shared/components/manager-devices-list/manager-devices-list.component";
import { UserDevicesListComponent } from "../../shared/components/user-devices-list/user-devices-list.component";

import { Router } from '@angular/router';

@Component({
  selector: 'app-my-gps',
  templateUrl: './my-gps.component.html',
  styleUrls: ['./my-gps.component.scss']
})
export class MyGpsComponent implements OnInit{ 
  moduleId:number=5;
  permissionsDataSgc:any=[];//permisos sobre el modulo
  permissionsDataIntgps:any=[]//permisos INTGPS
  userRole:any={};
  constructor(
    public dataService: DataService,
    public utils:UtilsService,
    private route:Router) {}

  ngOnInit(): void {
    this.dataService.checkPermissionIntgpsModule('devices').then((permissions: any) => {
      //this.permissionsDataIntgps = permissions;
      this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
        //this.permissionsDataSgc = permissions;
        this.userRole = this.dataService.getData("user").role_id;
      }).catch(() => {
        this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
        this.route.navigate(['/']);
      });
    }).catch((error:any) => {
      this.utils.showMsg("Página no autorizada","Tu cuenta no tiene permisos para ver esta página, contacte al administrador");
      this.route.navigate(['/']);
    });
  }
}