<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['device_list'].show_view">
  <section class="form-box-container__header">
      <span *ngIf="isSelectedUser" class="form-box-container__header-back" (click)="back()"><span class="description">Volver</span> </span>
      <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-list-check"></i></span>Administracion de dispositivos</h2>
      <div  class="form-box-container__header-options">                     
        <ng-container *ngIf="!isSelectedUser">
          <button matTooltip="crear dispositivo" class="btn btn-primary" (click)="openCreateDeviceModal()"><i class="fa-solid fa-plus icon-plus icon"></i><span class="description"> Crear</span></button>
          <button matTooltip="Ver dispositivos por última conexión" class="btn btn-outline-primary" (click)="utils.setSelectedView(viewsAvailable,viewsAvailable['offline_devices'].name)"><i class="fa-solid fa-plug-circle-xmark icon"></i> <span class="description"> Desconexiones</span></button>
          <button matTooltip="Exportar datos" class="btn btn-outline-secondary" (click)="exportFile()"><i class="fa-solid fa-file-export icon"></i><span class="description"> Exportar</span></button>
          <div class="search">
              <input id="inputSearch" type="search" placeholder="Buscar" (input)="searchValueInData()" [(ngModel)]="searchValue">
              <label for="inputsearch"><i class="fa-solid fa-magnifying-glass"></i></label>
          </div>
        </ng-container>
        <button  matTooltip="Habilitar certificación a dispositivos" *ngIf="isSelectedUser && !certificationEnabled" class="btn btn-primary animate__animated animate__fadeInDown" (click)="enableCertificate()" ><i class="fa-solid fa-file-signature"></i><span class="description"> Certificados</span></button>
      </div>
  </section>
  <div class="form-box animate__animated animate__fadeIn">
    <div class="form-box__content form-box__content-h-auto">
      <div class="w100 overflow-auto">
        <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="devices">
            
          <ng-container matColumnDef="certificate">
            <th mat-header-cell *matHeaderCellDef >
              <mat-checkbox color="primary"
              [matTooltip]="checkboxLabel()"
              (change)="$event ? toggleAllRows() : null"
              [checked]="devicesToGenerateCertificates.hasValue() && isAllSelected()"
              [indeterminate]="devicesToGenerateCertificates.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()">
              </mat-checkbox> 
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox color="primary"
              [matTooltip]="checkboxLabel(row)"
              matTooltipPosition="right"
              (click)="$event.stopPropagation()"
              (change)="$event ? devicesToGenerateCertificates.toggle(row.id) : null"
              [checked]="devicesToGenerateCertificates.isSelected(row.id)"
              [aria-label]="checkboxLabel(row.id)">
              </mat-checkbox>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de activación"> Activo </th>
            <td mat-cell *matCellDef="let device"> 
                <mat-slide-toggle
                [matTooltip]="toggleMessageActive(device.active)"
                    [checked]="isActive(device.active)"
                    color="primary"
                    class="z-index-last"
                    (change)="statusChange($event,device)">
                </mat-slide-toggle>    
            </td>
          </ng-container>
          <ng-container matColumnDef="plate_number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por número de placa"> Placa </th>
            <td mat-cell *matCellDef="let device"> {{device.plate_number}} </td>
          </ng-container>
          <ng-container matColumnDef="device_model">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha de modelo"> GPS </th>
            <td mat-cell *matCellDef="let device"> {{device.device_model}} </td>
          </ng-container>
          <ng-container matColumnDef="sim_number">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha numero de SIM"> Nro. SIM </th>
            <td mat-cell *matCellDef="let device"> {{device.sim_number}} </td>
          </ng-container>
          <ng-container matColumnDef="responsible_name">
            <th mat-header-cell *matHeaderCellDef > Nombre encargado </th>
            <td mat-cell *matCellDef="let device">
              <span class="d-flex align-items-center justify-content-start flex-wrap">
                <mat-icon aria-hidden="false" *ngIf="device.responsible_user.id != null"  class="text-info" [matTooltip]="device.responsible_user.email" aria-label="email de usuario encargado" fontIcon="info"></mat-icon>
                <span>
                  {{ device.responsible_user.name != '' ? device.responsible_user.name : device.responsible_user.email  }}</span>
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="phone_number">
            <th mat-header-cell *matHeaderCellDef > Contacto encargado </th>
            <td mat-cell *matCellDef="let device"> {{device.responsible_user.phone_number != '' && device.responsible_user.phone_number != null && device.responsible_user.phone_number != 'null' ? device.responsible_user.phone_number : '' }} </td>
          </ng-container>
          <ng-container matColumnDef="imei">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por imei"> Imei </th>
            <td mat-cell *matCellDef="let device"> {{device.imei}} </td>
          </ng-container>
          <ng-container matColumnDef="expiration_date">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por fecha de expiración"> Fecha de expiración </th>
            <td mat-cell *matCellDef="let device"> {{device.expiration_date | date:"medium"}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Ordenar por estado de datos" disabled=""> Estado </th>
            <td mat-cell *matCellDef="let device" class="text-center"> 
                <div class="status complete" *ngIf="isDataSGCComplete(device)" matTooltip="datos completos"><i class="fa-solid fa-circle-check"></i></div>
                <div class="status incomplete" *ngIf="!isDataSGCComplete(device)" matTooltip="datos incompletos"><i class="fa-solid fa-circle-xmark"></i></div>    
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disabled class="mat-header-center"> Acciones </th>
            <td mat-cell *matCellDef="let device" class="text-center"> 
              <div class="actions-container">
                <button mat-button [matMenuTriggerFor]="actions"><i class="fa-solid fa-gear"></i></button>
                <mat-menu #actions="matMenu">
                    <button class="text-primary" mat-menu-item matTooltipPosition="left" matTooltip="Ver próximas facturaciones" *ngIf="dataService.checkPermission(moduleSpecificPermissions['seePaymentProjections'],this.permissionsDataSgc.permissions)" [disabled]="!hasBillingPlan(device)" (click)="previewOfUpcomingInvoices(device)"><i class="fa-solid fa-file-invoice-dollar fa-sm"></i> Próximas facturaciones</button>
                    <button class="text-primary" mat-menu-item matTooltipPosition="left" matTooltip="Editar dispositivo" *ngIf="dataService.checkPermissionIntgps('edit', permissionsDataIntgps)"(click)="openUpdateDeviceModal(device)"><i class="fa-solid fa-pen-to-square fa-sm"></i> Editar</button>
                    <button class="text-primary" mat-menu-item matTooltipPosition="left" matTooltip="Eliminar dispositivo" *ngIf="dataService.checkPermissionIntgps('remove', permissionsDataIntgps)" (click)="deleteDevice(device)"><i class="fa-solid fa-trash-can fa-sm"></i> Eliminar</button>
                </mat-menu>
              </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr *matNoDataRow>
            <td class="mat-cell empty py-1" [attr.colspan]="displayedColumns.length">Sin datos</td>
        </tr>
        </table>
      </div>
      <mat-paginator [length]="pagination.length_page" [pageSize]="pagination.page_size" [pageIndex]="pagination.current_page"
        [pageSizeOptions]="pagination.page_size_options" (page)="paginator($event)"
        aria-label="Selecciona pagina de la lista de dispositivos">
      </mat-paginator>
    </div>
  </div>
  <div class="loader" *ngIf="loading">
      <img src="../../../../assets/loader.svg">
  </div>
</section>
<app-device-form *ngIf="viewsAvailable['form'].show_view" [newDevice]="newDevice" [usersList]="usersList" [isUpdateDevice]="isUpdateDevice" (close)="closeModal($event)"></app-device-form>
<app-disconnected-devices-list *ngIf="viewsAvailable['offline_devices'].show_view" (close)="utils.setSelectedView(this.viewsAvailable,this.viewsAvailable['device_list'].name)"></app-disconnected-devices-list>


<button  mat-mini-fab 
  *ngIf="isSelectedUser && certificationEnabled"
  class="floating-button animate__animated" color="primary"
  [ngClass]="{'animate__backInUp':isSelectedUser && certificationEnabled,'animate__backOutRight':!certificationEnabled && isSelectedUser}"   
  [matBadge]="devicesToGenerateCertificates.selected.length" 
  matBadgePosition="before"
  matBadgeColor="warn"
  [matMenuTriggerFor]="menuCertification"
  aria-label="Certificación de dispositivos" >
  <mat-icon class="text-white">description</mat-icon>
</button>

<mat-menu #menuCertification="matMenu">
  <mat-form-field appearance="fill" style="display:none;">
    <input matInput [min]="utils.getCurrentDate()" [(ngModel)]="dateSelectedForCertificate" (dateInput)="generateCertificate($event)" [matDatepicker]="certificateValidityDatePicker">
    <mat-datepicker-toggle matIconSuffix [for]="certificateValidityDatePicker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #certificateValidityDatePicker></mat-datepicker>
  </mat-form-field>
    <button mat-menu-item class="btn-descriptive text-primary" [disabled]="devicesToGenerateCertificates.selected.length<1" (click)="showCertificateValidityDatePicker(certificateValidityDatePicker)" matTooltipPosition="left"matTooltip="Generar certificado a dispositivos"><i class="fa-solid fa-file-circle-check"></i> <span class="description">Generar</span></button>
    <button mat-menu-item class="btn-descriptive text-secondary"  (click)="cancelCertification()" matTooltipPosition="left" matTooltip="Cancelar operación de certificación"><i class="fa-regular fa-circle-xmark"></i><span class="description">Cancelar</span></button>
</mat-menu>