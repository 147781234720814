import { Component,OnInit,Inject } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IDeviceChecklistQuestionCategory } from 'src/app/core/models/interfaces/idevice-checklist.model';
import { ValidationService } from '../../../../../core/services/validation/validation.service';
import { IUserSessionData } from 'src/app/core/models/interfaces/iuser-session-data.model';
import { DataService } from '../../../../../core/services/data/data.service';
import { ApiService } from '../../../../../core/services/api/api.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { IApiRequestData } from '../../../../../shared/models/interfaces/iapi-request-data.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-device-checklist-question-category',
  templateUrl: './device-checklist-question-category.component.html',
  styleUrls: ['./device-checklist-question-category.component.scss']
})
export class DeviceChecklistQuestionCategoryComponent implements OnInit {
  categoryList:IDeviceChecklistQuestionCategory[]=[];
  loading:boolean=false;
  /** Indica si la lista de categorias ha sido actualizada */
  updatedList:boolean=false;

  user!:IUserSessionData;

  newCategoryName:string = "";
  maxNameLength = 100;
  moduleId:number =18;
  constructor(
    private router:Router,
    private utils: UtilsService,
    private api:ApiService,
    private dataService:DataService,
    public validationService:ValidationService,
    @Inject(MAT_DIALOG_DATA) public data:IDeviceChecklistQuestionCategory[]
  ){
    this.user = this.dataService.getData("user") as IUserSessionData;
    this.categoryList = data;
  }
  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
    }).catch(():void => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/dashboard/fleet-control']);
    });
  }
  deleteCategory(category:IDeviceChecklistQuestionCategory){
    this.utils.showConfirm("¿Eliminar categoría?","","Confirmar","Cancelar").then(()=>{      
      this.api.createData({ user_api_hash:this.user.hash, id:category.id,action:"delete"}, "deviceChecklistQuestionCategory",true).then((response:any)=>{
        this.utils.showResultRequest(response.status==1?"success":"error","Información",response.message);
        if(response.status){
          this.categoryList.splice(this.categoryList.findIndex((item:IDeviceChecklistQuestionCategory)=>item.id==category.id),1);
          this.updatedList = true;
        }
        this.utils.hideLoading(()=>this.loading=false);
      }).catch((error:any)=>{
        this.utils.hideLoading(()=>this.loading=false);
        this.utils.showResultRequest("error","Información",this.api.getDefaultMessage( "la categoría",false,false,"DELETE"));  
      });  
    }).catch(()=>{ });
  }
  /** @description Agrega o modifica los nombres de categorias existentes pertenecientes al usuario */
  async saveCategoryChanges(category?:IDeviceChecklistQuestionCategory,isUpdate:boolean=true){
    this.loading = true;
    try{
      let data: IApiRequestData = { user_api_hash:this.user.hash, action:isUpdate?"update":"add"};
      if(isUpdate)
        data = {...data,...category};
      else
        data["name"] =  this.newCategoryName;
      if(this.validationService.isNullOrEmpty(data["name"])){
        this.utils.hideLoading(()=>this.loading = false);
        return this.utils.showResultRequest("error","Información","Debe de indicar un nombre de categoría");
      }
      let response:any = await this.api.createData(data, "deviceChecklistQuestionCategory",true);
      this.utils.showResultRequest(response.status==1?"success":"error","Información",response.message);
        if(!isUpdate && response.status){
          this.categoryList.push({id:response.data,name:this.newCategoryName,user_id:this.user.id,created_at:"",updated_at:""});
          this.newCategoryName = "";
          this.updatedList = true;
        }
    }catch(error:any){
      this.utils.showResultRequest("error","Información",this.api.getDefaultMessage( "La categoría",isUpdate,false,"POST"));
    }finally{
      this.utils.hideLoading(()=>this.loading=false);
    }
  }
}