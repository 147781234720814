<section class="form-box-container animate__animated animate__fadeIn" *ngIf="viewsAvailable['form'].show_view">
    <section class="form-box-container__header">
        <span class="form-box-container__header-back" (click)="closeModal()"><span class="description">Volver</span> </span>
        <h2 class="form-box-container__header-title"><span class="icon"><i class="fa-solid fa-microchip"></i></span>{{ !isUpdateDevice ? 'Nuevo dispositivo' : (newDevice.plate_number || (newDevice.name|lowercase)) }}</h2>
    <div class="form-box-container__header-options">
        <button class="btn btn-outline-primary" *ngIf="isUpdateDevice" (click)="utils.setSelectedView(viewsAvailable,viewsAvailable['device_notes'].name)" matTooltip="Notas sobre el dispositivo"><i class="fa-solid fa-book icon"></i><span class="description"> Notas</span> </button>
    </div>
    </section>
    <div class="form-box  animate__animated animate__fadeInRight">
        <form enctype="multipart/form-data" class="form-box__content form-box__content-h-auto" (submit)="adminDevice($event,isUpdateDevice)">
            <mat-tab-group>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="text-info">description</mat-icon>
                    Datos básicos
                  </ng-template>
                  <div  class="form-box__content-inputs">
                      <div  class="form-box__content-group-fields mt-2">
                        <mat-form-field id="inputdeviceUsers" appearance="fill"class="animate__animated animate__faster" >
                            <mat-label>Usuarios</mat-label>    
                            <mat-select (closed)="clearUserSearchFilter()" [multiple]="true" name="deviceUsers" [(ngModel)]="deviceUsers" [matTooltip]="printDeviceUsers()"[compareWith]="compareDeviceUsersSelected" (selectionChange)="assignResponsibleList($event)">
                                <mat-option>
                                    <ngx-mat-select-search [hideClearSearchButton]="true"   [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar cliente..." [(ngModel)]="emailToSearch" name="search_email" (keyup)="searchUserEmail()" >
                                        <mat-icon (click)="clearUserSearchFilter()" ngxMatSelectSearchClear>close</mat-icon>
                                    </ngx-mat-select-search>
                                    </mat-option>
                                <mat-option *ngFor="let user of usersListFilter" [value]="user"  >{{user.email}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field id="inputResposible" appearance="fill">
                            <mat-label>Responsable</mat-label>
                            <mat-select (closed)="clearSearchResponsibleEmail()" [(ngModel)]="newDevice.responsible_user!.id"name="responsible_user" placeholder="Buscar..." (selectionChange)="selectResponsibleUser($event)">
                                <mat-option>
                                <ngx-mat-select-search [noEntriesFoundLabel]="'Sin resultados'" placeholderLabel="Buscar cliente..." [(ngModel)]="responsableEmailToSearch" name="responsableEmailToSearch" (keyup)="searchResponsibleEmail()">
                                    <mat-icon (click)="clearSearchResponsibleEmail()" ngxMatSelectSearchClear>close</mat-icon>
                                </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let potentialResponsible of potentialResponsiblesListFilter" [value]="potentialResponsible.id" color="primary">
                                {{potentialResponsible.email}} <small *ngIf="!utils.isNullOrEmpty(potentialResponsible.name)">{{" - "+potentialResponsible.name}}</small>                   
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="text-info" matTooltip="Selecciona un único usuario a quien se le generará la facturación por el dispositivo">help</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Nombre</mat-label>
                            <input matInput type="text" name="name" [(ngModel)]="newDevice.name"  matTooltip="Campo obligatorio" required="">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Placa</mat-label>
                            <input matInput spellcheck="false" type="text" name="plate_number" [(ngModel)]="newDevice.plate_number">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Imei</mat-label>
                            <input matInput spellcheck="false" type="text" name="imei" [(ngModel)]="newDevice.imei"  matTooltip="Campo obligatorio"  required="">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Tipo de vehículo</mat-label>
                            <mat-select  name="vehicle_type" [(ngModel)]="newDevice.vehicle_type">
                            <mat-option *ngFor="let vehicleType of vehicleTypeList" [value]="vehicleType.id"  color="primary">{{vehicleType.name}}</mat-option>                      
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Marca del vehículo</mat-label>
                            <input matInput spellcheck="false" type="text" name="vehicle_brand" [(ngModel)]="newDevice.vehicle_brand" >
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Modelo del vehículo</mat-label>
                            <input matInput spellcheck="false" type="text" name="vehicle_model" [(ngModel)]="newDevice.vehicle_model" >
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Cilindraje</mat-label>
                            <input matInput spellcheck="false" type="text" name="cylinder_capacity" [(ngModel)]="newDevice.cylinder_capacity" >
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Color del vehículo</mat-label>
                            <mat-select  name="vehicle_color" [(ngModel)]="newDevice.vehicle_color">
                            <mat-option *ngFor="let color of dataService.vehicleColors" [value]="color.name"  color="primary">{{color.name}}</mat-option>                      
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Capacidad de carga útil (toneladas)</mat-label>

                            <input type="number"
                                   name="inputLoadCapacityInTons"
                                   placeholder="Capacidad de carga"
                                   aria-label="capacidad de carga"
                                   [max]="maxVehicleLoadCapacityInTons"
                                   min="0"
                                   (keydown)="!isAValidPositiveNumber($event) && $event.key !=='Backspace'?$event.preventDefault():null"
                                   matInput
                                   [(ngModel)]="newDevice.payload_capacity_in_tons"
                                   value="{{111}}"
                                   (input)="filterVehicleLoadCapacityInTons()"
                                   [matAutocomplete]="matAutocompleteLoadCapacityInTons">
                            <mat-autocomplete  #matAutocompleteLoadCapacityInTons="matAutocomplete">
                              <mat-option *ngFor="let option of filteredvehicleLoadCapacityInTons" [value]="option">
                                {{option + " tonelada"+(option ==1 ?"":"s")}}
                              </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Remolque</mat-label>
                            <input matInput spellcheck="false" type="text" name="trailer_plate_number" [(ngModel)]="newDevice.trailer_plate_number" >
                        </mat-form-field>

                        <mat-form-field appearance="fill"  *ngIf="false">
                            <mat-label>Nombre del propietario</mat-label>
                            <input matInput spellcheck="false" type="text" name="object_owner" [(ngModel)]="newDevice.object_owner" >
                        </mat-form-field>

                        <mat-form-field appearance="fill"  *ngIf="false">
                            <mat-label>telefono propietario</mat-label>
                            <input matInput spellcheck="false" type="text" inputmode="numeric" name="owner_phone" [(ngModel)]="newDevice.owner_phone" >
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Número Simcard GPS</mat-label>
                            <input matInput spellcheck="false" type="text" name="sim_number" [(ngModel)]="newDevice.sim_number" >
                        </mat-form-field>


                        <mat-form-field appearance="fill">
                            <mat-label>Operador Móvil</mat-label>
                            <mat-select  name="telephone_operator" [(ngModel)]="newDevice.telephone_operator_id">
                                <mat-option *ngFor="let operator of telephoneOperatorsList" [value]="operator.id"  color="primary">{{operator.name}}</mat-option>                      
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Marca GPS</mat-label>
                            <mat-select  name="device_model" [(ngModel)]="newDevice.device_model">
                                <mat-option *ngFor="let model of deviceModelList" [value]="model.name"  color="primary">{{model.name}}</mat-option>                      
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Fecha de garantía</mat-label>
                            <input matInput [matDatepicker]="picker" (click)="picker.open();$event.preventDefault()" name="warranty_date"  [(ngModel)]="newDevice.warranty_date" readonly="">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker disabled="false"></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="fill" *ngIf="isUpdateDevice">
                            <mat-label>Fecha de expiración</mat-label>
                            <input matInput spellcheck="false" disabled="" type="text" [value]="newDevice.expiration_date" >
                        </mat-form-field>
                      </div>
                  </div>
                </mat-tab>
              
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="text-success">monetization_on</mat-icon>
                    Plan de facturación
                  </ng-template>
                  <div  class="form-box__content-inputs">
                    <div  class="form-box__content-group-fields mt-2">
                        <section class="d-block mx-0 alert alert-light-info fill">
                            <mat-checkbox class="fill" value="1" color="primary" name="personalized_plan_disabled" [(ngModel)]="newDevice.personalized_plan!.disabled">Inactivar plan</mat-checkbox>
                            <p class="text-small m-0">Si inactiva el plan, entonces no se generará facturas automáticas para el dispositivo</p>
                        </section>
                        <mat-form-field appearance="fill">
                            <mat-label>Precio</mat-label>
                            <input matInput type="number" name="price" [(ngModel)]="newDevice.personalized_plan!.unit_price" min="0" (mousewheel)="$event.preventDefault()">
                            <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>Tipo de moneda</mat-label>
                            <mat-select  [(ngModel)]="newDevice.personalized_plan!.monetary_unit_id" name="monetaryUnitId">
                            <mat-option *ngFor="let item of monetaryUnitsList" [value]="item.id"  color="primary">{{item.code+' - '+item.currency }}</mat-option>                      
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>Frecuencia de pago</mat-label>
                            <mat-select name="personalized_plan[billing_frequency_id]" [(ngModel)]="newDevice.personalized_plan!.billing_frequency_id" >
                            <mat-option *ngFor="let item of billingFrequenciesList"[value]="item.id"  color="primary">{{item.name}}</mat-option>                      
                            </mat-select>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>Día de facturación</mat-label>
                            <input 
                            matTooltip="Indique el dia del mes en el cual se realizará la facturación"
                            matInput type="number" [(ngModel)]="newDevice.personalized_plan!.payday" name="personalized_plan[payday]"[value]="newDevice.personalized_plan!.payday||''" min="1"max="31" (mousewheel)="$event.preventDefault()">
                        </mat-form-field>
    
    
                        <mat-form-field appearance="fill">
                            <mat-label>Primer mes de facturación</mat-label>
                            <input [min]="configFirstBillingDate.min_value"  matInput [matDatepicker]="datepickerFirstBillingDate" name="first_billing_date" [(ngModel)]="configFirstBillingDate.value" disabled >
                        
                            <mat-datepicker-toggle matSuffix [for]="datepickerFirstBillingDate"></mat-datepicker-toggle>
                            <mat-datepicker #datepickerFirstBillingDate
                                            startView="year"
                                            disabled="false"
                                            (monthSelected)="setFirstBillingMonth($event, datepickerFirstBillingDate)">
                            </mat-datepicker>
                            <mat-hint>Solo se toma el mes para la referencia de la primera factura</mat-hint>
                        </mat-form-field> 
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            <div class="form-box__content-actions">
                <input 
                [disabled]="loading" 
                type="submit" 
                class="btn btn-primary" 
                [value]="isUpdateDevice?'Actualizar':'Registrar'">
                <button class="btn btn-outline-secondary"  (click)="closeModal()">cancelar</button>
            </div>
        </form>
    </div>
</section>
<app-device-notes [deviceId]="deviceIdToAddNote" (close)="utils.setSelectedView(viewsAvailable,viewsAvailable['form'].name)" *ngIf="viewsAvailable['device_notes'].show_view" ></app-device-notes>
<div class="loader" *ngIf="loading">
    <img src="../../../assets/loader.svg">
</div>