export enum ApiEndpointSgc {
    DEVICE = "device",
    USER = "user",
    DEVICE_CHECKLIST = "deviceChecklist",
    DEVICE_CHECKLIST_ANSWERED = "deviceChecklistAnswered",
    DRIVER = "driver",

    AUTOMATION = "automation",
    AUTOMATION_TYPE = "automationType",
    AUTOMATION_TARGET_USER = "automationTargetUser",
    DEVICE_GROUP_INTGPS = "deviceGroupIntgps",
    DEVICE_GROUP_WEB_SERVICE = "deviceGroupWebService",

    USER_MESSAGING_SERVICE_API_PHONE_LINE = "userMessagingServiceApiPhoneLine",
    USER_MESSAGING_SERVICE_API_CREDENTIAL = "userMessagingServiceApiCredential",
    MESSAGING_SERVICE_API = "messagingServiceApi",

    INVOICE_NOTE = "invoiceNote",
    REPORT = "report",

    PERSONALIZED_PLAN = "personalizedPlan",
    EXPORT = "export",
    CONFIGS = "configs"
};