import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'src/app/core/services/api/api.service';
import { DataService } from 'src/app/core/services/data/data.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { Router } from '@angular/router';

import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentNotification } from "../../../../shared/models/classes/payment-notification.model";
import { CommonConstant } from 'src/app/core/constant/common-constant';
@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit {
  //clientIdPaypal:string="AfpiVY1wYkrm88GJ6hj069HykMapdpwdtnwfNtkoldFc6oFBfTxyWph-p_QKMxH_d5_hbu-rsFc8qdh9";
  clientIdPaypal:string=environment.clientIdPaypal;

  user:any={};
  displayedColumnsInDataSummary: string[] = ["index","imei","name","plate_number","plate_number","expiration_date","actions"];
  devices: any = [];//lista de dispositivos a pagar
  paidDevices: any = {status:false,data:{}};//indica, cuando se cierre el dialogo si se ha realizado el pago, esto para actualizar la vista de tabla 
  planDetails:any=[];//detalles del plan a usar para facturar al cliente

  panelOpenState:boolean=false;//indica el acordeon del lateral derecho de template que se va a mostrar
  //itemsList:any=[];//almacena los items a pagar. en este caso almacena un item que es los dispositivos a pagar
  
  loading:boolean=false;//para mostrar el laoding cuando se esten cargando datos
  public payPalConfig?: IPayPalConfig;


  paypalCommission  = 0.06;
  refacilCommission  = 0.06;

  //propiedades para la vista cuando se pague
  amount:any;
  paidItems:any;

  moduleId=6;
  permissionsDataSgc:any=[];
  constructor(
    private api: ApiService,
    public utils: UtilsService,
    public dataService: DataService,
    private spinner:  NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private paymentNotification:PaymentNotification,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.dataService.checkPermissionModule(this.moduleId).then((permissions: any) => {
      this.permissionsDataSgc = permissions;
      this.user=this.dataService.getData("user");
      this.devices = this.data.devicesToPay;
      this.getPlanDetails();
    }).catch(() => {
      this.utils.showMsg("Página no autorizada","No tiene permisos para ver esta página, contacte al administrador");
      this.router.navigate(['/']);
    });

  }
  
  private initConfig(): void {
    this.payPalConfig = {
      currency: this.getPaymentCurrencyForPaypal(),
      clientId: this.clientIdPaypal,
      createOrderOnClient: (data:any) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: this.getPaymentCurrencyForPaypal(),
            value: this.getTotalToPay().toString(),
            breakdown: {
              item_total: {
                currency_code: this.getPaymentCurrencyForPaypal(),
                value: this.getTotalToPay().toString()
              }
            }
          },
          //custom_id:this.getdevicesIdentifiers(),//length 256
          description:"distributor_"+this.user.id,
          items: this.getItemsList()
        }],
        application_context:{
          shipping_preference:"NO_SHIPPING"
        }
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        
        this.spinner.show();
        //console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details: any) => {
          //console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
        actions.order.capture();
      },
      onClientAuthorization: (data) => {
        this.createBilling({payment:data,payment_option: CommonConstant.PAYMENT_OPTIONS.PAYPAL.VALUE});
        this.amount=data.purchase_units[0].amount.value;
        this.paidItems= this.cartSummaryExists;
        this.paidDevices.status=true;
        this.spinner.hide();
      },
      onCancel: (data, actions) => {
        //cuando cerramos la ventana de paypal sin haber realizado el pago
        //console.log('OnCancel', data, actions);
      },
      onError: err => {
        //cuando falle algo en el proceso de pago
        //console.log('OnError', err);
        this.utils.showMsg("Información","Al parecer la opción de pago de Paypal esta presentando problemas. Puede intentar realizar el pago con esta opción más tarde.<p class='text-small'>Si sigue experimentando problemas comuniquese con el administrador</p>");
      },
      onClick: (data, actions) => {
        //cuando damos click en e lboton de paypal
        //console.log('onClick', data, actions);
      }
    };
  }

  getPlanDetails(){
    this.showLoading(true);
    let data={
      "user_api_hash":this.user.hash,
      "action":"getPlanDetails"
    };

    this.api.getDataPost("user",data,true).then((data: any) => {
      
      if(data.status==1){
        this.planDetails=data.plan;
        //this.itemsList =this.getItemsList();
        if(this.isCurrencyUSD()){
          this.initConfig();//configuracion para paypal
        }
      }
      this.showLoading(false);
    }).catch((error)=>{});
  }
  //cuando es option paypal se recibe una propiedad llamada payment con datos de la orden de pago. el otro campo es payment_option
  createBilling(data:any){
    data["user_api_hash"]=this.user.hash;
    data["action"]="generateInvoice";
    data["devices"]= [];

    for(let device of this.devices){
      data["devices"].push({id:device.id,imei:device.imei});
    }
    this.api.createData(data, "invoice",true).then((response: any) => {
      if(response.status==1){
        if(this.paymentNotification.supportsNotifications()){
          let invoiceToConsultStatus:any = this.dataService.getData("checkStatusPayment");
          let reference = (data.payment_option==CommonConstant.PAYMENT_OPTIONS.PAYPAL.VALUE)?data.payment.id:response.reference;
          invoiceToConsultStatus["order_"+reference]={
           reference:reference,
           payment_option:data.payment_option,
           intervalId:null,
           action:"checkInvoiceStatus",
           paymentConfirmed:false
         };
          if(Notification.permission === 'granted'){
            this.paymentNotification.validateInvoiceStatus(invoiceToConsultStatus["order_"+reference]);
          }else{
            Notification.requestPermission().then((permission:any)=>{
              if (permission === "granted"){
                this.paymentNotification.validateInvoiceStatus(invoiceToConsultStatus["order_"+reference]);                
              }
            });
          }
        }
        this.openSnackBar("Los cambios se verán reflejados una vez "+(data.payment_option == CommonConstant.PAYMENT_OPTIONS.PAYPAL.VALUE?"PayPal":"el servicio de pago")+" nos confirme su transacción.", "ok",18000);
        if(data.payment_option == CommonConstant.PAYMENT_OPTIONS.REFACIL.VALUE){
          this.showLoading(false);
          setTimeout(() => {
            window.location.assign(response.url);            
          }, 3000);
        }
      } else {
        this.showLoading(false);
        this.utils.showMsg("Información sobre facturación",response.message||"No se pudo realizar la facturación correctamente");
      }
    }).catch((err: any) => {
      this.showLoading(false);
      this.utils.showMsg("","Se ha presentado un error al crear la factura en SGC");
    });
  }
  getdevicesIdentifiers(){

    let devicesIds="";
    if(this.devices.length>0){
      let index:any;
      for(index in this.devices){
          devicesIds +=this.devices[index].imei;
          if(parseInt(index)<this.devices.length-1)
          devicesIds +="|";
      }
    }
    return devicesIds; 
  }

  cartSummaryExists(){
    return (this.devices.length>0 && Object.entries(this.planDetails).length>0)
  }
  getCartSummary(){
    let devicesToPay:any={};
    if(this.cartSummaryExists()){
      devicesToPay ={
        name:"Licencia ("+this.planDetails.billing_frequency_name+") - Dispositivo GPS",
        category:"DIGITAL_GOODS",
        quantity:(this.devices.length).toString(),
        unit_amount:{
          currency_code:this.getPaymentCurrencyForPaypal(),
          value:(this.planDetails.unit_price).toString()
        },
        commission:0
      };
      devicesToPay.commission = (parseInt(devicesToPay.quantity)*parseInt(devicesToPay.unit_amount.value))*(this.isCurrencyUSD()?this.paypalCommission:(this.isCurrencyCOP()?this.refacilCommission:0));
    }
    return devicesToPay;
  }

  //para indicar los items a enviar en el pago con paypal
  getItemsList(){
    let itemsToPay:any=[];

    if(this.devices.length>0 && Object.entries(this.planDetails).length>0){
      for(let item of this.devices){
        let priceToPay:number = parseInt(this.planDetails.unit_price);
        priceToPay += parseFloat((this.planDetails.unit_price*this.paypalCommission).toFixed(2));
        const devicesToPay:any ={
          name:"Licencia ("+this.planDetails.billing_frequency_name+")  para dispositivo (imei) "+item.imei,
          category:"DIGITAL_GOODS",
          quantity:"1",
          description:"device_imei: "+item.imei,
          unit_amount:{
            currency_code:this.getPaymentCurrencyForPaypal(),
            value: priceToPay.toString()
          }
        };
        itemsToPay.push(devicesToPay);
      }
      
    }
    return itemsToPay;
  }
  getTotalToPay(){
    let items = this.getItemsList();
    let totalToPay = 0;
    for(let item of items){
      totalToPay += Number(item.unit_amount.value);
    }
    return totalToPay;
  }
  //metodo para obtener la moneda de pago. Esta moneda La utilizaremos para indicar cuanto va a pagar el cliente como tal
  getPaymentCurrencyForPaypal(){
    return this.planDetails.monetary_unit;
  }

  isCurrencyUSD(){return "USD"==this.getPaymentCurrencyForPaypal();}
  isCurrencyCOP(){return "COP"==this.getPaymentCurrencyForPaypal();}
  
  processNationalPayment(){
    let summary = this.getCartSummary();
    let cost = ((summary.unit_amount.value*summary.quantity)*this.refacilCommission).toLocaleString();//6% sobre valor a pagar. este se agregara desde base de datos para hacerlo administrable
    this.utils.showConfirm("¿Confirmar la acción de pago?", 
    `<span class='text-small'>Ten encuenta lo siguiente:<ul class='text-start'>
    <li class='text-small mt-1 mb-1'>El costo de la transacción tiene un valor adicional de $ `+cost+" "+summary.unit_amount.currency_code+`</li>
    <li class='text-small'>Una vez se confirme la transacción a traves del servicio de pagos, se realizará la actualización de los dispositivos</li>
    </ul> </span>`, "Confirmar", "Cancelar").then(() => {
      this.showLoading(true);
      let dataToSend = {payment_option: CommonConstant.PAYMENT_OPTIONS.REFACIL.VALUE};
      this.createBilling(dataToSend);
    }).catch((error:any)=>{});
  }
  removeFromTheListToPay(device: any) {
    let deviceIndex = this.devices.findIndex((item: any) => item.id == device.id);
    if (deviceIndex > -1)
      this.devices.splice(deviceIndex, 1);
  }
  showLoading(show: boolean = true):void{
    if(show) this.loading = true;
    else setTimeout(() => { this.loading = false; }, 500);
  }
  
  openSnackBar(message: string, action: string,duration:number=5000) {
    this._snackBar.open(message, action,{
      duration: duration
    });
  }
}